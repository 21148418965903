import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { machinie_image_url } from "../../machinieImageUrl2";

import {
  IconContentCopy,
  IconEdit,
  IconCard,
  IconReceipt,
} from "../component/Icon";
import { MouseEnterPanel } from "./MouseEnterPanel";
import { EditProfileModal } from "./EditProfileModal";
import { RedeemModal } from "./RedeemModal";
import { SELECT_REDEEM } from "../../value";
import { SendGiftModal } from "./SendGiftModal";
import { ActivityModal } from "./ActivityPanel";

type PROFILE_PANEL_PROPS_TYPES = {
  walletList: any;
  walletAddress: string;
  floppy: string;
  memberInfo: any;
  setMemberInfo: any;
  isMainnet: string;
  setWalletList: any;
  setToggleProfile: any;
};

export const ProfilePanel = ({
  walletList,
  walletAddress,
  setWalletList,
  floppy,
  memberInfo,
  setMemberInfo,
  isMainnet,
  setToggleProfile,
}: PROFILE_PANEL_PROPS_TYPES) => {
  const my_machinie: string = `${process.env.PUBLIC_URL}/assets/machinie/machinie_my_machinie.png`;
  const my_humach: string = `${process.env.PUBLIC_URL}/assets/humach/humach_my_humach.png`;

  const machinie =
    "https://lh3.googleusercontent.com/DRYsG5KGYyH0tedfXxUOcErm3cHVYzqFYmz0QzD1qtg1uCaz_tkzTOjzJi7fju95brh4WCy6hdb8vvzJ7SzjImZyxaQPL6j9vP5f";

  const inven_floppy: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`;
  const inven_current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  const inven_ticket: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Ticket.png`;
  const inven_discord: string = `${process.env.PUBLIC_URL}/assets/gameAssets/1000EXP.png`;

  const [isEnterCopyAddress, setIsEnterCopyAddress] = useState(false);
  const [textClickCopyAddress, setTextClickCopyAddress] =
    useState("Copy to clipboard");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRedeem, setIsOpenRedeem] = useState(false);
  const [isOpenSendGift, setIsOpenSendGift] = useState(false);
  const [isOpenActivity, setIsOpenActivity] = useState(false);
  const [selectPanelRedeem, setSelectPanelRedeem] = useState("");

  // const wrapperRef = useRef(null);

  const delayText = () => {
    navigator.clipboard.writeText(walletAddress);
    setTextClickCopyAddress("Copied!");
    var delay = 1500;
    setTimeout(function () {
      setTextClickCopyAddress("Copy to clipboard");
    }, delay);
  };

  return (
    <div className="profile-panel-container">
      <div className="profile-panel">
        <div className="profile-wallet d-flex justify-content-between ">
          <div className="p-10">
            <img
              src={
                "https://docs.metamask.io/metamask-fox.svg?__WB_REVISION__=c06f3a3e804ebc7343949fdca3fdd7f8"
              }
            ></img>
          </div>
          <div className="d-flex p-10">
            <div className="bold-body-1 text-color-gray-100 pr-12 m-auto-tb">
              {walletAddress !== ""
                ? walletAddress.slice(0, 5) +
                  "..." +
                  walletAddress.slice(
                    walletAddress.length - 5,
                    walletAddress.length
                  )
                : ""}
            </div>
            <div
              className="m-auto-tb cursor-pointer bg-focus positon-relative"
              onClick={() => delayText()}
              onMouseEnter={() => setIsEnterCopyAddress(true)}
              onMouseLeave={() => setIsEnterCopyAddress(false)}
            >
              <IconContentCopy colorClass="text-color-tertiary" />

              <MouseEnterPanel
                isEnter={isEnterCopyAddress}
                setIsEnter={setIsEnterCopyAddress}
                text={textClickCopyAddress}
              />
            </div>
          </div>
        </div>

        <div className="profile-second-container">
          <div className="profile-second">
            <div className="profile-second-edit-container p-10">
              <div className="d-flex">
                <div>
                  <img
                    src={machinie_image_url[594]}
                    alt="machinie"
                    width="46px"
                    className="img-profile-second"
                  ></img>
                </div>
                <div className="pl-12  m-auto-tb">
                  <div className="text-color-gray-100 bold-subtitle-1">
                    {memberInfo.displayName !== undefined
                      ? memberInfo.discordId === "Guest"
                        ? "Guest"
                        : memberInfo.displayName.slice(0, 19)
                      : "undefined"}
                  </div>
                  <div className="reg-caption text-color-line-tint-04">
                    {memberInfo.discordId !== undefined
                      ? memberInfo.discordId === "Guest"
                        ? ""
                        : memberInfo.discordId
                      : "undefined"}
                  </div>
                </div>
              </div>

              {memberInfo.displayName !== undefined ? (
                memberInfo.discordId === "Guest" ? (
                  ""
                ) : (
                  <div
                    className="m-auto-tb edit-profile-button cursor-pointer"
                    onClick={() => setIsOpen(true)}
                  >
                    <IconEdit colorClass="text-color-gray-100" />
                  </div>
                )
              ) : (
                ""
              )}
              <EditProfileModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                memberInfo={memberInfo}
                setMemberInfo={setMemberInfo}
              />
            </div>
            <Link
              to="/my-machinie/chilling"
              onClick={() => setToggleProfile(false)}
            >
              <div className="profile-each">
                <div className="d-flex">
                  <div>
                    <img src={my_machinie} alt="my machinie" />
                  </div>
                  <div className="pl-12 m-auto-tb bold-button text-color-gray-100">
                    My Machinie
                  </div>
                </div>
              </div>
            </Link>
            <Link
              to="/my-humach/chilling"
              onClick={() => setToggleProfile(false)}
            >
              <div className="profile-each">
                <div className="d-flex">
                  <div>
                    <img src={my_humach} alt="my humach"></img>
                  </div>
                  <div className="pl-12 m-auto-tb bold-button text-color-gray-100">
                    My Humach
                  </div>
                </div>{" "}
              </div>
            </Link>
          </div>
        </div>

        <div className="profile-inventory-container">
          <div className="bold-button text-color-line-tint-04 p-10">
            INVENTORY
          </div>
          <div className="d-flex p-10">
            <div className="inventory-img-container">
              <img
                src={inven_floppy}
                alt="floppy"
                width="18px"
                className="inventory-img"
              ></img>
            </div>
            <div className="pl-12 ">
              <div className="text-color-line-tint-04 reg-caption">$floppy</div>
              <div className="bold-subtitle-2 text-color-gray-100 mgt-4">
                {floppy}
              </div>
            </div>
          </div>
          <div className="d-flex p-10">
            <div className="inventory-img-container">
              <img
                src={inven_current}
                alt="floppy"
                width="18px"
                className="inventory-img"
              ></img>
            </div>
            <div className="pl-12 ">
              <div className="text-color-line-tint-04 reg-caption">
                $current
              </div>
              <div className="bold-subtitle-2 text-color-gray-100 mgt-4">
                {walletList.floppy}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between p-10">
            <div className="d-flex">
              <div className="inventory-img-container">
                <img
                  src={inven_ticket}
                  alt="floppy"
                  // width="43px"
                  width="18px"
                  className="inventory-img"
                ></img>
              </div>
              <div className="pl-12 ">
                <div className="text-color-line-tint-04 reg-caption">
                  Ticket
                </div>
                {walletList.ticket !== undefined ? (
                  <div className="bold-subtitle-2 text-color-gray-100 mgt-4">
                    {walletList.ticket.merchTicket}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <Link to="/redeem/ticket" className="m-auto-tb">
              <div
                className="bold-button text-color-gray-100  redeem-button cursor-pointer"
                // onClick={() => {
                //   setIsOpenRedeem(true);
                //   setSelectPanelRedeem(SELECT_REDEEM.REDEEM_DISCORD);
                // }}
              >
                REDEEM
              </div>
            </Link>

            {/* <div
              className="bold-button text-color-gray-100 redeem-button cursor-pointer"
              onClick={() => {
                setIsOpenRedeem(true);
                setSelectPanelRedeem(SELECT_REDEEM.REDEEM_TICKET);
              }}
            >
              REDEEM
            </div> */}

            {/* <RedeemModal
                walletList={walletList}
                setWalletList={setWalletList}
                setIsOpen={setIsOpenRedeem}
                isOpen={isOpenRedeem}
                selectRedeem={SELECT_REDEEM.REDEEM_TICKET}
                selectPanelRedeem={selectPanelRedeem}
                setSelectPanelRedeem={setSelectPanelRedeem}
                memberInfo={memberInfo}
                setMemberInfo={setMemberInfo}
              /> */}
          </div>
          <div className="d-flex justify-content-between p-10">
            <div className="d-flex">
              <div className="inventory-img-container">
                <img
                  src={inven_discord}
                  alt="floppy"
                  // width="56px"
                  width="18px"
                  className="inventory-img"
                ></img>
              </div>
              <div className="pl-12 ">
                <div className="text-color-line-tint-04 reg-caption">
                  Discord XP
                </div>
                {walletList.discord !== undefined ? (
                  <div className="bold-subtitle-2 text-color-gray-100 mgt-4">
                    {walletList.discord.discordLVL}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Link to="/redeem/discord-exp" className="m-auto-tb">
              <div
                className="bold-button text-color-gray-100  redeem-button cursor-pointer"
                // onClick={() => {
                //   setIsOpenRedeem(true);
                //   setSelectPanelRedeem(SELECT_REDEEM.REDEEM_DISCORD);
                // }}
              >
                REDEEM
              </div>
            </Link>

            {/* <RedeemModal
              setIsOpen={setIsOpenRedeem}
              isOpen={isOpenRedeem}
              selectRedeem={SELECT_REDEEM.REDEEM_DISCORD}
              selectPanelRedeem={selectPanelRedeem}
              setSelectPanelRedeem={setSelectPanelRedeem}
              walletList={walletList}
              setWalletList={setWalletList}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
            /> */}
          </div>

          <div
            className="d-flex profile-each "
            onClick={() => setIsOpenSendGift(true)}
          >
            <div>
              <IconCard colorClass="text-color-tertiary" />
            </div>
            <div className="bold-button text-color-gray-100 pl-12 m-auto-tb">
              SEND GIFT
            </div>
          </div>

          <SendGiftModal
            setIsOpen={setIsOpenSendGift}
            open={isOpenSendGift}
            walletList={walletList}
            setWalletList={setWalletList}
          />

          <div
            className="d-flex profile-each"
            onClick={() => setIsOpenActivity(true)}
          >
            <div>
              <IconReceipt colorClass="text-color-tertiary" />
            </div>
            <div className="bold-button text-color-gray-100 pl-12 m-auto-tb">
              ACTIVITY
            </div>
          </div>

          <ActivityModal open={isOpenActivity} setIsOpen={setIsOpenActivity} />
        </div>
      </div>
    </div>
  );
};
