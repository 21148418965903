import { IMAGE_ASSETS } from "./imageAssetsLink";

export const LOTTO_REWARD_HISTORY = [
  {
    name: "Metalinie",
    image:
      "https://i.seadn.io/gae/QoO4ThhQw-dWz9bJp-8I5Jnf3gISFvUkc8-k49OvJ0dXFSeH8rl7sGRyiRCNvUr0M34OI8gv3c3MvA0flpSvIko_AzRjLt-TbvzRNqE?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/0",
    ],
    date: "Oct 2021 R1",
  },
  {
    name: "Halloweenie",
    image:
      "https://i.seadn.io/gae/XjILbGfIM14jXesrsHUdg2eaISSTHaLWeSgiO7O3B08Vg5PaE3q7M8F_oUio4Ud6LkvHmAyvYrtOMg-Qk2hcVGoDteXS_lf39BS-uT4?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/1",
    ],
    date: "Oct 2021 R2",
  },
  {
    name: "Retromach",
    image:
      "https://i.seadn.io/gae/_4bq2ahGJsz0QF1AXMkTrmlzt7inv-AN71zR4YIqcGpFsdlBz5T2rjc03umb630fPR5hrsjb-3o0H54QForAzSJ2Z0EA2a8TeHMKWA?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/2",
    ],
    date: "Nov 2021 R1",
  },
  {
    name: "MACHIBROS",
    image:
      "https://i.seadn.io/gae/z-DdKMptnpWEDZH0vMAj60sEB9O3OR6-wSbRYIS3N7r1wRStw5YMDvk24V5akHkuOdkF1uCM3_TFLhKKB1r_ECjYRuMc5wwlIIrvvw?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/3",
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/4",
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/5",
    ],
    date: "Nov 2021 R2",
  },
  {
    name: "DinoMachi",
    image:
      "https://i.seadn.io/gae/z32JoeUvEXnSPtQFP4J3MTR64159mrCiaZ4se3Vc4eNwwMFYRPgIlvlVVBG657gzyvgPUz_92KXExDCXRHlNYuTGmAs83ptswF3h0A?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/6",
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/7",
    ],
    date: "Dec 2021 R1",
  },
  {
    name: "Machimas",
    image:
      "https://i.seadn.io/gae/_Aq0w30IhaMOb-3eSqIGWCyAxt5xsH0NmBId69kXhiwJ1N2a3WnSsv-sPulmRXm_ID6lEm5pTyF8hNJ4doLE5Zxfg5mw41-KjCIz_g?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/8",
    ],
    date: "Dec 2021 R2",
  },
  {
    name: "Machinom",
    image:
      "https://i.seadn.io/gae/39FuwjJVk55h8jsZp3QXGisL9uHuRRCUHNnVERB3b3EZahDsepujIZmfA4IvMlJOYD6KljmDgIf9eQHDfjZYj4jCAC_6VxQwLVoi3w?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/38",
    ],
    date: "Jan 2021",
  },
  {
    name: "Lunar Machinie",
    image:
      "https://i.seadn.io/gae/9dDCpQ8wujQcKmvVewnst4PhIQbm8UyaFMaB3PKztCELp7KJxYWhnpL7tfpw3n6WNF74E36KFK4iJcou5jdkZy4xOA2tMqTr2ChkiQ?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/39",
    ],
    date: "Feb 2022",
  },
  {
    name: "HellMach",
    image:
      "https://i.seadn.io/gae/BWp7fjx0W7w0q8pDeKHks7h6zzqpodCrgvtXtj2lsF9QZV3pDpFdCRw2a1wGLFOAOaUkX5fse_TuZ9I3PbtvGUIKp8HTbhmdGK3HGeo?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0xaa89e8eb3048a2d8cb1a3db7d2b49f8fa33d56ba/2524",
    ],
    date: "Apr 2022",
  },
  {
    name: "0.1 eth",
    image: IMAGE_ASSETS.LOTTO.ETH,
    link: [""],
    date: "May 2022",
  },
  {
    name: "Premium MachNecklace",
    image: IMAGE_ASSETS.MERCH.MOCK.RECKLACE.C,
    link: [""],
    date: "June 2022",
  },
  {
    name: "dysfunchinie",
    image:
      "https://i.seadn.io/gae/nt9DIeDwazsz3BEzdpRHR6picNQ8hh8mRKnjprMJj6NioBmzSBsxPC2YUpe1I-BJYWylx-jtmkwMatwCUMbEhcx1SBA7bKkwNhU3P48?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/40",
    ],
    date: "July 2022",
  },
  {
    name: "bigmach",
    image:
      "https://i.seadn.io/gae/OjJukX5WJ6Wh-X6YG1kLbTarhYvOtZ21NNJ7K_5XlSljpzcz32ZrA906WD1rDrD6EdyeWrk90NAkbHg4cal3HWnQI0vD80BaJh4lNA?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/41",
    ],
    date: "Aug 2022",
  },
  {
    name: "mCircular",
    image:
      "https://i.seadn.io/gae/G4lECIYub13Eun5O-8U9DBmLP1BNdS80iscOTHFjut7nrk25I69Zt5ZUm1dwov0jwNE93SYZ9-kpcUbqJu4TSaiA5ojID_IBJNHlig?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/42",
    ],
    date: "Sep 2022",
  },
  {
    name: "mCircular Halloween Edition",
    image:
      "https://i.seadn.io/gae/65sKLEipZ_eF8waETxx5C2bwjVAnCVDz-WnGjGg8zDnAre7ogQbNhYmhOSs-JW9vttHTVqc24gSAX4RR1NTxdQjOW_Dwjs_d5MntMgM?auto=format&w=1000",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/43",
    ],
    date: "Oct 2022",
  },
  {
    name: "Mampliflier",
    image: "https://machiniverse.xyz/assets/lottoReward/Mampliflier.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/44",
    ],
    date: "Nov 2022",
  },
  {
    name: "Machimas Crypto winter edition",
    image: "https://machiniverse.xyz/assets/lottoReward/2022_Machimas.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/45",
    ],
    date: "Dec 2022",
  },
  {
    name: "Valentine's Pink",
    image: "https://machiniverse.xyz/assets/lottoReward/valentine's-pink.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/46",
    ],
    date: "Feb 2023",
  },
  {
    name: "mTransmitter",
    image: "https://machiniverse.xyz/assets/lottoReward/mTransmitter.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/47",
    ],
    date: "Mar 2023",
  },
  {
    name: "mBeeform",
    image: "https://machiniverse.xyz/assets/lottoReward/mBeeform.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/48",
    ],
    date: "Apr 2023",
  },
  {
    name: "comhang",
    image: "https://machiniverse.xyz/assets/lottoReward/Machinie-comhang.jpg",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/49",
    ],
    date: "May 2023",
  },
  {
    name: "Machinie Pride",
    image: "https://machiniverse.xyz/assets/lottoReward/Machinie Pride.gif",
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/50",
    ],
    date: "June 2023",
  },
  {
    name: "Quantumizer",
    image: IMAGE_ASSETS.LOTTO.QUANTUMIZER,
    link: [
      "https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/51",
    ],
    date: "July 2023",
  },
  {
    name: "0.1 eth",
    image: IMAGE_ASSETS.LOTTO.ETH,
    link: [""],
    date: "Aug 2023",
  },
  {
    name: "TheNewBeginning",
    image: IMAGE_ASSETS.LOTTO.THE_NEW_BEGINNING,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/52"],
    date: "Sep 2023",
  },
  {
    name: "Halloweenie 2023",
    image: IMAGE_ASSETS.LOTTO.HALLOWEEN_2023,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/53"],
    date: "Oct 2023",
  },
  {
    name: "frozenfire",
    image: IMAGE_ASSETS.LOTTO.FROZENFIRE,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/54"],
    date: "Nov 2023",
  },
  {
    name: "Machimas 2023",
    image: IMAGE_ASSETS.LOTTO.MACHIMAS_2023,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/55"],
    date: "Dec 2023",
  },
  {
    name: "mCircular Dragon Spirit",
    image: IMAGE_ASSETS.LOTTO.MCIRCULAR_DRAGON,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/56"],
    date: "Jan 2024",
  },
  {
    name: "Valentine's Pink 2024",
    image: IMAGE_ASSETS.LOTTO.VALENTINE_PINK_2024,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/57"],
    date: "Feb 2024",
  },
  {
    name: "Machinom 2024",
    image: IMAGE_ASSETS.LOTTO.MACHINOM_2024,
    link: ["https://opensea.io/assets/ethereum/0x096e0e2c5c037d2959f8386998b904f8baf089ef/58"],
    date: "Mar 2024",
  },
];
