import { useEffect, useState } from "react";
import { api } from "../../axiosURL";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import { MERCH_TEXT } from "../../const/RedeemMerchConst";
import { API } from "../../valueApi";
import { IconCenterSqure, IconWallet } from "../component/Icon";
import { LoadingDots } from "../component/LoadingDots";
import { LOTTO_VALUE } from "../learn_game/lottoConfigValue";
import { Modal, Modal2, Modal3 } from "../modal/Modal";

type ACTIVITY_MODAL = {
  open: boolean;
  setIsOpen: any;
};

export const ActivityModal = ({ open, setIsOpen }: ACTIVITY_MODAL) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">ACTIVITY</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      <ActivityContainer />
    </Modal>
  );
};

export const ActivityContainer = () => {
  const [redeemSanta, setRedeemSanta] = useState<any>([]);
  const [redeemMerch, setRedeemMerch] = useState<any>([]);
  const [redeemDiscordPending, setRedeemDiscordPending] = useState(0);
  const [redeemDiscordSuccess, setRedeemDiscordSuccess] = useState(0);

  useEffect(() => {
    // testActionLog();
    testRewardLog();
  }, []);

  const testRewardLog = async () => {
    try {
      const res = await api.post(
        API.REWARD.SEARCH,
        {
          offset: 0,
          limit: 200,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const data = res.data.data;

      console.log("activity", data);
      const getRedeemTicket = [];
      const getRedeemMerch = [];
      let getRedeemDiscordPending = 0;
      let getRedeemDiscordSuccess = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i]["type"] === "merchTicket") {
          getRedeemTicket.push(data[i]);
        } else if (data[i]["type"] === "discordLVL") {
          if (data[i]["status"] === 0) {
            getRedeemDiscordPending++;
          } else if (data[i]["status"] === 2) {
            getRedeemDiscordSuccess++;
          }
        } else if (data[i]["type"] === "hoodie_redeem") {
          getRedeemMerch.push(data[i]);
        }
      }

      if (getRedeemTicket.length > 0) {
        setRedeemSanta(getRedeemTicket);
      } else {
        setRedeemSanta("");
      }

      if (getRedeemMerch.length > 0) {
        setRedeemMerch(getRedeemMerch);
      } else {
        setRedeemMerch("");
      }

      setRedeemDiscordPending(getRedeemDiscordPending);
      setRedeemDiscordSuccess(getRedeemDiscordSuccess);
    } catch (err: any) {}
  };

  return (
    <div className="p-24 overflow-y-activity">
      <div className="bold-subtitle-2 text-color-gray-100">
        Redeem Discord 1000 exp
      </div>
      <div className="mt-14 redeem-discord-container">
        <div className="redeem-discord-each">
          <div className="text-color-secondary reg-caption">Pending</div>
          <div className="text-color-gray-100 bold-headline-5">
            {redeemDiscordPending}
          </div>
        </div>
        <div className="redeem-discord-each">
          <div className="text-color-primary-200 reg-caption">Success</div>
          <div className="text-color-gray-100 bold-headline-5">
            {redeemDiscordSuccess}
          </div>
        </div>
      </div>

      <GetLottoEntry />
      <SantaMachEach redeemSanta={redeemSanta} />
      <MerchEachContainer redeemMerch={redeemMerch} />
    </div>
  );
};

type SANTA_MACH_EACH = {
  redeemSanta: any;
};

export const SantaMachEach = ({ redeemSanta }: SANTA_MACH_EACH) => {
  return (
    <div className="mt-24">
      {redeemSanta.length > 0 ? (
        <div>
          <div className="bold-subtitle-2 text-color-gray-100">
            Redeem SantaMach
          </div>
          {redeemSanta.map((x: any) => (
            <div className="pending-each-container mt-14">
              <div>
                <img
                  src={IMAGE_ASSETS.SANTA_MACH}
                  className="image-pending"
                  width="72px"
                ></img>
              </div>
              <div className="pending-each-left">
                {x.status === 0 ? (
                  <div className="pending-pending text-color-secondary reg-caption">
                    PENDING
                  </div>
                ) : x.status === 2 ? (
                  <div className="d-flex">
                    <div className="success-pending text-color-gray-100 reg-caption">
                      SUCCESS
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="bold-subtitle-2 text-color-gray-100">
                  SantaMach
                </div>
                <div className="d-flex">
                  <div className="payment-pending-container">
                    <img
                      src={IMAGE_ASSETS.INVENTORY.TICKET}
                      className="m-auto-tb"
                      width="30px"
                    ></img>

                    <div className="text-color-line-tint-04 reg-caption">
                      20 |
                    </div>
                  </div>

                  <div className="payment-pending-container">
                    <div> </div>
                    <div style={{ fontSize: "5px" }}>
                      <IconWallet
                        colorClass="text-color-tertiary"
                        size="20px"
                      />
                    </div>
                    <div className="text-color-line-tint-04 reg-caption">
                      {x.walletAddress.slice(0, 6) +
                        "..." +
                        x.walletAddress.slice(
                          x.walletAddress.length - 4,
                          x.walletAddress.length
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

type MERCH_EACH_CONTAINER = {
  redeemMerch: any;
};

export const MerchEachContainer = ({ redeemMerch }: MERCH_EACH_CONTAINER) => {
  return (
    <div className="mt-24">
      {redeemMerch.length > 0 ? (
        <div>
          <div className="bold-subtitle-2 text-color-gray-100">
            Redeem Merch
          </div>
          {redeemMerch.map((x: any) => (
            <MerchEach x={x} />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

type MERCH_EACH = {
  x: any;
};

export const MerchEach = ({ x }: MERCH_EACH) => {
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [asset, setAsset] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [wallet, setWallet] = useState("");

  const [isOpen3, setIsOpen3] = useState(false);

  useEffect(() => {
    getStart();
  }, []);

  const getStart = () => {
    const thisSplit = x.details.split(" ");
    const thisColor = thisSplit[0];
    const thisSize = thisSplit[1] + " " + thisSplit[2];
    const thisAsset = thisSplit[3];
    const thisTokenId = thisSplit[4];
    const thisWallet = thisSplit[5];

    setColor(thisColor);
    setSize(thisSize);
    setAsset(thisAsset);
    setTokenId(thisTokenId);
    setWallet(thisWallet);
  };
  return (
    <div>
      <div
        className="pending-each-container pt-14  cursor-pointer"
        onClick={() => setIsOpen3(true)}
      >
        <div>
          <img
            src={
              color === "Black"
                ? MERCH_TEXT.IMAGE.HOODIE_BLACK_FRONT
                : color === "White"
                ? MERCH_TEXT.IMAGE.HOODIE_WHITE_FRONT
                : ""
            }
            className="image-pending bg-blue"
            width="72px"
          ></img>
        </div>
        <div className="pending-each-left">
          {x.status === 0 ? (
            <div className="d-flex">
              <div className="pending-pending text-color-secondary reg-caption">
                PENDING
              </div>

              <div className="text-color-line-tint-04 reg-caption m-auto-tb pl-6">
                {" "}
                {asset} {tokenId}
              </div>
            </div>
          ) : x.status === 2 ? (
            <div className="d-flex">
              <div className="success-pending text-color-gray-100 reg-caption">
                SUCCESS
              </div>{" "}
              <div className="text-color-line-tint-04 reg-caption m-auto-tb pl-6">
                {" "}
                {asset} {tokenId}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="bold-subtitle-2 text-color-gray-100">
            Hoodie{" "}
            <span className="text-color-line-tint-04 reg-caption">
              {color} {size}
            </span>
          </div>
          <div className="d-flex">
            <div className="payment-pending-container">
              <img
                src={IMAGE_ASSETS.INVENTORY.TICKET}
                className="m-auto-tb"
                width="30px"
              ></img>

              <div className="text-color-line-tint-04 reg-caption">60 |</div>
            </div>

            <div className="payment-pending-container">
              <div> </div>
              <div style={{ fontSize: "5px" }}>
                <IconWallet colorClass="text-color-tertiary" size="20px" />
              </div>
              <div className="text-color-line-tint-04 reg-caption">
                {wallet !== ""
                  ? wallet.slice(0, 6) +
                    "..." +
                    wallet.slice(wallet.length - 4, wallet.length)
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Modal3 open={isOpen3} onClose={() => setIsOpen3(false)}>
        <div className="close-modal-container-stake">
          <div className="bold-headline-6 text-color-gray-100">
            Shipping Address
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen3(false);
              console.log("isOpen3", isOpen3);
            }}
          >
            <i className="fa fa-times close-modal"></i>
          </div>
        </div>

        <ModalCheckShipping x={x} />
      </Modal3>
    </div>
  );
};

type MODAL_CHECK_SHIPPING = {
  x: any;
};

export const ModalCheckShipping = ({ x }: MODAL_CHECK_SHIPPING) => {
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [asset, setAsset] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [wallet, setWallet] = useState("");

  useEffect(() => {
    getStart();
  }, []);

  const getStart = () => {
    const thisSplit = x.details.split(" ");
    const thisColor = thisSplit[0];
    const thisSize = thisSplit[1] + " " + thisSplit[2];
    const thisAsset = thisSplit[3];
    const thisTokenId = thisSplit[4];
    const thisWallet = thisSplit[5];

    setColor(thisColor);
    setSize(thisSize);
    setAsset(thisAsset);
    setTokenId(thisTokenId);
    setWallet(thisWallet);
  };

  return (
    <div className="p-24">
      <div className="text-center">
        <img
          src={
            color === "Black"
              ? MERCH_TEXT.IMAGE.HOODIE_BLACK_FRONT
              : color === "White"
              ? MERCH_TEXT.IMAGE.HOODIE_WHITE_FRONT
              : ""
          }
          className="image-pending bg-blue"
          width="200px"
        ></img>
      </div>

      <div className="mt-14 text-color-gray-100 reg-subtitle-2">
        <div>Color : {color}</div>
        <div>Size : {size}</div>
        <div>Asset : {asset}</div>
        <div>Token ID : {tokenId}</div>
        <div>Wallet : {wallet}</div>
      </div>

      <div className="mt-14 text-color-gray-100 reg-subtitle-2">
        <div>Name : {x.address.fullName}</div>
        <div>
          Address : {x.address.address} {x.address.subAddress}
        </div>
        <div>City : {x.address.city}</div>
        <div>State : {x.address.state}</div>
        <div>Postal code : {x.address.zip}</div>
        <div>Country : {x.address.country}</div>
      </div>
    </div>
  );
};

export const GetLottoEntry = () => {
  const [entryArray, setEntryArray] = useState<any>([]);

  const [winnerArray, setWinnerArray] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    lottoRaffle();
  }, []);
  const lottoRaffle = async () => {
    setLoading(true);
    try {
      const res = await api.get(API.LOTTO_ENTRY.GET_ENTRY, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const thisOldEntry = res.data.data;
      const thisEntry: any[] = [];

      let thisCheck: any[] = [];
      for (let i = 0; i < thisOldEntry.length; i++) {
        if (thisOldEntry[i]["entry"] > 0) {
          const res = await checkLotto(thisOldEntry[i]["lottoConfigId"]);
          if (res[0] === undefined) {
            const lottoCurrent = currentLotto(thisOldEntry[i]["lottoConfigId"]);
            thisCheck.push(lottoCurrent);
          } else {
            thisCheck.push(res[0]);
          }

          thisEntry.push(thisOldEntry[i]);
        }
      }

      const newEntry = [...thisEntry];

      for (let i = 0; i < thisEntry.length; i++) {
        for (let j = 0; j < thisCheck.length; j++) {
          if (thisEntry[i]["lottoConfigId"] === thisCheck[j]["lottoConfigId"]) {
            if (thisCheck[j]["winner"] !== null) {
              newEntry[i]["winner"] = thisCheck[j]["winner"]["displayName"];
              newEntry[i]["sum"] = thisCheck[j]["sum"];
            }
          }
        }
      }
      setEntryArray(newEntry.reverse());
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const checkLotto = async (configId: string) => {
    try {
      const res = await api.get(API.LOTTO_STATUS.CHECK_STATUS_PUBLIC, {
        params: {
          lottoConfigId: configId,
        },
      });

      return res.data.data;
    } catch (err: any) {}
  };

  const renderDate = (date: number) => {
    const dateNumber = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    return dateNumber + "/" + (month + 1) + "/" + year;
  };

  const currentLotto = (configId: string) => {
    return {
      lottoConfigId: configId,
      sum: "TBA",
      winner: {
        displayName: "TBA",
        entry: "TBA",
      },
    };
  };
  return (
    <div className="mt-24">
      {entryArray.length > 0 ? (
        <div>
          <div className="bold-subtitle-2 text-color-gray-100">Lotto Entry</div>
          <div className="lotto-entry-container">
            {entryArray.map((x: any) => (
              <div className="pending-each-container mt-14">
                <div>
                  {x.name === "lotto drop" ? (
                    <img
                      src={IMAGE_ASSETS.HUMACH_ONE_OF_ONE.HELLMACH}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "lotto0.1eth" ? (
                    <img
                      src="https://vistapointe.net/images/etherium-wallpaper-2.jpg"
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "Premium MachNecklace" ? (
                    <img
                      src={IMAGE_ASSETS.MERCH.MOCK.RECKLACE.B}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "dysfunctionie" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.DYSFUNCTIONAL}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "092022" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.BIGMACH}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "09-22" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.NEWGENESIS}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "10-22" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MCIRCULAR_HALLOWEEN}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "11-22" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.Mampliflier}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "12-22" ? (
                    <img
                      src={IMAGE_ASSETS.LOTTO.Xmas22}
                      className="image-pending"
                      width="72px"
                    ></img>
                  ) : (
                    ""
                  )}

                  {x.name === "02-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.VALENTINE_PINK}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "03-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MTRANSMITTER}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "04-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MBEEFORM}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "05-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.COMHANG}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "06-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MACHINIEPRIDE}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "07-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.QUANTUMIZER}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "08-23" && (
                    <img
                      src={'https://vistapointe.net/images/etherium-wallpaper-2.jpg'}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "09-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.THE_NEW_BEGINNING}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "10-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.THE_NEW_BEGINNING}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "11-23" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.FROZENFIRE}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "12-23-2" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MACHIMAS_2023}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "01-24" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MCIRCULAR_DRAGON}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "02-24" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.VALENTINE_PINK_2024}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "03-24" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.MACHINOM_2024}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}

                  {x.name === "04-24" && (
                    <img
                      src={IMAGE_ASSETS.LOTTO.RETRO_MACH_2024}
                      className="image-pending"
                      width="72px"
                    ></img>
                  )}
                  
                </div>
                <div className="pending-each-left">
                  <div className="text-color-gray-100 reg-caption">
                    {renderDate(Number(x.raffleDate))}
                  </div>{" "}
                  <div className="payment-pending-container">
                    <div className="bold-subtitle-2 text-color-gray-100">
                      {x.name === "lotto drop" ? "HellMach" : ""}
                      {x.name === "lotto0.1eth" ? "0.1 ETH" : ""}
                      {x.name === "Premium MachNecklace"
                        ? "Premium Machnecklace"
                        : ""}
                      {x.name === "dysfunctionie" ? "dysfunchinie" : ""}
                      {x.name === "092022" ? "bigmach" : ""}
                      {x.name === "09-22" ? "mCircular" : ""}

                      {x.name === "10-22" ? "mCircular halloween edition" : ""}
                      {x.name === "11-22" ? "Mampliflier" : ""}
                      {x.name === "12-22"
                        ? "Machimas Crypto winter edition"
                        : ""}

                      {x.name === "02-23" ? "Valentine's Pink" : ""}
                      {x.name === "03-23" ? "mTransmitter" : ""}
                      {x.name === "04-23" ? "mBeeform" : ""}
                      {x.name === "05-23" ? "comhang" : ""}
                      {x.name === "06-23" ? "Machinie Pride" : ""}
                      {x.name === "07-23" ? "Quantumizer" : ""}
                      {x.name === "08-23" ? "0.1 ETH" : ""}
                      {x.name === "09-23" ? "TheNewBeginning" : ""}
                      {x.name === "10-23" ? "Halloweenie 2023" : ""}
                      {x.name === "11-23" ? "frozenfire" : ""}
                      {x.name === "12-23-2" ? "Machimas 2023" : ""}
                      {x.name === "01-24" ? "mCircular Dragon Spirit" : ""}
                      {x.name === "02-24" ? "Valentine's Pink 2024" : ""}
                      {x.name === "03-24" ? "Machinom 2024" : ""}
                      {x.name === "04-24" ? "RetroMach 2024" : ""}
                    </div>
                    <div className="text-color-line-tint-04 reg-caption m-auto-tb">
                      | total entry :{" "}
                      {x.name === "09-22"
                        ? x.sum + 105
                        : x.name === "11-22"
                        ? x.sum + 94
                        : x.name === "12-22"
                        ? x.sum + 96
                        : x.name === "02-23"
                        ? x.sum + 186
                        : x.name === "03-23"
                        ? x.sum + 61
                        : x.name === "04-23"
                        ? x.sum + 107
                        : x.name === "05-23"
                        ? x.sum + 84
                        : x.name === "06-23"
                        ? x.sum + 47
                        : x.name === "07-23"
                        ? x.sum + 25
                        : x.name === "08-23"
                        ? x.sum + 30 + 10 + 15 + 11 + 20
                        : x.name === "09-23"
                        ? x.sum + 26
                        : x.name === "10-23"
                        ? x.sum + 25
                        : x.name === "11-23"
                        ? x.sum + 16
                        : x.name === "12-23-2"
                        ? x.sum + 16
                        : x.name === "01-24"
                        ? x.sum + 10
                        : x.name === "02-24"
                        ? x.sum + 4
                        : x.name === "03-24"
                        ? x.sum + 10
                        : x.name === "04-24"
                        ? "-"
                        : x.sum }
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="payment-pending-container">
                      <img
                        src={IMAGE_ASSETS.INVENTORY.TICKET}
                        className="m-auto-tb"
                        width="30px"
                      ></img>

                      <div className="text-color-line-tint-04 reg-caption">
                        {x.entry} |
                      </div>
                    </div>
                    <div className="payment-pending-container">
                      <div></div>
                      <div className="text-color-line-tint-04 reg-caption">
                        winner {x.winner}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      ) : loading ? (
        <div className="text-color-gray-100 reg-caption">
          Loading
          <LoadingDots />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
