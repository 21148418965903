import { HomePage } from "../homepage/HomePage";
import { LearnHumachPage } from "../learn_humach/LearnHumachPage";
import { LearnMachiniePage } from "../learn_machinie/LearnMachiniePage";
import { DocsPage } from "../docs/DocsPage";
import { Page404NotFound } from "../404/404Page";
import { Nav } from "../navbar/Nav";
import { MyNFTPage } from "../myNft/MyNFTPage";
import { FooterSection } from "../homepage/footer/FooterSection";
import { useState, useEffect } from "react";
import { LearnGamePage } from "../learn_game/LearnGamePage";
import { SELECT_PAGE } from "../../value";
import { LoadingScreen } from "../loading_screen/LoadingScreen";
import { RedeemMerch } from "../redeem_page/RedeemMerch";

export const Skeleton = ({ selectPage }: SKELETON_PROPS_TYPE) => {
  const [walletList, setWalletList] = useState([]);
  const [floppy, setFloppy] = useState("0");
  const [isMainnet, setIsMainnet] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [memberInfo, setMemberInfo] = useState([]);
  const [machinieList, setMachinieList] = useState([]);
  const [humachList, setHumachList] = useState([]);

  const [toggleProfile, setToggleProfile] = useState<boolean>(false);
  const [toggleSideNav, setToggleSideNav] = useState<boolean>(false);
  const [selecting, setSelecting] = useState("");

  useEffect(() => {
    setToggleProfile(false);
    setToggleSideNav(false);
  }, [selectPage]);

  useEffect(() => {
    if (toggleProfile) {
      setToggleSideNav(false);
    }
  }, [toggleProfile]);

  useEffect(() => {
    if (toggleSideNav) {
      setToggleProfile(false);
    }
  }, [toggleSideNav]);

  const renderSwitch = (selectpage: string | number) => {
    if (typeof selectPage === "string") {
      switch (selectpage) {
        case SELECT_PAGE.HOMEPAGE:
          return <HomePage setSelecting={setSelecting} />;
        case SELECT_PAGE.LEARN.MACHINIE:
          return <LearnMachiniePage setSelecting={setSelecting} />;
        case SELECT_PAGE.LEARN.HUMACH:
          return (
            <LearnHumachPage
              walletList={walletList}
              isMainnet={isMainnet}
              setSelecting={setSelecting}
            />
          );
        case SELECT_PAGE.GAME.MACHIPON:
          return (
            <LearnGamePage
              walletList={walletList}
              setWalletList={setWalletList}
              gameSelectPage={selectpage}
              setSelecting={setSelecting}
            />
          );
        case SELECT_PAGE.GAME.SLOT:
          return (
            <LearnGamePage
              walletList={walletList}
              setWalletList={setWalletList}
              gameSelectPage={selectpage}
              setSelecting={setSelecting}
            />
          );
        case SELECT_PAGE.GAME.LOTTO:
          return (
            <LearnGamePage
              walletList={walletList}
              setWalletList={setWalletList}
              gameSelectPage={selectpage}
              setSelecting={setSelecting}
            />
          );
        case SELECT_PAGE.NOT_FOUND:
          return <Page404NotFound />;
        case SELECT_PAGE.MY_NFT.MACHINIE.CHILLING:
          return (
            <MyNFTPage
              isMachinie={0}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.MY_NFT.MACHINIE.STAKING:
          return (
            <MyNFTPage
              isMachinie={0}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.MY_NFT.MACHINIE.MIGRATE:
          return (
            <MyNFTPage
              isMachinie={0}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.MY_NFT.HUMACH.CHILLING:
          return (
            <MyNFTPage
              isMachinie={1}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.MY_NFT.HUMACH.STAKING:
          return (
            <MyNFTPage
              isMachinie={1}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.MY_NFT.HUMACH.TRAINING:
          return (
            <MyNFTPage
              isMachinie={1}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          );
        case SELECT_PAGE.REDEEM.TICKET:
          return (
            <MyNFTPage
              isMachinie={2}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
            />
          );
        case SELECT_PAGE.REDEEM.DISCORD:
          return (
            <MyNFTPage
              isMachinie={2}
              selectedNftPage={selectpage}
              machinieList={machinieList}
              humachList={humachList}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              setWalletList={setWalletList}
              walletList={walletList}
              floppy={floppy}
              setFloppy={setFloppy}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
            />
          );

        default:
          return <DocsPage select={selectPage} setSelecting={setSelecting} />;
      }
    }
  };
  return (
    <div>
      {/* <LoadingScreen /> */}
      <Nav
        selecting={selecting}
        selectPage={selectPage}
        setWalletList={setWalletList}
        walletList={walletList}
        floppy={floppy}
        setFloppy={setFloppy}
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
        memberInfo={memberInfo}
        setMemberInfo={setMemberInfo}
        setMachinieList={setMachinieList}
        setHumachList={setHumachList}
        isMainnet={isMainnet}
        setIsMainnet={setIsMainnet}
        toggleProfile={toggleProfile}
        setToggleProfile={setToggleProfile}
        toggleSideNav={toggleSideNav}
        setToggleSideNav={setToggleSideNav}
      />
      {renderSwitch(selectPage)}

      <FooterSection />
    </div>
  );
};
type SKELETON_PROPS_TYPE = {
  selectPage: string | number;
};
