import { Link } from "react-router-dom";
import { IconOpensea } from "../component/Icon";
import { MachinieSlide } from "../homepage/slides/MachinieSlide";
import { ButtonGetMachinie } from "./ButtonGetMachinie";

export const LearnMachinieFirstSection = () => {
  const logo_machinie_l: string = `${process.env.PUBLIC_URL}/assets/logo/machinie_l.png`;

  return (
    <section className="section">
      <div className="container">
        <div className="learn-display">
          <div className="learn-display-each">
            <div>
              <img src={logo_machinie_l} alt="machinie_logo_l"></img>
            </div>
            <div className="reg-subtitle-2 text-color-secondary pt-4">
              Machinie (mah • shee • nee)
            </div>

            <div className="bold-headline-6 text-color-primary-200 pt-3">
              The first kind of Machinie to ever exist were genesis Machinies.
              There are only 888 units of the original species.
            </div>
          </div>
          <div className="left-machinie-learn-container">
            <div className="left-machinie-learn">
              <MyMachinie />
              <ButtonGetMachinie />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <MachinieSlide position={true} />
        <MachinieSlide position={false} />
      </div>
    </section>
  );
};

export const MachinieHistory = () => {
  const machinie_no_bg: string = `${process.env.PUBLIC_URL}/assets/machinie/machinie-no-bg.png`;

  return (
    <section className="history-section-container section bg-1">
      <div className="text-center container bg-glass-history">
        <div>
          <img src={machinie_no_bg} alt="machinie_no_bg"></img>
        </div>
        <div className="reg-subtitle-2 text-color-secondary mt-24">
          What are Machinies?
        </div>
        <div className="text-color-primary-200 bold-headline-6 mt-14">
          Highly advanced, ancient technological artifacts from a long forgotten
          age.
        </div>
        <div className="reg-body-2 text-color-gray-100 mt-14">
          They produce an infinite supply of energy to those that can unlock
          their true power. Only Humachs posses the ancient knowledge required
          to utilize the full potential of the 888 Genesis Machinie, generating
          a continuous stream of{" "}
          <span className="text-color-primary-400">$floppy</span> and{" "}
          <span className="text-color-secondary">$current</span> for their
          owners.
        </div>
      </div>
    </section>
  );
};

export const MyMachinie = () => {
  return (
    <Link to="/my-machinie/chilling" className="button-my-machinie">
      <div className="m-auto-tb text-color-tertiary bold-button">
        My Machinie
      </div>
    </Link>
  );
};
