import { useEffect, useState } from "react";
import { getUser } from "../../apiCallReturn";
import { api } from "../../axiosURL";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import { SELECT_PROFILE } from "../../value";
import { API } from "../../valueApi";
import { LoadingDots } from "../component/LoadingDots";
import { machinie_image_url } from "../../machinieImageUrl2";
import { Modal } from "../modal/Modal";
import { IconRandom } from "../component/Icon";

type EDIT_PROFILE_MODAL = {
  isOpen: boolean;
  setIsOpen: any;
  memberInfo: any;
  setMemberInfo: any;
};

export const EditProfileModal = ({
  isOpen,
  setIsOpen,
  memberInfo,
  setMemberInfo,
}: EDIT_PROFILE_MODAL) => {
  const [selectPanel, setSelectPanel] = useState(SELECT_PROFILE.EDIT_NOTHING);

  const renderSelectPanel = (selecPanel: string) => {
    switch (selecPanel) {
      case SELECT_PROFILE.EDIT_NOTHING:
        return (
          <div className="p-24">
            <div className="show-edit-name">
              {/* <RandomPictureProfile /> */}
              <ShowDisplayName
                memberInfo={memberInfo}
                setSelectPanel={setSelectPanel}
              />
              <ShowDiscordId
                memberInfo={memberInfo}
                setSelectPanel={setSelectPanel}
              />
            </div>{" "}
          </div>
        );
      case SELECT_PROFILE.EDIT_DISPLAY_NAME:
        return (
          <EditDisplayName
            memberInfo={memberInfo}
            setSelectPanel={setSelectPanel}
            setMemberInfo={setMemberInfo}
          />
        );
      case SELECT_PROFILE.EDIT_DISCORD_ID:
        return (
          <EditDiscordName
            memberInfo={memberInfo}
            setSelectPanel={setSelectPanel}
            setMemberInfo={setMemberInfo}
          />
        );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setSelectPanel(SELECT_PROFILE.EDIT_NOTHING);
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">Edit Profile</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setSelectPanel(SELECT_PROFILE.EDIT_NOTHING);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      {renderSelectPanel(selectPanel)}
    </Modal>
  );
};

type SHOW_DISPLAY_NAME = {
  memberInfo: any;
  setSelectPanel: any;
};

type EDIT_DISPLAY_NAME = {
  memberInfo: any;
  setSelectPanel: any;
  setMemberInfo: any;
};

export const RandomPictureProfile = () => {
  const [imageShow, setImageShow] = useState(0);

  const randomImage = () => {
    const res = Math.floor(Math.random() * 888);
    setImageShow(res);
  };
  return (
    <div className="text-center">
      <div>
        <img
          src={machinie_image_url[imageShow]}
          width="120px"
          className="random-profile-machinie"
          alt="profile machinie"
        ></img>
      </div>

      <div
        className="mt-14 bold-button random-text text-color-primary-200 cursor-pointer"
        onClick={() => randomImage()}
      >
        <span className="m-auto-tb">RANDOM</span>
        <IconRandom colorClass="text-tertiary" size="28px" />
      </div>
    </div>
  );
};

export const ShowDisplayName = ({
  memberInfo,
  setSelectPanel,
}: SHOW_DISPLAY_NAME) => {
  return (
    <div>
      <div className="reg-subtitle-2 text-color-gray-100">Display Name</div>
      <div
        className="reg-subtitle-1 text-color-gray-100 edit-display-name mt-14"
        onClick={() => setSelectPanel(SELECT_PROFILE.EDIT_DISPLAY_NAME)}
      >
        {memberInfo.displayName.slice(0, 19)}
      </div>
    </div>
  );
};

export const EditDisplayName = ({
  memberInfo,
  setSelectPanel,
  setMemberInfo,
}: EDIT_DISPLAY_NAME) => {
  const [newDisplayName, setNewDisplayName] = useState("");
  const [lastName, setLastName] = useState(memberInfo.displayName);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  useEffect(() => {
    setLastName(memberInfo.displayName);
  }, [memberInfo]);

  const editDisplayName = async (nameToCreate: string) => {
    setIsUpdating(true);
    try {
      const res = await api.put(
        API.USER.EDIT_USER,
        {
          displayName: nameToCreate,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisUser = await getUser();
      setMemberInfo(thisUser.data);
      setIsUpdateSuccess(true);
    } catch (err) {
      setIsUpdating(false);
      setIsUpdateSuccess(false);
    }
  };

  return (
    <div>
      {!isUpdating ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-color-gray-100">
            Edit Display Name
          </div>
          <input
            className="reg-subtitle-1 text-color-gray-100 input-display-name mt-14"
            value={newDisplayName}
            onChange={(e) => {
              if (e.target.value.length <= 20) {
                setNewDisplayName(e.target.value);
              }
            }}
            placeholder={lastName}
          ></input>

          <div className="reg-caption text-color-line-tint-04 mt-7">
            {newDisplayName.length}/20
          </div>
        </div>
      ) : !isUpdateSuccess ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-center text-color-gray-100">
            Updating Display Name
            <LoadingDots />
          </div>
        </div>
      ) : (
        <div className="p-24">
          <div className="bold-subtitle-2 text-center text-color-gray-100">
            Your new display name is {memberInfo.displayName}!
          </div>
        </div>
      )}

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setSelectPanel(SELECT_PROFILE.EDIT_NOTHING);
          }}
        >
          BACK
        </div>

        {newDisplayName.length > 0 ? (
          !isUpdating ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => editDisplayName(newDisplayName)}
            >
              SAVE
            </div>
          ) : !isUpdateSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SAVING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setSelectPanel(SELECT_PROFILE.EDIT_NOTHING)}
            >
              SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SAVE
          </div>
        )}
      </div>
    </div>
  );
};

export const ShowDiscordId = ({
  memberInfo,
  setSelectPanel,
}: SHOW_DISPLAY_NAME) => {
  return (
    <div>
      <div className="reg-subtitle-2 text-color-gray-100">Discord Username</div>
      <div
        className="reg-subtitle-1 text-color-gray-100 edit-display-name mt-14"
        onClick={() => setSelectPanel(SELECT_PROFILE.EDIT_DISCORD_ID)}
      >
        {memberInfo.discordId}
      </div>
    </div>
  );
};

export const EditDiscordName = ({
  memberInfo,
  setSelectPanel,
  setMemberInfo,
}: EDIT_DISPLAY_NAME) => {
  const [newDisplayName, setNewDisplayName] = useState("");
  const [lastName, setLastName] = useState(memberInfo.displayName);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  useEffect(() => {
    getStart();
  }, [memberInfo]);

  const getStart = async () => {
    if (memberInfo.discordId === undefined) {
      setLastName("Example ABC#123");
    } else {
      setLastName(memberInfo.discordId);
    }
  };

  const editDisplayName = async (nameToCreate: string) => {
    setIsUpdating(true);
    try {
      const res = await api.put(
        API.USER.EDIT_USER,
        {
          discordId: nameToCreate,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisUser = await getUser();
      setMemberInfo(thisUser.data);
      setIsUpdateSuccess(true);
    } catch (err) {
      setIsUpdating(false);
      setIsUpdateSuccess(false);
    }
  };

  return (
    <div>
      {!isUpdating ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-color-gray-100">
            Edit Discord Id
          </div>
          <input
            className="reg-subtitle-1 text-color-gray-100 input-display-name mt-14"
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
            placeholder={lastName}
          ></input>
        </div>
      ) : !isUpdateSuccess ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-center text-color-gray-100">
            Updating Discord Id
            <LoadingDots />
          </div>
        </div>
      ) : (
        <div className="p-24">
          <div className="bold-subtitle-2 text-center text-color-gray-100">
            Your new discord id is {memberInfo.discordId}!
          </div>
        </div>
      )}

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setSelectPanel(SELECT_PROFILE.EDIT_NOTHING);
          }}
        >
          BACK
        </div>

        {newDisplayName.length > 0 ? (
          !isUpdating ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => editDisplayName(newDisplayName)}
            >
              SAVE
            </div>
          ) : !isUpdateSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SAVING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setSelectPanel(SELECT_PROFILE.EDIT_NOTHING)}
            >
              SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SAVE
          </div>
        )}
      </div>
    </div>
  );
};
