export const VALUE = {
  VALUE_TEST_1: "hathum",
  INCREMENT: "incretment",
  DECREMENT: "decrement",
  DAMAGE: 30,
};

export const MACHINIE_LINK = {
  DISCORD: "https://discord.gg/asZurE4F4r",
  TWITTER: "https://twitter.com/machinie_nft",
  MEDIUM: "https://medium.com/@machinie_nft",
  INSTAGRAM: "https://www.instagram.com/machinie.nft/",
};

export const SMART_CONTRACT_ADDRESS = {
  ETH_MAINNET: {
    OLD_MACHINIE: "0xe6d3e488b9d31943df6e3b7d82f6f842679a1a8c",
    NEW_MACHINIE: "0xB826bDe739897ad50363d045d65eE5b83FDb730d",
    HUMACH_MAINNET: "0xAA89E8Eb3048A2D8cb1a3Db7D2B49f8FA33D56ba",
    MACHINIE_PRIZE: "0x096e0e2c5c037d2959f8386998b904f8baf089ef",
    FLOPPY: "0x9F3dDF3309D501FfBDC4587ab11e9D61ADD3126a",
  },
  POLYGON_MAINNET: {
    MESSAGE_FROM_MACHINIE: "0xE44Ab6F4df3761196b009D95b45559d44A5fC370",
  },

  ETH_RINKEBY: {
    HUMACH: "0x783d6f7317e22f342515f4fc821297BB2411519c",
    MINING: "0x5Bc28BEAD58e0D9147a23E35E3A38f9FC88552f3",
    FLOPPY: "0x51ff3b6e9cc491c2f3b7989c5af4525c5e227f92",
  },
  POLYGON_MUMBAI: {
    POAPS: "0x10E5911865B5Ce9CA351b423Ad2DAF884Bd2322B",
  },
};

export const CONTRACT_TOKEN = {
  HUMACH: "humach",
  MACHINIE: "machinie",
  PRIZE: "prize",
};

export const NETWORKS = {
  ETH_MAINNET: "eth_mainnet",
  NOT_MAINNET: "not_mainnet",
  NOT_CONNECT: "",
};

export const MACHIPON = {
  REWARDS_GET: {
    TICKET: "merchTicket",
    SCISSOR: "scissors",
    ROCK: "stone",
    PAPER: "paper",
    GM: "GM",
    DISCORD: "discordLVL",
  },
  REWARDS_TEXT: {
    TICKET: "Congrats! It's a ticket",
    SCISSOR: "You get a scissor",
    ROCK: "You get a rock",
    PAPER: "You get paper",
    GM: "It's a good day to try again",
    DISCORD: "Congrats! It's a ticket for 1000 discord XP",
  },
};

export const SELECTING = {
  HOME_PAGE: "HOME_PAGE",
  MACHINIE_PAGE: "MACHINIE_PAGE",
  HUMACH_PAGE: "HUMACH_PAGE",
  GAME_PAGE: "GAME_PAGE",
  DOCS_PAGE: "DOCS_PAGE",
};

export const SELECT_PAGE = {
  HOMEPAGE: "homePage",
  LEARN: {
    MACHINIE: "learnMachinie",
    HUMACH: "learnHumach",
  },
  GAME: {
    MACHIPON: "gameMachipon",
    SLOT: "gameSlot",
    LOTTO: "gameLotto",
  },
  NOT_FOUND: "pageNotFound",
  MY_NFT: {
    MACHINIE: {
      CHILLING: "myMachinieChilling",
      STAKING: "myMachinieStaking",
      MIGRATE: "myMachinieMigrate",
    },
    HUMACH: {
      CHILLING: "myHumachChilling",
      STAKING: "myHumachStaking",
      TRAINING: "myHumachTraining",
    },
  },
  REDEEM: {
    TICKET: "redeemTicket",
    MERCH: "redeemMerch",
    DISCORD: "redeemDiscord",
  },
  DOCS: {
    QUICK: {
      ECOSYSTEM: "ecosystem",
      GENERAL_FAQS: "general-faqs",
      GAS_CAL: "gas-price-calculator",
    },
    MACHINIE: {
      MACHINIE_FAQS: "machinie-faqs",
      MUST_READ: "must-read-before-stake",
      CHECK_MACHINIE_STATUS: "check-machinie-status",
      UNMIGRATED_LIST: "machinie-unmigrated-list",
      MACHINIE_TRAITS: "machinie-traits",
      MACHINIE_LICENSE: "machinie-license",
    },
    HUMACH: {
      HUMACH_FAQS: "humach-faqs",
      CHECK_HUMACH_STATUS: "check-humach-status",
      HUMACH_TRAITS: "humach-traits",
      ALLOWLIST: "humach-allowlist",
      HUMACH_LICENSE: "humach-license",
    },
    OTHER: {
      SMART_CONTRACT: "smart-contract",
      SECONDARY_MARKET: "secondary-market",
    },
  },
};

export const STATUS = {
  MACHINIE: {
    STAKING: "STAKING",
    CHILLING: "CHILLING",
    MIGRATE: "NEED MIGRATE",
  },
  HUMACH: {
    STAKING: "STAKING",
    CHILLING: "CHILLING",
    TRAINING: "TRAINING",
  },
};

export const SELECT_PROFILE = {
  EDIT_NOTHING: "edit_nothing",
  EDIT_DISPLAY_NAME: "edit_display_name",
  EDIT_DISCORD_ID: "edit_discord_id",
};

export const SELECT_REDEEM = {
  REDEEM_TICKET: "redeem_ticket",
  REDEEM_DISCORD: "redeem_discord",
};

export const SELECT_SEND_GIFT = {
  SEND_TICKET: "send_ticket",
  SEND_DISCORD: "send_discord",
  SEND_CURRENT: "send_current",
};

export const SELECT_SEND_GIFT_TYPE = {
  TICKET: "merchTicket",
  CURRENT: "floppy",
  DISCORD: "discordLVL",
};

export const SLOT_MACHI = {
  REWARDS_GET: {
    GM: 0,
    x05: 0.5,
    x2: 2,
    x3: 3,
    x5: 5,
    x10: 10,
    x20: 20,
  },
  REWARDS_TEXT: {
    GM: 0,
    x05: 1,
    x2: 2,
    x3: 3,
    x5: 5,
    x10: 10,
    x20: 20,
  },
};

export const VALUE_STATIC = {
  MINT_PANEL: {
    TOTAL_SUPPLY: "TBA",
    TOTLA_CALL_SUPPLY: "TBA",
    IS_ANNOUNCED_CHNAGE_SUPPLY: false,
  },
  FAQ: {
    // MINT_CAP:
    //   "The initial mint cap for Humach will be 3500. With breeding, that number will expand over time to a total 8888 Humach.",
    MINT_CAP: "The initial mint cap for Humach will be announced soon.",
  },
  CALL: {
    IS_HOLD: true,
    TEXT_HOLD: "Signal unstable. Calling is paused.",
  },
};
