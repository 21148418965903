import { useEffect, useState } from "react";
import { getWalletList } from "../../apiCallReturn";
import { api } from "../../axiosURL";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import {
  SELECT_REDEEM,
  SELECT_SEND_GIFT,
  SELECT_SEND_GIFT_TYPE,
} from "../../value";
import { API } from "../../valueApi";
import { LoadingDots } from "../component/LoadingDots";
import { Modal, Modal3 } from "../modal/Modal";
import { RedeemDiscord, RedeemTicket } from "./RedeemModal";

type SEND_GIFT_MODAL = {
  open: boolean;
  setIsOpen: any;
  walletList: any;
  setWalletList: any;
};

export const SendGiftModal = ({
  open,
  setIsOpen,
  setWalletList,
  walletList,
}: SEND_GIFT_MODAL) => {
  const [selectItem, setSelectItem] = useState(SELECT_SEND_GIFT.SEND_CURRENT);
  const [walletToGift, setWalletToGift] = useState("");
  const [amountToGift, setAmountToGift] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [open3, setIsOpen3] = useState(false);

  const sendGift = async (
    typeName: string,
    walletToGift: string,
    amountToGift: number
  ) => {
    setIsSending(true);
    try {
      const thisType = renderSelectType(typeName);
      const res = await api.post(
        API.TRADE,
        {
          name: thisType,
          amount: amountToGift,
          walletAddress: walletToGift,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      const thisWallet = await getWalletList();
      setWalletList(thisWallet.data);
      setIsSendSuccess(true);
    } catch (err: any) {
      let messageError = await err.response.data.message;

      if (messageError === "dont have enough : merchTicket") {
        setErrMessage("Not enough ticket.");
      } else if (messageError === "dont have enough : floppy") {
        setErrMessage("Not enough $current.");
      } else if (messageError === "dont have enough : discordLVL") {
        setErrMessage("Not enough Discord XP ticket.");
      } else if (messageError === "Dont have user for trade") {
        setErrMessage(
          "The wallet you are trying to gift is not in the system."
        );
      } else if (messageError === "Can not trade yourself") {
        setErrMessage("Sending youself a gift is not allowed :(");
      } else {
        setErrMessage(messageError);
      }
      setIsSendSuccess(false);
      setIsSending(false);
    }
  };

  const renderSelectNotEnough = (selectItem: string) => {
    switch (selectItem) {
      case SELECT_SEND_GIFT.SEND_CURRENT:
        return "$current";
      case SELECT_SEND_GIFT.SEND_DISCORD:
        return "Discord XP ticket";
      case SELECT_SEND_GIFT.SEND_TICKET:
        return "ticket";
    }
  };

  useEffect(() => {
    if (amountToGift > renderSelectValue(selectItem)) {
      setErrMessage("Not enough " + renderSelectNotEnough(selectItem));
    } else {
      setErrMessage("");
    }
  }, [amountToGift, selectItem]);

  const renderSelectValue = (selectItem: string) => {
    switch (selectItem) {
      case SELECT_SEND_GIFT.SEND_CURRENT:
        return walletList.floppy;
      case SELECT_SEND_GIFT.SEND_DISCORD:
        return walletList.discord.discordLVL;
      case SELECT_SEND_GIFT.SEND_TICKET:
        return walletList.ticket.merchTicket;
    }
  };

  const renderSelectType = (selectItem: string) => {
    switch (selectItem) {
      case SELECT_SEND_GIFT.SEND_CURRENT:
        return SELECT_SEND_GIFT_TYPE.CURRENT;
      case SELECT_SEND_GIFT.SEND_DISCORD:
        return SELECT_SEND_GIFT_TYPE.DISCORD;
      case SELECT_SEND_GIFT.SEND_TICKET:
        return SELECT_SEND_GIFT_TYPE.TICKET;
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setIsOpen(false);
        setIsSending(false);
        setIsSendSuccess(false);
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">SEND GIFT</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setIsSending(false);
            setIsSendSuccess(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      <div className="p-24">
        <SelectItemContainer
          selectPanelRedeem={selectItem}
          setSelectPanelRedeem={setSelectItem}
          setIsOpen={setIsOpen}
          walletList={walletList}
          setWalletList={setWalletList}
        />

        <div className="mt-24">
          <div className="bold-button text-color-line-tint-04">AMOUNT</div>
          <input
            className="reg-subtitle-1 text-color-gray-100 input-display-name mt-14"
            placeholder="Input amount"
            value={amountToGift}
            type="number"
            name="onluNumbers"
            min="0"
            max="99999"
            onChange={(e) => setAmountToGift(Number(e.target.value))}
          ></input>
        </div>

        <div className="mt-24">
          <div className="bold-button text-color-line-tint-04">
            WALLET ADDRESS
          </div>
          <input
            className="reg-subtitle-1 text-color-gray-100 input-display-name mt-14"
            placeholder="Input wallet address to give"
            value={walletToGift}
            onChange={(e) => setWalletToGift(e.target.value)}
          ></input>
        </div>

        {errMessage !== "" ? (
          <div className="reg-caption mt-14 text-color-secondary">
            {errMessage}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
            setIsSending(false);
            setIsSendSuccess(false);
          }}
        >
          BACK
        </div>

        {Number(amountToGift) > 0 &&
        walletToGift.length === 42 &&
        amountToGift <= renderSelectValue(selectItem) ? (
          !isSending ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => {
                sendGift(selectItem, walletToGift, amountToGift);
                setIsOpen3(true);
              }}
            >
              SEND GIFT
            </div>
          ) : !isSendSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SENDING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setIsOpen(false)}
            >
              SEND SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SEND GIFT
          </div>
        )}

        <SendingModal
          open={open3}
          setIsOpen={setIsOpen3}
          selectItem={selectItem}
          amountToGift={amountToGift}
          walletToGift={walletToGift}
          isSending={isSending}
          setIsSending={setIsSending}
          isSendSuccess={isSendSuccess}
          setIsSetSuccess={setIsSendSuccess}
          errMessage={errMessage}
        />
      </div>
    </Modal>
  );
};

type SELECT_ITEM_CONTAINER = {
  selectPanelRedeem: string;
  setSelectPanelRedeem: any;
  walletList: any;
  setWalletList: any;
  setIsOpen: any;
};

export const SelectItemContainer = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  walletList,
  setWalletList,
  setIsOpen,
}: SELECT_ITEM_CONTAINER) => {
  return (
    <div>
      <div className="bold-button text-color-line-tint-04">SELECT ITEM</div>
      <div className="mt-14 select-button-redeem-container">
        <CurrentSelect
          selectPanelRedeem={selectPanelRedeem}
          setSelectPanelRedeem={setSelectPanelRedeem}
          thisPanelRedeem={SELECT_SEND_GIFT.SEND_CURRENT}
          walletList={walletList}
        />
        <TicketSelect
          selectPanelRedeem={selectPanelRedeem}
          setSelectPanelRedeem={setSelectPanelRedeem}
          thisPanelRedeem={SELECT_SEND_GIFT.SEND_TICKET}
          walletList={walletList}
        />
        <RedeemDiscord
          selectPanelRedeem={selectPanelRedeem}
          setSelectPanelRedeem={setSelectPanelRedeem}
          thisPanelRedeem={SELECT_SEND_GIFT.SEND_DISCORD}
          walletList={walletList}
        />
      </div>
    </div>
  );
};

type REDEEM_BUTTON = {
  selectPanelRedeem: string;
  setSelectPanelRedeem: any;
  thisPanelRedeem: string;
  walletList: any;
};

export const CurrentSelect = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  thisPanelRedeem,
  walletList,
}: REDEEM_BUTTON) => {
  return (
    <div
      className={
        thisPanelRedeem === selectPanelRedeem
          ? "button-selected-redeem-each"
          : "button-select-redeem-each"
      }
      onClick={() => setSelectPanelRedeem(thisPanelRedeem)}
    >
      <div className="bg-color-gradient-radial img-button-select-redeem-container">
        <img
          src={IMAGE_ASSETS.INVENTORY.CURRENT}
          alt="current"
          width="18px"
          className="img-button-select-current"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="reg-caption text-color-line-tint-04">$current</div>
        <div className="bold-subtitle-2 text-color-gray-100">
          {walletList.floppy}
        </div>
      </div>
    </div>
  );
};

export const TicketSelect = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  thisPanelRedeem,
  walletList,
}: REDEEM_BUTTON) => {
  return (
    <div
      className={
        thisPanelRedeem === selectPanelRedeem
          ? "button-selected-redeem-each"
          : "button-select-redeem-each"
      }
      onClick={() => setSelectPanelRedeem(thisPanelRedeem)}
    >
      <div className="bg-color-gradient-radial img-button-select-redeem-container">
        <img
          src={IMAGE_ASSETS.INVENTORY.TICKET}
          alt="ticket"
          width="43px"
          className="img-button-select-redeem-ticket"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="reg-caption text-color-line-tint-04">TICKET</div>
        <div className="bold-subtitle-2 text-color-gray-100">
          {walletList.ticket.merchTicket}
        </div>
      </div>
    </div>
  );
};

type SENDING_MODAL = {
  open: boolean;
  setIsOpen: any;
  selectItem: string;
  amountToGift: number;
  walletToGift: string;
  isSending: boolean;
  setIsSending: any;
  isSendSuccess: boolean;
  setIsSetSuccess: any;
  errMessage: string;
};

export const SendingModal = ({
  open,
  setIsOpen,
  selectItem,
  amountToGift,
  walletToGift,
  isSending,
  setIsSending,
  isSendSuccess,
  setIsSetSuccess,
  errMessage,
}: SENDING_MODAL) => {
  const renderSelectType = (selectItem: string, amountToGift: number) => {
    switch (selectItem) {
      case SELECT_SEND_GIFT.SEND_CURRENT:
        return amountToGift + " $current";
      case SELECT_SEND_GIFT.SEND_DISCORD:
        return (amountToGift * 1000).toString() + " Discord XP";
      case SELECT_SEND_GIFT.SEND_TICKET:
        if (amountToGift > 1) {
          return amountToGift + " Tickets";
        } else {
          return amountToGift + " Ticket";
        }
    }
  };

  return (
    <Modal3 open={open}>
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">SEND GIFT</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsSending(false);
            setIsSetSuccess(false);
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      {isSending === true && isSendSuccess === false ? (
        <div className="p-24">
          <div className="bold-subtitle-2 text-color-gray-100">
            Sending{" "}
            <span className="text-color-primary-200">
              {renderSelectType(selectItem, amountToGift)}
            </span>{" "}
            to <span className="text-color-primary-200">{walletToGift}</span>
            <LoadingDots />
          </div>
        </div>
      ) : isSending === true && isSendSuccess === true ? (
        <div>
          <div className="p-24">
            <div className="bold-subtitle-2 text-color-gray-100">
              Sent{" "}
              <span className="text-color-secondary">
                {renderSelectType(selectItem, amountToGift)}
              </span>{" "}
              to <span className="text-color-primary-200">{walletToGift}</span>{" "}
              successfully.
            </div>
          </div>
          <div className="modal-stake-bottom-container-alone">
            <div
              className="modal-stake-button-cancel bold-button"
              onClick={() => {
                setIsSending(false);
                setIsSetSuccess(false);
                setIsOpen(false);
              }}
            >
              BACK
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="p-24">
            <div className="bold-subtitle-2 text-color-gray-100">
              Failed to send{" "}
              <span className="text-color-secondary">
                {renderSelectType(selectItem, amountToGift)}
              </span>{" "}
              to <span className="text-color-primary-200">{walletToGift}</span>{" "}
              <br />
              {errMessage}
            </div>
          </div>
          <div className="modal-stake-bottom-container-alone">
            <div
              className="modal-stake-button-cancel bold-button"
              onClick={() => {
                setIsSending(false);
                setIsSetSuccess(false);
                setIsOpen(false);
              }}
            >
              BACK
            </div>
          </div>
        </div>
      )}
    </Modal3>
  );
};
