import { useState, useEffect } from "react";

export const PowerOfHumach = () => {
  return (
    <section className="section">
      <div className="container">
        <div>
          <div className="text-center reg-subtitle-2 text-color-secondary">
            Utility
          </div>

          <HumachDoFourThings />

          <HumachFiveLevels />
        </div>
      </div>
    </section>
  );
};

export const HumachFiveLevels = () => {
  const level_1: string = `${process.env.PUBLIC_URL}/assets/humach5levels/lvl1.png`;
  const level_2: string = `${process.env.PUBLIC_URL}/assets/humach5levels/lvl2.png`;
  const level_3: string = `${process.env.PUBLIC_URL}/assets/humach5levels/lvl3.png`;
  const level_4: string = `${process.env.PUBLIC_URL}/assets/humach5levels/lvl4.png`;
  const level_5: string = `${process.env.PUBLIC_URL}/assets/humach5levels/lvl5.png`;

  return (
    <div className="mt-14 section">
      <div className="text-center bold-headline-4 text-color-primary-200 mt-14 mb-14">
        Humach Evolution
      </div>
      <div className="text-center reg-caption text-color-gray-100 mt-14 mb-14">
        The art changes when Humach leveled up
      </div>
      <div className="humach-levels-container mt-5 mb-5">
        <ImageLevelContainer image={level_1} level="1" des={["Default."]} />
        <ImageLevelContainer
          image={level_2}
          level="2"
          des={["Left and right armors are looted."]}
        />
        <ImageLevelContainer
          image={level_3}
          level="3"
          des={["Humach arm themsleves with their suitable main weapon."]}
        />
        <ImageLevelContainer
          image={level_4}
          level="4"
          des={[
            "An armor got removed and replaced by a sub weapon.",
            "Background got dimmed.",
          ]}
        />
        <ImageLevelContainer
          image={level_5}
          level="5"
          des={["Drone activation and join the pilar."]}
        />
      </div>{" "}
    </div>
  );
};

type IMAGE_LEVEL_CONTAINER = {
  image: string;
  level: string;
  des: string[];
};

export const ImageLevelContainer = ({
  level,
  image,
  des,
}: IMAGE_LEVEL_CONTAINER) => {
  return (
    <div className="image-level-container">
      <div className="text-levels-container">
        <div className="text-center mt-7 mb-7 reg-caption text-color-gray-100">
          {"Level " + level}
        </div>
      </div>
      <div className="image-levels-container">
        <img
          src={image}
          alt="humach level"
          className="img-levels"
          width={"100%"}
        ></img>
      </div>

      <div className="image-level-text-description">
        <div className="m-14">
          {des.map((x) => (
            <div className="reg-caption text-color-tertiary text-center">
              {x}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const HumachDoFourThings = () => {
  const action_1: string = `${process.env.PUBLIC_URL}/assets/humach4Actions/001-Sleep.gif`;
  const action_2: string = `${process.env.PUBLIC_URL}/assets/humach4Actions/002-Training.gif`;
  const action_3: string = `${process.env.PUBLIC_URL}/assets/humach4Actions/003-call.gif`;
  const action_4: string = `${process.env.PUBLIC_URL}/assets/humach4Actions/004-staking.gif`;

  const [value, setValue] = useState(1);
  return (
    <div>
      <div className="text-center bold-headline-4 text-color-primary-200 mt-14">
        Humach in actions
      </div>

      <div className="select-four-things-container">
        {value === 1 ? (
          <ButtonSelected text="CHILL" setValue={setValue} value={1} />
        ) : (
          <ButtonSelect text="CHILL" setValue={setValue} value={1} />
        )}
        {value === 2 ? (
          <ButtonSelected text="TRAIN" setValue={setValue} value={2} />
        ) : (
          <ButtonSelect text="TRAIN" setValue={setValue} value={2} />
        )}
        {value === 3 ? (
          <ButtonSelected text="CALL" setValue={setValue} value={3} />
        ) : (
          <ButtonSelect text="CALL" setValue={setValue} value={3} />
        )}
        {value === 4 ? (
          <ButtonSelected text="STAKE" setValue={setValue} value={4} />
        ) : (
          <ButtonSelect text="STAKE" setValue={setValue} value={4} />
        )}

        <div className="select-four-things-off">
          <div className="selet-text-four-things bold-button off">HUNT</div>
        </div>
      </div>

      <div className="m-auto-mobile">
        {value === 1 ? (
          <div className="action-panel-container m-auto-mobile">
            <HumachActionImagePanel image={action_1} />
            <TableHumachAction
              topic="Chill"
              detail="Humach chill in a wallet"
              cost="Nothing"
              result="Nothing"
            />
          </div>
        ) : value === 2 ? (
          <div className="action-panel-container">
            <HumachActionImagePanel image={action_2} />
            <TableHumachAction
              topic="Train"
              detail="Humach train in a wallet"
              cost="Time"
              result="Leveling, looting more parts, art changes"
            />
          </div>
        ) : value === 3 ? (
          <div className="action-panel-container">
            <HumachActionImagePanel image={action_3} />
            <TableHumachAction
              topic="Call"
              detail="Humach call another Humach using $floppy"
              cost="$floppy"
              result="Humach"
            />
          </div>
        ) : (
          <div className="action-panel-container">
            <HumachActionImagePanel image={action_4} />
            <TableHumachAction
              topic="Stake"
              detail="Humach using Machinie for stakig in a wallet"
              cost="Time and a Machinie puts in lock"
              result="$floppy"
            />
          </div>
        )}
      </div>
    </div>
  );
};

type BUTTON_SELECT_TYPES = {
  text: string;
  value: number;
  setValue: (value: number) => void;
};

export const ButtonSelect = ({
  text,
  setValue,
  value,
}: BUTTON_SELECT_TYPES) => {
  return (
    <div className="select-four-things" onClick={() => setValue(value)}>
      <div className="selet-text-four-things bold-button">{text}</div>
    </div>
  );
};

export const ButtonSelected = ({
  text,
  setValue,
  value,
}: BUTTON_SELECT_TYPES) => {
  return (
    <div className="selected-four-things" onClick={() => setValue(value)}>
      <div className="selet-text-four-things bold-button">{text}</div>
    </div>
  );
};

type HUMACH_ACTION_IMAGE_PANEL_TYPES = {
  image: string;
};

export const HumachActionImagePanel = ({
  image,
}: HUMACH_ACTION_IMAGE_PANEL_TYPES) => {
  return (
    <div className="bg-color-gradient-radial img-action-container">
      <img src={image} alt="humach action" className="img-action"></img>
    </div>
  );
};

type TABLE_HUMACH_ACTION_TYPES = {
  topic: string;
  detail: string;
  cost: string;
  result: string;
};

export const TableHumachAction = ({
  topic,
  detail,
  cost,
  result,
}: TABLE_HUMACH_ACTION_TYPES) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  return (
    <div className="game-right-panel text-machinie m-auto-tb width-responsive-flex">
      <div className="text-gold pb-3">{topic}</div>
      <div className="text-main  pb-3 font-bold">{detail}</div>
      <div className="table-cost-rewards-container">
        <div>
          <div className="p-3 h-50 border-cost bg-2">
            <div className="text-white">Cost</div>
          </div>
          <div className=" p-3 h-50 boder-rewards bg-2">
            <div className="text-white">Result</div>
          </div>
        </div>

        <div>
          <div className="p-3 h-50 border-cost-value ">
            <div className="text-white">{cost}</div>
          </div>

          <div className="text-white p-3 h-50">{result}</div>
        </div>
      </div>
    </div>
  );
};
