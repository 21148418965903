import machinieUpgrade from "../../../machinieUpgrade";
import floppyContract from "../../../floppyContract";
import { useState, useEffect } from "react";

export const CountFloppy = () => {
  const [floppySupply, setFloppySupply] = useState(0);
  const [floppyBurned, setFloppyBurned] = useState(0);
  const [totalFloppy, setTotalFloppy] = useState(0);
  //   const [machinieStaking, setMachinieStaking] = useState<any>([]);

  const [countId, setCountId] = useState(0);
  const [totalNotClaimed, setTotalNotClaim] = useState(0);

  useEffect(() => {
    getStart();
    getNext();
  }, []);

  const getStart = async () => {
    const getFloppyTotalSupply = await getFloppySupply();
    console.log(getFloppySupply);
    setFloppySupply(getFloppyTotalSupply);
    const getTotalBurned = await getFloppyBurned();
    setFloppyBurned(getTotalBurned);
  };

  const getNext = async () => {
    // let thisMachinie = [];
    let floppy = 0;
    for (let i = 0; i < 888; i++) {
      //   console.log("thisMachinie", thisMachinie);
      //   console.log("machinieStaking", machinieStaking);
      const thisFloppyToClaim = await getFloppyToClaim(i);
      //   const object: any = {};
      //   object["machinieId"] = i;
      //   object["floppyToClaim"] = thisFloppyToClaim;
      //   console.log("thisFloppyToClaim ", i, " : ", thisFloppyToClaim);
      //   thisMachinie.push(object);
      floppy += thisFloppyToClaim;
      let finalFloppy = floppy.toFixed(2);
      setCountId(i);
      setTotalNotClaim(Number(finalFloppy));

      //   setMachinieStaking(thisMachinie);
    }
  };

  const getFloppyToClaim = async (machinieId: number) => {
    let floppyToClaim = await machinieUpgrade.methods
      .getStakeReward(machinieId)
      .call();
    const convert = require("ether-converter");
    const result = convert(Number(floppyToClaim.toString()), "wei", "ether");
    const toNumber = Number(result).toFixed(2);

    return Number(toNumber);
  };

  const getTotalInWallet = (floppySupply: number, floppyBurned: number) => {
    let result = floppySupply - floppyBurned;
    let final = result.toFixed(2);
    return Number(final);
  };

  const getFloppySupply = async () => {
    let floppySupply = await floppyContract.methods.totalSupply().call();
    const convert = require("ether-converter");
    const result = convert(Number(floppySupply.toString()), "wei", "ether");
    const toNumber = Number(result).toFixed(2);

    return Number(toNumber);
  };

  const getFloppyBurned = async () => {
    let floppyBurned = await floppyContract.methods
      .balanceOf("0x000000000000000000000000000000000000dead")
      .call();

    const convert = require("ether-converter");
    const result = convert(Number(floppyBurned.toString()), "wei", "ether");
    const toNumber = Number(result).toFixed(2);

    return Number(toNumber);
  };

  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="bg-1 ">
      <div className="container pt-4 text-color-gray-100 reg-subtitle-2">
        <div className="text-color-primary-200 bold-headline-6">
          Count Floppy
        </div>

        <div className="pt-1">
          Total Claimed : {numberWithCommas(floppySupply)}
        </div>
        <div>Total Burned : {numberWithCommas(floppyBurned)}</div>
        <div className="reg-subtitle-1">
          Total In Wallet :{" "}
          {numberWithCommas(getTotalInWallet(floppySupply, floppyBurned))}
        </div>

        <div>Count : {countId}</div>

        <div>Total not claimed : {numberWithCommas(totalNotClaimed)}</div>

        {/* {machinieStaking.length > 0
          ? machinieStaking.map((x: any) => (
              <div>
                {x.machinieId} : {x.floppyToClaim}
              </div>
            ))
          : ""} */}
      </div>
    </div>
  );
};
