import { Link } from "react-router-dom";
import { MyNftSelectButton } from "./MyMachinie";
import { IconOpensea } from "../component/Icon";
import { useState, useEffect } from "react";
import { MyHumachChillingPanel } from "./MyHumachChilling/MyHumachChilling";
import { api } from "../../axiosURL";
import { MyHumachStakingPanel } from "./MyHumachStaking/MyHumachStaking";
import { MyHumachTrainingPanel } from "./MyHumachTraining/MyHumachTraining";
import web3 from "../../web3";
import machinieUpgrade from "../../machinieUpgrade";
import { CountDownClaim } from "../countdown_claim/CounDownClaim";
import { STATUS } from "../../value";
import { LoadingDots } from "../component/LoadingDots";

type MY_HUMACH_PROPS_TYPES = {
  select: string;
  humachList?: any;
  humachStaking: any;
  humachChilling: any;
  humachTraining: any;
  setHumachList: any;
  setWalletList: any;
  setMachinieList: any;
  machinieStaking: any;
  floppy: any;
  setFloppy: any;
};

export const MyHumach = ({
  select,
  humachList,
  humachStaking,
  humachChilling,
  humachTraining,
  setHumachList,
  setWalletList,
  setMachinieList,
  machinieStaking,
  floppy,
  setFloppy,
}: MY_HUMACH_PROPS_TYPES) => {
  return (
    <div className="width-100">
      <MyMachinieTop humachList={humachList} />

      <MyHumachSelect
        select={select}
        humachStaking={humachStaking}
        humachChilling={humachChilling}
        humachTraining={humachTraining}
        humachList={humachList}
        setHumachList={setHumachList}
        setWalletList={setWalletList}
        machinieStaking={machinieStaking}
      />

      <ShowHumachPanel
        select={select}
        setHumachList={setHumachList}
        humachChilling={humachChilling}
        humachStaking={humachStaking}
        humachTraining={humachTraining}
        setMachinieList={setMachinieList}
        setWalletList={setWalletList}
        machinieStaking={machinieStaking}
        floppy={floppy}
        setFloppy={setFloppy}
      />
    </div>
  );
};

type MY_HUMACH_TOP_PROPS = {
  humachList: any;
};

export const MyMachinieTop = ({ humachList }: MY_HUMACH_TOP_PROPS) => {
  const my_humach: string = `${process.env.PUBLIC_URL}/assets/humach/humach_my_humach_l.png`;
  return (
    <div className="d-flex">
      <div>
        <img src={my_humach} alt="machinie" width="100px"></img>
      </div>
      <div className="pl-24 m-auto-tb">
        <div className="bold-headline-4 text-color-primary-200">My Humach</div>
        {humachList !== undefined ? (
          <div className="reg-subtitle-2 text-color-line-tint-04 mt-12">
            {humachList.length} humach
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

type MY_HUMACH_SELECT_PROPS_TYPES = {
  select: string;
  humachStaking: any;
  humachChilling: any;
  humachTraining: any;
  setWalletList: any;
  humachList: any;
  setHumachList: any;
  machinieStaking: any;
};

export const MyHumachSelect = ({
  select,
  humachList,
  setHumachList,
  humachStaking,
  humachChilling,
  humachTraining,
  setWalletList,
  machinieStaking,
}: MY_HUMACH_SELECT_PROPS_TYPES) => {
  return (
    <div className="my-machinie-select-container-container">
      <div className="my-machinie-select-container">
        <MyNftSelectButton
          path="/my-humach/chilling"
          buttonName="CHILLING"
          unit={humachChilling.length.toString()}
          select={select}
        />

        <MyNftSelectButton
          path="/my-humach/staking"
          buttonName="STAKING"
          unit={humachStaking.length.toString()}
          select={select}
        />

        <MyNftSelectButton
          path="/my-humach/training"
          buttonName="TRAINING"
          unit={humachTraining.length.toString()}
          select={select}
        />
      </div>
      <div>
        {select === "STAKING" ? (
          <div className="staking-claim-top-container">
            <ClaimCurrentButtonHumach
              setWalletList={setWalletList}
              humachList={humachList}
              setHumachList={setHumachList}
            />
            <ClaimFloppyButton machinieStaking={machinieStaking} />
          </div>
        ) : (
          <ClaimCurrentButtonHumach
            setWalletList={setWalletList}
            humachList={humachList}
            setHumachList={setHumachList}
          />
        )}
      </div>
    </div>
  );
};

export const NoHumach = ({ topic, text, path, selectPage }: NO_HUMACH) => {
  const returnNoHumachPanel = (selectPage: string) => {
    switch (selectPage) {
      case "HUMACH_STAKING_PAGE":
        return (
          <div className="bold-button text-color-primary-200">
            see how it work{" "}
            <span className="text-color-tertiary">
              <i className="fa fa-plus"></i>
            </span>
          </div>
        );
      case "HUMACH_TRAINING_PAGE":
        return (
          <div className="bold-button text-color-primary-200">
            see how it work{" "}
            <span className="text-color-tertiary">
              <i className="fa fa-plus"></i>
            </span>
          </div>
        );
      case "HUMACH_CHILLING":
        return (
          <a href={path} target="_blank">
            <div className="button-buy-machinie m-auto">
              <div>
                <IconOpensea colorClass="text-color-gray-100" />
              </div>
              <div className="bold-button text-color-gray-100 m-auto-tb">
                MINT SOME HUMACH
              </div>
            </div>
          </a>
        );

      default:
        return (
          <a href={path} target="_blank">
            <div className="button-buy-machinie m-auto">
              <div>
                <IconOpensea colorClass="text-color-gray-100" />
              </div>
              <div className="bold-button text-color-gray-100 m-auto-tb">
                MINT SOME HUMACH
              </div>
            </div>
          </a>
        );
    }
  };
  return (
    <div className="no-machinie-panel">
      <div className="no-machinie-text-container">
        <div className="bold-headline-6 text-color-gray-100">{topic}</div>
        <div className="text-color-line-tint-04 reg-subtitle-2 mt-14 mb-14">
          {text}
        </div>
        {returnNoHumachPanel(selectPage)}
      </div>
    </div>
  );
};

type NO_HUMACH = {
  topic: string;
  text: string;
  path?: string;
  selectPage: string;
};

type SHOW_HUMACH_PANEL = {
  select: string;
  humachChilling: any;
  humachStaking: any;
  humachTraining: any;
  setWalletList: any;
  setHumachList: any;
  setMachinieList: any;
  machinieStaking: string[];
  floppy: any;
  setFloppy: any;
};

export const ShowHumachPanel = ({
  select,
  humachChilling,
  humachStaking,
  humachTraining,
  setWalletList,
  setHumachList,
  setMachinieList,
  machinieStaking,
  floppy,
  setFloppy,
}: SHOW_HUMACH_PANEL) => {
  const switchSelect = (select: string) => {
    switch (select) {
      case STATUS.HUMACH.CHILLING:
        return (
          <MyHumachChillingPanel
            humachChilling={humachChilling}
            setHumachList={setHumachList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case STATUS.HUMACH.STAKING:
        return (
          <MyHumachStakingPanel
            humachStaking={humachStaking}
            setHumachList={setHumachList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case STATUS.HUMACH.TRAINING:
        return (
          <MyHumachTrainingPanel
            humachTraining={humachTraining}
            setHumachList={setHumachList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      default:
        return "";
    }
  };
  return <>{switchSelect(select)}</>;
};

type CLAIM_CURRENT_BUTTON_HUMACH = {
  humachList: any;
  setHumachList: any;
  setWalletList: any;
};

export const ClaimCurrentButtonHumach = ({
  humachList,
  setHumachList,
  setWalletList,
}: CLAIM_CURRENT_BUTTON_HUMACH) => {
  const [humachNotClaimCount, setHumachNotClaimCount] = useState(0);
  const [isClaiming, setClaiming] = useState(false);
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  useEffect(() => {
    countHumachNotClaim();
  }, [humachList]);

  const countHumachNotClaim = async () => {
    let countNotClaimHumach = 0;
    const getHumachList = [...humachList];
    for (let i = 0; i < getHumachList.length; i++) {
      if (getHumachList[i]["isClaim"] === false) {
        countNotClaimHumach++;
      }
    }
    setHumachNotClaimCount(countNotClaimHumach);
  };

  const claimAllCurrntHumach = async () => {
    setClaiming(true);
    try {
      const url = `/api/humach/claim/all`;
      const res = await api.post(
        url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisWalletList = await getWalletList();
      const thisHumachList = await getHumachList();

      setHumachList(thisHumachList.data);
      setWalletList(thisWalletList.data);
    } catch (err: any) {}
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };
  return humachNotClaimCount > 0 ? (
    isClaiming ? (
      <div className="claimed-current-button cursor-pointer">
        <div className="bold-button text-color-gray-100 m-auto-tb">
          CLAIMING
          <LoadingDots />
        </div>
      </div>
    ) : (
      <div
        className="claim-current-button cursor-pointer"
        onClick={() => claimAllCurrntHumach()}
      >
        <div className="bold-button text-color-gray-100 m-auto-tb">
          CLAIM <span>{humachNotClaimCount * 5}</span>
        </div>
        <div>
          <img src={current} alt="current" width="18px"></img>
        </div>
      </div>
    )
  ) : (
    <div className="claimed-current-button text-center">
      <div className="bold-overline text-color-gray-100">
        NEXT CLAIM{" "}
        <span>
          <img src={current} alt="current" width="12px"></img>
        </span>
      </div>

      <div className="bold-subtitle-2 text-color-gray-100">
        <CountDownClaim />
      </div>
    </div>
  );
};

type CLAIM_FLOPPY_BUTTON_PROPS_TYPES = {
  machinieStaking: any[];
};

export const ClaimFloppyButton = ({
  machinieStaking,
}: CLAIM_FLOPPY_BUTTON_PROPS_TYPES) => {
  const floppy: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`;

  const [floppyToClaimALl, setFloppyToClaimAll] = useState("0");
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    getFloppyToClaimAll();
  }, [machinieStaking]);

  const claimFloppy = async () => {
    setIsClaiming(true);
    const newMachinieStakingArray = machinieStaking.map(
      (x) => x.machinieNumber
    );

    //console.log(newMachinieStakingArray);
    try {
      const accounts = await web3.eth.getAccounts();
      await machinieUpgrade.methods.claimFloppy(newMachinieStakingArray).send({
        from: accounts[0],
      });
      setFloppyToClaimAll("0");
      setIsClaiming(false);
    } catch (err: any) {
      //console.log(err.message);
      setIsClaiming(false);
    }
  };

  const getFloppyToClaimAll = async () => {
    let thisfloppyToClaimAll = 0;

    for (let i = 0; i < machinieStaking.length; i++) {
      let floppyToClaim = await machinieUpgrade.methods
        .getStakeReward(machinieStaking[i].machinieNumber)
        .call();
      const convert = require("ether-converter");
      const result = convert(Number(floppyToClaim.toString()), "wei", "ether");
      const toNumber = Number(result).toFixed(2);

      thisfloppyToClaimAll += Number(toNumber);

      //console.log(i, thisfloppyToClaimAll, Number(toNumber));
      // //console.log(i, Number(floppyToClaimAll).toFixed(2));
    }
    let result = Number(thisfloppyToClaimAll).toFixed(2);
    setFloppyToClaimAll(result);
  };
  return !isClaiming ? (
    <div
      className="claim-current-button cursor-pointer"
      onClick={() => claimFloppy()}
    >
      <div className="bold-button text-color-gray-100 m-auto-tb">
        CLAIM <span>{floppyToClaimALl}</span>
      </div>
      <div>
        <img src={floppy} alt="floppy" width="18px"></img>
      </div>
    </div>
  ) : (
    <div className="claim-current-button cursor-pointer">
      <div className="bold-button text-color-gray-100 m-auto-tb">
        CLAIM
        <LoadingDots /> <span>{floppyToClaimALl}</span>
      </div>
      <div>
        <img src={floppy} alt="floppy" width="18px"></img>
      </div>
    </div>
  );
};
