import { machinieTriple } from "../../../MachinieURL";
import { Modal, Modal2 } from "../../modal/Modal";
import { useState, useEffect } from "react";
import { machinie_image_url } from "../../../machinieImageUrl2";
import { HumachForStakeEach } from "./HumachForStakeEach";
import { ModalPairStake } from "./ModalPairStake";
import { api } from "../../../axiosURL";
import { NoMachinie } from "../MyMachinie";
import { MyNftMore } from "../MyNftMore/MyNftMore";
import { CONTRACT_TOKEN } from "../../../value";
import { LoadingDots } from "../../component/LoadingDots";
import machinieUpgrade from "../../../machinieUpgrade";

type MACHINIE_CHILLING_PANEL_PROPS_TYPES = {
  machinieChilling: any;
  humachChilling: any;
  setHumachList: any;
  setMachinieList: any;
  setWalletList: any;
  floppy: any;
  setFloppy: any;
};

export const MachinieChillingPanel = ({
  machinieChilling,
  humachChilling,
  setHumachList,
  setMachinieList,
  setWalletList,
  floppy,
  setFloppy,
}: MACHINIE_CHILLING_PANEL_PROPS_TYPES) => {
  const [machinieArray, setMachinieArray] = useState([]);
  const [humachArray, setHumachArray] = useState([]);
  const [selectIdMoreToggle, setSelectIdMoreToggle] = useState<string>("");

  return (
    <div>
      {machinieChilling.length > 0 ? (
        <div>
          <MachinieEachTopSelectAllChilling
            machinieArray={machinieArray}
            setMachinieArray={setMachinieArray}
            setHumachArray={setHumachArray}
            humachArray={humachArray}
            humachChilling={humachChilling}
            machinieChilling={machinieChilling}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
          />
          <div className="machinie-each-panel">
            {machinieChilling.map((x: any) => (
              <MachinieChillingEach
                tokenId={x.machinieNumber.toString()}
                setMachinieList={setMachinieList}
                machinieArray={machinieArray}
                setWalletList={setWalletList}
                machinieListEach={x}
                setMachinieArray={setMachinieArray}
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoMachinie
          topic="No machinie here"
          text="Your Machinie will show up here."
          path="https://opensea.io/collection/machinie"
          isStaking={false}
        />
      )}
    </div>
  );
};

export const MachinieEachTopSelectAllChilling = ({
  machinieArray,
  setMachinieArray,
  humachChilling,
  machinieChilling,
  setMachinieList,
  setHumachList,
}: MACHINIE_EACH_TOP_SELECT_PROPS_TYPES) => {
  const [isOpen, setIsOpen] = useState(false);
  const [objectPair, setObjectPair] = useState([{}]);
  const [machinieChillArray, setMachinieChillArray] = useState([]);
  const [humachChillArray, setHumachChillArray] = useState<any>([]);

  useEffect(() => {
    getHumachChillArray(humachChilling);
  }, [humachChilling]);

  const getHumachChillArray = (humachChilling: any[]) => {
    let thisHumachChilling = [];
    for (let i = 0; i < humachChilling.length; i++) {
      thisHumachChilling.push(humachChilling[i]["humachNumber"].toString());
    }

    setHumachChillArray(thisHumachChilling);
  };

  const selectAll = () => {
    const res = machinieChilling.map((x) => x.machinieNumber.toString());
    // //console.log(res);
    setMachinieArray(res);
  };

  const createObject = () => {
    let obj = [];

    interface PAIR_OBJECT {
      machinieId: string;
      humachId?: string;
    }

    for (let i = 0; i < machinieArray.length; i++) {
      let test: PAIR_OBJECT = {
        machinieId: machinieArray[i],
      };

      obj.push(test);
    }

    setObjectPair(obj);

    // //console.log(obj);
  };

  const deselectAll = () => {
    setMachinieArray([]);
  };

  return (
    <div className="machinie-chilling-top">
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => selectAll()}
      >
        SELECT ALL
      </div>

      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => deselectAll()}
      >
        DESELECT ALL
      </div>

      {machinieArray.length > 0 ? (
        <div
          className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer"
          onClick={() => {
            setIsOpen(true);
            createObject();
          }}
        >
          STAKE SELECTED
        </div>
      ) : (
        <div className="stake-select-all-button bold-button text-color-gray-100 cursor-pointer">
          STAKE SELECTED
        </div>
      )}

      <ModalPairStake
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        machinieArray={machinieArray}
        humachChilling={humachChillArray}
        machinieChilling={machinieChilling}
        objectPair={objectPair}
        setObjectPair={setObjectPair}
        setMachinieList={setMachinieList}
        setHumachList={setHumachList}
      />
    </div>
  );
};

type MACHINIE_EACH_TOP_SELECT_PROPS_TYPES = {
  machinieArray: string[];
  setMachinieArray: any;
  setHumachArray: any;
  humachArray: string[];
  humachChilling: any[];
  machinieChilling: any[];
  setMachinieList: any;
  setHumachList: any;
};

type MACHINIE_CHILLING_EACH_PROPS_TYPES = {
  tokenId: string;
  setMachinieList: any;
  machinieArray: string[];
  setWalletList: any;
  machinieListEach: any;
  setMachinieArray: any;
  selectIdMoreToggle: string;
  setSelectIdMoreToggle: any;
  floppy: any;
  setFloppy: any;
};

export const MachinieChillingEach = ({
  tokenId,
  machinieArray,
  setMachinieList,
  setWalletList,
  machinieListEach,
  setMachinieArray,
  selectIdMoreToggle,
  setSelectIdMoreToggle,
  floppy,
  setFloppy,
}: MACHINIE_CHILLING_EACH_PROPS_TYPES) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [machinieArrayNew, setMachinieArrayNew] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [traitsType, setTraitsType] = useState("");
  const [IsClaiming, setClaiming] = useState(false);
  const [tokenName, setTokenName] = useState("");

  useEffect(() => {
    if (machinieArray.includes(tokenId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [machinieArray]);

  useEffect(() => {
    getMachinieTrait();
    getTokenNameMachinie(tokenId);
  }, []);

  const getMachinieTrait = async () => {
    const res: any = await api.get(
      "https://rest-api-machinie-nft.herokuapp.com/tokens/" + tokenId
    );
    // //console.log("machinieId : " + machinieId, res["traits"][3]["value"]);

    if (res["data"]["traits"] !== undefined) {
      setTraitsType(res["data"]["traits"][3]["value"]);
    } else {
      setTraitsType("Dead token");
    }
  };

  const getTokenNameMachinie = async (tokenId: string | undefined) => {
    try {
      const res = await machinieUpgrade.methods.getTokenIdName(tokenId).call();
      setTokenName(
        res[0] !== "" ? res[0] + " #" + tokenId : "Machinie #" + tokenId
      );
      return;
    } catch (err) {}
  };

  const addThisMachinieToken = () => {
    const addMachinieArrayNew = [...machinieArray];
    addMachinieArrayNew.push(tokenId);
    setMachinieArray(addMachinieArrayNew);
    //console.log(addMachinieArrayNew);
  };

  const removeThisMachinieToken = () => {
    const removeMachinieArrayNew = [...machinieArray];
    var filteredArray = removeMachinieArrayNew.filter((e) => e !== tokenId);
    //console.log(filteredArray);
    setMachinieArray(filteredArray);
  };

  const onClickClaim = async () => {
    setClaiming(true);
    try {
      const url = `/api/machinie/claim/${machinieListEach["id"]}`;
      const res = await api.post(
        url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisMachinieList = await getMachinieList();
      const thisWalletList = await getWalletList();
      setMachinieList(thisMachinieList.data);
      setWalletList(thisWalletList.data);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // //console.log(res.data);
      return res.data;
    }
  };

  return (
    <div
      className={
        !isSelect
          ? "machinie-each-container"
          : "machinie-each-container-selected"
      }
    >
      {isSelect ? (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(false);
            removeThisMachinieToken();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-check-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      ) : (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(true);
            addThisMachinieToken();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      )}
      <div className="machinie-each-detail">
        <div className="display-mobile">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(tokenId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="border-left-mobile">
          <div className="text-color-gray-100 reg-caption machinie-each-text-second-container">
            <div className=" machinie-each-text-second">{traitsType}</div>
            <div className=" machinie-each-text-second border-text">
              Chilling
            </div>
          </div>
          <div className="display-mobile">
            <div className="claim-current-contianer">
              {!machinieListEach["isClaim"] ? (
                IsClaiming ? (
                  <div className="claim-current-padding">
                    <div className="claimed-current">
                      <div className="bold-button text-color-gray-100">
                        CLAIMING
                        <LoadingDots />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="claim-current-padding ">
                    <div
                      className="claim-current cursor-pointer"
                      onClick={() => onClickClaim()}
                    >
                      <div className="bold-button text-color-gray-100">
                        CLAIM 10{" "}
                        <span>
                          <img src={current} alt="current" width="13.5px"></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <MyNftMore
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                id={tokenId}
                contractToken={CONTRACT_TOKEN.MACHINIE}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            </div>
          </div>
        </div>

        <div className="display-desktop">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(tokenId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="display-desktop">
          <div className="claim-current-contianer">
            {!machinieListEach["isClaim"] ? (
              IsClaiming ? (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIMING
                      <LoadingDots />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="claim-current-padding ">
                  <div
                    className="claim-current cursor-pointer"
                    onClick={() => onClickClaim()}
                  >
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="claim-current-padding">
                <div className="claimed-current">
                  <div className="bold-button text-color-gray-100">
                    CLAIM 10{" "}
                    <span>
                      <img src={current} alt="current" width="13.5px"></img>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <MyNftMore
              selectIdMoreToggle={selectIdMoreToggle}
              setSelectIdMoreToggle={setSelectIdMoreToggle}
              id={tokenId}
              contractToken={CONTRACT_TOKEN.MACHINIE}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
