import { HumachSlide } from "./slides/HumachSlide";
import { Link } from "react-router-dom";

export const HomeHumachSection = () => {
  const logo_humach: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-humach-l.png`;

  return (
    <section className="home-machinie-section-container section bg-1">
      <div className="home-machinie-padding">
        <div className="container pb-5">
          <div>
            <img src={logo_humach} alt="logo_machinie_l"></img>
          </div>
          <div className="reg-body-2 text-color-secondary mt-24">
            Humach ( hu • mash)
          </div>
          <div className="justify-between-responsive mt-14">
            <div className="first-child-res bold-headline-6 text-color-primary-200 col-6">
              Mysterious space beings, searching the galaxy for their source of
              power <span className="text-color-primary-400">(Machinie)</span>.
            </div>
            <div className="second-child-res">
              <Link
                to="/humach"
                className="text-button text-machinie button-machinie"
              >
                LEARN MORE & MINT SOME!
              </Link>
            </div>
          </div>
        </div>

        <HumachSlide position={true} />
      </div>
    </section>
  );
};
