import { Link } from "react-router-dom";
import { IconOpensea } from "../component/Icon";
import { MachinieChillingPanel } from "./MyMachinieChilling/MyMachinieChillingEach";
import { machinieAbi, machinieAddress } from "../web3";
import { ethers } from "ethers";
import web3 from "../../web3";
import machinieUpgrade from "../../machinieUpgrade";
import { useEffect, useState } from "react";
import { MachinieStakingPanel } from "./MyMachinieStaking/MyMachinieStaking";
import { api } from "../../axiosURL";
import { CountDownClaim } from "../countdown_claim/CounDownClaim";
import { MachinieNotMigratePanel } from "./MyMachinieNotMigrate/MyMachinieNotMigrate";
import { LoadingDots } from "../component/LoadingDots";

declare var window: any;

type MY_MACHINIE_PROPS_TYPES = {
  select: string;
  machinieList?: any;
  humachList?: any;
  setMachinieList?: any;
  setHumachList?: any;
  setWalletList?: any;
  walletList?: any;
  machinieStaking?: any;
  machinieChilling?: any;
  machinieNotMigrate?: any;
  humachChilling?: any;
  floppy: any;
  setFloppy: any;
};

export const MyMachinie = ({
  select,
  machinieList,
  humachList,
  setMachinieList,
  setHumachList,
  setWalletList,
  walletList,
  humachChilling,
  machinieChilling,
  machinieStaking,
  machinieNotMigrate,
  floppy,
  setFloppy,
}: MY_MACHINIE_PROPS_TYPES) => {
  return (
    <div className="width-100">
      <MyMachinieTop machinieList={machinieList} />

      <MyMachinieSelect
        select={select}
        machinieList={machinieList}
        machinieChilling={machinieChilling}
        machinieStaking={machinieStaking}
        machinieNotMigrate={machinieNotMigrate}
        setMachinieList={setMachinieList}
        setWalletList={setWalletList}
        floppy={floppy}
        setFloppy={setFloppy}
      />

      <ShowMachiniePanel
        select={select}
        machinieList={machinieList}
        machinieChilling={machinieChilling}
        machinieStaking={machinieStaking}
        machinieNotMigrate={machinieNotMigrate}
        humachChilling={humachChilling}
        setHumachList={setHumachList}
        setMachinieList={setMachinieList}
        setWalletList={setWalletList}
        floppy={floppy}
        setFloppy={setFloppy}
      />
    </div>
  );
};

type MY_MACHINIE_TOP_PROPS = {
  machinieList: any;
};

export const MyMachinieTop = ({ machinieList }: MY_MACHINIE_TOP_PROPS) => {
  const my_machinie: string = `${process.env.PUBLIC_URL}/assets/machinie/machinie_my_machinie_l.png`;
  return (
    <div className="d-flex">
      <div>
        <img src={my_machinie} alt="machinie" width="100px"></img>
      </div>
      <div className="pl-24 m-auto-tb">
        <div className="bold-headline-4 text-color-primary-200">
          My Machinie
        </div>
        {machinieList !== undefined ? (
          <div className="reg-subtitle-2 text-color-line-tint-04 mt-12">
            {machinieList.length}{" "}
            {machinieList.length > 1 ? "machinies" : "machinie"}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const MyMachinieSelect = ({
  select,
  machinieList,
  setMachinieList,
  setWalletList,
  walletList,
  machinieStaking,
  machinieChilling,
  machinieNotMigrate,
  setFloppy,
}: MY_MACHINIE_PROPS_TYPES) => {
  return (
    <div className="my-machinie-select-container-container">
      <div className="my-machinie-select-container">
        <MyNftSelectButton
          path="/my-machinie/chilling"
          buttonName="CHILLING"
          unit={machinieChilling.length.toString()}
          select={select}
        />
        <MyNftSelectButton
          path="/my-machinie/staking"
          buttonName="STAKING"
          unit={machinieStaking.length.toString()}
          select={select}
        />
        <MyNftSelectButton
          path="/my-machinie/migrate"
          buttonName="NEED MIGRATE"
          unit={machinieNotMigrate.length.toString()}
          select={select}
        />
      </div>

      <div>
        {select === "STAKING" ? (
          <div className="staking-claim-top-container">
            <ClaimCurrentButton
              machinieList={machinieList}
              setMachinieList={setMachinieList}
              setWalletList={setWalletList}
              walletList={walletList}
            />
            <ClaimFloppyButton
              machinieStaking={machinieStaking}
              setFloppy={setFloppy}
            />
          </div>
        ) : (
          <ClaimCurrentButton
            machinieList={machinieList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            walletList={walletList}
          />
        )}
      </div>
    </div>
  );
};

export const MyNftSelectButton = ({
  path,
  buttonName,
  unit,
  select,
}: MY_NFT_SELECT_BUTTON_PROPS_TYPES) => {
  return (
    <Link to={path}>
      <div
        className={
          select === buttonName
            ? "my-nft-selected-button"
            : "my-nft-select-button"
        }
      >
        <div className="text-color-tertiary bold-button m-auto-tb">
          {buttonName}
        </div>
        <div className="text-color-primary-1100 bold-button text-unit">
          {unit}
        </div>
      </div>
    </Link>
  );
};

type MY_NFT_SELECT_BUTTON_PROPS_TYPES = {
  path: string;
  buttonName: string;
  unit: string;
  select: string;
};

export const NoMachinie = ({ topic, text, path, isStaking }: NO_MACHINIE) => {
  return (
    <div className="no-machinie-panel">
      <div className="no-machinie-text-container">
        <div className="bold-headline-6 text-color-gray-100">{topic}</div>
        <div className="text-color-line-tint-04 reg-subtitle-2 mt-14 mb-14">
          {text}
        </div>
        {isStaking ? (
          <div className="bold-button text-color-primary-200">
            see how it work{" "}
            <span className="text-color-tertiary">
              <i className="fa fa-plus"></i>
            </span>
          </div>
        ) : (
          <a href={path} target="_blank">
            <div className="button-buy-machinie m-auto">
              <div>
                <IconOpensea colorClass="text-color-gray-100" />
              </div>
              <div className="bold-button text-color-gray-100 m-auto-tb">
                BUY ON OPENSEA
              </div>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

type NO_MACHINIE = {
  topic: string;
  text: string;
  path?: string;
  isStaking: boolean;
};

type CALIM_CURRENT_BUTTON = {
  machinieList: any[];
  setMachinieList: any;
  walletList: any[];
  setWalletList: any;
};

export const ClaimCurrentButton = ({
  machinieList,
  setMachinieList,
  walletList,
  setWalletList,
}: CALIM_CURRENT_BUTTON) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [machinieNotClaimCount, setMachinieNotClaimCount] = useState(0);
  const [isClaiming, setClaiming] = useState(false);

  useEffect(() => {
    countMachinieNotClaim();
  }, [machinieList]);

  const claimAll = async () => {
    try {
      setClaiming(true);
      const url = `/api/machinie/claim/all`;
      const res = await api.post(
        url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      //console.log("res", res);

      const thisWalletList = await getWalletList();
      const thisMachinielist = await getMachinieList();

      //console.log("thisMachinielist claim all", thisMachinielist.data);
      //console.log("thisWalletList claim all", thisWalletList.data);
      setMachinieList(thisMachinielist.data);
      setWalletList(thisWalletList.data);
    } catch (err: any) {
      setClaiming(false);
      //console.log(err.message);
    }
  };

  const countMachinieNotClaim = async () => {
    setClaiming(false);
    let countNotClaim = 0;
    const getMachinieList = await [...machinieList];
    for (let i = 0; i < getMachinieList.length; i++) {
      if (getMachinieList[i]["isClaim"] === false) {
        countNotClaim++;
      }
    }
    setMachinieNotClaimCount(countNotClaim);
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return machinieNotClaimCount > 0 ? (
    isClaiming ? (
      <div className="claimed-current-button cursor-pointer">
        <div className="bold-button text-color-gray-100 m-auto-tb">
          CLAIMING
          <LoadingDots />
        </div>
      </div>
    ) : (
      <div
        className="claim-current-button cursor-pointer"
        onClick={() => claimAll()}
      >
        <div className="bold-button text-color-gray-100 m-auto">
          CLAIM <span>{machinieNotClaimCount * 10}</span>{" "}
          <span>
            <img src={current} alt="current" width="18px"></img>
          </span>
        </div>
      </div>
    )
  ) : (
    <div className="claimed-current-button text-center">
      <div className="bold-overline text-color-gray-100">
        NEXT CLAIM{" "}
        <span>
          <img src={current} alt="current" width="12px"></img>
        </span>
      </div>

      <div className="bold-subtitle-2 text-color-gray-100">
        <CountDownClaim />
      </div>
    </div>
  );
};

type CLAIM_FLOPPY_BUTTON_PROPS_TYPES = {
  machinieStaking: any[];
  setFloppy: any;
};

export const ClaimFloppyButton = ({
  machinieStaking,
  setFloppy,
}: CLAIM_FLOPPY_BUTTON_PROPS_TYPES) => {
  const floppy: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`;

  const [floppyToClaimALl, setFloppyToClaimAll] = useState("0");
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    getFloppyToClaimAll();
  }, [machinieStaking]);

  const claimFloppy = async () => {
    setIsClaiming(true);
    const newMachinieStakingArray = machinieStaking.map(
      (x) => x.machinieNumber
    );

    try {
      const accounts = await web3.eth.getAccounts();
      await machinieUpgrade.methods.claimFloppy(newMachinieStakingArray).send({
        from: accounts[0],
      });
      setFloppyToClaimAll("0");
      setIsClaiming(false);
    } catch (err: any) {
      setIsClaiming(false);
    }
  };

  const getFloppyToClaimAll = async () => {
    let thisfloppyToClaimAll = 0;

    for (let i = 0; i < machinieStaking.length; i++) {
      let floppyToClaim = await machinieUpgrade.methods
        .getStakeReward(machinieStaking[i].machinieNumber)
        .call();
      const convert = require("ether-converter");
      const result = convert(Number(floppyToClaim.toString()), "wei", "ether");
      const toNumber = Number(result).toFixed(2);

      thisfloppyToClaimAll += Number(toNumber);
    }
    let result = Number(thisfloppyToClaimAll).toFixed(2);
    setFloppyToClaimAll(result);
  };
  return !isClaiming ? (
    <div
      className="claim-current-button cursor-pointer"
      onClick={() => claimFloppy()}
    >
      <div className="bold-button text-color-gray-100 m-auto-tb">
        CLAIM <span>{floppyToClaimALl}</span>
      </div>
      <div>
        <img src={floppy} alt="floppy" width="18px"></img>
      </div>
    </div>
  ) : (
    <div className="claim-current-button cursor-pointer">
      <div className="bold-button text-color-gray-100 m-auto-tb">
        CLAIM
        <LoadingDots /> <span>{floppyToClaimALl}</span>
      </div>
      <div>
        <img src={floppy} alt="floppy" width="18px"></img>
      </div>
    </div>
  );
};

export const ShowMachiniePanel = ({
  select,
  machinieChilling,
  machinieStaking,
  machinieNotMigrate,
  humachChilling,
  setHumachList,
  setMachinieList,
  setWalletList,
  floppy,
  setFloppy,
}: MY_MACHINIE_PROPS_TYPES) => {
  const switchSelect = (select: string) => {
    switch (select) {
      case "CHILLING":
        return (
          <MachinieChillingPanel
            machinieChilling={machinieChilling}
            humachChilling={humachChilling}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case "STAKING":
        return (
          <MachinieStakingPanel
            machinieStaking={machinieStaking}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case "NEED MIGRATE":
        return (
          <MachinieNotMigratePanel
            machinieNotMigrate={machinieNotMigrate}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            setHumachList={setHumachList}
          />
        );
      default:
        return (
          <MachinieChillingPanel
            machinieChilling={machinieChilling}
            humachChilling={humachChilling}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
    }
  };
  return <>{switchSelect(select)}</>;
};
