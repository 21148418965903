import ReactDom from "react-dom";

const protalDiv = document.getElementById("portal")!;

export const Modal = ({
  open,
  setIsOpen,
  onClose,
  children,
  wrapperRef,
}: MODAL) => {
  if (!open) {
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "0px";
  } else {
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "15px";
  }
  //   if (!open) return null;
  return protalDiv
    ? ReactDom.createPortal(
        open ? (
          <div>
            <div className="modal-overlay" onClick={onClose} />
            <div className="modal-style width-modal">
              {/* <button onClick={onClose}>close modal</button> */}
              {children}
            </div>
          </div>
        ) : (
          ""
        ),
        protalDiv
      )
    : null;
};

type MODAL = {
  open: boolean;
  setIsOpen?: any;
  onClose?: () => void;
  children?: any;
  wrapperRef?: any;
};

export const Modal2 = ({ open, setIsOpen, onClose, children }: MODAL) => {
  // if (!open) return null;
  return protalDiv
    ? ReactDom.createPortal(
        open ? (
          <div>
            <div className="modal-overlay-2" />
            <div className="modal-style-2 width-modal">{children}</div>
          </div>
        ) : (
          ""
        ),
        protalDiv
      )
    : null;
};

export const Modal3 = ({ open, onClose, children }: MODAL) => {
  // if (!open) return null;
  return protalDiv
    ? ReactDom.createPortal(
        open ? (
          <>
            <div className="modal-overlay-2" />
            <div className="modal-style-2 width-modal">{children}</div>
          </>
        ) : (
          ""
        ),
        protalDiv
      )
    : null;
};
