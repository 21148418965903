import { FaqEach } from "../homepage/faq/FaqSection";
import { useState, useEffect } from "react";
import { FaqEachHowGenerateCurrent } from "../learn_machinie/FaqLearnMachinieSection";
import { VALUE_STATIC } from "../../value";

export const FaqLearnHumachSection = () => {
  return (
    <section className="section bg-1">
      <div className="container">
        <div className="text-center">
          <div className="text-gold text-machinie">Humach FAQs</div>
          <div className="text-main font-bold-xl text-machinie pt-2">
            Here are some important information you should read
          </div>
        </div>
        <FaqLearnHumachContainer />
      </div>
    </section>
  );
};

export const FaqLearnHumachContainer = () => {
  const [select, setSelect] = useState("");
  return (
    <div className="faq-container bg-2 mt-5">
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What are Humach?"
        description="Mysterious space beings, searching the galaxy for their source of power. Along the way, they train their minds and bodies to level up, battle each other for supremacy, and call upon other Humach for support. Humachs posses the ancient knowledge required to activate Genesis Machinie and generate $floppy. They can use $floppy to discover the truth about their mysterious origins, as well as to call in additional Humach (breed). Call transmission cost is lower the more Humach level up. Your Humach’s level will also play an important role in the next phase of the project. Humach alone can generate $current daily."
      />
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="Do Humach generate $floppy?"
        description="Yes, in the near future. Humach will be randomly generating $floppy on their space mission. One of the factor affecting $floppy drop rate is Humach levels.
        "
      />
      <FaqEachHowGenerateCurrent
        topic="How much $current can I generate per day?"
        select={select}
        setSelect={setSelect}
      />

      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What is the utility for $current token?"
        description="$current can be used to play game machines in Machiniverse. At the moment we have 2 games, Slot Machi and Machipon."
      />
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="I am concerned that I will not be able to acquire a Genesis Machinie and my Humach will have less utility."
        description="Not only now Humach can generate $current on their own, in the next phase of the Machinie ecosystem, as the utility of $floppy expands, There is a Humach prophecy that when all Humach have been called, a new generation of Machinie will emerge, and the hunt will begin.
        "
      />
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What is the max supply of Humach?"
        description={VALUE_STATIC.FAQ.MINT_CAP}
      />

      <FaqEachHowToGetHumach
        topic="How can I acquire a Humach?"
        select={select}
        setSelect={setSelect}
      />
    </div>
  );
};

type FAQ_HOW_TO_GET_HUMACH = {
  topic: string;
  select: string;
  setSelect: any;
};

export const FaqEachHowToGetHumach = ({
  topic,
  select,
  setSelect,
}: FAQ_HOW_TO_GET_HUMACH) => {
  const [bool, setBool] = useState(false);

  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };

  return (
    <div className="">
      <div className="faq-each-margin" onClick={() => switchBool(bool)}>
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">{topic}</div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>
      </div>
      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            Humach was a stealth mint on November 17th, 2021
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            Mint price is 0.068 ETH
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            Mint cap is 3 Humach per transaction
          </div>

          <div className="mt-24 reg-subtitle-2 text-color-line-tint-04">
            You can alternatively purchase a Humach on the secondary market
            here:{" "}
            <a href="https://opensea.io/collection/humach">
              https://opensea.io/collection/humach
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
