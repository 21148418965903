export const API = {
  USER: {
    GET: "/api/user/",
    EDIT_USER: "/api/user/",
    GENERATE_NAME: "/api/user/generate_name",
  },
  LOTTO_ENTRY: {
    GET_ENTRY: "/api/lotto/entry",
    GET_PUBLIC_ENTRY: "/api/lotto/entry/public",
    SUBMIT_ENTRY: "/api/lotto/entry/submit",
    GET_USER_ENTRY: "/api/lotto/entry/user",
  },
  LOTTO_STATUS: {
    CHECK_STATUS: "/api/lotto/status/check",
    CHECK_STATUS_TEST_ADMIN: "/api/lotto/status/check_test",
    CHECK_STATUS_PUBLIC: "/api/lotto/status/check_public",
  },
  LOTTO_CREATE: {
    CREATE: "/api/lotto/config",
  },
  TRADE: "/api/trade/",
  ACTION_LOG: {
    SEARCH: "/api/actionlog/search",
  },
  REWARD: {
    SEARCH: "/api/reward/search",
    CONFIG: "/api/reward.config",
    CLAIM: "/api/reward/custom",
  },
  ADDRESS: {
    GET: "/api/address/search",
    GET_BY_USER: "/api/address/",
    CREATE: "/api/address",
  },
  LOG: {
    SEARCH: "/actionlog/search",
  },
};
// 624f0a22aa44d73e94d62667

// "name": "testing new site",
// "openDate": 1649397759,
// "closeDate": 1649397939,
// "raffleDate": 1649398179,
// "minEntry": 1,
// "maxEntry": 30,
// "walletConfigId": "61c00c4752e1603b9cb95b4f",
// "isActive": true
