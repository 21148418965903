import { GasPriceCalculator } from "./GasPriceCalculator";
import { CheckStatusHumach, CheckStatusMachinie } from "./CheckStatus";
import { Link } from "react-router-dom";
import { VALUE_STATIC } from "../../value";

export const DocsEcosystem = () => {
  return (
    <div className="text-color-gray-100">
      <div>
        <div className="reg-overline text-color-secondary">Quick Start</div>
        <div className="bold-headline-5 mt-6">Ecosystem</div>
      </div>
      <div className="mt-32">
        <div className="bold-headline-6 mb-14">Machinieverse</div>
        <div className="reg-subtitle-2">
          In the modern galaxy, space beings are on their way pursuing what they
          aim for. There are combats, battles, businesses and races happening
          everywhere across the galaxy. Make a stop and you get passed by
          others. In order to stand out from the pack, a powerful source of
          energy that can keep you running forever in space is needed. A group
          of space travelers who know the truth behind the myths and are in
          search for their source of power are called Humach. What they seek is
          called Machinie.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">Genesis Machinie</div>
        <div className="reg-subtitle-2 mb-14">
          The first kind of Machinies to ever exist were genesis Machinies.
          There are only 888 units of of the original species. Each Humach who
          owns one can generate a certain amount of $floppy depending on how
          rare their genesis machinie is. The power of $floppy generation is due
          to the{" "}
          <span className="text-color-gray-100">‘combination of type’</span>{" "}
          each machinie contained.
        </div>
        <div className="reg-subtitle-2">
          <div className="d-flex">
            <div className="width-250">Triples generate</div>
            <div>8 $floppy a day.</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-250">Doubles generate</div>
            <div>9 $floppy a day.</div>
          </div>

          <div className="d-flex mt-6">
            <div className="width-250">Quadruples generate</div>
            <div>10 $floppy a day.</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-250">Singles generate</div>
            <div>11 $floppy a day.</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-250">Distorted generate</div>
            <div>12 $floppy a day.</div>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">Humach</div>
        <div className="reg-subtitle-2 mb-24">
          Space beings who are in search of Machinies. There will be 3,500
          Humach minted (888 for genesis machinie, 2,562 for public, 50
          reserved) However, those 888 genesis machinies are already owned,
          thus, Humachs must either fight for a Machinie or search for one
          elsewhere. A dangerous path awaits them, in order to start their
          journey. Teaming up is always a better option. Humach will be able to
          call their partners from across the galaxy.
        </div>

        <div className="bold-subtitle-1 mb-14">
          Humach can choose to do 4 things
        </div>
        <div className="reg-subtitle-2">
          <div className="d-flex">
            <div className="width-500">
              Chill (this is Humach chilling in a wallet)
            </div>
            <div>cost : none</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-500">Train (let them train and level up)</div>
            <div>cost : time</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-500">Call (call for another humach)</div>
            <div>cost : $floppy</div>
          </div>
          <div className="d-flex mt-6">
            <div className="width-500">
              Stake $floppy (need to pair up with a machinie)
            </div>
            <div>cost : $time</div>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="mb-14 bold-headline-6">Other use of $floppy</div>
        <div className="mb-14 reg-subtitle-2 ">
          Once we move on there will be a store for spending $floppy! However,
          there are certain options right away like lore creation.
        </div>

        <div className="reg-subtitle-2">
          <div className="d-flex">
            <div className="width-250">1. Rename</div>
            <div>cost : 35 $floppy</div>
          </div>

          <div className="d-flex mt-6">
            <div className="width-250">2. Rewrite Description</div>
            <div>cost : 35 $floppy</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocsMustRead = () => {
  return (
    <div className="text-color-gray-100">
      <div>
        <div className="reg-overline text-color-secondary">Machinie</div>
        <div className="bold-headline-5 mt-6">Must read before stake</div>
      </div>
      <div className="mt-32">
        <div>
          <div className="bold-subtitle-1">
            1. Do Genesis Machinie and Humach need to be in the same wallet for
            staking?
          </div>
          <div className="reg-subtitle-2 mt-6">
            Yes, both need to be in the same wallet to stake together and earn
            $floppy.
          </div>
        </div>

        <div className="mt-32">
          <div className="bold-subtitle-1">
            2. Does a staked Humach train while being staked?
          </div>
          <div className="reg-subtitle-2 mt-6">
            No, Humach can either be staked for $floppy, or put in training
            mode. They can not do both simultaneously.
          </div>
        </div>

        <div className="mt-32">
          <div className="bold-subtitle-1">
            3. What does training a Humach do?
          </div>
          <div className="reg-subtitle-2 mt-6">
            Training your Humach will level them up over time. As Humach level
            increases, the art changes, breeding cost decreases, as well as
            additional utility in the next phase of the project!
          </div>
        </div>

        <div className="mt-32">
          <div className="bold-subtitle-1">
            4. Can staking a Humach alone earn $floppy?
          </div>
          <div className="reg-subtitle-2 mt-6">
            Humach that reach level 2 and above will have a chance to discover
            and collect $floppy emissions as they travel through space at
            random. The exact details on how this will work and at what rate,
            will be released in the near future.
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocsGasPriceCalculator = () => {
  return (
    <div className="text-color-gray-100">
      <div>
        <div className="reg-overline text-color-secondary">Quick Start</div>
        <div className="bold-headline-5 mt-6">Gas price calculator</div>
      </div>

      <div className="mt-32">
        <GasPriceCalculator />
      </div>
    </div>
  );
};

export const DocsCheckStatusMachinie = () => {
  return (
    <div className="text-color-gray-100 reg-subtitle-1">
      <div>
        <div className="reg-overline text-color-secondary">Machinie</div>
        <div className="bold-headline-5 mt-6">Check status Machinie</div>
      </div>
      <div className="mt-32">
        <CheckStatusMachinie />
      </div>
    </div>
  );
};

export const DocsFaqs = () => {
  return (
    <div className="text-color-gray-100">
      <div>
        <div className="reg-overline text-color-secondary">Quick Start</div>
        <div className="bold-headline-5 mt-6">FAQs</div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">What is NFT?</div>
        <div className="reg-subtitle-2">
          NFT stands for Non-Fungible Token. It is a non-interchangeable unit of
          data stored on a blockchain. Each token is unique and maybe associated
          with media such as photos, video, and audio. Our NFT acts as a proof
          of ownership to Machinie family. Owning a Machinie or a Humach means
          you are a part of the family!
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">How do I buy one?</div>
        <div className="reg-subtitle-2">
          Machinie and Humach is stored on the Ethereum blockchain which
          requires Ethereum to buy. First you will need a cold wallet such as
          MetaMask,{" "}
          <a href="https://metamask.io/" target={"_blank"}>
            https://metamask.io/
          </a>{" "}
          . Once you open a wallet then note down your Seed phrase. Please
          remember, the seed phrase you have is the wallet itself! Do not ever
          expose this seed phrase to anyone and do not store it digitally. Then,
          top up your wallet with some ETH from an exchange or directly from
          MetaMask.{" "}
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          Do Machinie and Humach NFTs have utility?
        </div>
        <div className="reg-subtitle-2">
          <div>
            Machinie and Humach is stored on the Ethereum blockchain which
            requires Ethereum to buy. First you will need a cold wallet such as
            MetaMask,{" "}
            <a href="https://metamask.io/" target={"_blank"}>
              https://metamask.io/
            </a>{" "}
            . Once you open a wallet then note down your Seed phrase. Please
            remember, the seed phrase you have is the wallet itself! Do not ever
            expose this seed phrase to anyone and do not store it digitally.
            Then, top up your wallet with some ETH from an exchange or directly
            from MetaMask.{" "}
          </div>
          <div className="mt-12">
            Machinie is sold out of primary sale.{" "}
            <a href="https://opensea.io/collection/machinie" target={"_blank"}>
              Click here
            </a>{" "}
            to for secondary market.
          </div>
          <div className="mt-12">
            Humach is live on primary sale. <Link to="/humach">Click here</Link>{" "}
            for minting page.
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">What are Genesis Machinies?</div>
        <div className="reg-subtitle-2">
          <div>
            Highly advanced, ancient technological artifacts from a long
            forgotten age. They produce an infinite supply of energy to those
            that can unlock their true power. Only Humachs posses the ancient
            knowledge required to utilize the full potential of the 888 Genesis
            Machinie, generating a continuous stream of $floppy for their
            owners.
          </div>
          <div className="mt-12">
            <Link to="/machinie">Learn more about Machinie's utility</Link>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">What are Humach?</div>
        <div className="reg-subtitle-2 mb-14">
          <div>
            Mysterious space beings, searching the galaxy for their source of
            power. Along the way, they train their minds and bodies to level up,
            battle each other for supremacy, and call upon other Humach for
            support. Humachs posses the ancient knowledge required to activate
            Genesis Machinie and generate $floppy. They can use $floppy to
            discover the truth about their mysterious origins, as well as to
            call in additional Humach (breed). Call transmission cost is lower
            the more Humach level up. Your Humach’s level will also play an
            important role in the next phase of the project. Humach alone can
            generate $current daily."
          </div>
          <div className="mt-12">
            <Link to="/humach">Learn more about Humach's utility</Link>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          Do Machinie and Humach NFTs have utility?
        </div>
        <div className="reg-subtitle-2">
          Yes, Staking 1 Machinie with 1 Humach will allow the owner to generate
          $floppy, the utility token of the Machinie ecosystem. Additionally,
          Humach on their own, acquire the ability to level up over time. The
          higher your Humach levels, the less $floppy is required to call
          (breed) additional Humach. Your Humach’s level will also play an
          important role in the next phase of the project. On top of that, they
          can generate $current daily on their own.
        </div>
      </div>
    </div>
  );
};

export const DocsMachinieFaqs = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Machinie</div>
      <div className="bold-headline-5 mt-6">Machinie FAQs</div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">What are Genesis Machinies?</div>
        <div className="reg-subtitle-2">
          Highly advanced, ancient technological artifacts from a long forgotten
          age. They produce an infinite supply of energy to those that can
          unlock their true power. Only Humachs posses the ancient knowledge
          required to utilize the full potential of the 888 Genesis Machinie,
          generating a continuous stream of $floppy for their owners.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          What is the utility for $floppy token?
        </div>
        <div className="reg-subtitle-2">
          $floppy can be used to call (breed) additional Humach. It can also be
          used to customize the name and backstory of your Humach. There is
          additional utility planned for $floppy as the Machinie ecosystem
          expands.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          How much $floppy can I generate per day?
        </div>
        <div className="reg-subtitle-2">
          <div className="mt-14 reg-subtitle-2">
            A Genesis Machine when staked with a Humach, will generate between 8
            and 12 $floppy per day. The rarer your Genesis Machinie, the more
            $floppy it will generate. The trait that determines this rarity is
            “Combination of Type” and the variable $floppy output is as follows:
          </div>
          <div className="mt-14 reg-subtitle-2">
            <div>
              <span className="bold-subtitle-2">Triple:</span> generates 8
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Double:</span> generates 9
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Quadruple:</span> generates 10
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Single:</span> generates 11
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Distorted:</span> generates 12
              $floppy per day
            </div>
          </div>
          <div className="mt-14 reg-subtitle-2">
            Please note that 1 Humach must be paired with 1 Genesis Machinie to
            generate $floppy. As there are less Genesis Machinie than there are
            Humach, not all Humach will be able to generate $floppy in the
            beginning. This will make the Genesis Machinie highly sought after,
            and Humach will have to compete for the scarce supply. Humach that
            do not posses a Genesis Machinie, would be going on a space trip for
            $floppy hunting in the near future.
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          How much $current can I generate per day?
        </div>
        <div className="reg-subtitle-2">
          <div className="mt-14 reg-subtitle-2">
            10 $current / day / Machinie
          </div>
          <div className="reg-subtitle-2">5 $current / day / Humach</div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          What is the utility for $current token?
        </div>
        <div className="reg-subtitle-2">
          $current can be used to play game machines in Machiniverse. At the
          moment we have 2 games, Slot Machi and Machipon.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          How can I acquire a Genesis Machinie?
        </div>
        <div className="reg-subtitle-2">
          <div className="mt-14 reg-subtitle-2">
            You can purchase a Genesis Machinie (migrated) on OpenSea here:
            <a href="https://opensea.io/collection/machinie" target="blank_">
              https://opensea.io/collection/machinie
            </a>
          </div>
          <div className="mt-14 reg-subtitle-2">
            You can purchase a Genesis Machinie (non-migrated) on OpenSea here:{" "}
            <a
              href="https://opensea.io/collection/machinietomigrate"
              target="blank_"
            >
              https://opensea.io/collection/machinietomigrate
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocsMachinieStats = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Machinie</div>
      <div className="bold-headline-5 mt-6">Machinie stats</div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-subtitle-1">Background</div>
        <div>machinie silver : 146</div>
        <div>machinie blue : 139</div>
        <div>machinie red : 131</div>
        <div>machinie olive : 124</div>
        <div>machinie purp : 101</div>
        <div>machinie cyan : 90</div>
        <div>machinie pink : 84</div>
        <div>machinie gold : 73</div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-subtitle-1">Combination of type</div>
        <div>triple : 444</div>
        <div>double : 330</div>
        <div>quadruple : 88</div>
        <div>single : 26</div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-subtitle-1">Monitor count</div>
        <div>3 monitors : 395</div>
        <div>2 monitors : 282</div>
        <div>4 monitors : 211</div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-subtitle-1">Mold</div>
        <div>113 : 127</div>
        <div>23 : 113</div>
        <div>111 : 102</div>
        <div>13 : 88</div>
        <div>123 : 85</div>
        <div>12 : 81</div>
        <div>112 : 81</div>
        <div>1111 : 80</div>
        <div>1112 : 74</div>
        <div>1123 : 57</div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-subtitle-1">Extra</div>
        <div>energy leak : 88</div>
        <div>distorted : 8</div>
      </div>
    </div>
  );
};

export const DocsUnmigratedMachinies = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Machinie</div>
      <div className="bold-headline-5 mt-6">Unmigrated Machinies</div>
    </div>
  );
};

export const DocsMachinieLicense = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Machinie</div>
      <div className="bold-headline-5 mt-6">Machinie License</div>
      <div className="reg-subtitle-2 mt-6">
        Version 1.0, Last Revised: December 12, 2021
      </div>

      <div className="reg-subtitle-2 mt-32">
        The MachinieLicense (this "License") is designed to grant and clarify
        the rights of a Machinie owner to the Machinie’s underlying MachinieArt.
      </div>

      <div className="reg-subtitle-2 mt-32">
        MachinieCorp reserves the right to modify this License at any time by
        posting a notice either at https://machinienft.com, in the Discord
        server located at discord.gg/machinie, or in a conspicuous manner that
        MachinieCorp deems sufficient. Machinie owner to the Machinie’s
        underlying MachinieArt.
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 1. Definitions</div>
        <div className="mt-24">
          "Machinie" means the blockchain-tracked, non-fungible token that was
          originally generated on or in connection with the Ethereum smart
          contract located at 0xB826bDe739897ad50363d045d65eE5b83FDb730d.
        </div>
        <div className="mt-24">
          "MachinieArt" means any art, design, drawings, data, and content that
          may be associated with a Machinie, including any such material subject
          to Copyright or similar rights that is derived from or based upon the
          Machinie in compliance with this License.
        </div>
        <div className="mt-24">
          "Own" means, with respect to a Machinie, a Machinie that you have
          purchased or otherwise rightfully acquired from a legitimate source,
          where proof of such purchase is cryptographically recorded on the
          Ethereum blockchain. Proof of purchase must include the five-byte
          catId of a Machinie and the Ethereum public key to which it belongs. A
          person or entity owns a public key if it can sign a transaction on the
          Ethereum blockchain with the current date using the private key
          associated with that public key.
        </div>
        <div className="mt-24">
          "MachinieCorp" means Machinie Co., a partnership, and all parent
          companies, subsidiaries, affiliates, officers, directors, owners,
          members, employees, agents, contractors, subcontractors, licensees,
          licensors, and resellers of Machinie Co.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 2. Ownership</div>
        <div className="mt-24">
          You acknowledge and agree that MachinieCorp (or, as applicable, its
          licensors), as the creator of Machinie, the software that generates
          the images for and displays all Machinie, owns all legal right, title
          and interest in and to the MachinieArt, and all intellectual property
          rights therein. The rights that you have in and to the MachinieArt are
          limited to those described in this License. MachinieCorp reserves all
          rights in and to the MachinieArt not expressly granted to you in this
          License.
        </div>

        <div className="mt-24">
          MachinieCorp will not license any MachinieArt covered by this License
          to entities not in possession of the Machinie for that MachinieArt.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 3. License</div>
        <div className="mt-24">
          3.1. Grant of License. Subject to your continued compliance with the
          terms of this License, by virtue of your purchase or generation of
          your Machinie, MachinieCorp grants you a worldwide, non-exclusive,
          transferable, royalty-free license to use, copy, and display the
          MachinieArt for Machinie you Own.
        </div>
        <div className="mt-24">
          3.2. Purposes. You may only use your licensed MachinietArt for the
          following purposes:
        </div>
        <div className="mt-24 pl-20">(i) for your own personal use; or</div>
        <div className="mt-24 pl-20">
          (ii) for your own commercial use, provided that such commercial use is
          explicitly permitted by this Section 3 of this License.
        </div>
        <div className="mt-24">
          3.3. Transferability. Given that this License runs with the Machinie,
          you may only transfer this License to the MachinieArt in whole, and
          only by transferring the Machinie in a manner that cryptographically
          verifies the transfer of the Machinie to ensure that the transferee of
          any Machinie has received all right title and interest in the Machinie
          as well as the rights afforded by this Section 3 of this License.
        </div>
        <div className="mt-24">
          3.4. Downstream Recipients; License Runs with Machinie. You agree that
          this license is specific to the MachinieArt that is or was generated
          in connection with a specific Machinie, that the MachinieArt is
          inextricably linked to that specific Machinie, that the MachinieArt
          runs with the Machinie, and that therefore:
        </div>
        <div className="mt-24 pl-20">
          1) if you procured the Machinie by generating the Machinie on the site
          you hold only the License to the MachinieArt that was specifically
          generated for your specific Machinie,
        </div>
        <div className="mt-24 pl-20">
          (2) if you procured the Machinie that you currently hold from a third
          party you only possess the License and rights associated with that
          Machinie,
        </div>
        <div className="mt-24 pl-20">
          (3) should you ever transfer the Machinie from which your MachinieArt
          was derived to a third party you also transfer this License in whole
          to the third party to which you transfer the Machinie.
        </div>
        <div className="mt-24">
          The License applies only to the extent that you continue to own the
          applicable Machinie. If at any time you sell, trade, donate, give
          away, transfer, or otherwise dispose of your Machinie for any reason,
          your License will immediately expire with respect to those Machinie of
          which you have dispossessed, this License will transfer to the party
          to whom you sold, traded, donated, gave, or transferred the Machinie,
          the receiving party will automatically receive a license under this
          MachinieLicense from MachinieCorp, and you will have no further rights
          in or to the MachinieArt or any extension for those Machinies.
        </div>
        <div className="mt-24">
          You may not impose any further restrictions on the exercise of the
          rights granted or affirmed under this License, including, but not
          limited to binding future licensees of this License to sublicenses of
          this License.
        </div>
        <div className="mt-24">
          3.5. Derivatives. MachinieCorp also grants you a limited license to
          create derivative works of your MachinieArt, provided that
          MachinieCorp retains ownership of all such derivative works. All
          derivative works become MachinieArt and are licensed accordingly under
          this License.
        </div>
        <div className="mt-24">
          3.6. Commercial Use. Subject to your continued compliance with the
          terms of this License, MachinieCorp grants you a limited, worldwide,
          non-exclusive, transferable license to use, copy, and display the
          MachinieArt for your Machinie for the purpose of commercialization
          that includes, contains, or consists of the MachinieArt for your
          Machinie. MachinieArt and are licensed accordingly under this License.
        </div>
        <div className="mt-24">
          3.7. Restrictions. You agree that you may not, nor permit any third
          party to do or attempt to do any of the foregoing without
          MachineCorp's express prior written consent:
        </div>
        <div className="mt-24 pl-20">
          (i) use the MachinieArt for your Machinie in connection with images,
          videos, or other forms of media that depict hatred, intolerance,
          violence, cruelty, or anything else that could reasonably be found to
          constitute hate speech or otherwise infringe upon the rights of
          others;
        </div>
        <div className="mt-24 pl-20">
          (ii) attempt to trademark, copyright, or otherwise acquire additional
          intellectual property rights in or to the MachinieArt for your
          Machinie;
        </div>
        <div className="mt-24 pl-20">
          (iii) utilize the MachinieArt for your Machinie for your or any third
          party's benefit in contravention of this License;
        </div>
        <div className="mt-24 pl-20">
          (iv) propagate or modify a covered work except as expressly permitted
          by this License.
        </div>{" "}
        <div className="mt-24">
          To the extent that MachinieArt associated with your Machinie contains
          Third Party IP (e.g., licensed intellectual property from a celebrity,
          athlete, or other public figure), you understand and agree as follows:
        </div>
        <div className="mt-24 pl-20">
          (x) that you will not have the right to use such Third Party IP in any
          way except as incorporated in the MachinieArt, and subject to the
          license and restrictions contained herein;
        </div>{" "}
        <div className="mt-24 pl-20">
          (y) that, depending on the nature of the license granted from the
          owner of the Third Party IP, Creator may need to pass through
          additional restrictions on your ability to use the MachinieArt; and
        </div>{" "}
        <div className="mt-24 pl-20">
          (z) to the extent that Creator informs you of such additional
          restrictions in writing or email, you will be responsible for
          complying with all such restrictions from the date that you receive
          the notice, and that failure to do so will be deemed a breach of this
          license. The rest
        </div>{" "}
        <div className="mt-24">
          3.8. No endorsement. Nothing in this License constitutes or may be
          construed as permission to assert or imply that you are, or that your
          use of the Machinie or MachinieArt is, is connected with, or
          sponsored, endorsed, or granted official status by, MachinieCorp.
        </div>
        <div className="mt-24">
          3.9. Attribution. If you transfer or convey your Machinie, you must
        </div>
        <div className="mt-24 pl-20">
          (i) Retain any notices, identifications, or links attached to or
          associated with the Machinie if any are supplied by MachinieCorp with
          the Machinie, including any copyright notices, notices that refer to
          this License, or any URL or hyperlink to this License, and
        </div>{" "}
        <div className="mt-24 pl-20">
          (ii) indicate the Humach is licensed under this License, and include
          the text of, or the URI or hyperlink to, this Public License.
        </div>{" "}
        <div className="mt-24">
          You may satisfy these conditions in any reasonable manner based on the
          medium, means, and context in which you transfer or convey the
          Machinie. For example, it may be reasonable to satisfy the conditions
          by providing a URL or hyperlink to this License.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 4. Liabilities & Remedies</div>{" "}
        <div className="mt-24">
          4.1. Breach. If you exceed the scope of this License without entering
          into a broader license with or obtaining an exemption from
          MachinieCorp, you acknowledge and agree that:
        </div>{" "}
        <div className="mt-24 pl-20">
          (i) you are in breach of this License;
        </div>{" "}
        <div className="mt-24 pl-20">
          (ii) in addition to any remedies that may be available to MachinieCorp
          at law or in equity, MachinieCorp may immediately terminate this
          License, without the requirement of notice; and
        </div>{" "}
        <div className="mt-24 pl-20">
          (iii) you will be responsible to reimburse MachinieCorp for any costs
          and expenses incurred by MachinieCorp during the course of enforcing
          the terms of this License against you.
        </div>{" "}
        <div className="mt-24">
          4.2. Indemnification. You agree to indemnify and hold harmless
          MachinieCorp from and against all liability, damages, loss, cost or
          expense, including but not limited to reasonable attorneys' fees and
          expenses, arising out of or in connection with any claims that (i)
          your use of the Machinie infringes or otherwise violates any rights of
          any such third party, (ii) your use of the Machinie in contravention
          of this Agreement, including but not limited to the prohibitions in
          Section 3.
        </div>{" "}
        <div className="mt-24">
          4.3. Disclaimer of Representations Warranty. Except to the extent
          required by applicable law, the Machinie is provided "as is'' and "as
          available" without any representation or warranty of any kind, either
          expressed or implied, including, but not limited to, warranties of
          title, merchantability, fitness for a particular purpose,
          non-infringement, absence of latent or other defects, accuracy, or the
          presence or absence of errors, whether known or not known. The entire
          risk as to the quality and performance of the Machinie lies with you.
        </div>{" "}
        <div className="mt-24">
          4.4. Limitation of Liability. In no event unless required by
          applicable law will MachinieCorp be liable to you for damages,
          including any general, special, incidental or consequential damages
          arising out of the use or inability to use the Machinie (including but
          not limited to loss of data or data being rendered inaccurate or
          losses sustained by you or third parties or a failure of the Machinie
          to operate in any manner or a failure to notify any license holder of
          the existence of any derivative works.).
        </div>{" "}
        <div className="mt-24">
          4.5. Recourse. License holders shall have the right to commence action
          for copyright infringement based on the rights granted under this
          License. MachinieCorp is under no obligation to pursue actions on
          behalf of a license holder.
        </div>{" "}
        <div className="mt-24">
          4.6. Interpretation. If the disclaimer of warranty and limitation of
          liability provided above cannot be given local legal effect according
          to their terms, reviewing courts shall apply local law that most
          closely approximates an absolute waiver of all civil liability in
          connection with the Machinie.
        </div>{" "}
      </div>

      <div className="mt-32 text-color-gray-100 reg-subtitle-2">
        <div className="bold-headline-5">Section 5. Miscellaneous Clauses</div>
        <div className="mt-24">
          5.1. Mutual Representations and Warranties. Each party represents and
          warrants that: it has reviewed and understands all the terms of this
          License; it has the full right, power, and authority to enter into and
          perform its obligations and grant the rights, licenses, consents and
          authorizations it grants or is required to grant under this License;
          this License will constitute the legal, valid and binding obligation
          of such party, enforceable against such party in accordance with its
          terms.
        </div>{" "}
        <div className="mt-24">
          5.2. Severability. If any provision of this License is held to be
          unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining
          provisions will continue in full force and effect. To the extent it is
          not possible under applicable law to change or interpret the provision
          that is held to be unenforceable or invalid, such provision shall be
          severed from this License and the remainder of the License shall be
          given full force and effect.
        </div>{" "}
        <div className="mt-24">
          5.3. Entire Agreement. This License constitutes the sole and entire
          agreement between the parties. This License supersedes all prior
          understandings, Licenses, representations, and documentation relating
          to the subject matter of this License.
        </div>{" "}
        <div className="mt-24">
          5.4. Headings. The headings in this License are for reference only and
          do not affect the interpretation of this License.
        </div>{" "}
        <div className="mt-24">
          5.5. Notices. All notices and other communications given in connection
          with this License shall be in writing and shall be deemed given when
          sent via email to the last known email address of the party to whom
          the notice must be delivered, provided that receipt of the notice is
          acknowledged via a reply email or separate email acknowledging receipt
          of the notice; or posted at{" "}
          <a href="https://machiniverse.xyz/" target="_blank">
            https://machiniverse.xyz/
          </a>
          .
        </div>{" "}
        <div className="mt-24">
          5.6. Sale by MachinieCorp. This License shall inure to the benefit of
          MachinieCorp's successors in interest, including, without limitation,
          successors through merger, consolidation, or sale of substantially all
          of MachinieCorp's ownership or assets, and shall continue to be
          binding upon you.
        </div>
      </div>
    </div>
  );
};

export const DocsHumachFaqs = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Humach</div>
      <div className="bold-headline-5 mt-6">Humach FAQs</div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">What are Humach?</div>
        <div className="reg-subtitle-2">
          Mysterious space beings, searching the galaxy for their source of
          power. Along the way, they train their minds and bodies to level up,
          battle each other for supremacy, and call upon other Humach for
          support. Humachs posses the ancient knowledge required to activate
          Genesis Machinie and generate $floppy. They can use $floppy to
          discover the truth about their mysterious origins, as well as to call
          in additional Humach (breed). Call transmission cost is lower the more
          Humach level up. Your Humach’s level will also play an important role
          in the next phase of the project. Humach alone can generate $current
          daily.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">Do Humach generate $floppy?</div>
        <div className="reg-subtitle-2">
          Yes, in the near future. Humach will be randomly generating $floppy on
          their space mission. One of the factor affecting $floppy drop rate is
          Humach levels.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          How much $current can I generate per day?
        </div>

        <div className="reg-subtitle-2">
          <div className="mt-14 reg-subtitle-2">
            10 $current / day / Machinie
          </div>
          <div className="reg-subtitle-2">5 $current / day / Humach</div>
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          What is the utility for $current token?
        </div>
        <div className="reg-subtitle-2">
          $current can be used to play game machines in Machiniverse. At the
          moment we have 2 games, Slot Machi and Machipon.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          I am concerned that I will not be able to acquire a Genesis Machinie
          and my Humach will have less utility.
        </div>
        <div className="reg-subtitle-2">
          Not only now Humach can generate $current on their own, in the next
          phase of the Machinie ecosystem, as the utility of $floppy expands,
          There is a Humach prophecy that when all Humach have been called, a
          new generation of Machinie will emerge, and the hunt will begin.
        </div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">
          What is the max supply of Humach?
        </div>
        <div className="reg-subtitle-2">{VALUE_STATIC.FAQ.MINT_CAP}</div>
      </div>

      <div className="mt-32">
        <div className="bold-headline-6 mb-14">How can I acquire a Humach?</div>
        <div className="reg-subtitle-2">
          <div className="mt-14">
            Humach was a stealth mint on November 17th, 2021
          </div>
          <div className="mt-14">Mint price is 0.068 ETH</div>
          <div className="mt-14">Mint cap is 3 Humach per transaction</div>

          <div className="mt-24 ">
            You can alternatively purchase a Humach on the secondary market
            here:{" "}
            <a href="https://opensea.io/collection/humach">
              https://opensea.io/collection/humach
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocsHumachCheckStatus = () => {
  return (
    <div className="text-color-gray-100">
      <div>
        <div className="reg-overline text-color-secondary">Humach</div>
        <div className="bold-headline-5 mt-6">Check Status Humach</div>
      </div>
      <div className="mt-32">
        <CheckStatusHumach />
      </div>
    </div>
  );
};

export const DocsHumachTraits = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Humach</div>
      <div className="bold-headline-5 mt-6">Humach stats</div>
    </div>
  );
};

export const DocsAllowlist = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Humach</div>
      <div className="bold-headline-5 mt-6">Allowist</div>
    </div>
  );
};

export const DocsHumachLicense = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Humach</div>
      <div className="bold-headline-5 mt-6">Humach License</div>
      <div className="reg-subtitle-2 mt-6">
        Version 1.0, Last Revised: December 12, 2021
      </div>

      <div className="reg-subtitle-2 mt-32">
        The MachinieLicense (this "License") is designed to grant and clarify
        the rights of a Machinie owner to the Machinie’s underlying MachinieArt.
      </div>

      <div className="reg-subtitle-2 mt-32">
        MachinieCorp reserves the right to modify this License at any time by
        posting a notice either at https://machinienft.com, in the Discord
        server located at discord.gg/machinie, or in a conspicuous manner that
        MachinieCorp deems sufficient. Machinie owner to the Machinie’s
        underlying MachinieArt.
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 1. Definitions</div>
        <div className="mt-24">
          "Machinie" means the blockchain-tracked, non-fungible token that was
          originally generated on or in connection with the Ethereum smart
          contract located at 0xAA89E8Eb3048A2D8cb1a3Db7D2B49f8FA33D56ba.
        </div>
        <div className="mt-24">
          "HumachArt" means any art, design, drawings, data, and content that
          may be associated with a Humach, including any such material subject
          to Copyright or similar rights that is derived from or based upon the
          Humach in compliance with this License.
        </div>
        <div className="mt-24">
          "Own" means, with respect to a Humach, a Humach that you have
          purchased or otherwise rightfully acquired from a legitimate source,
          where proof of such purchase is cryptographically recorded on the
          Ethereum blockchain. Proof of purchase must include the five-byte
          catId of a Humach and the Ethereum public key to which it belongs. A
          person or entity owns a public key if it can sign a transaction on the
          Ethereum blockchain with the current date using the private key
          associated with that public key.
        </div>
        <div className="mt-24">
          "MachinieCorp" means Machinie Co., a partnership, and all parent
          companies, subsidiaries, affiliates, officers, directors, owners,
          members, employees, agents, contractors, subcontractors, licensees,
          licensors, and resellers of Machinie Co.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 2. Ownership</div>
        <div className="mt-24">
          You acknowledge and agree that MachinieCorp (or, as applicable, its
          licensors), as the creator of Humach, the software that generates the
          images for and displays all Humach, owns all legal right, title and
          interest in and to the HumachArt, and all intellectual property rights
          therein. The rights that you have in and to the HumachArt are limited
          to those described in this License. MachinieCorp reserves all rights
          in and to the HumachArt not expressly granted to you in this License.
        </div>

        <div className="mt-24">
          MachinieCorp will not license any HumachArt covered by this License to
          entities not in possession of the Humach for that HumachArt.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 3. License</div>
        <div className="mt-24">
          3.1. Grant of License. Subject to your continued compliance with the
          terms of this License, by virtue of your purchase or generation of
          your Humach, MachinieCorp grants you a worldwide, non-exclusive,
          transferable, royalty-free license to use, copy, and display the
          HumachArt for Humach you Own.
        </div>
        <div className="mt-24">
          3.2. Purposes. You may only use your licensed HumachArt for the
          following purposes:
        </div>
        <div className="mt-24 pl-20">(i) for your own personal use; or</div>
        <div className="mt-24 pl-20">
          (ii) for your own commercial use, provided that such commercial use is
          explicitly permitted by this Section 3 of this License.
        </div>
        <div className="mt-24">
          3.3. Transferability. Given that this License runs with the Humach,
          you may only transfer this License to the HumachArt in whole, and only
          by transferring the Humach in a manner that cryptographically verifies
          the transfer of the Humach to ensure that the transferee of any Humach
          has received all right title and interest in the Humach as well as the
          rights afforded by this Section 3 of this License.
        </div>
        <div className="mt-24">
          3.4. Downstream Recipients; License Runs with Humach. You agree that
          this license is specific to the HumachArt that is or was generated in
          connection with a specific Humach, that the HumachArt is inextricably
          linked to that specific Humach, that the HumachArt runs with the
          Humach, and that therefore:
        </div>
        <div className="mt-24 pl-20">
          (1) if you procured the Humach by generating the Humach on the site
          you hold only the License to the HumachArt that was specifically
          generated for your specific Humach,
        </div>
        <div className="mt-24 pl-20">
          (2) if you procured the Humach that you currently hold from a third
          party you only possess the License and rights associated with that
          Humach,
        </div>
        <div className="mt-24 pl-20">
          (3) should you ever transfer the Humach from which your HumachArt was
          derived to a third party you also transfer this License in whole to
          the third party to which you transfer the Humach.
        </div>
        <div className="mt-24">
          The License applies only to the extent that you continue to own the
          applicable Humach. If at any time you sell, trade, donate, give away,
          transfer, or otherwise dispose of your Humach for any reason, your
          License will immediately expire with respect to those Humach of which
          you have dispossessed, this License will transfer to the party to whom
          you sold, traded, donated, gave, or transferred the Humach, the
          receiving party will automatically receive a license under this
          HumachLicense from MachinieCorp, and you will have no further rights
          in or to the HumachArt or any extension for those Humach.
        </div>
        <div className="mt-24">
          You may not impose any further restrictions on the exercise of the
          rights granted or affirmed under this License, including, but not
          limited to binding future licensees of this License to sublicenses of
          this License.
        </div>
        <div className="mt-24">
          3.5. Derivatives. MachinieCorp also grants you a limited license to
          create derivative works of your HumachArt, provided that MachinieCorp
          retains ownership of all such derivative works. All derivative works
          become HumachArt and are licensed accordingly under this License.
        </div>
        <div className="mt-24">
          3.6. Commercial Use. Subject to your continued compliance with the
          terms of this License, MachinieCorp grants you a limited, worldwide,
          non-exclusive, transferable license to use, copy, and display the
          HumachArt for your Humach for the purpose of commercialization that
          includes, contains, or consists of the HumachArt for your Humach.
        </div>
        <div className="mt-24">
          3.7. Restrictions. You agree that you may not, nor permit any third
          party to do or attempt to do any of the foregoing without
          MachineCorp's express prior written consent:
        </div>
        <div className="mt-24 pl-20">
          (i) use the HumachArt for your Humach in connection with images,
          videos, or other forms of media that depict hatred, intolerance,
          violence, cruelty, or anything else that could reasonably be found to
          constitute hate speech or otherwise infringe upon the rights of
          others;
        </div>
        <div className="mt-24 pl-20">
          (ii) attempt to trademark, copyright, or otherwise acquire additional
          intellectual property rights in or to the HumachArt for your Humach;
        </div>
        <div className="mt-24 pl-20">
          (iii) utilize the HumachArt for your Humach for your or any third
          party's benefit in contravention of this License;
        </div>
        <div className="mt-24 pl-20">
          (iv) propagate or modify a covered work except as expressly permitted
          by this License.
        </div>{" "}
        <div className="mt-24">
          To the extent that HumachArt associated with your Humach contains
          Third Party IP (e.g., licensed intellectual property from a celebrity,
          athlete, or other public figure), you understand and agree as follows:
        </div>
        <div className="mt-24 pl-20">
          (x) that you will not have the right to use such Third Party IP in any
          way except as incorporated in the HumachArt, and subject to the
          license and restrictions contained herein;
        </div>{" "}
        <div className="mt-24 pl-20">
          (y) that, depending on the nature of the license granted from the
          owner of the Third Party IP, Creator may need to pass through
          additional restrictions on your ability to use the HumachArt; and
        </div>{" "}
        <div className="mt-24 pl-20">
          (z) to the extent that Creator informs you of such additional
          restrictions in writing or email, you will be responsible for
          complying with all such restrictions from the date that you receive
          the notice, and that failure to do so will be deemed a breach of this
          license. The restriction in this Section will survive the expiration
          or termination of this License.
        </div>{" "}
        <div className="mt-24">
          3.8. No endorsement. Nothing in this License constitutes or may be
          construed as permission to assert or imply that you are, or that your
          use of the Humach or HumachArt is, is connected with, or sponsored,
          endorsed, or granted official status by, MachinieCorp.
        </div>
        <div className="mt-24">
          3.9. Attribution. If you transfer or convey your Humach, you must
        </div>
        <div className="mt-24 pl-20">
          (i) Retain any notices, identifications, or links attached to or
          associated with the Humach if any are supplied by MachinieCorp with
          the Humach, including any copyright notices, notices that refer to
          this License, or any URL or hyperlink to this License, and
        </div>{" "}
        <div className="mt-24 pl-20">
          (ii) indicate the Humach is licensed under this License, and include
          the text of, or the URI or hyperlink to, this Public License.
        </div>{" "}
        <div className="mt-24">
          You may satisfy these conditions in any reasonable manner based on the
          medium, means, and context in which you transfer or convey the Humach.
          For example, it may be reasonable to satisfy the conditions by
          providing a URL or hyperlink to this License.
        </div>
      </div>

      <div className="mt-32 reg-subtitle-2">
        <div className="bold-headline-6">Section 4. Liabilities & Remedies</div>{" "}
        <div className="mt-24">
          4.1. Breach. If you exceed the scope of this License without entering
          into a broader license with or obtaining an exemption from
          MachinieCorp, you acknowledge and agree that:
        </div>{" "}
        <div className="mt-24 pl-20">
          (i) you are in breach of this License;
        </div>{" "}
        <div className="mt-24 pl-20">
          (ii) in addition to any remedies that may be available to MachinieCorp
          at law or in equity, MachinieCorp may immediately terminate this
          License, without the requirement of notice; and
        </div>{" "}
        <div className="mt-24 pl-20">
          (iii) you will be responsible to reimburse MachinieCorp for any costs
          and expenses incurred by MachinieCorp during the course of enforcing
          the terms of this License against you.
        </div>{" "}
        <div className="mt-24">
          4.2. Indemnification. You agree to indemnify and hold harmless
          MachinieCorp from and against all liability, damages, loss, cost or
          expense, including but not limited to reasonable attorneys' fees and
          expenses, arising out of or in connection with any claims that (i)
          your use of the Machinie infringes or otherwise violates any rights of
          any such third party, (ii) your use of the Machinie in contravention
          of this Agreement, including but not limited to the prohibitions in
          Section 3.
        </div>{" "}
        <div className="mt-24">
          4.3. Disclaimer of Representations Warranty. Except to the extent
          required by applicable law, the Machinie is provided "as is'' and "as
          available" without any representation or warranty of any kind, either
          expressed or implied, including, but not limited to, warranties of
          title, merchantability, fitness for a particular purpose,
          non-infringement, absence of latent or other defects, accuracy, or the
          presence or absence of errors, whether known or not known. The entire
          risk as to the quality and performance of the Machinie lies with you.
        </div>{" "}
        <div className="mt-24">
          4.4. Limitation of Liability. In no event unless required by
          applicable law will MachinieCorp be liable to you for damages,
          including any general, special, incidental or consequential damages
          arising out of the use or inability to use the Humach (including but
          not limited to loss of data or data being rendered inaccurate or
          losses sustained by you or third parties or a failure of the Humach to
          operate in any manner or a failure to notify any license holder of the
          existence of any derivative works.).
        </div>{" "}
        <div className="mt-24">
          4.5. Recourse. License holders shall have the right to commence action
          for copyright infringement based on the rights granted under this
          License. MachinieCorp is under no obligation to pursue actions on
          behalf of a license holder.
        </div>{" "}
        <div className="mt-24">
          4.6. Interpretation. If the disclaimer of warranty and limitation of
          liability provided above cannot be given local legal effect according
          to their terms, reviewing courts shall apply local law that most
          closely approximates an absolute waiver of all civil liability in
          connection with the Humach.
        </div>{" "}
      </div>

      <div className="mt-32 text-color-gray-100 reg-subtitle-2">
        <div className="bold-headline-5">Section 5. Miscellaneous Clauses</div>
        <div className="mt-24">
          5.1. Mutual Representations and Warranties. Each party represents and
          warrants that: it has reviewed and understands all the terms of this
          License; it has the full right, power, and authority to enter into and
          perform its obligations and grant the rights, licenses, consents and
          authorizations it grants or is required to grant under this License;
          this License will constitute the legal, valid and binding obligation
          of such party, enforceable against such party in accordance with its
          terms.
        </div>{" "}
        <div className="mt-24">
          5.2. Severability. If any provision of this License is held to be
          unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining
          provisions will continue in full force and effect. To the extent it is
          not possible under applicable law to change or interpret the provision
          that is held to be unenforceable or invalid, such provision shall be
          severed from this License and the remainder of the License shall be
          given full force and effect.
        </div>{" "}
        <div className="mt-24">
          5.3. Entire Agreement. This License constitutes the sole and entire
          agreement between the parties. This License supersedes all prior
          understandings, Licenses, representations, and documentation relating
          to the subject matter of this License.
        </div>{" "}
        <div className="mt-24">
          5.4. Headings. The headings in this License are for reference only and
          do not affect the interpretation of this License.
        </div>{" "}
        <div className="mt-24">
          5.5. Notices. All notices and other communications given in connection
          with this License shall be in writing and shall be deemed given when
          sent via email to the last known email address of the party to whom
          the notice must be delivered, provided that receipt of the notice is
          acknowledged via a reply email or separate email acknowledging receipt
          of the notice; or posted at{" "}
          <a href="https://machiniverse.xyz/" target="_blank">
            https://machiniverse.xyz/
          </a>
          .
        </div>{" "}
        <div className="mt-24">
          5.6. Sale by MachinieCorp. This License shall inure to the benefit of
          MachinieCorp's successors in interest, including, without limitation,
          successors through merger, consolidation, or sale of substantially all
          of MachinieCorp's ownership or assets, and shall continue to be
          binding upon you.
        </div>
      </div>
    </div>
  );
};

export const DocsSmartContract = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Other</div>
      <div className="bold-headline-5 mt-6">Smart Contract</div>

      <div className="mt-32 bold-subtitle-1">
        Machinie (to Migrate) :{" "}
        <span>
          <a
            href="https://etherscan.io/address/0xe6d3e488b9d31943df6e3b7d82f6f842679a1a8c"
            target="_blank"
          >
            0xe6d3e488b9d31943df6e3b7d82f6f842679a1a8c
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie (Migrated) :{" "}
        <span>
          <a
            href="https://etherscan.io/address/0xB826bDe739897ad50363d045d65eE5b83FDb730d"
            target="_blank"
          >
            0xB826bDe739897ad50363d045d65eE5b83FDb730d
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Humach :{" "}
        <span>
          <a
            href="https://etherscan.io/address/0xaa89e8eb3048a2d8cb1a3db7d2b49f8fa33d56ba"
            target="_blank"
          >
            0xaa89e8eb3048a2d8cb1a3db7d2b49f8fa33d56ba
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Prize :{" "}
        <span>
          <a
            href="https://etherscan.io/address/0x096e0e2c5c037d2959f8386998b904f8baf089ef"
            target="_blank"
          >
            0x096e0e2c5c037d2959f8386998b904f8baf089ef
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Message from Machinie :{" "}
        <span>
          <a
            href="https://polygonscan.com/address/0xE44Ab6F4df3761196b009D95b45559d44A5fC370"
            target="_blank"
          >
            0xE44Ab6F4df3761196b009D95b45559d44A5fC370
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Floppy :{" "}
        <span>
          <a
            href="https://etherscan.io/address/0x9F3dDF3309D501FfBDC4587ab11e9D61ADD3126a"
            target="_blank"
          >
            0x9F3dDF3309D501FfBDC4587ab11e9D61ADD3126a
          </a>
        </span>
      </div>
    </div>
  );
};

export const DocsOpensea = () => {
  return (
    <div className="text-color-gray-100">
      <div className="reg-overline text-color-secondary">Other</div>
      <DocsOpenseaCollection />
      <DocsLooksrareCollection />
    </div>
  );
};

export const DocsOpenseaCollection = () => {
  return (
    <div className="mt-6">
      <div className="bold-headline-5 text-color-primary-200">
        Opensea Collection
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Collection (to Migrate) :{" "}
        <span>
          <a
            href="https://opensea.io/collection/machinietomigrate/"
            target="_blank"
          >
            https://opensea.io/collection/machinietomigrate/
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Collection (Migrated) :{" "}
        <span>
          <a href="https://opensea.io/collection/machinie" target="_blank">
            https://opensea.io/collection/machinie
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Humach Collection :{" "}
        <span>
          <a href="https://opensea.io/collection/humach" target="_blank">
            https://opensea.io/collection/humach
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Prize Collection :{" "}
        <span>
          <a
            href="https://opensea.io/collection/machinie-prize"
            target="_blank"
          >
            https://opensea.io/collection/machinie-prize
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Message from Machinie Collection :{" "}
        <span>
          <a
            href="https://opensea.io/collection/message-from-machinie"
            target="_blank"
          >
            https://opensea.io/collection/message-from-machinie
          </a>
        </span>
      </div>
    </div>
  );
};

export const DocsLooksrareCollection = () => {
  return (
    <div className="mt-32">
      <div className="bold-headline-5 text-color-primary-200">
        Looksrare Collection
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Collection (to Migrate) :{" "}
        <span>
          <a
            href="https://looksrare.org/collections/0xE6d3e488b9D31943dF6e3B7d82f6F842679a1a8c?queryID=d34b3daddff7915024af2282599fa8ca&queryIndex=prod_tokens"
            target="_blank"
          >
            https://looksrare.org/collections/0xE6d3e488b9D31943d...
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Collection (Migrated) :{" "}
        <span>
          <a
            href="https://looksrare.org/collections/0xB826bDe739897ad50363d045d65eE5b83FDb730d?queryID=3331d09a2ad63e8950c28b68f0c4f7e2&queryIndex=prod_tokens"
            target="_blank"
          >
            https://looksrare.org/collections/0xB826bDe739897ad50,,,
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Humach Collection :{" "}
        <span>
          <a
            href="https://looksrare.org/collections/0xAA89E8Eb3048A2D8cb1a3Db7D2B49f8FA33D56ba?queryID=a1d903c1b6c78c2a0442896983ea7eff&queryIndex=prod_tokens"
            target="_blank"
          >
            https://looksrare.org/collections/0xAA89E8Eb3048A2D8c...
          </a>
        </span>
      </div>

      <div className="mt-14 bold-subtitle-1">
        Machinie Prize Collection :{" "}
        <span>
          <a
            href="https://looksrare.org/collections/0x096E0E2C5C037D2959F8386998B904F8bAF089Ef"
            target="_blank"
          >
            https://looksrare.org/collections/0x096E0E2C5C037D29...
          </a>
        </span>
      </div>
    </div>
  );
};
