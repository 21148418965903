import { useState, useEffect } from "react";

export const GasPriceCalculator = () => {
  const [inputGwei, setInputGwei] = useState<String>("88");
  const [migrationCal, setMigrationCal] = useState<String>("0");
  const [startStakeCal, setStartStakeCal] = useState<String>("0");
  const [stopStakeCal, setStopStakeCal] = useState<String>("0");
  const [startTrainCal, setStartTrainCal] = useState<String>("0");
  const [stopTrainCal, setStopTrainCal] = useState<String>("0");
  const [changeNameCal, setSetChangeNameCal] = useState<String>("0");
  const [changeBioCal, setChangeBioCal] = useState<String>("0");
  const [allowFloppyUseCal, setAllowFloppyUseCal] = useState<String>("0");
  const [claimFloppy1Cal, setClaimFloppy1Cal] = useState<String>("0");
  const [claimFloppy5Cal, setClaimFloppy5Cal] = useState<String>("0");
  const [claimFloppy10Cal, setClaimFloppy10Cal] = useState<String>("0");
  const [claimFloppy20Cal, setClaimFloppy20Cal] = useState<String>("0");
  const [callPayCall, setCallPayCal] = useState<String>("0");
  const [approveForAllCal, setApproveForAllCall] = useState<String>("0");

  const approveForAllPay = 46800;
  const migrationPay = 420000;
  const startStakingPay = 135000;
  const stopStakingPay = 63200;
  const startTrainingPay = 72000;
  const stopTrainingPay = 43000;
  const changeNamePay = 88200;
  const changeBioPay = 202600;
  const allowFloppyUsagePay = 29000;
  const claimFloppyPay = 57600;
  const claimFloppy5Pay = 110800;
  const claimFloppy10Pay = 179400;
  const claimFloppy20Pay = 301600;
  const callPay = 220300;

  useEffect(() => {
    const getStart = async () => {
      const convert = require("ether-converter");

      const result = convert(Number(inputGwei), "gwei", "ether");
      const toWei = result;

      setApproveForAllCall((toWei * approveForAllPay).toFixed(5));
      setMigrationCal((toWei * migrationPay).toFixed(5));
      setStartStakeCal((toWei * startStakingPay).toFixed(5));
      setStopStakeCal((toWei * stopStakingPay).toFixed(5));
      setStartTrainCal((toWei * startTrainingPay).toFixed(5));
      setStopTrainCal((toWei * stopTrainingPay).toFixed(5));
      setSetChangeNameCal((toWei * changeNamePay).toFixed(5));
      setChangeBioCal((toWei * changeBioPay).toFixed(5));
      setAllowFloppyUseCal((toWei * allowFloppyUsagePay).toFixed(5));
      setClaimFloppy1Cal((toWei * claimFloppyPay).toFixed(5));
      setClaimFloppy5Cal((toWei * claimFloppy5Pay).toFixed(5));
      setClaimFloppy10Cal((toWei * claimFloppy10Pay).toFixed(5));
      setClaimFloppy20Cal((toWei * claimFloppy20Pay).toFixed(5));
      setCallPayCal((toWei * callPay).toFixed(5));
    };

    getStart();
  }, [inputGwei]);

  return (
    <div className="reg-subtitle-2 text-color-gray-100">
      <div className="mt-32">
        Input gwei{" "}
        <input
          className="m-1 p-1 text-center text_day_3"
          placeholder="input gwei"
          value={Number(inputGwei)}
          onChange={(e) => setInputGwei(e.target.value)}
        ></input>
      </div>{" "}
      <div className="table-gas-container mt-32">
        <table className="width-table-gas">
          <tr className="reg-subtitle-1">
            <th>action</th>
            <th>estimated gas use</th>
            <th className="text-color-secondary">estimated gas price</th>
            <th>info</th>
          </tr>

          <tr className="reg-subtitle-2">
            <td>set approve for migration</td>
            <td>46,800</td>
            <td className="text-color-secondary">{approveForAllCal} eth</td>
            <td>only once</td>
          </tr>

          <tr>
            <td>migration</td>
            <td>420,000</td>
            <td className="text-color-secondary">{migrationCal} eth</td>
            <td>per machinie</td>
          </tr>

          <tr>
            <td>start staking</td>
            <td>130,000</td>
            <td className="text-color-secondary">{startStakeCal} eth</td>
            <td>per pair</td>
          </tr>
          <tr>
            <td>stop staking</td>
            <td>63,200</td>
            <td className="text-color-secondary">{stopStakeCal} eth</td>
            <td>per pair</td>
          </tr>

          <tr>
            <td>start training</td>
            <td>72,000</td>
            <td className="text-color-secondary">{startTrainCal} eth</td>
            <td>per humach</td>
          </tr>

          <tr>
            <td>stop training</td>
            <td>43,000</td>
            <td className="text-color-secondary">{stopTrainCal} eth</td>
            <td>per humach</td>
          </tr>

          <tr>
            <td>change name</td>
            <td>88,200</td>
            <td className="text-color-secondary">{changeNameCal} eth</td>
            <td></td>
          </tr>

          <tr>
            <td>change bio</td>
            <td>202,600</td>
            <td className="text-color-secondary">{changeBioCal} eth</td>
            <td></td>
          </tr>

          <tr>
            <td>allow $floppy usage</td>
            <td>29,000</td>
            <td className="text-color-secondary">{allowFloppyUseCal} eth</td>
            <td>
              This action is needed to be done once for both collection;
              machinie and humach
            </td>
          </tr>

          <tr>
            <td>claim $floppy</td>
            <td>57,600</td>
            <td className="text-color-secondary">{claimFloppy1Cal} eth</td>
            <td>1 pair staked</td>
          </tr>

          <tr>
            <td>claim $floppy</td>
            <td>110,800</td>
            <td className="text-color-secondary">{claimFloppy5Cal} eth</td>
            <td>5 pair staked</td>
          </tr>

          <tr>
            <td>claim $floppy</td>
            <td>179,400</td>
            <td className="text-color-secondary">{claimFloppy10Cal} eth</td>
            <td>10 pair staked</td>
          </tr>

          <tr>
            <td>claim $floppy</td>
            <td>301,600</td>
            <td className="text-color-secondary">{claimFloppy20Cal} eth</td>
            <td>20 pair staked</td>
          </tr>

          <tr>
            <td>Call (breed)</td>
            <td>220,300</td>
            <td className="text-color-secondary">{callPayCall} eth</td>
            <td>per call</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
