import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Skeleton } from "./components/skeleton/Skeleton";

import { SELECT_PAGE } from "./value";
// import { HumachHuntingPage } from "./components/test_hunt/HumachHuntingPage";
import { CreateLottoConfig } from "./components/admin/lotto/CreateLottoConfig";
import { CloseWeb } from "./components/close_web/CloseWeb";
import { useEffect, useState } from "react";
import { CountFloppy } from "./components/admin/count_floppy/CountFloppy";
import { RedeemMerch } from "./components/redeem_page/RedeemMerch";
import { Portfolio } from "./components/portfolio/Profolio";
import { PORTFOLIO_VALUE } from "./components/portfolio/portfolioConfig";
import { PortFolio2 } from "./components/portfolio/Portfolio2";
import { FindHolder } from "./components/admin/find_holder/FindHolder";
import { TestFunction } from "./components/admin/test_everything/TestFunction";

function App() {
  return (
    <Router>
      <div
        className="bg-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/bg.gif)`,
        }}
      >
        <Routes>
          {/* <Route path="/hunt" element={<HumachHuntingPage />} /> */}
          {/* <Route path="/find-holder" element={<FindHolder />} /> */}

          <Route path="/test-function" element={<TestFunction />} />

          <Route path="/banana/lotto" element={<CreateLottoConfig />} />
          <Route
            path={"/portfolio/KiNG/" + PORTFOLIO_VALUE.SELECT_PAGE.PROFILE}
            element={
              <Portfolio
                selectPage={PORTFOLIO_VALUE.SELECT_PAGE.PROFILE}
                selectProfile={PORTFOLIO_VALUE.KING}
              />
            }
          />
          <Route
            path={"/portfolio/KiNG/" + PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION}
            element={
              <Portfolio
                selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION}
                selectProfile={PORTFOLIO_VALUE.KING}
              />
            }
          />
          <Route
            path={"/portfolio/KiNG/" + PORTFOLIO_VALUE.SELECT_PAGE.EXP}
            element={
              <Portfolio
                selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EXP}
                selectProfile={PORTFOLIO_VALUE.KING}
              />
            }
          />

          <Route path={"/portfolio/kittitus/"} element={<PortFolio2 />} />
          <Route
            path={
              "/portfolio/kittitus/" + PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION
            }
            element={
              <Portfolio
                selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION}
                selectProfile={PORTFOLIO_VALUE.HATHUM}
              />
            }
          />
          <Route
            path={"/portfolio/kittitus/" + PORTFOLIO_VALUE.SELECT_PAGE.EXP}
            element={
              <Portfolio
                selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EXP}
                selectProfile={PORTFOLIO_VALUE.HATHUM}
              />
            }
          />
          <Route
            path="/"
            element={<Skeleton selectPage={SELECT_PAGE.HOMEPAGE} />}
          />
          <Route
            path="/machinie"
            element={<Skeleton selectPage={SELECT_PAGE.LEARN.MACHINIE} />}
          />

          <Route path="/count/floppy" element={<CountFloppy />}></Route>
          <Route
            path="/humach"
            element={<Skeleton selectPage={SELECT_PAGE.LEARN.HUMACH} />}
          />
          <Route
            path="/game/machipon"
            element={<Skeleton selectPage={SELECT_PAGE.GAME.MACHIPON} />}
          />

          <Route
            path="/game/slotmachi"
            element={<Skeleton selectPage={SELECT_PAGE.GAME.SLOT} />}
          />

          <Route
            path="/game/lotto"
            element={<Skeleton selectPage={SELECT_PAGE.GAME.LOTTO} />}
          />

          <Route
            path="/my-machinie/chilling"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.MACHINIE.CHILLING} />
            }
          />

          <Route
            path="/my-machinie/staking"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.MACHINIE.STAKING} />
            }
          />

          <Route
            path="/my-machinie/migrate"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.MACHINIE.MIGRATE} />
            }
          />

          <Route
            path="/my-humach/chilling"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.HUMACH.CHILLING} />
            }
          />

          <Route
            path="/my-humach/staking"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.HUMACH.STAKING} />
            }
          />

          <Route
            path="/my-humach/training"
            element={
              <Skeleton selectPage={SELECT_PAGE.MY_NFT.HUMACH.TRAINING} />
            }
          />

          <Route
            path="/redeem/ticket"
            element={<Skeleton selectPage={SELECT_PAGE.REDEEM.TICKET} />}
          />

          {/* <Route path="/redeem/merch" element={<RedeemMerch />} /> */}

          <Route
            path="/redeem/discord-exp"
            element={<Skeleton selectPage={SELECT_PAGE.REDEEM.DISCORD} />}
          />

          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.QUICK.GENERAL_FAQS}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.QUICK.GENERAL_FAQS} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.QUICK.ECOSYSTEM}
            element={<Skeleton selectPage={SELECT_PAGE.DOCS.QUICK.ECOSYSTEM} />}
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.QUICK.GAS_CAL}
            element={<Skeleton selectPage={SELECT_PAGE.DOCS.QUICK.GAS_CAL} />}
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.MACHINIE_FAQS}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_FAQS} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.MUST_READ}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.MACHINIE.MUST_READ} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.CHECK_MACHINIE_STATUS}
            element={
              <Skeleton
                selectPage={SELECT_PAGE.DOCS.MACHINIE.CHECK_MACHINIE_STATUS}
              />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.UNMIGRATED_LIST}
            element={
              <Skeleton
                selectPage={SELECT_PAGE.DOCS.MACHINIE.UNMIGRATED_LIST}
              />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.MACHINIE_TRAITS}
            element={
              <Skeleton
                selectPage={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_TRAITS}
              />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.MACHINIE.MACHINIE_LICENSE}
            element={
              <Skeleton
                selectPage={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_LICENSE}
              />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.HUMACH.HUMACH_FAQS}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.HUMACH.HUMACH_FAQS} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.HUMACH.CHECK_HUMACH_STATUS}
            element={
              <Skeleton
                selectPage={SELECT_PAGE.DOCS.HUMACH.CHECK_HUMACH_STATUS}
              />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.HUMACH.HUMACH_TRAITS}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.HUMACH.HUMACH_TRAITS} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.HUMACH.ALLOWLIST}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.HUMACH.ALLOWLIST} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.HUMACH.HUMACH_LICENSE}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.HUMACH.HUMACH_LICENSE} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.OTHER.SMART_CONTRACT}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.OTHER.SMART_CONTRACT} />
            }
          />
          <Route
            path={"/docs/" + SELECT_PAGE.DOCS.OTHER.SECONDARY_MARKET}
            element={
              <Skeleton selectPage={SELECT_PAGE.DOCS.OTHER.SECONDARY_MARKET} />
            }
          />

          <Route path="*" element={<Skeleton selectPage={"pageNotFound"} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
