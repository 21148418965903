export const CountStakingPanel = () => {
  var humachStaking = "462";
  var humachTraining = "981";
  var dateCount = "July 2023";

  return (
    <div>
      <div className="text-color-line-tint-04 reg-caption">
        Machinie/Humach in staking : {humachStaking} | Humach in training :{" "}
        {humachTraining} | As of {dateCount}
      </div>
    </div>
  );
};
