import { BeforeFooterSection } from "../homepage/beforefoot/BeforeFooterSection";
import { FooterSection } from "../homepage/footer/FooterSection";
import { Nav } from "../navbar/Nav";
import { useEffect } from "react";
import { DocsSection } from "./DocsSection";
import { SELECTING } from "../../value";

type DOCS_PAGE_SELECT = {
  select: string;
  setSelecting: any;
};

export const DocsPage = ({ select, setSelecting }: DOCS_PAGE_SELECT) => {
  useEffect(() => {
    setSelecting(SELECTING.DOCS_PAGE);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <DocsSection select={select} />
    </div>
  );
};
