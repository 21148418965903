export const IMAGE_ASSETS = {
  FLOPPY: `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`,
  HUMACH_ACTION: {
    CHILLING: `${process.env.PUBLIC_URL}/assets/humach4Actions/001-Sleep.gif`,
    TRAINING: `${process.env.PUBLIC_URL}/assets/humach4Actions/002-Training.gif`,
    CALLING: `${process.env.PUBLIC_URL}/assets/humach4Actions/003-call.gif`,
    STAKING: `${process.env.PUBLIC_URL}/assets/humach4Actions/004-staking.gif`,
  },
  MACHINIE: {
    LOGO: `${process.env.PUBLIC_URL}/assets/logo/machinie_l.png`,
    MACHINIE_NO_BG: `${process.env.PUBLIC_URL}/assets/machinie/machinie-no-bg.png`,
    NEW_LOGO:
      "https://i.seadn.io/gae/6GNt4DeHE_U8GFGbExmbvKxyy8vd5HtLPbbNZMLe9AaRvPHYTCnl0dnv2GDhgJaOs-5vBn8vjiYr0VNR5NFZRt61WhkMPoRoa6njPQ?auto=format&w=1920",
  },
  BACKGROUND: {
    STAR: `${process.env.PUBLIC_URL}/assets/background/bg.gif`,
    PREVIEW: `${process.env.PUBLIC_URL}/assets/machinie/machinie-no-bg.png`,
  },
  LOTTO: {
    DYSFUNCTIONAL: `${process.env.PUBLIC_URL}/assets/lottoReward/dysfunctional_Mach.gif`,
    BIGMACH: `${process.env.PUBLIC_URL}/assets/lottoReward/bigmach.gif`,
    NEWGENESIS: `${process.env.PUBLIC_URL}/assets/lottoReward/thenewGenesis.gif`,
    MCIRCULAR_HALLOWEEN: `${process.env.PUBLIC_URL}/assets/lottoReward/mCircular-halloween-edition.gif`,
    Mampliflier: `${process.env.PUBLIC_URL}/assets/lottoReward/Mampliflier.gif`,
    Xmas22: `${process.env.PUBLIC_URL}/assets/lottoReward/2022_Machimas.gif`,
    VALENTINE_PINK: `${process.env.PUBLIC_URL}/assets/lottoReward/valentine's-pink.gif`,
    MTRANSMITTER: `${process.env.PUBLIC_URL}/assets/lottoReward/mTransmitter.gif`,
    MBEEFORM: `${process.env.PUBLIC_URL}/assets/lottoReward/mBeeform.gif`,
    COMHANG: `${process.env.PUBLIC_URL}/assets/lottoReward/Machinie-comhang.jpg`,
    MACHINIEPRIDE: `${process.env.PUBLIC_URL}/assets/lottoReward/Machinie Pride.gif`,
    QUANTUMIZER: `${process.env.PUBLIC_URL}/assets/lottoReward/Quantumizer.gif`,
    THE_NEW_BEGINNING: `${process.env.PUBLIC_URL}/assets/lottoReward/TheNewBeginning.gif`,
    HALLOWEEN_2023: `${process.env.PUBLIC_URL}/assets/lottoReward/Halloweenie 2023.gif`,
    FROZENFIRE: `${process.env.PUBLIC_URL}/assets/lottoReward/frozenfire.gif`,
    MACHIMAS_2023: `${process.env.PUBLIC_URL}/assets/lottoReward/2023_Machimas.gif`,
    MCIRCULAR_DRAGON: `${process.env.PUBLIC_URL}/assets/lottoReward/mCircular Dragon Spirit.gif`,
    VALENTINE_PINK_2024: `${process.env.PUBLIC_URL}/assets/lottoReward/Valentine's Pink 2024.gif`,
    MACHINOM_2024: `${process.env.PUBLIC_URL}/assets/lottoReward/Machinom 2024.gif`,
    RETRO_MACH_2024: `${process.env.PUBLIC_URL}/assets/lottoReward/RetroMach 2024.gif`,
    ETH: `${process.env.PUBLIC_URL}/assets/lottoReward/eth.png`,
  },
  INVENTORY: {
    TICKET: `${process.env.PUBLIC_URL}/assets/gameAssets/Ticket.png`,
    DISCORD: `${process.env.PUBLIC_URL}/assets/gameAssets/1000EXP.png`,
    CURRENT: `${process.env.PUBLIC_URL}/assets/learnAssets/current.png`,
  },
  SANTA_MACH:
    "https://lh3.googleusercontent.com/JMkKCmoofBJL4uB-Rdy52Yy8NP-_svv_nLmyj0Db2beoVSjTLj4AMJoDAVdPBeuBlKMKdWpk4j2v70I3Pun8aBGJiDXvlre50SmR=w190",
  HUMACH_ONE_OF_ONE: {
    GREEN:
      "https://lh3.googleusercontent.com/xSdul7dtaEV9UaV_dmoGJXXgPh8lF5-xmkrEpDjdfKC8ts2RPJ9mmMwBr9f1-dt0XBvqi9tQohXvaqTrSginhihxojh8pZL_NvSc3Q=w600",
    HELLMACH:
      "https://lh3.googleusercontent.com/BWp7fjx0W7w0q8pDeKHks7h6zzqpodCrgvtXtj2lsF9QZV3pDpFdCRw2a1wGLFOAOaUkX5fse_TuZ9I3PbtvGUIKp8HTbhmdGK3HGeo=w600",
  },
  TREASURE_HUNT_EVENT: {
    TEASER: {
      IMAGE: `${process.env.PUBLIC_URL}/assets/treasure_hunt/Humach_Spaceship_Control_Room.gif`,
      TEXT: `${process.env.PUBLIC_URL}/assets/treasure_hunt/text_1_for_treasure_hunting_event.png`,
      TEXT_FIND: `${process.env.PUBLIC_URL}/assets/treasure_hunt/text_2_for_treasure_hunting_event.png`,
    },
  },
  GAME: {
    RPS: {
      ROCK: `${process.env.PUBLIC_URL}/assets/gameAssets/rock.png`,
      PAPER: `${process.env.PUBLIC_URL}/assets/gameAssets/paper.png`,
      SCISSOR: `${process.env.PUBLIC_URL}/assets/gameAssets/scissor.png`,
    },
  },
  MERCH: {
    MOCK: {
      RECKLACE: {
        A: `${process.env.PUBLIC_URL}/assets/merch/machnecklace.jpg`,
        B: `${process.env.PUBLIC_URL}/assets/merch//Machinie_Necklace_Mockup_B.jpg`,
        C: `${process.env.PUBLIC_URL}/assets/merch/machnecklace2.png`,
      },
      HOODIE: `${process.env.PUBLIC_URL}/assets/merch/TeamMach-hoodies.jpg`,
      HOODIE_WHITE: `${process.env.PUBLIC_URL}/assets/merch/hoodie-color-white.png`,
      HOODIE_BLACK: `${process.env.PUBLIC_URL}/assets/merch/hoodie-color-black.png`,
    },
  },
};

// /Users/hathumkittitus/machiniverse-project/public/assets/gameAssets/rock.png
// /Users/hathumkittitus/machiniverse-project/public/assets/treasure_hunt/text_2_for_treasure_hunting_event.png
// /Users/hathumkittitus/machiniverse-project/public/assets/treasure_hunt/text_1_for_treasure_hunting_event.png
// /Users/hathumkittitus/machiniverse-project/public/assets/treasure_hunt/Humach_Spaceship_Control_Room.gif

// /Users/hathumkittitus/machiniverse-project/public/assets/gameAssets/Ticket.png
// /Users/hathumkittitus/machiniverse-project/public/assets/learnAssets/current.png
// /Users/hathumkittitus/machiniverse-project/public/assets/gameAssets/1000EXP.png
