import { Modal } from "../modal/Modal";
import { useEffect, useState } from "react";
import { MachinieGenerateCurrent } from "../learn_machinie/MachinieGenerateCurrent";

export const HumachGenerateCurrent = () => {
  return <MachinieGenerateCurrent />;
};

{
  /* <section className="section bg-2">
      <div className="container">
        <div className="machinie-generate-top-container">
          <div className="text-machinie machinie-generate-top-right">
            <div className="text-gold">Utility </div>
            <div className="text-main font-bold-xl pt-2 pb-2">
              Generate $current
            </div>
            <div className="text-white pb-3">
              Every Humach alone can generate $current daily. $current is a
              centralized token that fuels the Machiniverse ecosystem.
            </div>
            <div>
              <div
                className="text-color-primary-200 bold-button mt-24 cursor-pointer hover-text-color-secondary"
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  document.body.style.paddingRight = "15px";
                  setIsOpen(true);
                }}
              >
                SEE WHAT IS $CURRENT{" "}
                <span className="text-color-tertiary">
                  <i className="fa fa-plus"></i>
                </span>{" "}
              </div>

              <ModalGenerateCurrent isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
          <div className="img-floppy-container-container">
            <div className="img-floppy-container">
              <img src={current_img} className="img-floppy" alt="floppy"></img>
            </div>
          </div>
        </div>
      </div>
    </section> */
}

type MODAL_GENERATE_TYPE = {
  isOpen: boolean;
  setIsOpen: any;
};

export const ModalGenerateCurrent = ({
  isOpen,
  setIsOpen,
}: MODAL_GENERATE_TYPE) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="p-24">
        <div className="close-modal-container">
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="fa fa-times close-modal"></i>
          </div>
        </div>

        <div className="table-modal-container mt-24">
          <div className="">
            <div className="p-3 h-50 border-cost bg-2">
              <div className="text-color-gray-100 bold-body-2">
                Play Slot Machi
              </div>
            </div>
            <div className=" p-3 h-50 boder-rewards bg-2">
              <div className="text-color-gray-100 bold-body-2">
                Play Machipon
              </div>
            </div>
          </div>

          <div>
            <div className="p-3 h-50 border-cost-value ">
              <div className="text-color-gray-100 reg-body-2">
                Spending 1-20 $current to win some extra $current in return
              </div>
            </div>

            <div className="p-3 h-50 text-color-gray-100 reg-body-2">
              Spending 5 $current per play to win some rewards.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
