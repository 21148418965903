export const TEAM_PFP = {
  king: "https://machinienft.com/assets/images/KiNG_mcn.gif",
  hathum: "https://machinienft.com/assets/images/hathum_mcn.gif",
  plzdontcry: `${process.env.PUBLIC_URL}/assets/team_assets/Plzdontcry.png`,
  ppla: "https://lh3.googleusercontent.com/-usMqLXDLy6ZDarTDEijScdgKKMy0i2wV6i0aCygsPbQ_xQ-CnD1xZ_QKLfT6UrbcY_lXZb_hRgl32YeaXmYd8FHNsm-jkKTUqY6",
  pkumz: "https://machinienft.com/assets/images/kumz.gif",
  xtheowl: `${process.env.PUBLIC_URL}/assets/team_assets/xtheowl.png`,
  top: `${process.env.PUBLIC_URL}/assets/team_assets/TOP.png`,
  nftchief: `${process.env.PUBLIC_URL}/assets/team_assets/NFTChief.png`,
};

export const TEAM_LINK_CONTACT = {
  king: "https://twitter.com/0xKiNG_machinie",
  hathum: "https://twitter.com/Nijuusanji",
  pkumz: "https://twitter.com/kumsmkiiii80",
  xtheowl: "https://mobile.twitter.com/xtheowl420",
  plzdontcray: "https://www.linkedin.com/in/naraphat-sanujit-a30686224",
  nftchief: "https://mobile.twitter.com/TheNFTChief",
  top: "",
  ppla: "",
};

export const TEAM_LINK_TYPE = {
  TWITTER: "twitter",
  LINKIN: "linkin",
};
