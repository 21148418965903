import { useEffect, useState } from "react";
import { getUser, getWalletList, redeemDiscordLVL } from "../../apiCallReturn";
import { api } from "../../axiosURL";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import { SELECT_REDEEM } from "../../value";
import { API } from "../../valueApi";
import { LoadingDots } from "../component/LoadingDots";
import { Modal, Modal2, Modal3 } from "../modal/Modal";

export const RedeemModal = ({
  isOpen,
  setIsOpen,
  selectRedeem,
  setSelectPanelRedeem,
  selectPanelRedeem,
  walletList,
  setWalletList,
  memberInfo,
  setMemberInfo,
}: REDEEM_MODAL) => {
  useEffect(() => {
    setSelectPanelRedeem(selectRedeem);
  }, []);

  const renderSelectRedeemPanel = (selectPanelRedeem: string) => {
    switch (selectPanelRedeem) {
      case SELECT_REDEEM.REDEEM_DISCORD:
        return (
          <DiscordRedeemModal
            selectPanelRedeem={selectPanelRedeem}
            setSelectPanelRedeem={setSelectPanelRedeem}
            walletList={walletList}
            setWalletList={setWalletList}
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
            setIsOpen={setIsOpen}
          />
        );
      case SELECT_REDEEM.REDEEM_TICKET:
        return (
          <TicketRedeemModal
            selectPanelRedeem={selectPanelRedeem}
            setSelectPanelRedeem={setSelectPanelRedeem}
            walletList={walletList}
            setWalletList={setWalletList}
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
            setIsOpen={setIsOpen}
          />
        );
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">Redeem</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      {renderSelectRedeemPanel(selectPanelRedeem)}
    </Modal>
  );
};

type REDEEM_MODAL = {
  isOpen: boolean;
  setIsOpen: any;
  selectRedeem: string;
  selectPanelRedeem: string;
  setSelectPanelRedeem: any;
  walletList: any;
  setWalletList: any;
  memberInfo: any;
  setMemberInfo: any;
};

type REDEEM_PANEL = {
  selectPanelRedeem: string;
  setSelectPanelRedeem: any;
  walletList: any;
  setWalletList: any;
  memberInfo: any;
  setMemberInfo: any;
  setIsOpen: any;
};

export const DiscordRedeemModal = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  walletList,
  setWalletList,
  memberInfo,
  setMemberInfo,
  setIsOpen,
}: REDEEM_PANEL) => {
  const [open2, setIsOpen2] = useState(false);
  const [open3, setIsOpen3] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [isRedeemSuccess, setIsRedeemSuccess] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [sucessesMessage, setSuccessMessage] = useState("");

  const errMessagePop = (errText: string) => {
    setErrMessage(errText);
    var delay = 5000;
    setTimeout(() => {
      setErrMessage("");
    }, delay);
  };

  const redeemDiscord = async () => {
    setIsRedeeming(true);
    try {
      await redeemDiscordLVL(memberInfo.discordId);
      const thisWalletList = await getWalletList();
      setWalletList(thisWalletList.data);
      setIsRedeemSuccess(true);
      setIsOpen3(true);
    } catch (err: any) {
      setIsRedeeming(false);
    }
  };

  return (
    <div>
      <div className="p-24">
        <div className="">
          <div className="bold-button text-color-line-tint-04">SELECT ITEM</div>
          <div className="mt-14 select-button-redeem-container">
            <RedeemTicket
              selectPanelRedeem={selectPanelRedeem}
              setSelectPanelRedeem={setSelectPanelRedeem}
              thisPanelRedeem={SELECT_REDEEM.REDEEM_TICKET}
              walletList={walletList}
            />
            <RedeemDiscord
              selectPanelRedeem={selectPanelRedeem}
              setSelectPanelRedeem={setSelectPanelRedeem}
              thisPanelRedeem={SELECT_REDEEM.REDEEM_DISCORD}
              walletList={walletList}
            />
          </div>
        </div>

        <div className="mt-24">
          <div className="text-color-line-tint-04 bold-button">
            YOUR DISCORD ID
          </div>
          <div
            className="mt-14 edit-display-name reg-subtitle-2 text-color-gray-100"
            onClick={() => {
              setIsOpen2(true);
            }}
          >
            {memberInfo.discordId === undefined
              ? "Please insert your Discord Id"
              : memberInfo.discordId}
          </div>

          {sucessesMessage !== "" ? (
            <div className="reg-caption mt-14 text-color-secondary">
              {sucessesMessage}
            </div>
          ) : (
            ""
          )}

          {errMessage !== "" ? (
            <div className="reg-caption mt-14 text-color-secondary">
              {errMessage}
            </div>
          ) : (
            ""
          )}

          <ModalSuccessRedeem
            open={open3}
            setIsOpen={setIsOpen3}
            memberInfo={memberInfo}
            setIsRedeeming={setIsRedeeming}
            setIsRedeemSuccess={setIsRedeemSuccess}
          />

          <ModalEditDiscordId
            open={open2}
            setIsOpen={setIsOpen2}
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
          />
        </div>
      </div>
      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          BACK
        </div>
        {memberInfo.discordId !== undefined ? (
          !isRedeeming ? (
            walletList.discord.discordLVL > 0 ? (
              <div
                className="modal-stake-button-stake-selected cursor-pointer bold-button"
                onClick={() => redeemDiscord()}
              >
                REDEEM
              </div>
            ) : (
              <div
                className="modal-stake-button-stake-selected bold-button off"
                onClick={() =>
                  errMessagePop(
                    "You have no discord level left let's spin more on Machipon!"
                  )
                }
              >
                REDEEM
              </div>
            )
          ) : !isRedeemSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              REDEEMING
              <LoadingDots />
            </div>
          ) : (
            <div className="modal-stake-button-stake-selected cursor-pointer bold-button">
              SUCCESS!
            </div>
          )
        ) : (
          <div
            className="modal-stake-button-stake-selected bold-button off"
            onClick={() => errMessagePop("Please insert Discord Id")}
          >
            REDEEM
          </div>
        )}
      </div>
    </div>
  );
};

type MODAL_SUCCESS = {
  open: boolean;
  setIsOpen: any;
  memberInfo: any;
  setIsRedeeming: any;
  setIsRedeemSuccess: any;
};

export const ModalSuccessRedeem = ({
  open,
  setIsOpen,
  memberInfo,
  setIsRedeeming,
  setIsRedeemSuccess,
}: MODAL_SUCCESS) => {
  return (
    <Modal open={open} onClose={() => setIsOpen(false)}>
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">
          Redeem Success
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsRedeeming(false);
            setIsRedeemSuccess(false);
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>
      <div className="p-24">
        <div className="bold-subtitle-2 text-color-gray-100">
          Redeem discrod level for{" "}
          <span className="text-color-primary-200">{memberInfo.discordId}</span>{" "}
          successfully.
        </div>
      </div>

      <div className="modal-stake-bottom-container-alone">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsRedeeming(false);
            setIsRedeemSuccess(false);
            setIsOpen(false);
          }}
        >
          BACK
        </div>
      </div>
    </Modal>
  );
};

type MODAL_EDIT_DISCORD_ID = {
  open: boolean;
  setIsOpen: any;
  memberInfo: any;
  setMemberInfo: any;
};

export const ModalEditDiscordId = ({
  open,
  setIsOpen,
  memberInfo,
  setMemberInfo,
}: MODAL_EDIT_DISCORD_ID) => {
  const [newDiscordName, setNewDiscordName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  useEffect(() => {
    getStart();
  }, [memberInfo]);

  const getStart = async () => {
    if (memberInfo.discordId === undefined) {
      setLastName("Example ABC#123");
    } else {
      setLastName(memberInfo.discordId);
    }
  };

  const editDisplayName = async (nameToCreate: string) => {
    setIsUpdating(true);
    try {
      const res = await api.put(
        API.USER.EDIT_USER,
        {
          discordId: nameToCreate,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisUser = await getUser();
      setMemberInfo(thisUser.data);
      setIsUpdateSuccess(true);
    } catch (err) {
      setIsUpdating(false);
      setIsUpdateSuccess(false);
    }
  };

  return (
    <Modal open={open} onClose={() => setIsOpen(false)}>
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">
          Edit Discord ID
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      {!isUpdating ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-color-gray-100">
            Edit Discord Id
          </div>
          <input
            className="reg-subtitle-1 text-color-gray-100 input-display-name mt-14"
            value={newDiscordName}
            onChange={(e) => setNewDiscordName(e.target.value)}
            placeholder={lastName}
          ></input>
        </div>
      ) : !isUpdateSuccess ? (
        <div className="p-24">
          <div className="reg-subtitle-2 text-center text-color-gray-100">
            Updating Discord Id
            <LoadingDots />
          </div>
        </div>
      ) : (
        <div className="p-24">
          <div className="bold-subtitle-2 text-center text-color-gray-100">
            Your new discord id is {memberInfo.discordId}!
          </div>
        </div>
      )}

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          BACK
        </div>

        {newDiscordName.length > 0 ? (
          !isUpdating ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => editDisplayName(newDiscordName)}
            >
              SAVE
            </div>
          ) : !isUpdateSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SAVING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setIsOpen(false)}
            >
              SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SAVE
          </div>
        )}
      </div>
    </Modal>
  );
};

type REDEEM_BUTTON = {
  selectPanelRedeem: string;
  setSelectPanelRedeem: any;
  thisPanelRedeem: string;
  walletList: any;
};

export const RedeemDiscord = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  thisPanelRedeem,
  walletList,
}: REDEEM_BUTTON) => {
  return (
    <div
      className={
        thisPanelRedeem === selectPanelRedeem
          ? "button-selected-redeem-each"
          : "button-select-redeem-each"
      }
      onClick={() => setSelectPanelRedeem(thisPanelRedeem)}
    >
      <div className="bg-color-gradient-radial img-button-select-redeem-container">
        <img
          src={IMAGE_ASSETS.INVENTORY.DISCORD}
          alt="discord"
          width="46px"
          className="img-button-select-redeem-discord"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="reg-caption text-color-line-tint-04">Discord XP</div>
        <div className="bold-subtitle-2 text-color-gray-100">
          {walletList.discord.discordLVL}
        </div>
      </div>
    </div>
  );
};

export const TicketRedeemModal = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  walletList,
  setWalletList,
}: REDEEM_PANEL) => {
  return (
    <div className="p-24">
      <div className="">
        <div className="bold-button text-color-line-tint-04">SELECT ITEM</div>
        <div className="mt-14 select-button-redeem-container">
          <RedeemTicket
            selectPanelRedeem={selectPanelRedeem}
            setSelectPanelRedeem={setSelectPanelRedeem}
            thisPanelRedeem={SELECT_REDEEM.REDEEM_TICKET}
            walletList={walletList}
          />
          <RedeemDiscord
            selectPanelRedeem={selectPanelRedeem}
            setSelectPanelRedeem={setSelectPanelRedeem}
            thisPanelRedeem={SELECT_REDEEM.REDEEM_DISCORD}
            walletList={walletList}
          />
        </div>
      </div>
    </div>
  );
};

export const RedeemTicket = ({
  selectPanelRedeem,
  setSelectPanelRedeem,
  thisPanelRedeem,
  walletList,
}: REDEEM_BUTTON) => {
  return (
    <div
      className="button-select-redeem-each-off"
      // className={
      //   thisPanelRedeem === selectPanelRedeem
      //     ? "button-selected-redeem-each off"
      //     : "button-select-redeem-each off"
      // }
      // onClick={() => setSelectPanelRedeem(thisPanelRedeem)}
    >
      <div className="bg-color-gradient-radial img-button-select-redeem-container">
        <img
          src={IMAGE_ASSETS.INVENTORY.TICKET}
          alt="ticket"
          width="43px"
          className="img-button-select-redeem-ticket"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="reg-caption text-color-line-tint-04">TICKET</div>
        <div className="bold-subtitle-2 text-color-gray-100">
          {walletList.ticket.merchTicket}
        </div>
      </div>
    </div>
  );
};
