import { Link } from "react-router-dom";
import { MACHINIE_LINK } from "../../../value";
import {
  IconDiscord,
  IconInstagram,
  IconOpensea,
  IconMedium,
  IconTwitter,
  IconCopyRight,
} from "../../component/Icon";

export const FooterSection = () => {
  return (
    <section className="bg-1 footer">
      <div className="container">
        <div className="display-footer">
          <div className="d-flex m-auto-mobile">
            <div className="text-color-line-tint-07 copy-right-margin">
              <IconCopyRight />
            </div>

            <div className="text-machinie text-color-line-tint-07">
              2021 Machinie
            </div>
          </div>
          <div className="footer-community">
            <div className="text-machinie text-color-line-tint-07">
              Join our family!
            </div>{" "}
            <div className="contact-grid">
              <a
                className="icon-nav-right a-icon-nav"
                href={MACHINIE_LINK.DISCORD}
                target="_blank"
                rel="noreferrer"
              >
                <IconDiscord />
              </a>
              <a
                className="icon-nav-right a-icon-nav"
                href={MACHINIE_LINK.TWITTER}
                target="_blank"
                rel="noreferrer"
              >
                <IconTwitter />
              </a>
              <a
                className="icon-nav-right a-icon-nav"
                href={MACHINIE_LINK.MEDIUM}
                target="_blank"
                rel="noreferrer"
              >
                <IconMedium />
              </a>
              <a
                className="icon-nav-right a-icon-nav"
                href={MACHINIE_LINK.INSTAGRAM}
                target="_blank"
                rel="noreferrer"
              >
                <IconInstagram />
              </a>
              <Link
                className="icon-nav-right a-icon-nav"
                to="/docs/secondary-market"
              >
                <IconOpensea />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
