import humach from "../../../humach";
import web3 from "../../../web3";
import { api } from "../../../axiosURL";
import { useState } from "react";
import { MyHumachTrainingEach } from "./MyHumachTrainingEach";
import { NoHumach } from "../MyHumach";

type MY_HUMACH_TRAINING_PROPS_TYPE = {
  humachTraining: any;
  setHumachList: any;
  setMachinieList: any;
  setWalletList: any;
  floppy: any;
  setFloppy: any;
};

export const MyHumachTrainingPanel = ({
  humachTraining,
  setHumachList,
  setMachinieList,
  setWalletList,
  floppy,
  setFloppy,
}: MY_HUMACH_TRAINING_PROPS_TYPE) => {
  const [humachArrayForStopTrain, setHumachArrayForStopTrain] = useState([]);
  const [selectIdMoreToggle, setSelectIdMoreToggle] = useState<string>("");
  return (
    <div>
      {humachTraining.length > 0 ? (
        <div>
          <HumachEachTopSelectAllTraining
            humachArrayForStopTrain={humachArrayForStopTrain}
            setHumachArrayForStopTrain={setHumachArrayForStopTrain}
            humachTraining={humachTraining}
            setHumachList={setHumachList}
          />
          <div className="machinie-each-panel">
            {humachTraining.map((x: any) => (
              <MyHumachTrainingEach
                humachId={x.humachNumber.toString()}
                setHumachArrayForStopTrain={setHumachArrayForStopTrain}
                humachArrayForStopTrain={humachArrayForStopTrain}
                setWalletList={setWalletList}
                humachListEach={x}
                setHumachList={setHumachList}
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoHumach
          topic="No training Humach"
          text="Your training Humach will show up here."
          selectPage="HUMACH_TRAINING"
        />
      )}
    </div>
  );
};

type HUMACH_EACH_TOP_SELECT_ALL_TRAINING = {
  humachArrayForStopTrain: string[];
  setHumachArrayForStopTrain: any;
  humachTraining: any;
  setHumachList: any;
};

export const HumachEachTopSelectAllTraining = ({
  humachArrayForStopTrain,
  setHumachArrayForStopTrain,
  humachTraining,
  setHumachList,
}: HUMACH_EACH_TOP_SELECT_ALL_TRAINING) => {
  const selectAll = () => {
    setHumachArrayForStopTrain(
      humachTraining.map((x: any) => x.humachNumber.toString())
    );
  };

  const deselectAll = () => {
    setHumachArrayForStopTrain([]);
  };

  const stopTrainSelectHumach = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      await humach.methods.unStakeHumach(humachArrayForStopTrain).send({
        from: accounts[0],
      });

      const thisHumachList = await getHumachList();
      setHumachList(thisHumachList.data);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return (
    <div className="machinie-chilling-top">
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => selectAll()}
      >
        SELECT ALL
      </div>
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => deselectAll()}
      >
        DESELECT ALL
      </div>
      {humachArrayForStopTrain.length > 0 ? (
        <div
          className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer"
          onClick={() => stopTrainSelectHumach()}
        >
          STOP TRAIN SELECTED
        </div>
      ) : (
        <div className="stake-select-all-button bold-button text-color-gray-100 cursor-pointer">
          STOP TRAIN SELECTED
        </div>
      )}
    </div>
  );
};
