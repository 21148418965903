import {
  machinieTriple,
  machinieSingle,
  machinieDouble,
  machinieQuadruple,
  machinieDistorted,
} from "../../MachinieURL";

import { useEffect, useState } from "react";
import { Modal } from "../modal/Modal";

export const MachinieGenerateFloppy = () => {
  const floppy_img: string = `${process.env.PUBLIC_URL}/assets/learnAssets/floppy.png`;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="section">
      <div className="container">
        <div className="machinie-generate-top-container">
          <div className="img-floppy-container-container">
            <div className="img-floppy-container">
              <img src={floppy_img} className="img-floppy" alt="floppy"></img>
            </div>
          </div>

          <div className="machinie-generate-top-right">
            <div className="reg-subtitle-2 text-color-secondary">Utility</div>
            <div className="bold-headline-4 text-color-primary-200 mt-14">
              Generate $floppy
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-14">
              Each Humach can pair up with a Machinie to generate $floppy.
              $floppy is a decentralized token that is used to fuel the
              Machiniverse ecosystem.
            </div>
            <div>
              <div
                className="text-color-primary-200 bold-button mt-24 cursor-pointer hover-text-color-secondary"
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  document.body.style.paddingRight = "15px";
                  setIsOpen(true);
                }}
              >
                SEE WHAT IS THE $FLOPPY USAGE{" "}
                <span className="text-sub">
                  <i className="fa fa-plus"></i>
                </span>{" "}
              </div>
              <ModalGenerateFloppy isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>

        <div className="text-machinie font-bold text-main text-center cot-text-intro">
          The power of $floppy generation is due to the{" "}
          <span className="text-color-gray-100">‘combination of type’</span>{" "}
          each machinie contained.
        </div>
        <div>
          <CotMachinieContainer />
        </div>
      </div>
    </section>
  );
};

type MODAL_GENERATE_TYPE = {
  isOpen: boolean;
  setIsOpen: any;
};

export const ModalGenerateFloppy = ({
  isOpen,
  setIsOpen,
}: MODAL_GENERATE_TYPE) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="p-24">
        <div className="close-modal-container">
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="fa fa-times close-modal"></i>
          </div>
        </div>

        <div className="table-modal-container mt-24">
          <div className="">
            <div className="p-3 h-50 border-cost bg-2">
              <div className="text-color-gray-100 bold-body-2">
                Mint a Humach
              </div>
            </div>
            <div className=" p-3 h-50 boder-rewards bg-2">
              <div className="text-color-gray-100 bold-body-2">
                Change Name/Bio of Token
              </div>
            </div>
          </div>

          <div>
            <div className="p-3 h-50 border-cost-value ">
              <div className="text-color-gray-100 reg-body-2">
                150-170 $floppy depending on Humach level you use to call.
              </div>
            </div>

            <div className="p-3 h-50 text-color-gray-100 reg-body-2">
              35 $floppy.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

{
  /* <div className="table-modal-container mt-24">
<div className="">
  <div className="p-3 h-60 border-cost bg-2">
    <div className="text-color-gray-100 bold-body-2">
      Mint a Humach
    </div>
  </div>
  <div className=" p-3 h-40 boder-rewards bg-2">
    <div className="text-color-gray-100 bold-body-2">Change Name</div>
  </div>
</div>

<div>
  <div className="p-3 h-60 border-cost-value text-color-gray-100 reg-body-2">
    150-170 $floppy depending on Humach level you use to call.
  </div>

  <div className="p-3 h-40 text-color-gray-100 reg-body-2">
    35 $floppy.
  </div>
</div>
</div> */
}

export const CotMachinieContainer = () => {
  return (
    <div className="cot-machinie-container">
      <CotMachinieEach cot="Triple" floppyPerDay="8" />
      <CotMachinieEach cot="Double" floppyPerDay="9" />
      <CotMachinieEach cot="Quadruple" floppyPerDay="10" />
      <CotMachinieEach cot="Single" floppyPerDay="11" />
      <CotMachinieEach cot="Distorted" floppyPerDay="12" />
    </div>
  );
};

type COT_MACHINIE_EACH_TYPES = {
  cot: string;
  floppyPerDay: string;
};

export const CotMachinieEach = ({
  cot,
  floppyPerDay,
}: COT_MACHINIE_EACH_TYPES) => {
  const floppy_mini_img: string = `${process.env.PUBLIC_URL}/assets/learnAssets/floppy_mini.png`;

  const [imgUrl, setImgUrl] = useState<string>("");
  const [number, setNum] = useState<number>();
  useEffect(() => {
    const getStart = () => {
      if (cot === "Triple") {
        const numRandom = Math.floor(
          Math.random() * (machinieTriple.length - 1)
        );
        setImgUrl(machinieTriple[numRandom]);
        setNum(numRandom);
      } else if (cot === "Double") {
        const numRandom = Math.floor(
          Math.random() * (machinieDouble.length - 1)
        );
        setImgUrl(machinieDouble[numRandom]);
        setNum(numRandom);
      } else if (cot === "Quadruple") {
        const numRandom = Math.floor(
          Math.random() * (machinieQuadruple.length - 1)
        );
        setImgUrl(machinieQuadruple[numRandom]);
        setNum(numRandom);
      } else if (cot === "Single") {
        const numRandom = Math.floor(
          Math.random() * (machinieSingle.length - 1)
        );
        setImgUrl(machinieSingle[numRandom]);
        setNum(numRandom);
      } else if (cot === "Distorted") {
        const numRandom = Math.floor(
          Math.random() * (machinieDistorted.length - 1)
        );
        setImgUrl(machinieDistorted[numRandom]);
        setNum(numRandom);
      }
    };

    getStart();
  }, []);
  return (
    <div className="machinie-cot-each-container bg-1 text-machinie text-center">
      <div className="cot-text-container">
        <div className="cot-text text-white font-mini">{cot}</div>
      </div>
      <div className="img-cot-each-container">
        <img
          src={imgUrl}
          alt={number?.toString()}
          className="img-cot-each"
        ></img>
      </div>
      <div className="cot-per-day-each-container">
        <div className="cot-per-day-each">
          <div className="font-bold-s text-white">
            {floppyPerDay}{" "}
            <span>
              <img src={floppy_mini_img} alt="floppy_logo"></img>
            </span>
          </div>
          <div className="text-sub font-mini">Per day</div>
        </div>
      </div>
    </div>
  );
};
