import smart_contact_machinieUpgrade from "../../smart_contact_call/smart_contact_machinieUpgrade";
import smart_contact_humach from "../../smart_contact_call/smart_contact_humach";
import { useState } from "react";
import { api } from "../../axiosURL";
import { LoadingDots } from "../component/LoadingDots";
import { machinie_image_url } from "../../machinieImageUrl2";

export const CheckStatusHumach = () => {
  const [humachId, setHumachId] = useState<number | undefined>(undefined);
  const [image, setImageToken] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState("");
  const [isChecking, setIsChecking] = useState(false);

  const checkStatusThisHumach = async (params: number) => {
    setImageToken(undefined);
    setStatus("Loading");
    setIsChecking(true);
    try {
      const res = await smart_contact_humach.methods.isStaking(params).call();
      const getImage = await api.get("/api/humach/metadata/" + humachId);

      setImageToken(getImage.data.image);
      if (res) {
        const res2 = await smart_contact_humach.methods
          .getStakeTime(params)
          .call();
        if (Number(res2) > 0) {
          setStatus("Training");
        } else {
          setStatus("Staking");
        }
      } else {
        setStatus("Chilling");
      }
      setIsChecking(false);
    } catch (error: any) {
      setStatus("Token not exist");
      setIsChecking(false);
    }
  };

  return (
    <div className="check-status-container-container">
      <div className="reg-subtitle-2 p-14 text-color-gray-100 text-center">
        Humach #{humachId}
      </div>
      <div className="image-container-check-status">
        {image !== undefined ? (
          <img
            src={image.replace(
              "https://gateway.pinata.cloud",
              "https://ipfs.io"
            )}
            className="image-check-status"
          ></img>
        ) : (
          <div className="image-check-status"></div>
        )}
      </div>
      <div className="reg-subtitle-2 p-14 text-color-secondary text-center">
        {status}
      </div>
      <div className="p-14">
        <input
          value={humachId}
          onChange={(e: any) => setHumachId(Number(e.target.value))}
          placeholder="Input Token ID"
          className="input-check-status text-color-gray-100 reg-subtitle-2"
        ></input>
      </div>
      <div className="m-auto text-center p-14">
        {typeof humachId === "number" ? (
          isChecking ? (
            <div className="modal-stake-button-cancel-off reg-subtitle-2">
              Check <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-cancel reg-subtitle-2 "
              onClick={() => checkStatusThisHumach(humachId)}
            >
              Check
            </div>
          )
        ) : (
          <div className="modal-stake-button-cancel-off reg-subtitle-2">
            Check
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export const CheckStatusMachinie = () => {
  const [machId, setMachId] = useState<number | undefined>(undefined);
  const [image, setImageToken] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState("");
  const [isChecking, setIsChecking] = useState(false);

  const checkStatusMach = async (params: number) => {
    setIsChecking(true);
    setImageToken(undefined);
    setStatus("Loading");
    try {
      const res = await smart_contact_machinieUpgrade.methods
        .isStaking(params)
        .call();

      // const getImage = await api.get("/api/machinie/" + machId);
      setImageToken(machinie_image_url[Number(machId)]);
      if (res) {
        setStatus("Staking");
      } else {
        setStatus("Chilling");
      }
      setIsChecking(false);
    } catch (error: any) {
      setStatus("Token not exist");
      setIsChecking(false);
    }
  };

  return (
    <div className="check-status-container-container">
      <div className="reg-subtitle-2 p-14 text-color-gray-100 text-center">
        Machinie #{machId}
      </div>
      <div className="image-container-check-status">
        {image !== undefined ? (
          <img src={image} className="image-check-status"></img>
        ) : (
          <div className="image-check-status"></div>
        )}
      </div>
      <div className="reg-subtitle-2 p-14 text-color-secondary text-center">
        {status}
      </div>
      <div className="p-14">
        <input
          value={machId}
          onChange={(e: any) => setMachId(Number(e.target.value))}
          placeholder="Input Token ID"
          className="input-check-status text-color-gray-100 reg-subtitle-2"
        ></input>
      </div>
      <div className="m-auto text-center p-14">
        {typeof machId === "number" ? (
          isChecking ? (
            <div className="modal-stake-button-cancel-off reg-subtitle-2">
              Check
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-cancel reg-subtitle-2"
              onClick={() => checkStatusMach(machId)}
            >
              Check
            </div>
          )
        ) : (
          <div className="modal-stake-button-cancel-off reg-subtitle-2">
            Check
          </div>
        )}{" "}
      </div>
    </div>
  );
};
