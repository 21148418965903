export const guestWalletList = {
  floppy: 0,
  ticket: {
    merchTicket: 0,
  },
  rps: {
    stone: 0,
    paper: 0,
    scissors: 0,
  },
  discord: {
    discordLVL: 0,
  },
};

export const guestMachinieList = [];

export const guestHumachList = [];

export const guestUser = {
  id: "",
  createdDate: "2021-10-24T23:35:34.788Z",
  updateDate: "2021-11-01T09:14:23.127Z",
  walletAddress: "",
  discordId: "Guest",
};
