import { HumachSlide } from "../homepage/slides/HumachSlide";
import { NETWORKS } from "../../value";
import { PublicMintPanel } from "./MintHumachPanel";

export const LearnHumachFirstSection = ({
  walletList,
  isMainnet,
}: LEARN_HUMACH_FIRST_SECTION) => {
  const logo_machinie_l: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-humach-l.png`;

  return (
    <section className="section">
      <div className="container">
        <div className="learn-display">
          <div
            className={
              isMainnet === NETWORKS.ETH_MAINNET
                ? "right-humach-learn-container pt-63"
                : "right-humach-learn-container"
            }
          >
            <div>
              <img src={logo_machinie_l} alt="machinie_logo_l"></img>
            </div>
            <div className="text-machinie text-gold pt-4">
              Humach ( hu • mash)
            </div>

            <div className="text-color-primary-200 bold-headline-6 pt-3">
              The initial 3,500 space beings who understand the mystic truth of
              the universe and in the search for a powerful source of energy,
              Machinies.
            </div>
          </div>

          <div className="m-auto-mobile">
            <PublicMintPanel walletList={walletList} isMainnet={isMainnet} />
          </div>
        </div>
      </div>{" "}
      <div className="pt-4">
        <HumachSlide position={true} />
        <HumachSlide position={false} />
      </div>
    </section>
  );
};

type LEARN_HUMACH_FIRST_SECTION = {
  walletList: any;
  isMainnet: string;
};

export const HumachHistory = () => {
  const humach_main: string = `${process.env.PUBLIC_URL}/assets/humach/humach_main.png`;
  ///Users/hathumkittitus/machiniverse-project/public/assets/humach/humach_main.png
  return (
    <section className="history-section-container section bg-1">
      <div className="text-center container bg-glass-history text-machinie">
        <div>
          <img src={humach_main} alt="machinie_no_bg"></img>
        </div>
        <div className="text-subtitle-2 text-color-secondary mt-24">
          What are Humach?
        </div>
        <div className="text-color-primary-200 bold-headline-6 mt-14">
          Mysterious space beings, searching the galaxy for their source of
          power.
        </div>
        <div className="reg-body-2 text-color-gray-100 mt-14">
          Along the way, they train their minds and bodies to level up, battle
          each other for supremacy, and call upon other Humach for support.
          Humachs posses the ancient knowledge required to activate Genesis
          Machinie and generate{" "}
          <span className="text-color-primary-400">$floppy</span>. They can use{" "}
          <span className="text-color-primary-400">$floppy</span> to discover
          the truth about their mysterious origins, as well as to call in
          additional Humach (breed). Call transmission cost is lower the more
          Humach level up. Your Humach’s level will also play an important role
          in the next phase of the project. Humach alone can generate $current
          daily.
        </div>
      </div>
    </section>
  );
};
