import { useEffect, useState } from "react";
import { NoMachinie } from "../MyMachinie";
import web3 from "../../../web3";
import { SMART_CONTRACT_ADDRESS } from "../../../value";
import { api } from "../../../axiosURL";
import humach from "../../../humach";
import machinieToMigrate from "../../../machinieToMigrate";
import { MachinieNeedMigrateEach } from "./MyMachinieEachMigrateEach";

type MY_MACHINIE_NOT_MIGRATE_PROPS_TYPES = {
  machinieNotMigrate: any[];
  setMachinieList: any;
  setWalletList: any;
  setHumachList: any;
};

export const MachinieNotMigratePanel = ({
  machinieNotMigrate,
  setMachinieList,
  setWalletList,
  setHumachList,
}: MY_MACHINIE_NOT_MIGRATE_PROPS_TYPES) => {
  const [selectForMigrate, setSelectForMigrate] = useState([]);
  const [isApprovedForAllBool, setIsApprovedForAllBoll] = useState(false);

  useEffect(() => {
    isApprovedForAll();
  }, []);

  const isApprovedForAll = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const res = await machinieToMigrate.methods
        .isApprovedForAll(
          accounts[0],
          SMART_CONTRACT_ADDRESS.ETH_MAINNET.HUMACH_MAINNET
        )
        .call();

      setIsApprovedForAllBoll(res);
      //console.log(res);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  return (
    <div>
      {machinieNotMigrate.length > 0 ? (
        <div>
          <MachinieEachTopSelectAllMigrate
            selectForMigrate={selectForMigrate}
            machinieNotMigrate={machinieNotMigrate}
            setSelectForMigrate={setSelectForMigrate}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            isApprovedForAllBool={isApprovedForAllBool}
            setIsApprovedForAllBoll={setIsApprovedForAllBoll}
          />
          <div className="machinie-each-panel">
            {machinieNotMigrate.map((x) => (
              <MachinieNeedMigrateEach
                machinieId={x.machinieNumber.toString()}
                machinieListEach={x}
                setSelectForMigrate={setSelectForMigrate}
                selectForMigrate={selectForMigrate}
                isApprovedForAllBool={isApprovedForAllBool}
                setMachinieList={setMachinieList}
                setWalletList={setWalletList}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoMachinie
          topic="No machinie to migrate here"
          text="Your Machinie to migrate will show up here."
          path="https://opensea.io/collection/machinietomigrate"
          isStaking={false}
        />
      )}
    </div>
  );
};

type MACHINIE_EACH_TOP_SELECT_ALL_MIGRATE_PROPS_TYPES = {
  selectForMigrate: string[];
  setSelectForMigrate: any;
  machinieNotMigrate: any[];
  setMachinieList: any;
  setHumachList: any;
  isApprovedForAllBool: boolean;
  setIsApprovedForAllBoll: any;
};

const MachinieEachTopSelectAllMigrate = ({
  selectForMigrate,
  setSelectForMigrate,
  machinieNotMigrate,
  setMachinieList,
  setHumachList,
  isApprovedForAllBool,
  setIsApprovedForAllBoll,
}: MACHINIE_EACH_TOP_SELECT_ALL_MIGRATE_PROPS_TYPES) => {
  const selectAll = () => {
    const selectForMigrateAll = machinieNotMigrate.map((x) =>
      x.machinieNumber.toString()
    );
    setSelectForMigrate(selectForMigrateAll);
    //console.log("selectForUnstake", selectForMigrateAll);
  };

  const deselectAll = () => {
    const emtryArray: [] = [];
    setSelectForMigrate(emtryArray);
    //console.log("deselectForUnstake", emtryArray);
  };

  const setApprovalForAll = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      await machinieToMigrate.methods
        .setApprovalForAll(
          SMART_CONTRACT_ADDRESS.ETH_MAINNET.HUMACH_MAINNET,
          true
        )
        .send({
          from: accounts[0],
        });
      await isApprovedForAll();
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const isApprovedForAll = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const res = await machinieToMigrate.methods
        .isApprovedForAll(
          accounts[0],
          SMART_CONTRACT_ADDRESS.ETH_MAINNET.HUMACH_MAINNET
        )
        .call();

      setIsApprovedForAllBoll(res);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const machiniesUpgradeSelect = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      await humach.methods.machiniesUpgrade(selectForMigrate).send({
        from: accounts[0],
      });

      const thisHumachList = await getHumachList();
      const thisMachinieList = await getMachinieList();
      setMachinieList(thisMachinieList.data);
      setHumachList(thisHumachList.data);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return (
    <div>
      {isApprovedForAllBool ? (
        <div className="machinie-chilling-top">
          <div
            className="select-all-button bold-button text-color-gray-100 cursor-pointer"
            onClick={() => selectAll()}
          >
            SELECT ALL
          </div>
          <div
            className="select-all-button bold-button text-color-gray-100 cursor-pointer"
            onClick={() => deselectAll()}
          >
            DESELECT ALL
          </div>
          {selectForMigrate.length > 0 ? (
            <div
              className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer"
              onClick={() => machiniesUpgradeSelect()}
            >
              MIGRATE SELECTED
            </div>
          ) : (
            <div className="stake-select-all-button bold-button text-color-gray-100 cursor-pointer">
              MIGRATE SELECTED
            </div>
          )}
        </div>
      ) : (
        <div className="machinie-chilling-top-not-approve">
          <div className="text-color-secondary-200 bold-button text-center mb-12">
            In order to allow the contract to migrate Machinies and claim your
            Humachs, you will need to grant approval for all Machiniverse
            assets.
          </div>
          <div
            className="button-click-approve-migrate bold-button"
            onClick={() => {
              setApprovalForAll();
            }}
          >
            APPROVE
          </div>
        </div>
      )}
    </div>
  );
};
