import { useCallback, useEffect, useState } from "react";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

type SUB_NAV = {
  show: boolean;
};

export const SubNav = ({ show }: SUB_NAV) => {
  return (
    <nav className={"subnav-container"}>
      <div className="subnav-text-container">
        <a className="text-sub-nav cursor-pointer" onClick={scroll.scrollToTop}>
          Overview
        </a>
        <Link
          className="text-sub-nav cursor-pointer"
          to="roadmap-section"
          offset={-100}
        >
          Roadmap
        </Link>
        <Link
          className="text-sub-nav cursor-pointer"
          to="team-section"
          offset={-100}
        >
          Teams
        </Link>
        <Link
          className="text-sub-nav cursor-pointer"
          to="faq-section"
          offset={-100}
        >
          FAQs
        </Link>
      </div>
    </nav>
  );
};
