import { ConnectWallet } from "../wallet_connect/ConnectWallet";
import { useRef, useEffect, useState } from "react";
import { ProfilePanel } from "../wallet_connect/ProfilePanel";
import {
  IconDiscord,
  IconInstagram,
  IconOpensea,
  IconMedium,
  IconTwitter,
} from "../component/IconSD";
import { Link } from "react-router-dom";
import { MACHINIE_LINK } from "../../value";

type NAVRIGT_PROPS_TYPES = {
  walletList: any;
  setWalletList: any;
  floppy: string;
  setFloppy: any;
  walletAddress: string;
  setWalletAddress: any;
  memberInfo: string[];
  setMemberInfo: any;
  setMachinieList: any;
  setHumachList: any;
  isMainnet: string;
  setIsMainnet: any;
  toggleProfile: boolean;
  setToggleProfile: any;
  toggleSideNav: boolean;
  setToggleSideNav: any;
};

export const NavRight = ({
  walletList,
  setWalletList,
  floppy,
  setFloppy,
  walletAddress,
  setWalletAddress,
  memberInfo,
  setMemberInfo,
  setMachinieList,
  setHumachList,
  isMainnet,
  setIsMainnet,
  toggleProfile,
  setToggleProfile,
}: NAVRIGT_PROPS_TYPES) => {
  // const [toggleProfile, setToggleProfile] = useState<boolean>(false);

  return (
    <div className="nav-right-container">
      <div className="nav-contact-icon-container">
        <a
          className="icon-nav-right a-icon-nav"
          href={MACHINIE_LINK.DISCORD}
          target="_blank"
          rel="noreferrer"
        >
          <IconDiscord />
        </a>
        <a
          className="icon-nav-right a-icon-nav"
          href={MACHINIE_LINK.TWITTER}
          target="_blank"
          rel="noreferrer"
        >
          <IconTwitter />
        </a>
        <a
          className="icon-nav-right a-icon-nav"
          href={MACHINIE_LINK.MEDIUM}
          target="_blank"
          rel="noreferrer"
        >
          <IconMedium />
        </a>
        <a
          className="icon-nav-right a-icon-nav"
          href={MACHINIE_LINK.INSTAGRAM}
          target="_blank"
          rel="noreferrer"
        >
          <IconInstagram />
        </a>
        <Link className="icon-nav-right a-icon-nav" to="/docs/secondary-market">
          <IconOpensea />
        </Link>
      </div>
      <div className="wallet-connect-container-outside">
        <ConnectWallet
          setToggleProfile={setToggleProfile}
          toggleProfile={toggleProfile}
          setWalletList={setWalletList}
          walletList={walletList}
          setFloppy={setFloppy}
          floppyBalance={floppy}
          setWalletAddress={setWalletAddress}
          setMemberInfo={setMemberInfo}
          setHumachList={setHumachList}
          setMachinieList={setMachinieList}
          isMainnet={isMainnet}
          setIsMainnet={setIsMainnet}
          walletAddress={walletAddress}
          memberInfo={memberInfo}
        />
      </div>

      {/* {toggleProfile ? (
        <ProfilePanel
          setToggleProfile={setToggleProfile}
          walletList={walletList}
          floppy={floppy}
          walletAddress={walletAddress}
          memberInfo={memberInfo}
          isMainnet={isMainnet}
          setMemberInfo={setMemberInfo}
          setWalletList={setWalletList}
        />
      ) : (
        ""
      )} */}
    </div>
  );
};
