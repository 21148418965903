import { PublicMintPanel } from "./MintHumachPanel";

type WANT_SOME_HUMACH_PROPS_TYPE = {
  walletList: any;
  isMainnet: string;
};

export const WantSomeHumachSection = ({
  walletList,
  isMainnet,
}: WANT_SOME_HUMACH_PROPS_TYPE) => {
  const logo_opensea_white: string = `${process.env.PUBLIC_URL}/assets/logo/logo_opensea_white.png`;

  return (
    <section className="section">
      <div className="container">
        <div className="want-some-container-border bg-1">
          <div className="text-machinie want-some-container">
            <div className="m-auto-tb">
              <div className="font-bold-xl text-main">Mint Humach</div>
              <div className="reg-subtitle-2 text-color-gray-100 pt-2">
                Humach's primary sale is live! Get your Humach now.
              </div>
            </div>

            <div className="m-auto-mobile">
              <PublicMintPanel walletList={walletList} isMainnet={isMainnet} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
