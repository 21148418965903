export const HistorySection = () => {
  const machinie_no_bg: string = `${process.env.PUBLIC_URL}/assets/machinie/machinie-no-bg.png`;
  // /Users/hathumkittitus/machiniverse-project/public/assets/machinie/machinie-no-bg.png
  return (
    <div className="history-section-container section bg-1">
      <div className="text-center container bg-glass-history text-machinie">
        <div>
          <img src={machinie_no_bg} alt="machinie_no_bg"></img>
        </div>
        <div className="text-color-secondary reg-subtitle-2 mt-24">
          A Brief History of Machiniverse
        </div>
        <div className="text-color-primary-200 bold-headline-6 mt-14">
          In the modern galaxy, space beings are on their way pursuing what they
          aim for.
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-14">
          There are combats, battles, businesses and races happening everywhere
          across the galaxy. Make a stop and you get passed by others. In order
          to stand out from the pack, a powerful source of energy that can keep
          you running forever in space is needed. A group of space travelers who
          know the truth behind the myths and are in search for their source of
          power are called <span className="text-color-secondary">Humach</span>.
          What they seek is called{" "}
          <span className="text-color-primary-200">Machinie</span>.
        </div>
      </div>
    </div>
  );
};
