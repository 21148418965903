import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  API_REDEEM,
  API_REDEEM_MERCH,
  API_REDEEM_OUTDATE,
  API_REDEEM_UP_COMING,
} from "../../api/api_redeem";
import { getWalletList, redeemDiscordLVL } from "../../apiCallReturn";
import { SELECT_PAGE } from "../../value";
import { LoadingDots } from "../component/LoadingDots";
import { CountDownPanel } from "../learn_game/PlayLotto";
import {
  ModalEditDiscordId,
  ModalSuccessRedeem,
} from "../wallet_connect/RedeemModal";

type REDEEM_PAGE = {
  select: string;
  walletList: any;
  setWalletList: any;
  memberInfo: any;
  setMemberInfo: any;
};

export const RedeemPage = ({
  select,
  walletList,
  setWalletList,
  setMemberInfo,
  memberInfo,
}: REDEEM_PAGE) => {
  return (
    <div className="width-100">
      <RedeemTop
        select={select}
        walletList={walletList}
        memberInfo={memberInfo}
        setMemberInfo={setMemberInfo}
      />

      {select === SELECT_PAGE.REDEEM.DISCORD ? (
        <RedeemDiscord
          memberInfo={memberInfo}
          setMemberInfo={setMemberInfo}
          setWalletList={setWalletList}
          walletList={walletList}
        />
      ) : (
        <RedeemTicketContainer
          walletList={walletList}
          setWalletList={setWalletList}
        />
      )}
    </div>
  );
};

type REDEEM_TOP = {
  select: string;
  walletList: any;
  memberInfo: any;
  setMemberInfo: any;
};

export const RedeemTop = ({
  select,
  walletList,
  setMemberInfo,
  memberInfo,
}: REDEEM_TOP) => {
  return (
    <div>
      <div className="bold-headline-4 text-color-primary-200">Redeem Deck</div>

      <div className="my-machinie-select-container mt-24">
        <SelectRedeemEach
          select={select}
          amount={
            walletList.ticket !== undefined
              ? walletList.ticket.merchTicket
              : "0"
          }
          check={SELECT_PAGE.REDEEM.TICKET}
          buttonName="TICKET"
          path="/redeem/ticket"
        />

        <SelectRedeemEach
          select={select}
          amount={
            walletList.discord !== undefined
              ? walletList.discord.discordLVL
              : "0"
          }
          check={SELECT_PAGE.REDEEM.DISCORD}
          buttonName="DISCORD EXP"
          path="/redeem/discord-exp"
        />
      </div>
    </div>
  );
};

type SELECT_REDEEM_EACH = {
  select: string;
  amount: any;
  buttonName: string;
  check: string;
  path: string;
};

export const SelectRedeemEach = ({
  select,
  buttonName,
  amount,
  check,
  path,
}: SELECT_REDEEM_EACH) => {
  return (
    <Link to={path}>
      <div
        className={
          select === check ? "my-nft-selected-button" : "my-nft-select-button"
        }
      >
        <div className="text-color-tertiary bold-button m-auto-tb">
          {buttonName}
        </div>
        <div className="text-color-primary-1100 bold-button text-unit">
          {amount}
        </div>
      </div>
    </Link>
  );
};

type REDEEM_TICKET_CONTAINER = {
  walletList: any;
  setWalletList: any;
};

export const RedeemTicketContainer = ({
  walletList,
  setWalletList,
}: REDEEM_TICKET_CONTAINER) => {
  var openRedeemMerch: any = new Date(1659906000 * 1000);
  // var openRedeemMerch: any = new Date(1659894960 * 1000);
  //1657227600

  const [isOpenRedeemMerch, setIsOpenRedeemMerch] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      var now = new Date().getTime();

      var distanceRN = openRedeemMerch - now;

      var daysNR = Math.floor(distanceRN / (1000 * 60 * 60 * 24));
      var hoursNR = Math.floor(
        (distanceRN % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutesNR = Math.floor((distanceRN % (1000 * 60 * 60)) / (1000 * 60));
      var secondsNR = Math.floor((distanceRN % (1000 * 60)) / 1000);

      if (daysNR <= 0 && hoursNR <= 0 && minutesNR <= 0 && secondsNR <= 0) {
        setIsOpenRedeemMerch(true);
      } else {
        setIsOpenRedeemMerch(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="redeem-ticket-container">
      {!isOpenRedeemMerch ? (
        <div>
          <div className="text-color-primary-200 reg-caption pb-2">
            Redeem Upcoming
          </div>
          <div className="redeem-ticket-live-container">
            {API_REDEEM_UP_COMING.length > 0
              ? API_REDEEM_UP_COMING.map((x) => (
                  <RedeemEachTicketUpcoming
                    x={x}
                    walletList={walletList}
                    setWalletList={setWalletList}
                  />
                ))
              : ""}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* {isOpenRedeemMerch ? (
        <div>
          <div className="text-color-secondary reg-caption pb-2">
            Redeem Live
          </div>
          <div className="redeem-ticket-live-container">
            {API_REDEEM_MERCH.length > 0
              ? API_REDEEM_MERCH.map((x) => (
                  <RedeemEachMerch
                    x={x}
                    walletList={walletList}
                    setWalletList={setWalletList}
                  />
                ))
              : ""}
          </div>
        </div>
      ) : (
        ""
      )} */}

      <div>
        <div className="text-color-line-tint-04 reg-caption pb-2">
          Redeem Ended
        </div>
        <div className="redeem-ticket-live-container">
          {API_REDEEM_OUTDATE.length > 0
            ? API_REDEEM_OUTDATE.map((x) => (
                <RedeemEachTicketOutDate
                  x={x}
                  walletList={walletList}
                  setWalletList={setWalletList}
                />
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

type REDEEM_EACH = {
  x: any;
  walletList: any;
  setWalletList: any;
};

export const RedeemEachMerch = ({
  x,
  walletList,
  setWalletList,
}: REDEEM_EACH) => {
  return (
    <div className="redeem-ticket-each-container">
      <div className="redeem-ticket-each-display">
        <div className="image-container-redeem-ticket-container">
          <img
            src={x.image}
            alt={x.name}
            className="image-container-redeem-ticket"
          ></img>
        </div>
        <div className="name-des-redeem-ticket-container">
          <div className="text-color-gray-100 bold-headline-6">{x.name}</div>
          <div className="text-color-gray-100 reg-subtitle-2">
            {x.description}
          </div>
        </div>
        <div className=" display-desktop">
          <div className="redeem-detail-ticket-container">
            <div className="d-flex justify-content-between">
              <div>
                {/* <div className="text-color-line-tint-04 reg-caption">
                  Close in
                </div> */}
                {/* <div className="text-color-line-tint-04 reg-caption">
                  <CountDownPanel timestamp={x.closeIn} />
                </div> */}
                <div className="text-color-line-tint-04 reg-caption">
                  Limit per user : {x.maxPerUser}
                </div>
              </div>
              <div className="bold-headline-6 text-color-gray-100">
                {x.type}
              </div>
            </div>

            <Link
              to="/redeem/merch"
              target={"_blank"}
              className="modal-stake-button-cancel bold-button"
            >
              REDEEM{" "}
              <span className="need-ticket-for-redeem reg-overline">
                {x.need}
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="display-mobile">
        <div className="redeem-detail-ticket-container-mobile">
          <div className="d-flex justify-content-between">
            <div>
              <div className="text-color-line-tint-04 reg-caption"></div>
              <div className="text-color-line-tint-04 reg-caption">
                Limit per user : {x.maxPerUser}
              </div>
            </div>
            <div className="bold-headline-6 text-color-gray-100">{x.type}</div>
          </div>

          <Link
            to="/redeem/merch"
            target={"_blank"}
            className="modal-stake-button-cancel bold-button"
          >
            REDEEM{" "}
            <span className="need-ticket-for-redeem reg-overline">
              {x.need}
            </span>
          </Link>
          {/* {walletList.ticket !== undefined ? (
            walletList.ticket.merchTicket >= x.need ? (
              <div className="modal-stake-button-cancel bold-button">
                REDEEM{" "}
                <span className="need-ticket-for-redeem reg-overline">
                  {x.need}
                </span>
              </div>
            ) : (
              <div className="modal-stake-button-cancel-off bold-button">
                REDEEM{" "}
                <span className="need-ticket-for-redeem reg-overline">
                  {x.need}
                </span>
              </div>
            )
          ) : (
            <div className="modal-stake-button-cancel-off bold-button">
              REDEEM{" "}
              <span className="need-ticket-for-redeem reg-overline">
                {x.need}
              </span>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export const RedeemEachTicket = ({
  x,
  walletList,
  setWalletList,
}: REDEEM_EACH) => {
  return (
    <div className="redeem-ticket-each-container">
      <div className="redeem-ticket-each-display">
        <div className="image-container-redeem-ticket-container">
          <img
            src={x.image}
            alt={x.name}
            className="image-container-redeem-ticket"
          ></img>
        </div>
        <div className="name-des-redeem-ticket-container">
          <div className="text-color-gray-100 bold-headline-6">{x.name}</div>
          <div className="text-color-gray-100 reg-subtitle-2">
            {x.description}
          </div>
        </div>
        <div className=" display-desktop">
          <div className="redeem-detail-ticket-container">
            <div className="d-flex justify-content-between">
              <div>
                <div className="text-color-line-tint-04 reg-caption">
                  Close in
                </div>
                <div className="text-color-line-tint-04 reg-caption">
                  <CountDownPanel timestamp={x.closeIn} />
                </div>
                <div className="text-color-line-tint-04 reg-caption">
                  Max entry : {x.max}
                </div>
              </div>
              <div className="bold-headline-6 text-color-gray-100">
                {x.type}
              </div>
            </div>

            {walletList.ticket !== undefined ? (
              walletList.ticket.merchTicket >= x.need ? (
                <div className="modal-stake-button-cancel bold-button">
                  REDEEM{" "}
                  <span className="need-ticket-for-redeem reg-overline">
                    {x.need}
                  </span>
                </div>
              ) : (
                <div className="modal-stake-button-cancel-off bold-button">
                  REDEEM{" "}
                  <span className="need-ticket-for-redeem reg-overline">
                    {x.need}
                  </span>
                </div>
              )
            ) : (
              <div className="modal-stake-button-cancel-off bold-button">
                REDEEM{" "}
                <span className="need-ticket-for-redeem reg-overline">
                  {x.need}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="display-mobile">
        <div className="redeem-detail-ticket-container-mobile">
          <div className="d-flex justify-content-between">
            <div>
              <div className="text-color-line-tint-04 reg-caption"></div>
              <div className="text-color-line-tint-04 reg-caption">
                Max entry : {x.max}
              </div>
            </div>
            <div className="bold-headline-6 text-color-gray-100">{x.type}</div>
          </div>
          {walletList.ticket !== undefined ? (
            walletList.ticket.merchTicket >= x.need ? (
              <div className="modal-stake-button-cancel bold-button">
                REDEEM{" "}
                <span className="need-ticket-for-redeem reg-overline">
                  {x.need}
                </span>
              </div>
            ) : (
              <div className="modal-stake-button-cancel-off bold-button">
                REDEEM{" "}
                <span className="need-ticket-for-redeem reg-overline">
                  {x.need}
                </span>
              </div>
            )
          ) : (
            <div className="modal-stake-button-cancel-off bold-button">
              REDEEM{" "}
              <span className="need-ticket-for-redeem reg-overline">
                {x.need}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const RedeemEachTicketOutDate = ({
  x,
  walletList,
  setWalletList,
}: REDEEM_EACH) => {
  return (
    <div className="redeem-ticket-each-container off">
      <div className="redeem-ticket-each-display">
        <div className="image-container-redeem-ticket-container">
          <img
            src={x.image}
            alt={x.name}
            className="image-container-redeem-ticket"
          ></img>
        </div>
        <div className="name-des-redeem-ticket-container">
          <div className="text-color-gray-100 bold-headline-6">{x.name}</div>
          <div className="text-color-gray-100 reg-subtitle-2">
            {x.description}
          </div>
        </div>
        <div className=" display-desktop">
          <div className="redeem-detail-ticket-container">
            <div className="d-flex justify-content-between">
              <div>
                <div className="text-color-line-tint-04 reg-caption">
                  Max entry : {x.max}
                </div>
              </div>
              <div className="bold-headline-6 text-color-gray-100">
                {x.type}
              </div>
            </div>

            <div className="modal-stake-button-cancel-off bold-button">
              REDEEM{" "}
              <span className="need-ticket-for-redeem reg-overline">
                {x.need}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="display-mobile">
        <div className="redeem-detail-ticket-container-mobile">
          <div className="d-flex justify-content-between">
            <div>
              <div className="text-color-line-tint-04 reg-caption"></div>
              <div className="text-color-line-tint-04 reg-caption">
                Max entry : {x.max}
              </div>
            </div>
            <div className="bold-headline-6 text-color-gray-100">{x.type}</div>
          </div>
          <div className="modal-stake-button-cancel bold-button">
            REDEEM{" "}
            <span className="need-ticket-for-redeem reg-overline">
              {x.need}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RedeemEachTicketUpcoming = ({
  x,
  walletList,
  setWalletList,
}: REDEEM_EACH) => {
  return (
    <div className="redeem-ticket-each-container ">
      <div className="redeem-ticket-each-display">
        <div className="image-container-redeem-ticket-container">
          <img
            src={x.image}
            alt={x.name}
            className="image-container-redeem-ticket"
          ></img>
        </div>
        <div className="name-des-redeem-ticket-container">
          <div className="text-color-gray-100 bold-headline-6">{x.name}</div>
          <div className="text-color-gray-100 reg-subtitle-2">
            {x.description}
          </div>
        </div>
        <div className=" display-desktop">
          <div className="redeem-detail-ticket-container">
            <div className="d-flex justify-content-between">
              <div>
                <div className="text-color-line-tint-04 reg-caption">
                  Max entry : {x.max}
                </div>
              </div>
              <div className="bold-headline-6 text-color-gray-100">
                {x.type}
              </div>
            </div>

            <div className="modal-stake-button-cancel-off bold-button">
              <CountDownPanel timestamp={x.openIn} />
            </div>
          </div>
        </div>
      </div>

      <div className="display-mobile">
        <div className="redeem-detail-ticket-container-mobile">
          <div className="d-flex justify-content-between">
            <div>
              <div className="text-color-line-tint-04 reg-caption"></div>
              <div className="text-color-line-tint-04 reg-caption">
                Max entry : {x.max}
              </div>
            </div>
            <div className="bold-headline-6 text-color-gray-100">{x.type}</div>
          </div>
          <div className="modal-stake-button-cancel bold-button">
            <CountDownPanel timestamp={x.openIn} />
          </div>
        </div>
      </div>
    </div>
  );
};

type REDEEM_DISCORD_TYPE = {
  memberInfo: any;
  setWalletList: any;
  setMemberInfo: any;
  walletList: any;
};

export const RedeemDiscord = ({
  memberInfo,
  setMemberInfo,
  setWalletList,
  walletList,
}: REDEEM_DISCORD_TYPE) => {
  const [open, setIsOpen] = useState(false);
  const [open3, setIsOpen3] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [isRedeemSuccess, setIsRedeemSuccess] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const errMessagePop = (errText: string) => {
    setErrMessage(errText);
    var delay = 5000;
    setTimeout(() => {
      setErrMessage("");
    }, delay);
  };

  const redeemDiscord = async () => {
    setIsRedeeming(true);
    try {
      await redeemDiscordLVL(memberInfo.discordId);
      const thisWalletList = await getWalletList();
      setWalletList(thisWalletList.data);
      setIsRedeemSuccess(true);
      setIsOpen3(true);
    } catch (err: any) {
      setIsRedeeming(false);
    }
  };

  return (
    <div className="redeem-discord-page-container">
      <div className="text-color-gray-100 bold-headline-5">Your Discord ID</div>
      <div
        className="mt-24 edit-display-name reg-subtitle-2 text-color-gray-100"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {memberInfo.discordId === undefined
          ? "Please insert your Discord Id"
          : memberInfo.discordId}
      </div>

      <div className="mt-24">
        {memberInfo.discordId !== undefined ? (
          !isRedeeming ? (
            walletList.discord.discordLVL > 0 ? (
              <div
                className="button-machinie bold-button"
                onClick={() => redeemDiscord()}
              >
                REDEEM
              </div>
            ) : (
              <div
                className="button-machinie-off bold-button"
                onClick={() =>
                  errMessagePop(
                    "You have no discord level left let's spin more on Machipon!"
                  )
                }
              >
                REDEEM
              </div>
            )
          ) : !isRedeemSuccess ? (
            <div className="button-machinie-off bold-button">
              REDEEMING
              <LoadingDots />
            </div>
          ) : (
            <div className="button-machinie-off cursor-pointer bold-button">
              SUCCESS!
            </div>
          )
        ) : (
          <div
            className="button-machinie-off bold-button"
            onClick={() =>
              errMessagePop("Please set your Discord ID before redeem")
            }
          >
            REDEEM
          </div>
        )}
      </div>

      <ModalEditDiscordId
        memberInfo={memberInfo}
        setMemberInfo={setMemberInfo}
        open={open}
        setIsOpen={setIsOpen}
      />

      <ModalSuccessRedeem
        open={open3}
        setIsOpen={setIsOpen3}
        memberInfo={memberInfo}
        setIsRedeeming={setIsRedeeming}
        setIsRedeemSuccess={setIsRedeemSuccess}
      />
    </div>
  );
};
