import { BeforeFooterSection } from "../homepage/beforefoot/BeforeFooterSection";
import { FooterSection } from "../homepage/footer/FooterSection";
import { Nav } from "../navbar/Nav";
import { FaqLearnMachinieSection } from "./FaqLearnMachinieSection";
import {
  LearnMachinieFirstSection,
  MachinieHistory,
} from "./LearnMachinieFirstSection";
import { MachinieGenerateCurrent } from "./MachinieGenerateCurrent";
import { MachinieGenerateFloppy } from "./MachinieGenerateFloppy";
import { WantSomeMachinieSection } from "./WantSomeMachinieSection";
import { useEffect } from "react";
import { SELECTING } from "../../value";

type LEARN_MACHINIE_PAGE = {
  setSelecting: any;
};

export const LearnMachiniePage = ({ setSelecting }: LEARN_MACHINIE_PAGE) => {
  useEffect(() => {
    setSelecting(SELECTING.MACHINIE_PAGE);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LearnMachinieFirstSection />
      <MachinieHistory />
      <MachinieGenerateFloppy />
      <MachinieGenerateCurrent />
      <WantSomeMachinieSection />
      <FaqLearnMachinieSection />
      <BeforeFooterSection />
    </div>
  );
};
