import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MyMachinie } from "./MyMachinie";
import { MyHumach } from "./MyHumach";
import humach from "../../humach";
import machinieUpgrade from "../../machinieUpgrade";
import web3 from "../../web3";
import { SELECT_PAGE, STATUS } from "../../value";
import { RedeemPage } from "../redeem_page/RedeemPage";

export const MyNFTPage = ({
  isMachinie,
  selectedNftPage,
  machinieList,
  humachList,
  setMachinieList,
  setHumachList,
  setWalletList,
  walletList,
  floppy,
  setFloppy,
  setMemberInfo,
  memberInfo,
}: MY_NFT_PAGE_PROPS_TYPES) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-1">
      <MyMachinieSection
        selectedMachinie={isMachinie}
        selectedNftPage={selectedNftPage}
        machinieList={machinieList}
        humachList={humachList}
        setMachinieList={setMachinieList}
        setHumachList={setHumachList}
        setWalletList={setWalletList}
        walletList={walletList}
        floppy={floppy}
        setFloppy={setFloppy}
        memberInfo={memberInfo}
        setMemberInfo={setMemberInfo}
      />
    </div>
  );
};

type MY_NFT_PAGE_PROPS_TYPES = {
  isMachinie: number;
  selectedNftPage: string;
  machinieList: any;
  humachList: any;
  setMachinieList: any;
  setHumachList: any;
  setWalletList: any;
  walletList: any;
  floppy: any;
  setFloppy: any;
  memberInfo?: any;
  setMemberInfo?: any;
};

type MY_NFT_SECTION_PROPS_TYPES = {
  selectedMachinie: number;
  selectedNftPage: string;
  machinieList: any;
  humachList: any;
  setMachinieList: any;
  setHumachList: any;
  setWalletList: any;
  walletList: any;
  floppy: any;
  setFloppy: any;
  memberInfo: any;
  setMemberInfo: any;
};

export const MyMachinieSection = ({
  selectedMachinie,
  selectedNftPage,
  machinieList,
  humachList,
  setMachinieList,
  setHumachList,
  setWalletList,
  walletList,
  floppy,
  setFloppy,
  setMemberInfo,
  memberInfo,
}: MY_NFT_SECTION_PROPS_TYPES) => {
  const [humachChilling, setHumachChilling] = useState<any>([]);
  const [humachStaking, setHumachStaking] = useState<any>([]);
  const [humachTraining, setHumachTraining] = useState<any>([]);

  const [machinieChilling, setMachinieChilling] = useState<any>([]);
  const [machinieStaking, setMachinieStaking] = useState<any>([]);

  const [machinieNotMigrate, setMachinieNotMigrate] = useState<any>([]);

  const [isToggleMoreSelects, setIsToggleMoreSelect] = useState(false);

  useEffect(() => {
    const sortHumach = async (humachList: any[]) => {
      const thisStaking: any[] = [];
      const thisChilling: any[] = [];
      const thisTraining: any[] = [];

      for (let i = 0; i < humachList.length; i++) {
        const checkIsStaking = await isStaking(
          humachList[i]["humachNumber"].toString()
        );
        if (!checkIsStaking) {
          thisChilling.push(humachList[i]);
        } else {
          const checkTimeStake = await getStakeTime(
            humachList[i]["humachNumber"].toString()
          );
          if (checkTimeStake > 0) {
            thisTraining.push(humachList[i]);
          } else {
            thisStaking.push(humachList[i]);
          }
        }
      }

      setHumachChilling(thisChilling);
      setHumachStaking(thisStaking);
      setHumachTraining(thisTraining);
    };
    sortHumach(humachList);
  }, [humachList]);

  useEffect(() => {
    const sortMachinie = async (machinieList: any) => {
      const thisNotMigrate: any[] = [];
      const thisStaking: any[] = [];
      const thisChilling: any[] = [];

      for (let i = 0; i < machinieList.length; i++) {
        if (machinieList[i].isMigrate) {
          const checkIsStaking = await isStaking(
            machinieList[i]["machinieNumber"].toString()
          );
          if (checkIsStaking) {
            thisStaking.push(machinieList[i]);
          } else {
            thisChilling.push(machinieList[i]);
          }
        } else {
          thisNotMigrate.push(machinieList[i]);
        }
      }

      setMachinieChilling(thisChilling);
      setMachinieStaking(thisStaking);
      setMachinieNotMigrate(thisNotMigrate);
    };

    const isStaking = async (tokenId: string) => {
      const res = await machinieUpgrade.methods.isStaking(tokenId).call();
      return res;
    };
    sortMachinie(machinieList);
  }, [machinieList]);

  const isStaking = async (tokenId: string) => {
    const res = await humach.methods.isStaking(tokenId).call();
    return res;
  };

  const getStakeTime = async (tokenId: string) => {
    const stakeTime = await humach.methods.getStakeTime(tokenId).call();
    return stakeTime;
  };

  const renderNftPage = (selectedNftPage: string) => {
    switch (selectedNftPage) {
      case SELECT_PAGE.MY_NFT.MACHINIE.CHILLING:
        return (
          <MyMachinie
            select={STATUS.MACHINIE.CHILLING}
            machinieList={machinieList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            walletList={walletList}
            humachList={humachList}
            humachChilling={humachChilling}
            setHumachList={setHumachList}
            machinieChilling={machinieChilling}
            machinieNotMigrate={machinieNotMigrate}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case SELECT_PAGE.MY_NFT.MACHINIE.STAKING:
        return (
          <MyMachinie
            select={STATUS.HUMACH.STAKING}
            machinieList={machinieList}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            walletList={walletList}
            machinieChilling={machinieChilling}
            machinieNotMigrate={machinieNotMigrate}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case SELECT_PAGE.MY_NFT.MACHINIE.MIGRATE:
        return (
          <MyMachinie
            select={STATUS.MACHINIE.MIGRATE}
            machinieList={machinieList}
            setMachinieList={setMachinieList}
            setWalletList={setWalletList}
            walletList={walletList}
            machinieChilling={machinieChilling}
            machinieNotMigrate={machinieNotMigrate}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case SELECT_PAGE.MY_NFT.HUMACH.CHILLING:
        return (
          <MyHumach
            select={STATUS.HUMACH.CHILLING}
            humachList={humachList}
            humachStaking={humachStaking}
            humachChilling={humachChilling}
            humachTraining={humachTraining}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            setMachinieList={setMachinieList}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case SELECT_PAGE.MY_NFT.HUMACH.STAKING:
        return (
          <MyHumach
            select={STATUS.HUMACH.STAKING}
            humachList={humachList}
            humachStaking={humachStaking}
            humachChilling={humachChilling}
            humachTraining={humachTraining}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            setMachinieList={setMachinieList}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );
      case SELECT_PAGE.MY_NFT.HUMACH.TRAINING:
        return (
          <MyHumach
            select={STATUS.HUMACH.TRAINING}
            humachList={humachList}
            humachStaking={humachStaking}
            humachChilling={humachChilling}
            humachTraining={humachTraining}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            setMachinieList={setMachinieList}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );

      case SELECT_PAGE.MY_NFT.HUMACH.TRAINING:
        return (
          <MyHumach
            select={STATUS.HUMACH.TRAINING}
            humachList={humachList}
            humachStaking={humachStaking}
            humachChilling={humachChilling}
            humachTraining={humachTraining}
            setHumachList={setHumachList}
            setWalletList={setWalletList}
            setMachinieList={setMachinieList}
            machinieStaking={machinieStaking}
            floppy={floppy}
            setFloppy={setFloppy}
          />
        );

      case SELECT_PAGE.REDEEM.TICKET:
        return (
          <RedeemPage
            select={SELECT_PAGE.REDEEM.TICKET}
            walletList={walletList}
            setWalletList={setWalletList}
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
          />
        );
      case SELECT_PAGE.REDEEM.DISCORD:
        return (
          <RedeemPage
            select={SELECT_PAGE.REDEEM.DISCORD}
            walletList={walletList}
            setWalletList={setWalletList}
            memberInfo={memberInfo}
            setMemberInfo={setMemberInfo}
          />
        );

      default:
        <div>{selectedNftPage}</div>;
    }
  };
  return (
    <section className="section-games">
      <div className="container">
        <div className="display-section-my-nft">
          <SelectMyNFT selectedMachinie={selectedMachinie} />

          {renderNftPage(selectedNftPage)}
        </div>
      </div>
    </section>
  );
};

type SELECT_MY_NFT = {
  selectedMachinie: number;
};

export const SelectMyNFT = ({ selectedMachinie }: SELECT_MY_NFT) => {
  const my_machinie: string = `${process.env.PUBLIC_URL}/assets/machinie/machinie_my_machinie_l.png`;
  const my_humach: string = `${process.env.PUBLIC_URL}/assets/humach/humach_my_humach_l.png`;
  const ticket: string = `${process.env.PUBLIC_URL}/assets/gameAssets/learn_game_ticket.png`;
  ///Users/hathumkittitus/machiniverse-project/public/assets/gameAssets/Ticket.png
  const my_lotto: string =
    "https://lh3.googleusercontent.com/QoO4ThhQw-dWz9bJp-8I5Jnf3gISFvUkc8-k49OvJ0dXFSeH8rl7sGRyiRCNvUr0M34OI8gv3c3MvA0flpSvIko_AzRjLt-TbvzRNqE=w600";

  return (
    <div className="select-my-nft-button-container">
      {/* <Link to="/my-machinie/chilling">
        <div
          className={
            selectedMachinie === 0
              ? "selected-my-nft-container"
              : "not-select-my-nft-container"
          }
        >
          <div>
            <img
              src={my_machinie}
              width="46px"
              alt="machinie"
              className="image-selec-my-nft"
            ></img>
          </div>
          <div className="pl-12 text-color-tertiary bold-subtitle-1 m-auto-tb">
            Machinie
          </div>
        </div>
      </Link>
      <Link to="/my-humach/chilling">
        <div
          className={
            selectedMachinie === 1
              ? "selected-my-nft-container"
              : "not-select-my-nft-container"
          }
        >
          <div>
            <img
              src={my_humach}
              width="46px"
              alt="machinie"
              className="image-selec-my-nft"
            ></img>
          </div>
          <div className="pl-12 text-color-tertiary bold-subtitle-1 m-auto-tb">
            Humach
          </div>
        </div>
      </Link> */}

      {/* <Link to="/redeem/ticket">
        <div
          className={
            selectedMachinie === 2
              ? "selected-my-nft-container"
              : "not-select-my-nft-container"
          }
        >
          <div className="select-redeem d-flex image-selec-my-nft bg-color-gradient-radial">
            <img
              src={ticket}
              width="46px"
              alt="ticket"
              className="m-auto"
            ></img>
          </div>
          <div className="pl-12 text-color-tertiary bold-subtitle-1 m-auto-tb">
            Redeem
          </div>
        </div>
      </Link> */}

      <SelectMyNftEach
        path="/my-machinie/chilling"
        image={my_machinie}
        numberCheck={0}
        selectedMachinie={selectedMachinie}
        text="Machinie"
      />

      <SelectMyNftEach
        path="/my-humach/chilling"
        image={my_humach}
        numberCheck={1}
        selectedMachinie={selectedMachinie}
        text="Humach"
      />

      <SelectMyNftEach
        path="/redeem/ticket"
        image={ticket}
        numberCheck={2}
        selectedMachinie={selectedMachinie}
        text="Redeem"
      />
    </div>
  );
};

type SELECT_MY_NFT_EACH = {
  path: string;
  image: string;
  numberCheck: number;
  selectedMachinie: number;
  text: string;
};

export const SelectMyNftEach = ({
  path,
  image,
  numberCheck,
  selectedMachinie,
  text,
}: SELECT_MY_NFT_EACH) => {
  return (
    <Link to={path}>
      <div
        className={
          selectedMachinie === numberCheck
            ? "selected-my-nft-container"
            : "not-select-my-nft-container"
        }
      >
        <div className="select-redeem d-flex image-selec-my-nft bg-color-gradient-radial">
          <img src={image} width="46px" alt={text} className="m-auto"></img>
        </div>
        <div className="text-my-nft-select pl-12 text-color-tertiary bold-subtitle-1 m-auto-tb">
          {text}
        </div>
      </div>
    </Link>
  );
};
