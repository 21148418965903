import { api } from "./axiosURL";
import { API } from "./valueApi";
import lodash from "lodash";
import { defaultMaxListeners } from "events";

export const getSubmissionCount = async (configId: string) => {
  try {
    const res = await api.get(API.LOTTO_ENTRY.GET_ENTRY, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      params: {
        lottoConfigId: configId,
      },
    });
    const thisRes = res.data.data;

    return thisRes[0]["entry"];
  } catch (err) {
    return 0;
  }
};

export const getSubmissionBoard = async (configId: string) => {
  try {
    const res = await api.get(API.LOTTO_ENTRY.GET_USER_ENTRY, {
      params: {
        lottoConfigId: configId,
      },
    });

    if (res.data.data[0]["userEntryList"].length > 0) {
      const submissionBeforeSort = res.data.data[0]["userEntryList"];
      const newSubmission = lodash.orderBy(
        submissionBeforeSort,
        ["entry"],
        ["desc"]
      );

      return newSubmission;
    } else {
      return [];
    }
  } catch (err: any) {
    return [];
  }
};

export const getLottoAdminBoard = async () => {
  try {
    const res = await api.get(API.LOTTO_ENTRY.GET_PUBLIC_ENTRY);

    return res;
  } catch (err: any) {
    return [];
  }
};

export const getLottoStatus = async () => {
  try {
    const res = await api.get(API.LOTTO_ENTRY.GET_PUBLIC_ENTRY);

    return res;
  } catch (err: any) {
    return [];
  }
};

export const getUser = async () => {
  if (localStorage.getItem("accessToken")) {
    const res = await api.get("/api/user/", {
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return res.data;
  }
};

export const getWalletList = async () => {
  if (localStorage.getItem("accessToken")) {
    const res = await api.get("/api/wallet/list", {
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return res.data;
  }
};

export const checkRandomName = async (forCheck: any) => {
  if (forCheck.displayName === undefined) {
    const getName = await getRandomName();
    await createRandomName(getName);
  }
};

export const getRandomName = async () => {
  try {
    const res = await api.get(API.USER.GENERATE_NAME, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return res.data.data;
  } catch (err) {}
};

export const createRandomName = async (nameToCreate: string) => {
  try {
    const res = await api.put(
      API.USER.EDIT_USER,
      {
        displayName: nameToCreate,
      },
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
  } catch (err) {}
};

export const redeemDiscordLVL = async (discordId: string) => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.post(
        "/api/reward/discord",
        { toUserId: discordId },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
    }
  } catch (err: any) {}
};

//reward

export const getRewardList = async () => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.post(
        API.REWARD.SEARCH,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      console.log("getRewardList", res);
    }
  } catch (err: any) {
    console.log("getRewardList err", err);
  }
};

export const getRewardListAdmin = async () => {
  try {
    if (localStorage.getItem("adminToken")) {
      const res = await api.post(
        API.REWARD.SEARCH,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("adminToken"),
          },
        }
      );
    }
  } catch (err: any) {
    console.log("getRewardList err", err);
  }
};

export const createRedeemConfig = async () => {
  try {
    if (localStorage.getItem("adminToken")) {
      const res = await api.post(
        API.REWARD.CONFIG,
        {
          name: "hoodie_redeem",
          requireList: [["addressId", "discordId"]],
          walletConfigId: "61c00c4752e1603b9cb95b4f",
          cost: 60,
          amount: 120,
          limitPerUser: 3,
          isActive: true,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("adminToken"),
          },
        }
      );
      console.log("createRedeemConfig", res);
    }
  } catch (err: any) {
    console.log("createRedeemConfig err", err);
  }
};

//62c69652ceb08b24c867f2cc hoodie_reward_test_3

//61c00c4752e1603b9cb95b4f ticket type

export const createAddress = async (
  fullName: string,
  phoneNumber: string,
  address: string,
  subAdress: string,
  city: string,
  state: string,
  zip: string,
  country: string
) => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.post(
        API.ADDRESS.CREATE,
        {
          fullName: fullName,
          phoneNumber: phoneNumber,
          address: address,
          subAdress: subAdress,
          city: city,
          state: state,
          zip: zip,
          country: country,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log("createAddress", res);
      console.log("address id", res.data.data.id);
      return { err: false, id: res.data.data.id };
    }
  } catch (err: any) {
    console.log("createAddress err", err);
    return { err: true, errMessage: err.message };
  }
};

export const getAddress = async () => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get(API.ADDRESS.GET_BY_USER, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      console.log("getAddress", res);
      // console.log("getAddress id", res.data.data.id);
      return res.data.data.id;
    }
  } catch (err: any) {
    console.log("getAddress err", err);
  }
};

export const searchAddress = async () => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.post(
        API.ADDRESS.GET,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log("getAddress", res);
      // console.log("getAddress id", res.data.data.id);
      return res.data.data;
    }
  } catch (err: any) {
    console.log("getAddress err", err.message);
  }
};

export const editDiscordId = async (discordId: string) => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.put(
        API.USER.EDIT_USER,
        {
          discordId: discordId,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log("editDiscordId", res);
    }
  } catch (err: any) {
    console.log("editDiscordId err", err);
  }
};

export const redeemRewardCustom = async (
  detail: string,
  addressId: string,
  rewardConfigId: string
) => {
  try {
    if (localStorage.getItem("accessToken")) {
      const res = await api.post(
        API.REWARD.CLAIM,
        {
          details: detail,
          addressId: addressId,
          rewardConfigId: rewardConfigId,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log("redeemRewardCustom", res);
      return { err: false };
    }
  } catch (err: any) {
    console.log("redeemRewardCustom err", err);
    return { err: true, errMessage: err.message };
  }
};

// var delay = 5000; //(5 sec)
// setTimeout(() => {
// your function
// }, delay);

// const object = {
//   id: "62c4d80aceb08b24c867eb21",
//   userId: "6178e12e78cb3f1148685c51",
//   toUserId: "",
//   type: "hoodie_reward_test",
//   addressId: "62c43a265d688726284325b6",
//   address: {
//     id: "62c43a265d688726284325b6",
//     createdDate: "2022-07-05T13:18:30.157Z",
//     updateDate: "2022-07-05T13:18:30.157Z",
//     userId: "6178e12e78cb3f1148685c51",
//     fullName: "Test2 Test2",
//     phoneNumber: "Test2",
//     subAdress: "Test2",
//     address: "Test2",
//     city: "Test2",
//     state: "Test2",
//     zip: "Test2",
//     country: "Test2",
//   },
//   status: 0,
//   user: {
//     id: "6178e12e78cb3f1148685c51",
//     createdDate: "2021-10-27T05:18:38.639Z",
//     updateDate: "2022-05-30T12:03:29.504Z",
//     walletAddress: "0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c",
//     discordId: "Machiniverse",
//     displayName: "Ma",
//   },
//   walletAddress: "0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c",
//   details:
//     'Black The 54" Machinie 0 0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c ',
// };
