import { Modal } from "../modal/Modal";
import { useState, useEffect } from "react";
import { ModalGenerateCurrent } from "../learn_humach/HumachGenerateCurrent";

export const MachinieGenerateCurrent = () => {
  const current_img: string = `${process.env.PUBLIC_URL}/assets/learnAssets/current.png`;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="section bg-1">
      <div className="container">
        <div className="machinie-generate-top-container upside">
          <div className="machinie-generate-top-right">
            <div className="reg-subtitle-2 text-color-secondary">Utility</div>
            <div className="bold-headline-4 text-color-primary-200 mt-14">
              Generate $current
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-14">
              Every Machinie alone can generate $current daily. $current is a
              centralized token that fuels the Machiniverse ecosystem.
            </div>
            <div>
              <div
                className="text-color-primary-200 bold-button mt-24 cursor-pointer hover-text-color-secondary"
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  document.body.style.paddingRight = "15px";
                  setIsOpen(true);
                }}
              >
                SEE WHAT IS THE $CURRENT USAGE{" "}
                <span className="text-color-tertiary">
                  <i className="fa fa-plus"></i>
                </span>{" "}
              </div>

              <ModalGenerateCurrent isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
          <div className="img-floppy-container-container">
            <div className="img-floppy-container">
              <img src={current_img} className="img-floppy" alt="floppy"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
