import { useEffect, useState } from "react";

type ON_MOUSE_ENTER_PROPS_TYPES = {
  isEnter: boolean;
  setIsEnter?: any;
  isClick?: boolean;
  setIsClick?: any;
  text: string;
};

export const MouseEnterPanel = ({
  isEnter,
  setIsEnter,
  isClick,
  setIsClick,
  text,
}: ON_MOUSE_ENTER_PROPS_TYPES) => {
  return (
    <div className={isEnter ? "mouse-enter-panel" : "mouse-enter-panel-hide"}>
      <div className="reg-subtitle-2 text-color-gray-100">{text}</div>
    </div>
  );
};

export const MouseEnterPanelTop = ({
  isEnter,
  text,
}: ON_MOUSE_ENTER_PROPS_TYPES) => {
  return (
    <div
      className={isEnter ? "mouse-enter-panel-top" : "mouse-enter-panel-hide"}
    >
      <div className="reg-subtitle-2 text-color-gray-100 mouse-enter-text">
        {text}
      </div>
      <div className="triangle-container">
        <div className="triangle"></div>
      </div>
    </div>
  );
};
