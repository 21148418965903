import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SELECT_PAGE } from "../../value";
import { IconOpenInNew } from "../component/Icon";
import { CountStakingPanel } from "../count_staking_training/CountStakingPanel";
import { CheckStatusMachinie, CheckStatusHumach } from "./CheckStatus";
import {
  DocsAllowlist,
  DocsCheckStatusMachinie,
  DocsEcosystem,
  DocsFaqs,
  DocsGasPriceCalculator,
  DocsHumachCheckStatus,
  DocsHumachFaqs,
  DocsHumachLicense,
  DocsHumachTraits,
  DocsMachinieFaqs,
  DocsMachinieLicense,
  DocsMachinieStats,
  DocsMustRead,
  DocsOpensea,
  DocsSmartContract,
  DocsUnmigratedMachinies,
} from "./DocsContent";

type DOCS_SECTION_TYPES = {
  select: string;
};

export const DocsSection = ({ select }: DOCS_SECTION_TYPES) => {
  //   const [select, setselect] = useState(1);

  const switchDocs = (select: number | string) => {
    switch (select) {
      case SELECT_PAGE.DOCS.QUICK.ECOSYSTEM:
        return <DocsEcosystem />;
      case SELECT_PAGE.DOCS.MACHINIE.MUST_READ:
        return <DocsMustRead />;
      case SELECT_PAGE.DOCS.QUICK.GAS_CAL:
        return <DocsGasPriceCalculator />;
      case SELECT_PAGE.DOCS.MACHINIE.CHECK_MACHINIE_STATUS:
        return <DocsCheckStatusMachinie />;
      case SELECT_PAGE.DOCS.MACHINIE.MACHINIE_FAQS:
        return <DocsMachinieFaqs />;
      case SELECT_PAGE.DOCS.MACHINIE.MACHINIE_TRAITS:
        return <DocsMachinieStats />;
      case SELECT_PAGE.DOCS.MACHINIE.UNMIGRATED_LIST:
        return <DocsUnmigratedMachinies />;
      case SELECT_PAGE.DOCS.MACHINIE.MACHINIE_LICENSE:
        return <DocsMachinieLicense />;
      case SELECT_PAGE.DOCS.HUMACH.ALLOWLIST:
        return <DocsAllowlist />;
      case SELECT_PAGE.DOCS.HUMACH.CHECK_HUMACH_STATUS:
        return <DocsHumachCheckStatus />;
      case SELECT_PAGE.DOCS.HUMACH.HUMACH_FAQS:
        return <DocsHumachFaqs />;
      case SELECT_PAGE.DOCS.HUMACH.HUMACH_TRAITS:
        return <DocsHumachTraits />;
      case SELECT_PAGE.DOCS.HUMACH.HUMACH_LICENSE:
        return <DocsHumachLicense />;
      case SELECT_PAGE.DOCS.QUICK.GENERAL_FAQS:
        return <DocsFaqs />;
      case SELECT_PAGE.DOCS.OTHER.SMART_CONTRACT:
        return <DocsSmartContract />;
      case SELECT_PAGE.DOCS.OTHER.SECONDARY_MARKET:
        return <DocsOpensea />;

      default:
        return <DocsEcosystem />;
    }
  };
  return (
    <div className="section-games bg-1">
      <div className="container">
        <div className="bold-headline-3 text-color-primary-200 mb-12">Docs</div>
        <div className="reg-body-2 text-color-gray-100">
          The secret ancient knowledge is here.
        </div>
        <div className="mt-14">
          <CountStakingPanel />
        </div>

        <div className="mt-5 docs-section-container">
          <div className="this-side-docs-container">
            <SideDocs select={select} />
          </div>
          {switchDocs(select)}
        </div>
      </div>
    </div>
  );
};

type SIDE_DOCS_TYPES = {
  select: string;
};

export const SideDocs = ({ select }: SIDE_DOCS_TYPES) => {
  return (
    <div className="side-docs-container">
      <div className="side-docs-container-each">
        <div className="bold-button text-color-line-tint-04 p-13">
          Quick Start
        </div>
        <Sideselect
          text="General FAQs"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.QUICK.GENERAL_FAQS}
        />
        <Sideselect
          text="Ecosystem"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.QUICK.ECOSYSTEM}
        />

        <Sideselect
          text="Gas price calculator"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.QUICK.GAS_CAL}
        />
      </div>

      <div className="side-docs-container-each">
        <div className="bold-button text-color-line-tint-04 p-13">Machinie</div>
        <Sideselect
          text="Machinie FAQs"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_FAQS}
        />
        <Sideselect
          text="Must read b4 stake"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.MUST_READ}
        />
        <Sideselect
          text="Check Machinie Status"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.CHECK_MACHINIE_STATUS}
        />
        {/* <Sideselect
          text="Unmigrated list"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.UNMIGRATED_LIST}
        /> */}
        <Sideselect
          text="Trait Stats"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_TRAITS}
        />

        <Sideselect
          text="Machinie License"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.MACHINIE.MACHINIE_LICENSE}
        />
      </div>

      <div className="side-docs-container-each">
        <div className="bold-button text-color-line-tint-04 p-13">Humach</div>

        <Sideselect
          text="Humach FAQs"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.HUMACH.HUMACH_FAQS}
        />

        <Sideselect
          text="Check Humach Status"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.HUMACH.CHECK_HUMACH_STATUS}
        />

        <a
          className="text-color-gray-100"
          href="https://raritysniper.com/humach"
          target="_blank"
        >
          <div className="text-color-gray-100 reg-body-2 p-13 side-docs-select-text">
            Rarity Sniper <IconOpenInNew colorClass="text-color-line-tint-04" />
          </div>
        </a>

        {/* <Sideselect
          text="Trait stats"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.HUMACH.HUMACH_TRAITS}
        /> */}

        {/* <Sideselect
          text="Allowlist"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.HUMACH.ALLOWLIST}
        /> */}

        <Sideselect
          text="Humach License"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.HUMACH.HUMACH_LICENSE}
        />
      </div>

      <div className="side-docs-container-each-end">
        <div className="bold-button text-color-line-tint-04 p-13">Other</div>
        <Sideselect
          text="Smart Contract"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.OTHER.SMART_CONTRACT}
        />
        <Sideselect
          text="Secondary Market"
          select={select}
          pathUrl={SELECT_PAGE.DOCS.OTHER.SECONDARY_MARKET}
        />
      </div>
    </div>
  );
};

type SIDE_DOCS_SELECT_TYPES = {
  text: string;
  select: string;
  pathUrl: string;
};

export const Sideselect = ({
  text,
  select,
  pathUrl,
}: SIDE_DOCS_SELECT_TYPES) => {
  return (
    <Link to={"/docs/" + pathUrl} onClick={() => window.scrollTo(0, 0)}>
      <div
        className={
          pathUrl === select
            ? "text-color-tertiary reg-body-2 side-docs-selected-text"
            : "text-color-gray-100 reg-body-2 p-13 side-docs-select-text"
        }
      >
        {text}
      </div>
    </Link>
  );
};

// https://raritysniper.com/humach
