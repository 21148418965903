import { SelectButtonGameSection } from "./SelectButtonGameSection";

export const GameSection = () => {
  return (
    <section>
      <div className="container section ">
        <div className="text-machinie text-gold text-center">Games</div>
        <div className="text-machinie text-main text-center font-bold-big">
          May the luck be with you!
        </div>
        <SelectButtonGameSection />
      </div>
    </section>
  );
};
