import { Link } from "react-router-dom";
import { Navigate } from "react-router";

export const Page404NotFound = () => {
  return (
    <div className="bg-1 vh-100">
      <div className=" p-5 text-center">
        <div className="bold-headline-2 text-color-line-tint-04 mb-32">
          404 Page Not Found
        </div>

        <Link to="/" className="button-back-home text-color-line-tint-07">
          Navigate to Machiniverse
        </Link>
        {/* <Navigate to="/" /> */}
      </div>
    </div>
  );
};
