import { useState, useEffect } from "react";
import { humachLogo } from "../../../imageForMachiniverse";
import { api } from "../../../axiosURL";
import web3 from "../../../web3";
import humach from "../../../humach";
import { MyNftMore } from "../MyNftMore/MyNftMore";
import { CONTRACT_TOKEN } from "../../../value";
import { LoadingDots } from "../../component/LoadingDots";

type MY_MACHINIE_CHILLING_EACH = {
  humachId: string;
  setHumachArrayForTrain: any;
  humachArrayForTrain: string[];
  setWalletList: any;
  humachListEach: any;
  setHumachList: any;
  selectIdMoreToggle: string;
  setSelectIdMoreToggle: any;
  floppy: any;
  setFloppy: any;
};

export const MyHumachChillingEach = ({
  humachId,
  setHumachArrayForTrain,
  humachArrayForTrain,
  setWalletList,
  humachListEach,
  setHumachList,
  selectIdMoreToggle,
  setSelectIdMoreToggle,
  floppy,
  setFloppy,
}: MY_MACHINIE_CHILLING_EACH) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [isSelect, setIsSelect] = useState(false);
  const [humachImage, setHumachImage] = useState<any>(humachLogo);
  const [IsClaiming, setClaiming] = useState(false);

  const [humachLevel, setHumachLevel] = useState<any>(["0", "0"]);
  const [tokenName, setTokenName] = useState("");

  useEffect(() => {
    getStart();
  }, []);

  useEffect(() => {
    if (humachArrayForTrain.includes(humachId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [humachArrayForTrain]);

  const getStart = async () => {
    const thisImage = await getImage(humachId);
    setHumachImage(thisImage);
    setClaiming(false);
    await getTokenNameHumach(humachId);
  };

  const getTokenNameHumach = async (tokenId: string | undefined) => {
    try {
      const res = await humach.methods.getTokenIdName(tokenId).call();
      setTokenName(
        res[0] !== "" ? res[0] + " #" + tokenId : "Humach #" + tokenId
      );
      return;
    } catch (err) {}
  };

  const getImage = async (humachId: string) => {
    const res = await api.get(
      "https://api.machinienft.com/api/humach/metadata/" + humachId
    );
    const getImageArray = res.data.image_list;

    const getImage = getImageArray[getImageArray.length - 1]["image"];

    const getIpfsImage = getImage.replace(
      "https://gateway.pinata.cloud/ipfs/",
      "https://ipfs.io/ipfs/"
    );
    // setHumachWith(getIpfsImage);
    // return getIpfsImage;
    return getImage;
  };

  const selectToTrain = () => {
    const getSelectArray = [...humachArrayForTrain];
    getSelectArray.push(humachId);
    setHumachArrayForTrain(getSelectArray);
  };

  const removeSelect = () => {
    const getSelectArray = [...humachArrayForTrain];
    var filteredArray = getSelectArray.filter((e) => e !== humachId);
    setHumachArrayForTrain(filteredArray);
  };

  const onClickClaim = async () => {
    try {
      setClaiming(true);
      const url = `/api/humach/claim/${humachListEach["id"]}`;
      const res = await api.post(
        url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      const thisHumach = await getHumachList();
      const thisWalletList = await getWalletList();
      setHumachList(thisHumach.data);
      setWalletList(thisWalletList.data);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // //console.log(res.data);
      return res.data;
    }
  };

  return (
    <div
      className={
        !isSelect
          ? "machinie-each-container"
          : "machinie-each-container-selected"
      }
    >
      {isSelect ? (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(false);
            removeSelect();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-check-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      ) : (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(true);
            selectToTrain();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      )}

      <div className="machinie-each-detail">
        <div className="display-mobile">
          <div className="img-machinie-each-container">
            <img
              src={humachImage}
              alt="humach"
              className="img-machinie-each"
              width="100%"
              height="100%"
            ></img>
          </div>
        </div>
        <div className="border-left-mobile">
          <div className="text-color-gray-100 reg-caption machinie-each-text-second-container">
            <div className="machinie-each-text-second border-text">
              Chilling
            </div>
          </div>

          <div className="display-mobile">
            <div className="claim-current-contianer">
              {!humachListEach["isClaim"] ? (
                IsClaiming ? (
                  <div className="claim-current-padding">
                    <div className="claimed-current">
                      <div className="bold-button text-color-gray-100">
                        CLAIMING
                        <LoadingDots />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="claim-current-padding">
                    <div
                      className="claim-current cursor-pointer"
                      onClick={() => onClickClaim()}
                    >
                      <div className="bold-button text-color-gray-100">
                        CLAIM 5{" "}
                        <span>
                          <img src={current} alt="current" width="13.5px"></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIM 5{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <MyNftMore
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                id={humachId}
                contractToken={CONTRACT_TOKEN.HUMACH}
                floppy={floppy}
                setFloppy={setFloppy}
                humachLevel={humachLevel}
                isTraining={true}
              />
            </div>
          </div>

          <div className="display-mobile">
            <HumachLevelContainer
              humachId={humachId}
              humachLevel={humachLevel}
              setHumachLevel={setHumachLevel}
            />
          </div>
        </div>
        <div className="display-desktop">
          <div className="img-machinie-each-container">
            <img
              src={humachImage}
              alt="humach"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="display-desktop">
          <HumachLevelContainer
            humachId={humachId}
            humachLevel={humachLevel}
            setHumachLevel={setHumachLevel}
          />
        </div>

        <div className="display-desktop">
          <div className="claim-current-contianer">
            {!humachListEach["isClaim"] ? (
              IsClaiming ? (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIMING
                      <LoadingDots />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="claim-current-padding">
                  <div
                    className="claim-current cursor-pointer"
                    onClick={() => onClickClaim()}
                  >
                    <div className="bold-button text-color-gray-100">
                      CLAIM 5{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="claim-current-padding">
                <div className="claimed-current">
                  <div className="bold-button text-color-gray-100">
                    CLAIM 5{" "}
                    <span>
                      <img src={current} alt="current" width="13.5px"></img>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <MyNftMore
              selectIdMoreToggle={selectIdMoreToggle}
              setSelectIdMoreToggle={setSelectIdMoreToggle}
              id={humachId}
              contractToken={CONTRACT_TOKEN.HUMACH}
              floppy={floppy}
              humachLevel={humachLevel}
              setFloppy={setFloppy}
              isTraining={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type HUMACH_LEVEL_CONTAINER_PROPS_TYPES = {
  humachId: any;
  humachLevel: any;
  setHumachLevel: any;
};

export const HumachLevelContainer = ({
  humachId,
  humachLevel,
  setHumachLevel,
}: HUMACH_LEVEL_CONTAINER_PROPS_TYPES) => {
  useEffect(() => {
    getStart();
  }, [humachId]);

  const getStart = async () => {
    if (humachId !== "") {
      const thisLevel = await getHumachLevel(humachId);
      setHumachLevel(thisLevel);
    }
  };

  const getHumachLevel = async (x: string) => {
    const level = await humach.methods.calculateLevel(x).call();
    return level;
  };
  return (
    <div className="level-humach-container-2 level-second-border-top ">
      <div className="text-color-gray-100 reg-body-2 text-level-humach">
        Level
      </div>

      <HumachLevel humachLevel={humachLevel} />

      <div className="level-second-border-top text-color-gray-100 reg-body-2  text-level-humach">
        EXP
      </div>

      <HumachExp humachLevel={humachLevel} />
    </div>
  );
};

type HUMACH_LEVEL_PROPS_TYPES = {
  humachLevel: any;
};

export const HumachLevel = ({ humachLevel }: HUMACH_LEVEL_PROPS_TYPES) => {
  const [notYet, setNotYet] = useState<any>([]);
  const [get, setGet] = useState<any>([]);

  useEffect(() => {
    const getStart = async () => {
      const num_not = 5 - Number(humachLevel[0]);
      const num_yes = Number(humachLevel[0]);

      let use_not_array = [];
      let use_yes_array = [];

      for (let i = 0; i < num_not; i++) {
        use_not_array.push(i);
      }

      for (let j = 0; j < num_yes; j++) {
        use_yes_array.push(j);
      }

      //console.log("use_not_array ", use_not_array);
      //console.log("use_yes_array ", use_yes_array);
      setNotYet(use_not_array);
      setGet(use_yes_array);
    };
    getStart();
  }, [humachLevel]);

  return (
    <div className="level-humach-each-container">
      {get.map(() => (
        <HumachLevelEachActive />
      ))}

      {notYet.map(() => (
        <HumachLevelEachInactive />
      ))}
    </div>
  );
};

export const HumachExp = ({ humachLevel }: HUMACH_LEVEL_PROPS_TYPES) => {
  const [notYet, setNotYet] = useState<any>([]);
  const [get, setGet] = useState<any>([]);

  useEffect(() => {
    const getStart = async () => {
      const num_not = 9 - Number(humachLevel[1]);
      const num_yes = Number(humachLevel[1]);

      let use_not_array = [];
      let use_yes_array = [];

      for (let i = 0; i < num_not; i++) {
        use_not_array.push(i);
      }

      for (let j = 0; j < num_yes; j++) {
        use_yes_array.push(j);
      }

      setNotYet(use_not_array);
      setGet(use_yes_array);
    };
    getStart();
  }, [humachLevel]);
  return humachLevel[0] !== "5" ? (
    <div className="level-humach-each-container  level-second-border-top">
      {get.map(() => (
        <HumachExpEachActive />
      ))}

      {notYet.map(() => (
        <HumachExpEachInactive />
      ))}
    </div>
  ) : (
    <div className="level-second-border-top text-color-secondary bold-body-2  text-max">
      MAX
    </div>
  );
};

export const HumachLevelEachActive = () => {
  return <div className="level-active-each"></div>;
};

export const HumachLevelEachInactive = () => {
  return <div className="level-inactive-each"></div>;
};

export const HumachExpEachActive = () => {
  return <div className="exp-active-each"></div>;
};

export const HumachExpEachInactive = () => {
  return <div className="exp-inactive-each"></div>;
};
