import { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";

export const SelectButtonGameSection = () => {
  const [panelGame, setPanelGame] = useState<number>(0);
  return (
    <div className="pb-4 pt-4 container">
      <div className="d-flex justify-content-center">
        <ButtonSelection
          panelGame={panelGame}
          setPanelGame={setPanelGame}
          name="MACHI LOTTO"
          numberSelect={0}
        />
        <ButtonSelection
          panelGame={panelGame}
          setPanelGame={setPanelGame}
          name="SLOT MACHI"
          numberSelect={1}
        />
        <ButtonSelection
          panelGame={panelGame}
          setPanelGame={setPanelGame}
          name="MACHIPON"
          numberSelect={2}
        />
      </div>

      {panelGame == 0 ? (
        <HomePanelGameLotto />
      ) : panelGame == 1 ? (
        <HomePanelGameSlotMachi />
      ) : (
        <HomePanelGameMachipon />
      )}
    </div>
  );
};

export const HomePanelGameLotto = () => {
  const lotto: string = `${process.env.PUBLIC_URL}/assets/lotto/lotto_1.gif`;
  //   /Users/hathumkittitus/machiniverse-project/public/assets/lotto/lotto_1.gif
  return (
    <div className="home-game-container">
      <div className="text-center width-responsive-flex">
        <img
          src={lotto}
          className="img-machine-width"
          alt="Machinie Lotto Machine"
        ></img>
      </div>
      <div className="game-right-panel text-machinie m-auto-tb width-responsive-flex">
        <div className="text-gold pb-3">Machi lotto</div>
        <div className="text-main  pb-3 font-bold">
          The Lotto prizes drop on the 8th of each month.
        </div>

        <TableCostRewardsLotto />

        <Link
          to="/game/lotto"
          className="button-machinie  button-home-game-click-play text-center"
        >
          MAKE A WISH
        </Link>
      </div>
    </div>
  );
};

export const HomePanelGameSlotMachi = () => {
  // const logo_machinie: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-machinie-l.png`;
  const slot: string = `${process.env.PUBLIC_URL}/assets/gameAssets/slot_idle_final.gif`;
  return (
    <div className="home-game-container">
      <div className="text-center width-responsive-flex">
        <img
          src={slot}
          className="img-machine-width"
          alt="Machinie Lotto Machine"
        ></img>
      </div>
      <div className="game-right-panel text-machinie m-auto-tb width-responsive-flex">
        <div className="text-gold pb-3">Slot Machi</div>
        <div className="text-main  pb-3 font-bold">
          Easy, insert your <span className="text-gold">$current</span>, and
          good luck.
        </div>

        <TableCostRewardsSlotMachi />

        <Link
          to="/game/slotmachi"
          className="button-machinie button-home-game-click-play text-center"
        >
          PLAY
        </Link>
      </div>
    </div>
  );
};

export const HomePanelGameMachipon = () => {
  // const logo_machinie: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-machinie-l.png`;
  const machipon: string = `${process.env.PUBLIC_URL}/assets/gameAssets/gasha_idle_final.gif`;
  return (
    <div className="home-game-container">
      <div className="text-center width-responsive-flex">
        <img
          src={machipon}
          className="img-machine-width"
          alt="Machinie Lotto Machine"
        ></img>
      </div>
      <div className="game-right-panel text-machinie m-auto-tb width-responsive-flex">
        <div className="text-gold pb-3">Machipon</div>
        <div className="text-main  pb-3 font-bold">
          Just like gashapon, insert your{" "}
          <span className="text-gold">$current</span> and wish to get some
          Machiniverse assets.
        </div>

        <TableCostRewardsMachipon />

        <Link
          to="/game/machipon"
          className="button-machinie  button-home-game-click-play text-center"
        >
          PLAY
        </Link>
      </div>
    </div>
  );
};

type BUTTON_TYPES = {
  name: string;
  numberSelect: number;
  panelGame: number;
  setPanelGame: any;
};

export const ButtonSelection = ({
  name,
  numberSelect,
  panelGame,
  setPanelGame,
}: BUTTON_TYPES) => {
  return (
    <div
      className={
        panelGame === numberSelect
          ? "button-home-game button-home-game-selected cursor-pointer m-2"
          : "button-home-game button-home-game-not-select cursor-pointer m-2"
      }
      onClick={() => {
        if (panelGame != numberSelect) {
          setPanelGame(numberSelect);
        }
      }}
    >
      <div className="text-game-button-select text-sub text-machinie font-name-game-button">
        {name}
      </div>
    </div>
  );
};

export const TableCostRewardsLotto = () => {
  return (
    <div className="table-cost-rewards-container">
      <div>
        <div className="p-3 h-50 border-cost bg-2">
          <div className="text-white">Cost</div>
        </div>
        <div className=" p-3 h-50 boder-rewards bg-2">
          <div className="text-white">Rewards</div>
        </div>
      </div>

      <div>
        <div className="p-3 h-50 border-cost-value ">
          <div className="text-white">1 ticket = 1 entry</div>
        </div>

        <div className="text-white p-3 h-50">
          Special NFTs, IRL Merch, and etc
        </div>
      </div>
    </div>
  );
};

export const TableCostRewardsSlotMachi = () => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  return (
    <div className="table-cost-rewards-container">
      <div>
        <div className="p-3 h-50 border-cost bg-2">
          <div className="text-white">Cost</div>
        </div>
        <div className=" p-3 h-50 boder-rewards bg-2">
          <div className="text-white">Rewards</div>
        </div>
      </div>

      <div>
        <div className="p-3 h-50 border-cost-value ">
          <div className="text-white">
            <span>
              <img src={current} alt="machinie $current"></img>
            </span>{" "}
            $current
          </div>
        </div>

        <div className="text-white p-3 h-50">
          {" "}
          <span>
            <img src={current} alt="machinie $current"></img>
          </span>{" "}
          $current
        </div>
      </div>
    </div>
  );
};

export const TableCostRewardsMachipon = () => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  const discord1000: string = `${process.env.PUBLIC_URL}/assets/gameAssets/1000EXP.png`;
  const ticket: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Ticket.png`;
  return (
    <div className="table-cost-rewards-container">
      <div>
        <div className="p-3 h-50 border-cost bg-2">
          <div className="text-white">Cost</div>
        </div>
        <div className=" p-3 h-50 boder-rewards bg-2">
          <div className="text-white">Rewards</div>
        </div>
      </div>

      <div>
        <div className="p-3 h-50 border-cost-value ">
          <div className="text-white">
            <span>
              <img src={current} alt="machinie $current"></img>
            </span>{" "}
            $current
          </div>
        </div>

        <div className="text-white p-3 h-50">
          <span>
            <img src={discord1000} alt="machinie $current"></img>
          </span>{" "}
          {/* Discord EXP ,{" "} */},{" "}
          <span>
            <img src={ticket} alt="machinie $current"></img>
          </span>{" "}
          {/* Ticket */}
        </div>
      </div>
    </div>
  );
};
