export const machinie_url = [
  "https://lh3.googleusercontent.com/okxR26LVtW8j0UMZEsil0BAbLFh0uyPwGkmRpz7bqGb8p5f8xYoFIAkvOd9x-VIiElPu0M_VjtuXKUailZIrnce-XaMtHo2lRnO-fA",
  "https://lh3.googleusercontent.com/E533Rv4p0pLulKgrYz54H4PdLON_jT2Q8cz9_9cexHjk5WUR6CVDeXWXT2kumsXemII7O9lFEyExdrlrlNbHblObflrppnUsWjtK",
  "https://lh3.googleusercontent.com/85x3bV0n75U2AmqrMszHeKFP2HL4fsSA9wAy2Ov3ItV9mCknmxMyaNcK6DuC-NB5R27WSNAUXkLbBRvvn1wmyN1BPczkJqY_koTrbg",
  "https://lh3.googleusercontent.com/Eux6O4i5_xybJdW-5L2OOz5HxWqTv59MKbs_n4nRer1juSMD4k56X1g4P8fPZsiBvCRd01LyaRpAulJsghXFXKVHC21gEEaub77G",
  "https://lh3.googleusercontent.com/aPJ2C-oTdXnawd3vGsjuD48-zpqRWsMrdQbIQgiFseS_IjsvwDLwZBBsOwU_UiQRSdBydgAJPWoalMKmC9FG-1Zreo2mh3IMlm4BiQ",
  "https://lh3.googleusercontent.com/mBgZM4kl4j_Y8XkdGylJ_VN4LFa610RMIL9WL8KiCSYiEqhIC64iJ1mRS7_rGOP0fAtT_bINHgZEGs918asdpBY3Pc_qinbnyaBjZQ",
  "https://lh3.googleusercontent.com/6294ugGWEmknUh4kbZU-yNFtaBGkaA-hB5QiV_n0zyXK8AkWEMfwggjHDmMWlSBDdjweGSTtPGvNQ9b7vJr-a6JCmPfbU_6EWN7-TFU",
  "https://lh3.googleusercontent.com/j4xlzYI-1KiEgywaiB1JE-xFEp7BTwqoW-QLICBV0ix8sO_vNfn8NrWZ8VkJV9OCeBgFxgUL3Qa7FV5oAKG31iHwc2o8mOOsw1H9Vw",
  "https://lh3.googleusercontent.com/51lmFVQ5LdDgLsrpmJcoUiF1Un9Zs-3mwRwA_z28fskhjch9zOOfBg66esfjSB1hddKHKJVNu8QswW1U9HBzNbb6iWc0aln08Hr207o",
  "https://lh3.googleusercontent.com/UDFcqmequIiBiHP-B-bEaZ8am8eUEVZOUSQiLAQPIYFPDuSk6FLnJyGXTlF3BW6Da-ndzoD1-bq2BHmnKlo8HN1ggXXY_S8j5Gt3qNE",
  "https://lh3.googleusercontent.com/lWfFE_LB2v1sXD_7ASzPCtn_oZPG_W23uViUdKkTrmsoO5iu7vwyS3PZqm3p_i-qGKiFilYXL0RTVcLsI_6_7iDsMx8DYFz7RNcMbpc",
  "https://lh3.googleusercontent.com/DRYsG5KGYyH0tedfXxUOcErm3cHVYzqFYmz0QzD1qtg1uCaz_tkzTOjzJi7fju95brh4WCy6hdb8vvzJ7SzjImZyxaQPL6j9vP5f",
];

export const machinieTriple = [
  "https://lh3.googleusercontent.com/rz38nodqKfbER_DvYus9ncFbdZsbjCDW8G74SD1ZGeyf9RY4rvY9TsHGYJKPhx1WJhMOr99_GHK6IZQSlXcwx9pfSC4kUNkKhAlm1A",
  "https://lh3.googleusercontent.com/5aQ3UFZzyYYWJY6pPcYIEpDduxqZj8eA61HoCYONH2mX1JnOLP1biC9hXqVQCXQfKQP6oeIdUrwy_WCYJXRPPXxfZxDGG5Hh8wQ3",
  "https://lh3.googleusercontent.com/wLh6phYAYnAIqF4R9fHT3n5-9SDhBFZYqR-w8wGjIULEt3fMZSCEFQT9mjjG65z02TwEWWrwg0cA7fwVkCFEqB085Mzte0EnSECD7g",
  "https://lh3.googleusercontent.com/wLh6phYAYnAIqF4R9fHT3n5-9SDhBFZYqR-w8wGjIULEt3fMZSCEFQT9mjjG65z02TwEWWrwg0cA7fwVkCFEqB085Mzte0EnSECD7g",
  "https://lh3.googleusercontent.com/ysAfMY1O807uUjkjzZcoXteILjoLIfWEnXgdc0_WigwCl9z1-_s9GFetS4HEKRDWidGFee4-hPJBoz8_MCAtjW11y314VydlIgb3C_0",
];
export const machinieDouble = [
  "https://lh3.googleusercontent.com/c2AFXIe5aKcLMM7zf22oXlYmirVu1k37G16VjB2lMonOxJPFp579u-FK6fUbzCo_Jx92wGkZt1VkbRojyNbr--XQYc5OSUMx8TeBMA",
  "https://lh3.googleusercontent.com/05sllbgmz_2d4yYCY0Q9C2d_X72HIUIuDH35_lGzQhONpcjYlj3dddXbr5CdopVoITr2Ff4NKhaoaCTOGjgfLiR5FsiVKc7e1WdUyQ",
  "https://lh3.googleusercontent.com/4_6t02HzssIb63xseQjL4h2Fm4MQJ7v6nkvGXRQjyy4aaB8oMrdoZ2PZBrLOme7ikq7HbtFHWQ47_kIS8imFaOjM5IGM3BPxnYZ8oCQ",
  "https://lh3.googleusercontent.com/j4xlzYI-1KiEgywaiB1JE-xFEp7BTwqoW-QLICBV0ix8sO_vNfn8NrWZ8VkJV9OCeBgFxgUL3Qa7FV5oAKG31iHwc2o8mOOsw1H9Vw",
  "https://lh3.googleusercontent.com/N2oDWX53uGfe-m89HFkSpDKypUHydrc3adweQCAHgWpqYT2uZmNyCJjNHnz_RBpY1R7b9cYl_7DotO_Dik8bXDdAGFDBXTPyu17m",
];
export const machinieQuadruple = [
  "https://lh3.googleusercontent.com/2LVNAf0iRFz7mLj1qo7Mp0VGGzwf-OKSrReJDtl1hJFoBGyn_-tkaP8ekD1MJYb9DVmS6Nyaz0QIb_omtWib0wscPqQy9g_oKVMgb6A",
  "https://lh3.googleusercontent.com/X_qBl1He6A3mbMvXTnocM6NIdMtmNkRNQYLCt5s3QbdlkcMsP7JKQ6DkkH9KcIKNk4FR4p74k8krFNFEafzCeI2r4R6TrA7sI_wsMA",
  "https://lh3.googleusercontent.com/uNdg1KYbctVA2Y6YJM3UPLudg6y09RRkpGgtK8bgOR_RGWr-3br8dZXGipyEGGBuAgfptpbcX1YtphjnVcCwswtmY9qASElj1WbiXAM",
  "https://lh3.googleusercontent.com/VA6omGT7FcuOFDWjpiSy96f9y2gu7JeDcktretna3HHvFW81UVGFnwns4vsZQPX-xPcoLMQrkdkEr4uhGjJx1oO4SxC2Vvgzpx6baw8",
  "https://lh3.googleusercontent.com/qkg_w2GidWO8UPQLe49unB36-LFSNklxx4U5bOp_FxV7BXA_zXRNMJryUoJGOJWzNRF5EP9wN-OvQzJSJX4NkKnGy1NhhhBaP6vB1w",
];
export const machinieSingle = [
  "https://lh3.googleusercontent.com/okxR26LVtW8j0UMZEsil0BAbLFh0uyPwGkmRpz7bqGb8p5f8xYoFIAkvOd9x-VIiElPu0M_VjtuXKUailZIrnce-XaMtHo2lRnO-fA",
  "https://lh3.googleusercontent.com/cf4hwAyAABLCnjOGIxdhazt1SQB_5P8sHBRUPlxEuMzttGGO_Ofiu0AwKzergv6HWUW9IVguvTAJxa-zb3QT7irdyCxBAYSUKTuOxw",
  "https://lh3.googleusercontent.com/Eux6O4i5_xybJdW-5L2OOz5HxWqTv59MKbs_n4nRer1juSMD4k56X1g4P8fPZsiBvCRd01LyaRpAulJsghXFXKVHC21gEEaub77G",
  "https://lh3.googleusercontent.com/nLNlGPNLXiX9Bn_-EIYRWJYHaQ2fU_YL_QFsvhr_WKgAwcjiP6swfABkta-8jJlfadb5RmtP718UNp6l6V_Z-4I9jJRHILa8nRe-j8A",
  "https://lh3.googleusercontent.com/PDDlBjjYQv_6YabsWvv0q3V2AJNNzWJDlGQUGqiTT4iZb4lABXpAeFrRi9ZpZ5d8w8rtdN2_yAeZiNcLQDT_JKjuygzFPvgR4dR4tHs",
];
export const machinieDistorted = [
  "https://lh3.googleusercontent.com/pegw6yfu25gHf3SrRSOEgv_4pxO2Y5s947krI9ZApmBJlvyknRj5c4PK0b73_9ttjJW9lpMRRDHRTMqBIoxFx7LbuHlShpwEjmN7glE",
  "https://lh3.googleusercontent.com/vZc_LEhx4Pf3AJpc18zRm-q-rBTy1d9oI3Om7NKxWQ4SPsNVOGncjLm76FWhGTrepZi7CFtI3Ak1mi6_o2ICy62TnQ-w9Z5NGlnYxA",
  "https://lh3.googleusercontent.com/85x3bV0n75U2AmqrMszHeKFP2HL4fsSA9wAy2Ov3ItV9mCknmxMyaNcK6DuC-NB5R27WSNAUXkLbBRvvn1wmyN1BPczkJqY_koTrbg",
  "https://lh3.googleusercontent.com/hM51WVcu9F89xsw8Rr9heNnCbsU8PRi7oD7aT62GZa2LJAtDjXWUaHSjCTZo1qK9xZ-6YqfiI2umKmvz3FlLgVyrLowVa9EHfIlTLA",
  "https://lh3.googleusercontent.com/E533Rv4p0pLulKgrYz54H4PdLON_jT2Q8cz9_9cexHjk5WUR6CVDeXWXT2kumsXemII7O9lFEyExdrlrlNbHblObflrppnUsWjtK",
  "https://lh3.googleusercontent.com/O-2VQTFDTEnoKNaux2qtNPqX6UwI-WyD8iH3bOygmguE0n3sShNiJA-wAU8X0NUK9P8VhcirPlqmYUwfs2djDjr1vQjCTwOlW_Ojsw",
  "https://lh3.googleusercontent.com/5lm_cuIiRPlpZwsJuGkM0lXoPb4iVayUfJFITKY3nG4swA_rwUfOjx6XY6EL2bzErDSNUYwWrrULd6iOgSdzC-rrZ4dMV2-d0ad8jw",
  "https://lh3.googleusercontent.com/x0PQJnG1tnc509XhpQz6NGn2MA8yqUv0teGpAPQXCoyOhq7_dm51C5K6e0YDwLFVmBPYGQ79pfaCq6NBbQfBGw3eMhgtbkexMxoXwQ",
];
