import { useEffect } from "react";
import { SELECTING } from "../../value";
import { BeforeFooterSection } from "../homepage/beforefoot/BeforeFooterSection";
import { FooterSection } from "../homepage/footer/FooterSection";
import { Nav } from "../navbar/Nav";
import { FaqLearnHumachSection } from "./FaqLearnHumachSection";
import { HumachGenerateCurrent } from "./HumachGenerateCurrent";
import {
  HumachHistory,
  LearnHumachFirstSection,
} from "./LearnHumachFirstSection";
import { PowerOfHumach } from "./PowerOfHumach";
import { WantSomeHumachSection } from "./WantSomeHumachSection";

export const LearnHumachPage = ({
  walletList,
  setSelecting,
  isMainnet,
}: LEARN_HUMACH_PAGE) => {
  useEffect(() => {
    setSelecting(SELECTING.HUMACH_PAGE);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LearnHumachFirstSection walletList={walletList} isMainnet={isMainnet} />
      <HumachHistory />
      <PowerOfHumach />
      <HumachGenerateCurrent />
      <WantSomeHumachSection walletList={walletList} isMainnet={isMainnet} />
      <FaqLearnHumachSection />
      <BeforeFooterSection />
    </div>
  );
};

type LEARN_HUMACH_PAGE = {
  walletList: any;
  isMainnet: string;
  setSelecting: any;
};
