import { FaqEach } from "../homepage/faq/FaqSection";
import { useState, useEffect } from "react";

export const FaqLearnMachinieSection = () => {
  return (
    <section className="section bg-1">
      <div className="container">
        <div className="text-center">
          <div className="reg-subtitle-2 text-color-secondary">
            Machinie FAQs
          </div>
          <div className="text-color-primary-200 bold-headline-4 mt-14">
            Here are some important information you should read
          </div>
        </div>
        <FaqLearnMachinieContainer />
      </div>
    </section>
  );
};

export const FaqLearnMachinieContainer = () => {
  const [select, setSelect] = useState("");
  return (
    <div className="faq-container bg-2 mt-5">
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What are Genesis Machinies?"
        description="Highly advanced, ancient technological artifacts from a long forgotten age. They produce an infinite supply of energy to those that can unlock their true power. Only Humachs posses the ancient knowledge required to utilize the full potential of the 888 Genesis Machinie, generating a continuous stream of $floppy for their owners."
      />
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What is the utility for $floppy token?"
        description="$floppy can be used to call (breed) additional Humach. It can also be used to customize the name and backstory of your Humach. There is additional utility planned for $floppy as the Machinie ecosystem expands."
      />

      <FaqEachHowGenerateFloppy
        topic="How much $floppy can I generate per day?"
        select={select}
        setSelect={setSelect}
      />

      <FaqEachHowGenerateCurrent
        topic="How much $current can I generate per day?"
        select={select}
        setSelect={setSelect}
      />

      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What is the utility for $current token?"
        description="$current can be used to play game machines in Machiniverse. At the moment we have 2 games, Slot Machi and Machipon."
      />

      <FaqEachHowAcquireAMachinie
        topic="How can I acquire a Genesis Machinie?"
        select={select}
        setSelect={setSelect}
      />
    </div>
  );
};

type FAQ_MACHINIE_EACH = {
  topic: string;
  select: string;
  setSelect: any;
};

export const FaqEachHowGenerateFloppy = ({
  topic,
  select,
  setSelect,
}: FAQ_MACHINIE_EACH) => {
  const [bool, setBool] = useState(false);

  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };

  return (
    <div className="faq-each-container">
      <div className="faq-each-margin" onClick={() => switchBool(bool)}>
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">{topic}</div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>
      </div>
      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            A Genesis Machine when staked with a Humach, will generate between 8
            and 12 $floppy per day. The rarer your Genesis Machinie, the more
            $floppy it will generate. The trait that determines this rarity is
            “Combination of Type” and the variable $floppy output is as follows:
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            <div>
              <span className="bold-subtitle-2">Triple:</span> generates 8
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Double:</span> generates 9
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Quadruple:</span> generates 10
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Single:</span> generates 11
              $floppy per day
            </div>
            <div>
              <span className="bold-subtitle-2">Distorted:</span> generates 12
              $floppy per day
            </div>
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            Please note that 1 Humach must be paired with 1 Genesis Machinie to
            generate $floppy. As there are less Genesis Machinie than there are
            Humach, not all Humach will be able to generate $floppy in the
            beginning. This will make the Genesis Machinie highly sought after,
            and Humach will have to compete for the scarce supply. Humach that
            do not posses a Genesis Machinie, would be going on a space trip for
            $floppy hunting in the near future.
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const FaqEachHowGenerateCurrent = ({
  topic,
  setSelect,
  select,
}: FAQ_MACHINIE_EACH) => {
  const [bool, setBool] = useState(false);

  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };
  return (
    <div className="faq-each-container">
      <div className="faq-each-margin" onClick={() => switchBool(bool)}>
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">{topic}</div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>
      </div>
      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            10 $current / day / Machinie
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            5 $current / day / Humach
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const FaqEachHowAcquireAMachinie = ({
  topic,
  select,
  setSelect,
}: FAQ_MACHINIE_EACH) => {
  const [bool, setBool] = useState(false);

  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };
  return (
    <div className="faq-each-container">
      <div className="faq-each-margin" onClick={() => switchBool(bool)}>
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">
            How can I acquire a Genesis Machinie?
          </div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>
      </div>
      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            You can purchase a Genesis Machinie (migrated) on OpenSea here:
            <a href="https://opensea.io/collection/machinie" target="blank_">
              https://opensea.io/collection/machinie
            </a>
          </div>
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            You can purchase a Genesis Machinie (non-migrated) on OpenSea here:{" "}
            <a
              href="https://opensea.io/collection/machinietomigrate"
              target="blank_"
            >
              https://opensea.io/collection/machinietomigrate
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
