import { useEffect, useState } from "react";
import { Modal2, Modal3 } from "../../modal/Modal";
import { HumachForStakeEach } from "./HumachForStakeEach";
import { humachLogo } from "../../../imageForMachiniverse";
import axios from "axios";
import { machinie_image_url } from "../../../machinieImageUrl2";
import { MachinieForStakeEach } from "./MachinieForStakeEach";

type MODAL_PAIR_STAKE_EACH_PROPS_TYPES = {
  index: number;
  humachNotSelect: string[];

  objectPair: { machinieId?: string; humachId?: string }[];
  setObjectPair: any;
  objectPairEach: {
    machinieId?: string | undefined | null;
    humachId?: string | undefined | null;
  };
  machinieNotSelect: string[];
};

export const ModalPairStakeEach = ({
  index,
  humachNotSelect,
  objectPair,
  setObjectPair,
  objectPairEach,
  machinieNotSelect,
}: MODAL_PAIR_STAKE_EACH_PROPS_TYPES) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const removePaired = () => {
    const getObjectPair = [...objectPair];
    getObjectPair.splice(index, 1);
    setObjectPair(getObjectPair);
    //console.log("removed", getObjectPair);
  };

  return (
    <div className="modal-pair-stake-each-container">
      <div className="modal-pair-stake-each-container-bottom">
        <div className="modal-pair-stake-each-container-bottom-left">
          {objectPairEach?.humachId ? (
            <ModalPairStakeSelectedHumach
              humachNotSelect={humachNotSelect}
              setIsOpen={setIsOpen}
              index={index}
              isOpen={isOpen}
              objectPair={objectPair}
              setObjectPair={setObjectPair}
            />
          ) : (
            <ModalPairStakeNotSelectHumach
              humachNotSelect={humachNotSelect}
              index={index}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              objectPair={objectPair}
              setObjectPair={setObjectPair}
            />
          )}

          <div className="m-auto text-color-tertiary bold-subtitle-2">
            {index + 1}
          </div>

          <ModalPairStakeSelectedMachinie
            machinieNotSelect={machinieNotSelect}
            setIsOpen3={setIsOpen3}
            index={index}
            isOpen3={isOpen3}
            objectPair={objectPair}
            setObjectPair={setObjectPair}
          />
        </div>

        <div className="minus-button-container" onClick={() => removePaired()}>
          <div className="minus-button text-color-tertiary bold-subtitle-2">
            <i className="fa fa-minus"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

type MODAL_PAIR_STAKE_EACH_NOT_SELECT_HUMACH = {
  humachNotSelect: string[];
  index: number;
  setIsOpen: any;
  isOpen: boolean;

  objectPair: { machinieId?: string; humachId?: string }[];
  setObjectPair: any;
};

export const ModalPairStakeNotSelectHumach = ({
  humachNotSelect,
  setIsOpen,
  index,
  isOpen,
  objectPair,
  setObjectPair,
}: MODAL_PAIR_STAKE_EACH_NOT_SELECT_HUMACH) => {
  return (
    <div>
      <div className="modal-pair-stake-each-not-select-container">
        <div
          className="modal-pair-stake-each-not-select cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="bold-subtitle-2 text-color-tertiary m-auto-tb">
            Select Humach
          </div>
          <div className="m-auto-tb">
            <i className="text-color-tertiary fa fa-caret-down "></i>
          </div>
        </div>
      </div>
      <Modal2 open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <div className="close-modal-container-stake">
            <div className="bold-headline-6 text-color-gray-100">
              Select Humach
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <i className="fa fa-times close-modal"></i>
            </div>
          </div>
        </div>

        <div className="p-24">
          <div className="bold-button text-color-line-tint-04 mb-12">
            {humachNotSelect.length}{" "}
            {humachNotSelect.length > 1 ? "HUMACHS" : "HUMACH"}
          </div>
          <div className="humach-for-stake-each-container">
            {humachNotSelect.map((x) => (
              <HumachForStakeEach
                index={index}
                humachId={x}
                setIsOpen={setIsOpen}
                objectPair={objectPair}
                setObjectPair={setObjectPair}
              />
            ))}
          </div>
        </div>
      </Modal2>
    </div>
  );
};

export const ModalPairStakeSelectedHumach = ({
  humachNotSelect,
  setIsOpen,
  isOpen,
  objectPair,
  setObjectPair,
  index,
}: MODAL_PAIR_STAKE_EACH_NOT_SELECT_HUMACH) => {
  const [image, setImage] = useState(humachLogo);
  useEffect(() => {
    getImage();
  }, [objectPair[index]["humachId"]]);

  const getImage = async () => {
    setImage(humachLogo);
    const res = await axios.get(
      "https://api.machinienft.com/api/humach/metadata/" +
        objectPair[index]["humachId"]
    );
    const getImageArray = res.data.image_list;
    const getImage = getImageArray[getImageArray.length - 1]["image"];
    const getIpfsImage = getImage.replace(
      "https://gateway.pinata.cloud/ipfs/",
      "https://ipfs.io/ipfs/"
    );
    setImage(getImage);
  };

  return (
    <div>
      <div
        className="modal-pair-stake-each-machinie cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <img
            src={image}
            className="modal-pair-stake-each-machinie-image"
            width="50px"
          ></img>
        </div>
        <div className="bold-subtitle-2 text-color-tertiary m-auto-tb">
          Humach #{objectPair[index]["humachId"]}
        </div>
        <div className="text-color-tertiary  m-auto-tb">
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
      <Modal2 open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <div className="close-modal-container-stake">
            <div className="bold-headline-6 text-color-gray-100">
              Select Humach
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <i className="fa fa-times close-modal"></i>
            </div>
          </div>
        </div>

        <div className="p-24">
          <div className="bold-button text-color-line-tint-04 mb-12">
            {humachNotSelect.length}{" "}
            {humachNotSelect.length > 1 ? "HUMACHS" : "HUMACH"}
          </div>
          <div className="humach-for-stake-each-container">
            {humachNotSelect.map((x) => (
              <HumachForStakeEach
                index={index}
                humachId={x}
                setIsOpen={setIsOpen}
                objectPair={objectPair}
                setObjectPair={setObjectPair}
              />
            ))}
          </div>
        </div>
      </Modal2>
    </div>
  );
};

type MODAL_PAIR_STAKE_EACH_NOT_SELECT_MACHINIE = {
  index: number;
  setIsOpen3: any;
  isOpen3: boolean;
  machinieNotSelect: string[];
  objectPair: { machinieId?: string; humachId?: string }[];
  setObjectPair: any;
};

// export const ModalPairStakeSelectedMachinie = ({
//   index,
//   setIsOpen3,
//   isOpen3,
//   objectPair,
//   setObjectPair,
//   machinieNotSelect,
// }: MODAL_PAIR_STAKE_EACH_NOT_SELECT_MACHINIE) => {
//   return (
//     <div>
//       <div
//         className="modal-pair-stake-each-machinie cursor-pointer"
//         onClick={() => setIsOpen3(true)}
//       >
//         <div>
//           <img
//             src={machinie_image_url[Number(objectPair[index]["machinieId"])]}
//             className="modal-pair-stake-each-machinie-image"
//             width="50px"
//           ></img>
//         </div>
//         <div className="bold-subtitle-2 text-color-tertiary m-auto-tb">
//           Machinie #{objectPair[index]["machinieId"]}
//         </div>
//         <div className="text-color-tertiary  m-auto-tb">
//           <i className="fa fa-caret-down"></i>
//         </div>
//       </div>
//       <Modal3 open={isOpen3}>
//         <div>
//           <div className="close-modal-container-stake">
//             <div className="bold-headline-6 text-color-gray-100">
//               Select Machinie
//             </div>
//             <div
//               className="cursor-pointer"
//               onClick={() => {
//                 setIsOpen3(false);
//               }}
//             >
//               <i className="fa fa-times close-modal"></i>
//             </div>
//           </div>
//         </div>

//         <div className="p-24">
//           <div className="bold-button text-color-line-tint-04 mb-12">
//             {machinieNotSelect.length}{" "}
//             {machinieNotSelect.length > 1 ? "MACHINIES" : "MACHINIE"}
//           </div>
//           <div className="humach-for-stake-each-container">
//             {machinieNotSelect.map((x, index) => (
//               <MachinieForStakeEach
//                 index={index}
//                 machinieId={x}
//                 setIsOpen={setIsOpen3}
//                 objectPair={objectPair}
//                 setObjectPair={setObjectPair}
//               />
//             ))}
//           </div>
//         </div>
//       </Modal3>
//     </div>
//   );
// };

export const ModalPairStakeSelectedMachinie = ({
  index,
  setIsOpen3,
  isOpen3,
  objectPair,
  setObjectPair,
  machinieNotSelect,
}: MODAL_PAIR_STAKE_EACH_NOT_SELECT_MACHINIE) => {
  return (
    <div>
      <div className="modal-pair-stake-each-machinie cursor-pointer">
        <div>
          <img
            src={machinie_image_url[Number(objectPair[index]["machinieId"])]}
            className="modal-pair-stake-each-machinie-image"
            width="50px"
          ></img>
        </div>
        <div className="bold-subtitle-2 text-color-tertiary m-auto-tb">
          Machinie #{objectPair[index]["machinieId"]}
        </div>
        {/* <div className="text-color-tertiary  m-auto-tb">
          <i className="fa fa-caret-down"></i>
        </div> */}
      </div>
    </div>
  );
};
