import { ethers } from "ethers";
import { machinie_image_url } from "../../../machinieImageUrl2";
import { useState, useEffect } from "react";
import { humachLogo } from "../../../imageForMachiniverse";
import { api } from "../../../axiosURL";

import axios from "axios";
import machinieUpgrade from "../../../machinieUpgrade";
import { MyNftMore } from "../MyNftMore/MyNftMore";
import { CONTRACT_TOKEN } from "../../../value";

declare var window: any;

type MACHINIE_STAKING_EACH_PROPS_TYPES = {
  machinieId: string;
  setSelectForUnstake: any;
  selectForUnstake: string[];
  setWalletList: any;
  machinieListEach: any;
  setMachinieList: any;
  selectIdMoreToggle: string;
  setSelectIdMoreToggle: any;
  floppy: any;
  setFloppy: any;
};

export const MachinieStakingEach = ({
  machinieId,
  setSelectForUnstake,
  selectForUnstake,
  setWalletList,
  machinieListEach,
  setMachinieList,
  selectIdMoreToggle,
  setSelectIdMoreToggle,
  floppy,
  setFloppy,
}: MACHINIE_STAKING_EACH_PROPS_TYPES) => {
  const floppy_image: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`;
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [humachWith, setHumachWith] = useState<string>(humachLogo);
  const [floppyToClaim, setFloppyToClaim] = useState("0");

  const [isSelect, setIsSelect] = useState(false);
  const [traitsType, setTraitsType] = useState("");

  const [IsClaiming, setClaiming] = useState(false);
  const [tokenName, setTokenName] = useState("");

  useEffect(() => {
    getMachinieTrait();
    getCurrentVal();
    getTokenNameMachinie(machinieId);
  }, []);

  useEffect(() => {
    if (selectForUnstake.includes(machinieId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectForUnstake]);

  const getCurrentVal = async () => {
    setClaiming(false);

    let humachStakeWith = await machinieUpgrade.methods
      .getHumachTokenId(machinieId)
      .call();

    let humachImage = await getImage(humachStakeWith.toString());
    setHumachWith(humachImage);
    let floppyToClaimEach = await getFloppyToClaim();

    setFloppyToClaim(floppyToClaimEach);
  };

  const getTokenNameMachinie = async (tokenId: string | undefined) => {
    try {
      const res = await machinieUpgrade.methods.getTokenIdName(tokenId).call();
      setTokenName(
        res[0] !== "" ? res[0] + " #" + tokenId : "Machinie #" + tokenId
      );
      return;
    } catch (err) {}
  };

  const getFloppyToClaim = async () => {
    let floppyToClaim = await machinieUpgrade.methods
      .getStakeReward(machinieId)
      .call();
    const convert = require("ether-converter");
    const result = convert(Number(floppyToClaim.toString()), "wei", "ether");
    const toNumber = Number(result).toFixed(2);

    return toNumber.toString();
  };

  const getImage = async (humachId: string) => {
    const res = await axios.get(
      "https://api.machinienft.com/api/humach/metadata/" + humachId
    );
    const getImageArray = res.data.image_list;

    const getImage = getImageArray[getImageArray.length - 1]["image"];

    const getIpfsImage = getImage.replace(
      "https://gateway.pinata.cloud/ipfs/",
      "https://ipfs.io/ipfs/"
    );
    // setHumachWith(getIpfsImage);
    return getImage;
    // return getIpfsImage;
  };

  const selectToUnstake = () => {
    const getSelectArray = [...selectForUnstake];
    getSelectArray.push(machinieId);
    setSelectForUnstake(getSelectArray);
  };

  const removeSelect = () => {
    const getSelectArray = [...selectForUnstake];
    var filteredArray = getSelectArray.filter((e) => e !== machinieId);
    //console.log(filteredArray);
    setSelectForUnstake(filteredArray);
  };

  const onClickClaim = async () => {
    setClaiming(true);
    const url = `/api/machinie/claim/${machinieListEach["id"]}`;
    const res = await api.post(
      url,
      {},
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );

    const thisMachinieList = await getMachinieList();
    const thisWalletList = await getWalletList();
    setMachinieList(thisMachinieList.data);
    setWalletList(thisWalletList.data);
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // //console.log(res.data);
      return res.data;
    }
  };

  const getMachinieTrait = async () => {
    const res: any = await api.get(
      "https://rest-api-machinie-nft.herokuapp.com/tokens/" + machinieId
    );

    if (res["data"]["traits"] !== undefined) {
      setTraitsType(res["data"]["traits"][3]["value"]);
    } else {
      setTraitsType("Dead token");
    }
  };

  return (
    <div
      className={
        !isSelect
          ? "machinie-each-container"
          : "machinie-each-container-selected"
      }
    >
      {isSelect ? (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(false);
            removeSelect();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-check-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      ) : (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(true);
            selectToUnstake();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      )}

      <div className="machinie-each-detail">
        <div className="display-mobile">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(machinieId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>
        <div className="border-left-mobile">
          <div className="text-color-gray-100 reg-caption machinie-each-text-second-container-stake">
            <div className=" machinie-each-text-second">{traitsType}</div>
            <div className=" machinie-each-text-second border-text-stake">
              Staking
            </div>
          </div>

          <div className="thrid-stake-machinie-each">
            <div className="img-humach-mini-staking">
              <img
                src={humachWith}
                alt="humach"
                width="32px"
                className="img-humach-mini"
              ></img>
            </div>

            <div className="button-claim-current-container">
              <div className="bold-caption text-color-gray-100">
                {floppyToClaim}{" "}
                <span>
                  <img src={floppy_image} alt="current" width="13.5px"></img>
                </span>
              </div>
            </div>
          </div>

          <div className="display-mobile">
            <div className="claim-current-contianer">
              {!machinieListEach["isClaim"] ? (
                IsClaiming ? (
                  <div className="claim-current-padding">
                    <div className="claimed-current">
                      <div className="bold-button text-color-gray-100">
                        CLAIMING..
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="claim-current-padding ">
                    <div
                      className="claim-current cursor-pointer"
                      onClick={() => onClickClaim()}
                    >
                      <div className="bold-button text-color-gray-100">
                        CLAIM 10{" "}
                        <span>
                          <img src={current} alt="current" width="13.5px"></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <MyNftMore
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                id={machinieId}
                contractToken={CONTRACT_TOKEN.MACHINIE}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            </div>
          </div>
        </div>

        <div className="display-desktop">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(machinieId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="display-desktop">
          <div className="claim-current-contianer">
            {!machinieListEach["isClaim"] ? (
              IsClaiming ? (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIMING..
                    </div>
                  </div>
                </div>
              ) : (
                <div className="claim-current-padding ">
                  <div
                    className="claim-current cursor-pointer"
                    onClick={() => onClickClaim()}
                  >
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="claim-current-padding">
                <div className="claimed-current">
                  <div className="bold-button text-color-gray-100">
                    CLAIM 10{" "}
                    <span>
                      <img src={current} alt="current" width="13.5px"></img>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <MyNftMore
              selectIdMoreToggle={selectIdMoreToggle}
              setSelectIdMoreToggle={setSelectIdMoreToggle}
              id={machinieId}
              contractToken={CONTRACT_TOKEN.MACHINIE}
              floppy={floppy}
              setFloppy={setFloppy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
