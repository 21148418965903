import { Link } from "react-router-dom";
import { MachinieSlide } from "./slides/MachinieSlide";

export const HomeMachinieSection = () => {
  const logo_machinie: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-machinie-l.png`;

  return (
    <section
      className="home-machinie-section-container section"
      //   style={{
      //     backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/bg.gif)`,
      //   }}
    >
      <div className="home-machinie-padding">
        <div className="container pb-5">
          <div>
            <img src={logo_machinie} alt="logo_machinie_l"></img>
          </div>
          <div className="reg-body-2 text-color-secondary mt-24">
            Machinie (mah • shee • nee)
          </div>
          <div className="justify-between-responsive mt-14">
            <div className="first-child-res bold-headline-6 text-color-primary-200">
              Highly advanced, ancient technological artifacts from a long
              forgotten age.
            </div>
            <div className="second-child-res">
              <Link
                to="/machinie"
                className="text-button text-machinie button-machinie"
              >
                LEARN MORE & GET SOME!
              </Link>
            </div>
          </div>
        </div>

        <MachinieSlide position={true} />
      </div>
    </section>
  );
};
