import { useEffect } from "react";
import { API } from "../../../valueApi";
import { api } from "../../../axiosURL";

export const TestFunction = () => {
  useEffect(() => {
    getActionLog();
  }, []);
  const getActionLog = async () => {
    try {
      const res = await api.post(
        API.ACTION_LOG.SEARCH,
        { offset: 0, limit: 10, updateDate: -1 },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log(res);
    } catch (err: any) {
      console.log(err);
    }
  };
  return <div></div>;
};
