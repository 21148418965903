import { useEffect, useState } from "react";

export const RoadmapSection = () => {
  return (
    <div className="bg-1 section" id="roadmap">
      <div className="container">
        <div className="roadmap-container">
          <RoadmapSticky />
          <RoadmapEachContainer />
        </div>
      </div>
    </div>
  );
};

export const RoadmapSticky = () => {
  const roadmap_logo: string = `${process.env.PUBLIC_URL}/assets/roadmap/roadmap_logo.png`;
  //   /Users/hathumkittitus/machiniverse-project/public/assets/roadmap/roadmap_logo.png
  return (
    <div className="roadmap-logo-container">
      <div>
        <img src={roadmap_logo} alt="roadmap_logo"></img>
      </div>
      <div className="reg-body-2 text-color-secondary mt-24">Roadmap</div>
      <div className="text-color-primary-200 bold-headline-6 mt-14">
        History of Machiniverse
        <br />
        It all began in August 2021
      </div>
    </div>
  );
};

export const RoadmapEachContainer = () => {
  return (
    <div className="roadmap-each-container">
      <RoadmapEach
        position={"right"}
        topic={"Energy Leak"}
        date="12 Aug 2021"
        id="1"
        description="The very first Machinies got through the portal from the Machiniverse. Eight precious Machinies were distorted during the trip, a few more were rescued by the team and the rest were owned for free."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/001.gif`}
      />
      <RoadmapEach
        position={"left"}
        topic={"Private Sale"}
        date="2 Sep 2021"
        id="2"
        description="A private sale for Machinies released and was available to only those chosen few who were deemed worthy; each participant who minted at least 15 Machinies received a free irl hand-made rug."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/002.gif`}
      />
      <RoadmapEach
        position={"right"}
        topic={"Genesis Machinie Sold out"}
        date="18 Sep 2021"
        id="3"
        description="Our public sale was opened and within a short time, our genesis collection sold out."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/003.gif`}
      />
      <RoadmapEach
        position={"left"}
        topic={"Machinie Lotto Launched"}
        date="8 Oct 2021"
        id="4"
        description="Our first reward system for our owners was launched! The Lotto prizes range from one-of-one Machinies to collab pieces and they dropped on the 8th and 18th of each month."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/004.gif`}
      />
      <RoadmapEach
        position={"right"}
        topic={"Genesis game and Economy"}
        date=""
        // 2 Nov 2021
        id="5"
        description="Machinies will enable owners to gather centralized tokens ($current) through a game of luck to entertain and delight with rewards like more tokens or real world merch."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/005.gif`}
      />
      <RoadmapEach
        position={"left"}
        topic={"Derivative Art Competition"}
        date=""
        id="6"
        description="Machinie avatars will travel here from across the galaxy. Their purpose is to search and conquer Machinies. Take part in our derivative art competition and show us your version of how they would look!"
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/006.gif`}
      />
      <RoadmapEach
        position={"right"}
        topic={"Contract Migration"}
        date=""
        id="7"
        description="Genesis Machinies will migrate to another contract in preparation of our launch of a full economy."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/007.gif`}
      />
      <RoadmapEach
        position={"left"}
        topic={"Machinie Avatar"}
        date=""
        id="8"
        description="Machinie avatars will hit the market! The release of Machinie avatars marks the ability to grow, breed, and hunt for Machinies."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/008.gif`}
      />
      <RoadmapEach
        position={"right"}
        topic={"Machiniverse Ecosystem"}
        date=""
        id="9"
        description="Genesis Machinies and avatars will be locked; staking, leveling, and breeding begins. $floppy will begin to act as our currency in the Machiniverse ecosystem!"
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/009.gif`}
      />
      <RoadmapEach
        position={"left"}
        topic={"Metaverse Invasion"}
        date=""
        id="10"
        description="Project integration began. Machinie and Humach in a form of playble asset in Metaverse."
        gifUrl={`${process.env.PUBLIC_URL}/assets/roadmap/gif/010.gif`}
      />
    </div>
  );
};

type ROADMAPEACH_TYPES = {
  position: string;
  topic: string;
  date: string;
  gifUrl: string;
  description: string;
  descriptionfull?: string;
  id: string;
};

export const RoadmapEach = ({
  position,
  topic,
  date,
  gifUrl,
  description,
  descriptionfull,
  id,
}: ROADMAPEACH_TYPES) => {
  const [windowSize, setWindowSize] = useState<number>(0);

  const updateDimensions = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    setWindowSize(window.innerWidth);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <>
      {position === "right" ? (
        <div className="roadmap-each pt-1 pb-1">
          <div className="roadmap-none"></div>
          <MidNumberRoadmap id={id} />
          <RoadmapPanelEach
            gifUrl={gifUrl}
            id={id}
            topic={topic}
            date={date}
            description={description}
            descriptionfull={descriptionfull}
          />
        </div>
      ) : (
        <div className="roadmap-each ">
          {windowSize < 601 ? <MidNumberRoadmap id={id} /> : ""}
          <RoadmapPanelEach
            gifUrl={gifUrl}
            id={id}
            topic={topic}
            date={date}
            description={description}
            descriptionfull={descriptionfull}
          />
          {windowSize > 601 ? <MidNumberRoadmap id={id} /> : ""}
          <div className="roadmap-none"></div>
        </div>
      )}
    </>
  );
};

type ROADMAP_PANEL_EACH_TYPES = {
  topic: string;
  date: string;
  gifUrl: string;
  description: string;
  descriptionfull?: string;
  id: string;
};

export const RoadmapPanelEach = ({
  gifUrl,
  id,
  topic,
  date,
  description,
  descriptionfull,
}: ROADMAP_PANEL_EACH_TYPES) => {
  const [bool, setBool] = useState(false);

  const swichBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
    } else {
      setBool(true);
    }
  };

  const reduceDescription = () => {
    let newText = description.slice(0, 99);

    return newText + "...";
  };
  return (
    <div className="roadmap-panel-each-container">
      <div className="text-center">
        <img src={gifUrl} alt={"roadmap_machinie_" + id} width="290px"></img>
      </div>
      <div className="text-gold text-machinie font-bold pt-3">{topic}</div>
      {date !== "" ? (
        <div className="text-machinie text-sub pt-2">{date}</div>
      ) : (
        ""
      )}

      {description.length >= 100 ? (
        <div className="text-white text-machinie pt-2">
          {!bool ? reduceDescription() : description}
          {!bool ? (
            <div
              className="text-machinie text-main font-bold-mini mt-2 cursor-pointer"
              onClick={() => swichBool(bool)}
            >
              READ MORE{" "}
              <span>
                <i className="fa fa-plus"></i>
              </span>
            </div>
          ) : (
            <div
              className="text-machinie text-main font-bold-mini mt-2 cursor-pointer"
              onClick={() => swichBool(bool)}
            >
              READ LESS{" "}
              <span>
                <i className="fa fa-times"></i>
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="text-white text-machinie pt-2">{description}</div>
      )}
    </div>
  );
};

type MID_NUMBER_ROADMAP_TYPES = {
  id: string;
};

export const MidNumberRoadmap = ({ id }: MID_NUMBER_ROADMAP_TYPES) => {
  return (
    <div className={id !== "10" ? "line-container" : "line-container-last"}>
      <div className="id-line">
        <div className="text-sub font-bold-mini m-auto text-center id-line-text">
          {id}
        </div>
      </div>
      <div className="line-line"></div>
      {id !== "10" ? (
        ""
      ) : (
        <div className="id-line">
          <div className="dot-box-container">
            <div className="m-auto dot-box"></div>
          </div>
        </div>
      )}
    </div>
  );
};
