import { machinie_image_url } from "../../../machinieImageUrl2";
import { api } from "../../../axiosURL";
import { useState, useEffect } from "react";
import { MyNftMore } from "../MyNftMore/MyNftMore";
import { LoadingDots } from "../../component/LoadingDots";

type MACHINIE_NEED_MIGRATE_EACH = {
  machinieId: string;
  isApprovedForAllBool: boolean;
  setSelectForMigrate: any;
  selectForMigrate: string[];
  setMachinieList: any;
  machinieListEach: any;
  setWalletList: any;
};

export const MachinieNeedMigrateEach = ({
  machinieId,
  machinieListEach,
  isApprovedForAllBool,
  setSelectForMigrate,
  selectForMigrate,
  setMachinieList,
  setWalletList,
}: MACHINIE_NEED_MIGRATE_EACH) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [isSelect, setIsSelect] = useState(false);
  const [traitsType, setTraitsType] = useState("");
  const [IsClaiming, setClaiming] = useState(false);
  // const [isClaim, setIsClaim] = useState(false);

  useEffect(() => {
    if (selectForMigrate.includes(machinieId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectForMigrate]);

  useEffect(() => {
    getMachinieTrait();
  }, []);

  const onClickselectForMigrate = () => {
    if (isApprovedForAllBool) {
      const getSelectForMigrate = [...selectForMigrate];
      getSelectForMigrate.push(machinieId);
      setSelectForMigrate(getSelectForMigrate);
      //console.log(getSelectForMigrate);
    }
  };

  const removeSelect = () => {
    if (isApprovedForAllBool) {
      const getSelectArray = [...selectForMigrate];
      var filteredArray = getSelectArray.filter((e) => e !== machinieId);
      setSelectForMigrate(filteredArray);
      //console.log(filteredArray);
    }
  };

  const onClickClaim = async () => {
    setClaiming(true);
    const url = `/api/machinie/claim/${machinieListEach["id"]}`;
    const res = await api.post(
      url,
      {},
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );

    const thisMachinieList = await getMachinieList();
    const thisWalletList = await getWalletList();
    setMachinieList(thisMachinieList.data);
    setWalletList(thisWalletList.data);
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      return res.data;
    }
  };

  const getMachinieTrait = async () => {
    const res: any = await api.get(
      "https://rest-api-machinie-nft.herokuapp.com/tokens/" + machinieId
    );

    if (res["data"]["traits"] !== undefined) {
      setTraitsType(res["data"]["traits"][3]["value"]);
    } else {
      setTraitsType("Dead token");
    }
  };

  return (
    <div
      className={
        !isSelect
          ? "machinie-each-container"
          : "machinie-each-container-selected"
      }
    >
      {isSelect ? (
        <div
          className={
            isApprovedForAllBool
              ? "machinie-each-top cursor-pointer"
              : "machinie-each-top-not-select"
          }
          onClick={() => {
            if (isApprovedForAllBool) {
              setIsSelect(false);
              removeSelect();
            }
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            Machinie#{machinieId}
          </div>
          <div className="check-box-container">
            <i className="fa fa-check-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      ) : (
        <div
          className={
            isApprovedForAllBool
              ? "machinie-each-top cursor-pointer"
              : "machinie-each-top-not-select"
          }
          onClick={() => {
            if (isApprovedForAllBool) {
              setIsSelect(true);
              onClickselectForMigrate();
            }
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            Machinie#{machinieId}
          </div>
          <div className="check-box-container">
            <i className="fa fa-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      )}
      <div className="machinie-each-detail">
        <div className="display-mobile">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(machinieId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="border-left-mobile">
          <div className="text-color-gray-100 reg-caption machinie-each-text-second-container">
            <div className=" machinie-each-text-second">{traitsType}</div>
            <div className=" machinie-each-text-second border-text">
              Need Migrate
            </div>
          </div>
          <div className="display-mobile">
            <div className="claim-current-contianer">
              {!machinieListEach["isClaim"] ? (
                IsClaiming ? (
                  <div className="claim-current-padding">
                    <div className="claimed-current">
                      <div className="bold-button text-color-gray-100">
                        CLAIMING
                        <LoadingDots />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="claim-current-padding ">
                    <div
                      className="claim-current cursor-pointer"
                      onClick={() => onClickClaim()}
                    >
                      <div className="bold-button text-color-gray-100">
                        CLAIM 10{" "}
                        <span>
                          <img src={current} alt="current" width="13.5px"></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* <MyNftMore /> */}
            </div>
          </div>
        </div>

        <div className="display-desktop">
          <div className="img-machinie-each-container">
            <img
              src={machinie_image_url[Number(machinieId)]}
              alt="machinie"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="display-desktop">
          <div className="claim-current-contianer">
            {!machinieListEach["isClaim"] ? (
              IsClaiming ? (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIMING
                      <LoadingDots />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="claim-current-padding ">
                  <div
                    className="claim-current cursor-pointer"
                    onClick={() => onClickClaim()}
                  >
                    <div className="bold-button text-color-gray-100">
                      CLAIM 10{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="claim-current-padding">
                <div className="claimed-current">
                  <div className="bold-button text-color-gray-100">
                    CLAIM 10{" "}
                    <span>
                      <img src={current} alt="current" width="13.5px"></img>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* <MyNftMore /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
