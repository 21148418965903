import { useEffect, useState } from "react";
import humach from "../../../humach";
import { Modal } from "../../modal/Modal";
import { ModalPairStakeEach } from "./ModalPairStakeEach";
import web3 from "../../../web3";
import { api } from "../../../axiosURL";
import machinieUpgrade from "../../../machinieUpgrade";
import { LoadingDots } from "../../component/LoadingDots";

export const ModalPairStake = ({
  isOpen,
  setIsOpen,
  humachChilling,
  machinieChilling,
  objectPair,
  setObjectPair,
  setMachinieList,
  setHumachList,
}: MODAL_PAIR_STAKE_PROPS_TYPES) => {
  const [humachNotSelect, setHumachNotSelect] = useState(humachChilling);
  const [machinieNotSelect, setMachinieNotSelect] = useState(machinieChilling);

  const [countFill, setCountFill] = useState(0);

  const [messageError, setMessageError] = useState(false);
  const [isSetStaking, setIsSetStaking] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [stakeSuccess, setStakeSuccess] = useState(false);

  useEffect(() => {
    setCountFill(checkObjectPairFillAll());
    updateHumachNotSelected();
    updateMachinieNotSelected();
    setMessageError(false);
  }, [objectPair]);

  const checkObjectPairFillAll = () => {
    let count = 0;
    for (let i = 0; i < objectPair.length; i++) {
      if (
        objectPair[i]["humachId"] !== undefined &&
        objectPair[i]["machinieId"] !== undefined
      ) {
        count++;
      }
    }
    return count;
  };

  const updateHumachNotSelected = () => {
    const getHumachNotSelectList = [...humachChilling];
    let humachSelected: string[] = [];
    for (let i = 0; i < objectPair.length; i++) {
      if (objectPair[i]["humachId"]) {
        humachSelected.push(objectPair[i]["humachId"] || "");
      }
    }

    const difference = getHumachNotSelectList.filter(
      (el) => !humachSelected.includes(el)
    );
    // //console.log("humachNotSelect", difference);

    setHumachNotSelect(difference);
  };

  const updateMachinieNotSelected = () => {
    const getMachiniNotSelectList = [...machinieChilling];
    let machinieSelected: string[] = [];
    for (let i = 0; i < objectPair.length; i++) {
      if (objectPair[i]["machinieId"]) {
        machinieSelected.push(objectPair[i]["machinieId"] || "");
      }
    }

    const difference = getMachiniNotSelectList.filter(
      (el) => !machinieSelected.includes(el)
    );
    // //console.log("machinieNotSelect", difference);

    setMachinieNotSelect(difference);
  };

  const returnMadol = () => {
    return objectPair.map((x, index) => (
      <ModalPairStakeEach
        index={index}
        humachNotSelect={humachNotSelect}
        objectPair={objectPair}
        objectPairEach={x}
        setObjectPair={setObjectPair}
        machinieNotSelect={machinieNotSelect}
      />
    ));
  };

  const fillMessage = () => {
    return messageError ? (
      <div className="pb-4 text-center text-color-secondary reg-caption">
        {"please select humach in every slot"}
      </div>
    ) : (
      ""
    );
  };

  const stakeMachinies = async () => {
    setIsSetStaking(true);
    setMessageError(false);
    setErrMessage("");
    const getObjectPair = [...objectPair];
    const machinieSelectedArray: string[] = [];
    const humachSelectedArray: string[] = [];
    for (let i = 0; i < getObjectPair.length; i++) {
      machinieSelectedArray.push(getObjectPair[i]["machinieId"] || "");
      humachSelectedArray.push(getObjectPair[i]["humachId"] || "");
    }

    try {
      const accounts = await web3.eth.getAccounts();
      await machinieUpgrade.methods
        .stakeMachinie(machinieSelectedArray, humachSelectedArray)
        .send({
          from: accounts[0],
        });

      const thisMachinieList = await getMachinieList();
      const thisHumachList = await getHumachList();
      setMachinieList(thisMachinieList.data);
      setHumachList(thisHumachList.data);
      setStakeSuccess(true);
      setIsSetStaking(false);
    } catch (err: any) {
      //console.log(err.message);
      setErrMessage(err.message);
      setIsSetStaking(false);
    }

    //console.log(machinieSelectedArray);
    //console.log(humachSelectedArray);
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setHumachNotSelect(humachChilling);
        setErrMessage("");
        setStakeSuccess(false);
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">Stake</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setMessageError(false);
            setHumachNotSelect(humachChilling);
            setErrMessage("");
            setStakeSuccess(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      {stakeSuccess ? (
        <div>
          <div className="p-24">
            <div className="bold-headline-5 text-color-secondary text-center">
              STAKING SUCCESS!
            </div>
          </div>

          <div className="modal-stake-bottom-container-alone">
            <div
              className="modal-stake-button-cancel bold-button"
              onClick={() => {
                setIsOpen(false);
                setMessageError(false);
                setHumachNotSelect(humachChilling);
                setErrMessage("");
                setStakeSuccess(false);
              }}
            >
              Close
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="modal-pair-stake-contianer">{returnMadol()}</div>

          {errMessage !== "" ? (
            <div className="p-lr-24 mb-24">
              <div className="reg-caption text-color-secondary">
                {errMessage}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="modal-stake-bottom-container">
            <div
              className="modal-stake-button-cancel bold-button"
              onClick={() => {
                setIsOpen(false);
                setMessageError(false);
                setHumachNotSelect(humachChilling);
                setErrMessage("");
                setStakeSuccess(false);
              }}
            >
              CANCEL
            </div>
            {countFill >= objectPair.length && objectPair.length > 0 ? (
              !isSetStaking ? (
                <div
                  className="modal-stake-button-stake-selected cursor-pointer bold-button"
                  onClick={() => stakeMachinies()}
                >
                  STAKE
                </div>
              ) : (
                <div
                  className="modal-stake-button-stake-selected cursor-pointer bold-button"
                  onClick={() => stakeMachinies()}
                >
                  STAKING
                  <LoadingDots />
                </div>
              )
            ) : (
              <div
                className="modal-stake-button-stake-select bold-button"
                onClick={() => setMessageError(true)}
              >
                STAKE
              </div>
            )}
          </div>

          {fillMessage()}
        </div>
      )}
    </Modal>
  );
};

type MODAL_PAIR_STAKE_PROPS_TYPES = {
  isOpen: boolean;
  setIsOpen: any;
  machinieArray: string[];
  humachChilling: string[];
  objectPair: {
    machinieId?: string | undefined;
    humachId?: string | undefined;
  }[];
  setObjectPair: any;
  machinieChilling: string[];
  setMachinieList: any;
  setHumachList: any;
};
