import { IMAGE_ASSETS } from "../../const/imageAssetsLink";

const SUBMISSION_START = 1712595600;
const SUBMISSION_END = SUBMISSION_START + 85800;
const RAFFLE_TIME = SUBMISSION_START + 86400;

export const LOTTO_VALUE = {
  NEXTROUND: 1709917200, //last submission //put last sumission start
  SUBMISSION_START: SUBMISSION_START, //put submission start next
  SUBMISSION_END: SUBMISSION_END, //wait for decision of date
  RAFFLE_TIME: RAFFLE_TIME,
  SUBMISSION_START_NEXT: 1715101200,

  NEXT_SUBMISSION: "8 May 2024", //usually 7
  CURRENT_DROP: "9 Apr 2024", //usually 8
  SUBMISSION_DATE: "8 Apr 2024",//usually 7
  LAST_DROP: "9 Mar 2023",
  //immortant for lotto is to find const lottoPlayer
  CONFIG_ID: "661410760946350aec6d04ef", //
  LAST_CONFIG_ID: "65eb2b4d0946350aec6cf815", //
  REWARD: {
    CURRENT_REWARD: {
      IMAGE: IMAGE_ASSETS.LOTTO.RETRO_MACH_2024,
      NAME: "RetroMach 2024",
      SET: [],
    },
    LAST_REWARD: {
      IMAGE: IMAGE_ASSETS.LOTTO.MACHINOM_2024,
      NAME: "Machinom 2024",
      SET: [],
    },
  },

  MAX_ENTRY: "30",
};

//Raffle_time = 
//Submission_start = raffle_time - 86400
//SUBMISSION_END = Raffle_time - 600

export const LOTTO_ACTION_IMAGE = {
  ACTION_1: `${process.env.PUBLIC_URL}/assets/lotto/lotto_1.gif`,
  ACTION_2: `${process.env.PUBLIC_URL}/assets/lotto/lotto_2.gif`,
  ACTION_3: `${process.env.PUBLIC_URL}/assets/lotto/lotto_3.gif`,
  ACTION_4: `${process.env.PUBLIC_URL}/assets/lotto/lotto_4.gif`,
  ACTION_5: `${process.env.PUBLIC_URL}/assets/lotto/lotto_5.gif`,
};

export const LOTTO_QUEUE = {
  BeforeSubmission: "BeforeSubmission",
  SubmissionClose: "SubmissionClose",
  Submission: "Submission",
  LottoWinnerLast: "LottoWinnerLast",
  LottoWinner: "LottoWinner",
};
