export const WantSomeMachinieSection = () => {
  const logo_opensea_white: string = `${process.env.PUBLIC_URL}/assets/logo/logo_opensea_white.png`;

  return (
    <section className="section" id="buy-machinie-section">
      <div className="container">
        <div className="want-some-container-border bg-1">
          <div className="text-machinie want-some-container">
            <div>
              <div className="bold-headline-4 text-color-primary-200">
                SOLD OUT!
              </div>
              <div className="text-color-gray-100 reg-body-2 mt-14 width-sold-out">
                Machinie primary sale has been sold out. You can get some from
                secondary markets like OpenSea or Looksrare.
              </div>
            </div>
            <div className="m-auto-tb">
              <GetMachinieOnOpensea />
              <div className="mt-14">
                <GetMachinieOnLookrare />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const GetMachinieOnOpensea = () => {
  return (
    <a
      href="https://opensea.io/collection/machinie"
      className="button-my-machinie"
      target="_blank"
    >
      <div className="m-auto-tb text-color-tertiary bold-button">
        BUY ON OPENSEA
      </div>
    </a>
  );
};

export const GetMachinieOnLookrare = () => {
  const logo_opensea_white: string = `${process.env.PUBLIC_URL}/assets/logo/logo_opensea_white.png`;

  return (
    <a
      href="https://looksrare.org/collections/0xB826bDe739897ad50363d045d65eE5b83FDb730d?queryID=d34b3daddff7915024af2282599fa8ca&queryIndex=prod_tokens"
      className="button-get-machinie"
      target="_blank"
    >
      <div className="m-auto-tb text-color-gray-100 bold-button">
        BUY ON LOOKSRARE
      </div>
    </a>
  );
};
