import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

export const ButtonGetMachinie = () => {
  const logo_opensea_white: string = `${process.env.PUBLIC_URL}/assets/logo/logo_opensea_white.png`;

  return (
    <Link
      to="buy-machinie-section"
      className="button-get-machinie"
      offset={-100}
    >
      <div className="m-auto-tb text-color-gray-100 bold-button">BUY</div>
    </Link>
  );
};
