import { useState, useEffect } from "react";

type REDER_NUMBER_DIV = {
  num: number;
};

export const AnimateNumber = ({ num }: REDER_NUMBER_DIV) => {
  const [thisNum, setThisNum] = useState<number>(num);
  const [legnth, setLength] = useState<number>(0);

  //   useEffect(() => {
  //     setThisNum(num);
  //   }, [num]);

  useEffect(() => {
    delayNumDiv();
    rederNumb();
  }, [num]);

  const delayNumDiv = async () => {
    setLength(num - thisNum);
    await sleep(2000);
    setLength(0);
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const rederNumb = async () => {
    let getLenght = Number(num - thisNum);
    let toDivine: number = 5;

    let getNum = thisNum;

    let percen = (getLenght / toDivine).toFixed(0);

    for (let i = 1; i <= toDivine; i++) {
      const perCen = Number(percen) * i;
      setThisNum(getNum + perCen);
      await sleep(50);
    }
    setThisNum(num);
  };

  return (
    <div className="this-num-container">
      <div>{thisNum}</div>

      {legnth === 0 ? (
        ""
      ) : legnth > 0 ? (
        <div className="this-num-div-plus"> +{legnth}</div>
      ) : (
        <div className="this-num-div-minus"> {legnth}</div>
      )}
    </div>
  );
};
