export const BeforeFooterSection = () => {
  const ship: string = `${process.env.PUBLIC_URL}/assets/spaceship/ship.gif`;

  return (
    <section className="section">
      <div className="container text-center">
        <div className="text-center">
          <img src={ship} width="80px" className=""></img>
        </div>
        <div className="text-machinie text-gold pt-3">
          In a galaxy far, far away...
        </div>
        <div className="text-machinie font-bold text-main pt-3">
          You have reached the edge of the galaxy.
        </div>

        <button
          className="button-to-top mt-4"
          onClick={() => window.scrollTo(0, 0)}
        >
          <div className="m-2 text-white text-machinie">
            {" "}
            TO THE TOP{" "}
            <span>
              <i className="fa fa-arrow-up"></i>
            </span>
          </div>
        </button>
      </div>
    </section>
  );
};
