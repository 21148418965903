import {
  TEAM_LINK_CONTACT,
  TEAM_LINK_TYPE,
  TEAM_PFP,
} from "../../../const/ConstContainer";
import { machinie_image_url } from "../../../machinieImageUrl2";

export const TeamSection = () => {
  return (
    <section className="section" id="teams">
      <div className="container">
        <div className="text-machinie text-center">
          <div className="text-gold">Our Team</div>
          <div className="text-main font-bold-xl pt-3">
            Nothing great is made alone.
          </div>
        </div>

        <TeamPanel />
      </div>
    </section>
  );
};

export const TeamPanel = () => {
  return (
    <div className="team-panel-container text-center pt-5">
      <MemberEach
        position="Genesis Creator"
        name="KiNG"
        imgUrl={TEAM_PFP.king}
        linkContact={TEAM_LINK_CONTACT.king}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />{" "}
      <MemberEach
        position="Humach Assembler"
        name="Kumz"
        imgUrl={TEAM_PFP.pkumz}
        linkContact={TEAM_LINK_CONTACT.pkumz}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />
      <MemberEach
        position="Lead Dev"
        name="hatang"
        imgUrl={TEAM_PFP.hathum}
        linkContact={TEAM_LINK_CONTACT.hathum}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />
      <MemberEach
        position="Crypto Dev"
        name="Pla"
        imgUrl={TEAM_PFP.ppla}
        linkType={TEAM_LINK_TYPE.LINKIN}
      />
      <MemberEach
        position="Backend Dev"
        name="Plzdontcry"
        imgUrl={TEAM_PFP.plzdontcry}
        linkContact={TEAM_LINK_CONTACT.plzdontcray}
        linkType={TEAM_LINK_TYPE.LINKIN}
      />
      <MemberEach
        position="Community Manager"
        name="XtheOWL"
        imgUrl={TEAM_PFP.xtheowl}
        linkContact={TEAM_LINK_CONTACT.xtheowl}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />
      <MemberEach
        position="Community Manager"
        name="TOP"
        imgUrl={TEAM_PFP.top}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />
      <MemberEach
        position="Community Manager"
        name="NFTChief"
        imgUrl={TEAM_PFP.nftchief}
        linkContact={TEAM_LINK_CONTACT.nftchief}
        linkType={TEAM_LINK_TYPE.TWITTER}
      />
    </div>
  );
};

type MEMBER_EACH_TYPES = {
  position: string;
  imgUrl?: string;
  name: string;
  linkContact?: string;
  linkType: string;
};

export const MemberEach = ({
  position,
  imgUrl,
  name,
  linkContact,
  linkType,
}: MEMBER_EACH_TYPES) => {
  const twitter_white_logo: string = `${process.env.PUBLIC_URL}/assets/logo/twitter-white.png`;

  return (
    <div className="member-each-container text-machinie">
      <div className="text-position-container">
        <div className="text-center text-white  text-positon">{position}</div>
      </div>

      <div className="pfp-image-container">
        <img src={imgUrl} alt="pfp" className="pfp-image"></img>
      </div>

      <div className="member-contact-container">
        <div className="member-contact-display">
          <div className="text-bold-s padding-member-contact text-white text-center">
            {name}
          </div>
          <a
            className="twitter-team-contact-container padding-member-contact"
            href={linkContact}
            target="_blank"
            rel="noreferrence"
          >
            {linkType === TEAM_LINK_TYPE.LINKIN ? (
              <i className="fas fa-link text-color-gray-100"></i>
            ) : (
              <img src={twitter_white_logo} alt="twitter logo"></img>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};
