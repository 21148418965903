import { FooterSection } from "../homepage/footer/FooterSection";
import { Link } from "react-router-dom";
import { SELECTING } from "../../value";

type SIDE_NAV_TYPES = {
  selecting: string;
};

export const SideNav = ({ selecting }: SIDE_NAV_TYPES) => {
  return (
    <div className="side-nav-container">
      <div className="text-side-nav-container   text-machinie text-sub">
        <div className="pt-5 pb-5 container">
          <div>
            <Link
              to="/machinie"
              className={
                selecting === SELECTING.MACHINIE_PAGE
                  ? "bold-subtitle-1 text-nav-left a-icon-nav"
                  : "text-nav-left a-icon-nav"
              }
            >
              MACHINIE
            </Link>
          </div>
          <div className="pt-5">
            <Link
              to="/humach"
              className={
                selecting === SELECTING.HUMACH_PAGE
                  ? "bold-subtitle-1 text-nav-left a-icon-nav"
                  : "text-nav-left a-icon-nav"
              }
            >
              HUMACH
            </Link>{" "}
          </div>
          <div className="pt-5">
            <Link
              to="/game/lotto"
              className={
                selecting === SELECTING.GAME_PAGE
                  ? "bold-subtitle-1 text-nav-left a-icon-nav"
                  : "text-nav-left a-icon-nav"
              }
            >
              GAMES
            </Link>{" "}
          </div>
          <div className="pt-5">
            <Link
              to="/docs/general-faqs"
              className={
                selecting === SELECTING.DOCS_PAGE
                  ? "bold-subtitle-1 text-nav-left a-icon-nav"
                  : "text-nav-left a-icon-nav"
              }
            >
              DOCS
            </Link>{" "}
          </div>
        </div>
      </div>
      <div className="footer-side-nav">
        <FooterSection />
      </div>
    </div>
  );
};
