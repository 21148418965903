import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SELECTING, SELECT_PAGE } from "../../value";

type NAVLEFT_TYPES = {
  selecting: string;
  setToggleSideNav: any;
  toggleSideNav: boolean;
  toggleProfile: boolean;
  setToggleProfile: any;
};

export const NavLeft = ({
  setToggleSideNav,
  selecting,
  toggleSideNav,
}: NAVLEFT_TYPES) => {
  const machinie_logo: string = `${process.env.PUBLIC_URL}/assets/navAssets/logo/logo-machinie.png`;

  // const switchBool = (bool: boolean) => {
  //   if (bool) {
  //     setBool(false);
  //     setToggleSideNav(false);
  //     document.body.style.overflow = "unset";
  //     document.body.style.paddingRight = "0";
  //   } else {
  //     setBool(true);
  //     setToggleSideNav(true);
  //     document.body.style.overflow = "hidden";
  //     document.body.style.paddingRight = "15px";
  //   }
  // };
  return (
    <div className="nav-left-container">
      {!toggleSideNav ? (
        <div
          className="text-sub nav-bars-display cursor-pointer"
          onClick={() => setToggleSideNav(true)}
        >
          <i className="fa fa-bars fa-lg"></i>
        </div>
      ) : (
        <div
          className="text-sub nav-bars-display cursor-pointer"
          onClick={() => setToggleSideNav(false)}
        >
          <i className="fa fa-times fa-lg"></i>
        </div>
      )}

      <div className="nav-logo-left-container  m-auto-tb">
        <Link to="/">
          <img src={machinie_logo} alt="machinie logo" className="col-12"></img>
        </Link>
      </div>
      <div className="text-nav-left-container">
        <Link
          to="/machinie"
          className={
            selecting === SELECTING.MACHINIE_PAGE
              ? "bold-subtitle-1 text-nav-left a-icon-nav"
              : "text-nav-left a-icon-nav"
          }
        >
          MACHINIE
        </Link>
        <Link
          to="/humach"
          className={
            selecting === SELECTING.HUMACH_PAGE
              ? "bold-subtitle-1 text-nav-left a-icon-nav"
              : "text-nav-left a-icon-nav"
          }
        >
          HUMACH
        </Link>{" "}
        <Link
          to="/game/lotto"
          className={
            selecting === SELECTING.GAME_PAGE
              ? "bold-subtitle-1 text-nav-left a-icon-nav"
              : "text-nav-left a-icon-nav"
          }
        >
          GAMES
        </Link>{" "}
        <Link
          to="/docs/general-faqs"
          className={
            selecting === SELECTING.DOCS_PAGE
              ? "bold-subtitle-1 text-nav-left a-icon-nav"
              : "text-nav-left a-icon-nav"
          }
        >
          DOCS
        </Link>{" "}
      </div>
    </div>
  );
};
