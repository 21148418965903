import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const FaqSection = () => {
  return (
    <section className="section bg-1" id="faqs">
      <div className="container">
        <div className="text-center">
          <div className="reg-subtitle-2 text-color-secondary">
            General FAQs
          </div>
          <div className="text-color-primary-200 bold-headline-4 mt-14">
            General information for you
          </div>
        </div>
        <FaqContainer />
      </div>
    </section>
  );
};

export const FaqContainer = () => {
  const [select, setSelect] = useState("");
  return (
    <div className="faq-container bg-2 mt-5">
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="What is NFT?"
        description="NFT stands for Non-Fungible Token. It is a non-interchangeable unit of data stored on a blockchain. Each token is unique and maybe associated with media such as photos, video, and audio. Our NFT acts as a proof of ownership to Machinie family. Owning a Machinie or a Humach means you are a part of the family!"
      />
      <FaqEachChild
        select={select}
        setSelect={setSelect}
        topic="How do I buy one?"
      >
        <div>
          Machinie and Humach is stored on the Ethereum blockchain which
          requires Ethereum to buy. First you will need a cold wallet such as
          MetaMask,{" "}
          <a href="https://metamask.io/" target={"_blank"}>
            https://metamask.io/
          </a>{" "}
          . Once you open a wallet then note down your Seed phrase. Please
          remember, the seed phrase you have is the wallet itself! Do not ever
          expose this seed phrase to anyone and do not store it digitally. Then,
          top up your wallet with some ETH from an exchange or directly from
          MetaMask.{" "}
        </div>
        <div className="mt-12">
          Machinie is sold out of primary sale.{" "}
          <a href="https://opensea.io/collection/machinie" target={"_blank"}>
            Click here
          </a>{" "}
          to for secondary market.
        </div>
        <div className="mt-12">
          Humach is live on primary sale. <Link to="/humach">Click here</Link>{" "}
          for minting page.
        </div>
      </FaqEachChild>

      <FaqEachChild
        select={select}
        setSelect={setSelect}
        topic="What are Genesis Machinies?"
      >
        <div>
          Highly advanced, ancient technological artifacts from a long forgotten
          age. They produce an infinite supply of energy to those that can
          unlock their true power. Only Humachs posses the ancient knowledge
          required to utilize the full potential of the 888 Genesis Machinie,
          generating a continuous stream of $floppy for their owners.
        </div>
        <div className="mt-12">
          <Link to="/machinie">Learn more about Machinie's utility</Link>
        </div>
      </FaqEachChild>

      <FaqEachChild
        select={select}
        setSelect={setSelect}
        topic="What are Humach?"
      >
        <div>
          Mysterious space beings, searching the galaxy for their source of
          power. Along the way, they train their minds and bodies to level up,
          battle each other for supremacy, and call upon other Humach for
          support. Humachs posses the ancient knowledge required to activate
          Genesis Machinie and generate $floppy. They can use $floppy to
          discover the truth about their mysterious origins, as well as to call
          in additional Humach (breed). Call transmission cost is lower the more
          Humach level up. Your Humach’s level will also play an important role
          in the next phase of the project. Humach alone can generate $current
          daily."
        </div>
        <div className="mt-12">
          <Link to="/humach">Learn more about Humach's utility</Link>
        </div>
      </FaqEachChild>
      <FaqEach
        select={select}
        setSelect={setSelect}
        topic="Do Machinie and Humach NFTs have utility?"
        description="Yes, Staking 1 Machinie with 1 Humach will allow the owner to generate $floppy, the utility token of the Machinie ecosystem. Additionally, Humach on their own, acquire the ability to level up over time. The higher your Humach levels, the less $floppy is required to call (breed) additional Humach. Your Humach’s level will also play an important role in the next phase of the project. On top of that, they can generate $current daily on their own."
        last={true}
      />
    </div>
  );
};

type FAQ_EACH_TPYES = {
  topic: string;
  description?: string;
  last?: boolean;
  children?: any;
  select: string;
  setSelect: any;
};

export const FaqEachChild = ({
  topic,
  description,
  last,
  children,
  select,
  setSelect,
}: FAQ_EACH_TPYES) => {
  const [bool, setBool] = useState(false);

  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };

  return (
    <div className={last ? "" : "faq-each-container"}>
      <div className="faq-each-margin" onClick={() => switchBool(bool)}>
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">{topic}</div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>{" "}
      </div>

      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            {children}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const FaqEach = ({
  topic,
  description,
  last,
  select,
  setSelect,
}: FAQ_EACH_TPYES) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    if (select === topic) {
      setBool(true);
    } else {
      setBool(false);
    }
  }, [select]);

  const switchBool = (bool: boolean) => {
    if (bool) {
      setBool(false);
      setSelect("");
    } else {
      setBool(true);
      setSelect(topic);
    }
  };
  return (
    <div className={last ? "" : "faq-each-container"}>
      <div
        className="faq-each-margin cursor-pointer"
        onClick={() => switchBool(bool)}
      >
        <div className="d-flex justify-content-between">
          <div className="bold-headline-6 text-color-gray-100">{topic}</div>
          <div>
            {!bool ? (
              <i
                className="fa fa-plus faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            ) : (
              <i
                className="fa fa-times faq-each-more text-color-gray-100 cursor-pointer"
                onClick={() => switchBool(bool)}
              ></i>
            )}
          </div>
        </div>
      </div>

      {bool ? (
        <div className="faq-each-margin-more">
          <div className="mt-14 reg-subtitle-2 text-color-line-tint-04">
            {description}
          </div>{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
