import { useState, useEffect } from "react";

export const CountDownClaim = () => {
  var countDownDate = new Date("2030-11-10T04:00:00.000Z").getTime();
  //var countDownDate2 = new Date("2030-11-10T05:00:00.000Z").getTime();

  const [h, setH] = useState(0);
  const [m, setM] = useState(0);
  const [s, setS] = useState(0);

  // const [h2, setH2] = useState(0);
  // const [m2, setM2] = useState(0);
  // const [s2, setS2] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      var now = new Date().getTime();
      var distance = countDownDate - now;

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setH(hours);
      setM(minutes);
      setS(seconds);

      // var now2 = new Date().getTime();
      // var distance2 = countDownDate2 - now2;

      // var hours2 = Math.floor(
      //   (distance2 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      // );
      // var minutes2 = Math.floor((distance2 % (1000 * 60 * 60)) / (1000 * 60));
      // var seconds2 = Math.floor((distance2 % (1000 * 60)) / 1000);

      // setH2(hours2);
      // setM2(minutes2);
      // setS2(seconds2);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span className="grey_text">{h + "H :" + m + "M :" + s + "S"}</span>;
};
