import { useEffect, useState } from "react";
import { Modal } from "../../modal/Modal";
import { IMAGE_ASSETS } from "../../../const/imageAssetsLink";
import web3 from "../../../web3";
import floppyContract from "../../../floppyContract";
import ReactLoading from "react-loading";
import {
  CONTRACT_TOKEN,
  SMART_CONTRACT_ADDRESS,
  VALUE_STATIC,
} from "../../../value";
import humach from "../../../humach";
import { LoadingDots } from "../../component/LoadingDots";
import { Contract } from "ethers";
import machinieUpgrade from "../../../machinieUpgrade";

export const MyNftMore = ({
  selectIdMoreToggle,
  setSelectIdMoreToggle,
  humachLevel,
  id,
  contractToken,
  floppy,
  setFloppy,
  isTraining,
}: MY_NFT_MORE) => {
  const [isPop, setIsPop] = useState(false);

  useEffect(() => {
    if (selectIdMoreToggle === id) {
      setIsPop(true);
    } else {
      setIsPop(false);
    }
  }, [selectIdMoreToggle]);
  const toggleClick = () => {
    if (selectIdMoreToggle === id) {
      setSelectIdMoreToggle("");
    } else {
      setSelectIdMoreToggle(id);
    }
  };
  return (
    <div className="more-button-click-container">
      <div className="more-button-click-relative">
        <div
          className="more-button-click text-color-gray-100 bold-button cursor-pointer"
          onClick={() => toggleClick()}
        >
          <i className="fas fa-ellipsis-h"></i>
        </div>
        {isPop ? (
          <MyNftMorePanel
            humachLevel={humachLevel}
            contractToken={contractToken}
            floppy={floppy}
            setFloppy={setFloppy}
            id={id}
            isTraining={isTraining}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

type MY_NFT_MORE = {
  selectIdMoreToggle: any;
  setSelectIdMoreToggle: any;
  id: any;
  contractToken: string;
  floppy: any;
  setFloppy: any;
  humachLevel?: any;
  isTraining?: boolean;
};

type MY_NFR_MORE_PANEL = {
  id: any;
  contractToken: string;
  floppy: any;
  setFloppy: any;
  humachLevel?: any;
  isTraining?: boolean;
};

export const MyNftMorePanel = ({
  id,
  contractToken,
  floppy,
  setFloppy,
  humachLevel,
  isTraining,
}: MY_NFR_MORE_PANEL) => {
  const [isAllowance, setIsAllowance] = useState<string | boolean>("");
  useEffect(() => {
    getAllowanceHumach();
  }, []);
  const getAllowanceHumach = async () => {
    try {
      const accounts = await web3.eth.getAccounts();

      if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        const res = await floppyContract.methods
          .allowance(
            accounts[0],
            SMART_CONTRACT_ADDRESS.ETH_MAINNET.NEW_MACHINIE
          )
          .call();

        if (res > 0) {
          setIsAllowance(true);
        } else {
          setIsAllowance(false);
        }
      } else if (contractToken === CONTRACT_TOKEN.HUMACH) {
        const res = await floppyContract.methods
          .allowance(
            accounts[0],
            SMART_CONTRACT_ADDRESS.ETH_MAINNET.HUMACH_MAINNET
          )
          .call();

        if (res > 0) {
          setIsAllowance(true);
        } else {
          setIsAllowance(false);
        }
      }
    } catch (err: any) {}
  };

  const renderMyNftMorePanel = (contractToken: string) => {
    switch (contractToken) {
      case CONTRACT_TOKEN.MACHINIE:
        return (
          <MorePanel
            contractToken={contractToken}
            id={id}
            isAllowance={isAllowance}
            floppy={floppy}
            setFloppy={setFloppy}
            setIsAllowance={setIsAllowance}
          />
        );
      case CONTRACT_TOKEN.HUMACH:
        return (
          <MorePanel
            contractToken={contractToken}
            id={id}
            isAllowance={isAllowance}
            floppy={floppy}
            setFloppy={setFloppy}
            humachLevel={humachLevel}
            isTraining={isTraining}
            setIsAllowance={setIsAllowance}
          />
        );
      default:
        return <div></div>;
    }
  };

  return <div>{renderMyNftMorePanel(contractToken)}</div>;
};

type NOT_ALLOWANCE_PROPS_TYPES = {
  text: string;
};

export const NotAllowance = ({ text }: NOT_ALLOWANCE_PROPS_TYPES) => {
  return (
    <div className="more-select-button-container-off">
      <div className="reg-subtitle-2 text-color-line-tint-04">{text}</div>
    </div>
  );
};

type MORE_PANEL = {
  contractToken: string;
  isAllowance: string | boolean;
  floppy: any;
  setFloppy: any;
  humachLevel?: any;
  isTraining?: boolean;
  setIsAllowance: any;
  id: string;
};

export const MorePanel = ({
  contractToken,
  isAllowance,
  floppy,
  setFloppy,
  humachLevel,
  id,
  isTraining,
  setIsAllowance,
}: MORE_PANEL) => {
  useEffect(() => {}, []);

  return isAllowance !== "" ? (
    <div className="more-button-panel">
      {contractToken === CONTRACT_TOKEN.HUMACH ? (
        <CallHumach
          isTraining={isTraining}
          floppy={floppy}
          setFloppy={setFloppy}
          humachLevel={humachLevel}
          isAllowance={isAllowance}
          setIsAllowance={setIsAllowance}
          id={id}
          contractToken={contractToken}
        />
      ) : (
        ""
      )}

      <ChangeName
        floppy={floppy}
        setFloppy={setFloppy}
        isAllowance={isAllowance}
        setIsAllowance={setIsAllowance}
        id={id}
        contractToken={contractToken}
      />
      <ChangeBio
        floppy={floppy}
        setFloppy={setFloppy}
        isAllowance={isAllowance}
        setIsAllowance={setIsAllowance}
        id={id}
        contractToken={contractToken}
      />
    </div>
  ) : (
    <div className="more-button-panel">
      <div className="more-select-button-container-off">
        <div className="reg-caption text-color-gray-100">
          Loading
          <LoadingDots />
        </div>
      </div>
    </div>
  );
};

type USE_FLOPPY = {
  floppy: any;
  setFloppy: any;
  humachLevel?: any;
  isTraining?: boolean;
  isAllowance: boolean | string;
  setIsAllowance: any;
  id?: string;
  contractToken: string;
};

export const CallHumach = ({
  floppy,
  setFloppy,
  humachLevel,
  isTraining,
  isAllowance,
  setIsAllowance,
  id,
  contractToken,
}: USE_FLOPPY) => {
  const [open, setIsOpen] = useState(false);
  const [isCalling, setIsCalling] = useState(0);
  const [floppyNeed, setFloppyNeed] = useState("");
  const [untrainFloppy, setUntrainFloppy] = useState<string | undefined>("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    getStart();
  }, [humachLevel, floppy]);

  const getStart = async () => {
    const thisFloppyNeed = await setShowNeedFloppy(id);
    const thisUntrainFloppy = await renderIfUntrainUse(humachLevel[0]);
    setUntrainFloppy(thisUntrainFloppy);
    setFloppyNeed(thisFloppyNeed);
  };

  const breedHumach = async (tokenId: string | undefined) => {
    setErrMessage("");
    setIsCalling(1);
    setIsOpen(true);
    const accounts = await web3.eth.getAccounts();
    try {
      await humach.methods.breedHumach(tokenId).send({
        from: accounts[0],
      });
      const thisFloppy = await getFloppy();
      setFloppy(thisFloppy);
      setIsCalling(2);
    } catch (err: any) {
      setIsCalling(3);
      setErrMessage(err.message);
    }
  };

  const getFloppy = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const thisFloppy = await floppyContract.methods
        .balanceOf(accounts[0])
        .call();

      return thisFloppy;
    } catch (err: any) {
      return 0;
    }
  };

  const setShowNeedFloppy = async (selectHumach: string | undefined) => {
    try {
      const floppyToBreed = await humach.methods
        .getBreedHumachFee(selectHumach)
        .call();

      const toWei = await web3.utils.fromWei(floppyToBreed, "ether");
      const floppyToInt = parseInt(toWei).toFixed(0);

      return floppyToInt;
    } catch (err) {
      return "";
    }
  };

  const renderIfUntrainUse = async (humachLevel: any) => {
    switch (humachLevel) {
      case "1":
        return "170";
      case "2":
        return "165";
      case "3":
        return "160";
      case "4":
        return "155";
      case "5":
        return "150";
    }
  };

  return (
    <div>
      {floppyNeed !== "" ? (
        Number(floppy) >= Number(floppyNeed) ? (
          <div
            className="more-select-button-container"
            // onClick={() => breedHumach(id)} //ให้ uncomment ตอนเสร็จแล้ว
          >
            {!VALUE_STATIC.CALL.IS_HOLD ? (
              <div className="reg-caption text-color-gray-100 off">
                Call{" "}
                <span>
                  <img
                    src={IMAGE_ASSETS.FLOPPY}
                    alt="floppy"
                    width="15px"
                  ></img>
                </span>
              </div>
            ) : (
              <div className="reg-caption text-color-gray-100 off">
                {VALUE_STATIC.CALL.TEXT_HOLD}
              </div>
            )}
            {/* <div className="reg-caption text-color-gray-100">
              Call {floppyNeed + " "}
              <span>
                <img src={IMAGE_ASSETS.FLOPPY} alt="floppy" width="15px"></img>
              </span>
            </div> */}
            {isTraining ? (
              floppyNeed !== untrainFloppy ? (
                <div className="pt-4px reg-caption text-color-line-tint-04">
                  Untrain to update humach level in smart contract to be able to
                  call at {untrainFloppy} $floppy
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="more-select-button-container-off">
            {!VALUE_STATIC.CALL.IS_HOLD ? (
              <div className="reg-caption text-color-gray-100">
                Call {floppyNeed + " "}
                <span>
                  <img
                    src={IMAGE_ASSETS.FLOPPY}
                    alt="floppy"
                    width="15px"
                  ></img>
                </span>
              </div>
            ) : (
              <div className="reg-caption text-color-gray-100 off">
                {VALUE_STATIC.CALL.TEXT_HOLD}
              </div>
            )}

            {isTraining ? (
              floppyNeed !== untrainFloppy ? (
                <div className="pt-4px reg-caption text-color-line-tint-04">
                  Untrain to update humach level in smart contract to be able to
                  call at {untrainFloppy} $floppy
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        )
      ) : (
        ""
      )}

      {!isAllowance ? (
        <SetAllowance
          open={open}
          setIsOpen={setIsOpen}
          setIsAllowance={setIsAllowance}
          isAllowance={isAllowance}
          contractToken={contractToken}
        />
      ) : (
        <CallingHumachPanel
          contractToken={contractToken}
          open={open}
          setIsOpen={setIsOpen}
          id={id}
          isCalling={isCalling}
          floppyNeed={floppyNeed}
          errMessage={errMessage}
          breedHumach={breedHumach}
        />
      )}
    </div>
  );
};

export const ChangeName = ({
  floppy,
  setFloppy,
  setIsAllowance,
  isAllowance,
  id,
  contractToken,
}: USE_FLOPPY) => {
  const [open, setIsOpen] = useState(false);
  return (
    <div>
      {Number(floppy) >= 35 ? (
        <div
          className="more-select-button-container"
          onClick={() => setIsOpen(true)}
        >
          <div className="reg-caption text-color-gray-100">
            Change Name 35{" "}
            <span>
              <img src={IMAGE_ASSETS.FLOPPY} alt="floppy" width="15px"></img>
            </span>
          </div>
        </div>
      ) : (
        <div className="more-select-button-container-off off">
          <div className="reg-caption text-color-gray-100">
            Change Name 35{" "}
            <span>
              <img src={IMAGE_ASSETS.FLOPPY} alt="floppy" width="15px"></img>
            </span>
          </div>
        </div>
      )}
      {isAllowance ? (
        <ChangeNamePanel
          contractToken={contractToken}
          open={open}
          setIsOpen={setIsOpen}
          id={id}
        />
      ) : (
        <SetAllowance
          open={open}
          setIsOpen={setIsOpen}
          setIsAllowance={setIsAllowance}
          isAllowance={isAllowance}
          contractToken={contractToken}
        />
      )}
    </div>
  );
};

export const ChangeBio = ({
  floppy,
  setFloppy,
  isAllowance,
  setIsAllowance,
  id,
  contractToken,
}: USE_FLOPPY) => {
  const [open, setIsOpen] = useState(false);

  return (
    <div>
      {Number(floppy) >= 35 ? (
        <div
          className="more-select-button-container"
          onClick={() => setIsOpen(true)}
        >
          <div className="reg-caption text-color-gray-100">
            Change Bio 35{" "}
            <span>
              <img src={IMAGE_ASSETS.FLOPPY} alt="floppy" width="15px"></img>
            </span>
          </div>
        </div>
      ) : (
        <div className="more-select-button-container-off off">
          <div className="reg-caption text-color-gray-100">
            Change Bio 35{" "}
            <span>
              <img src={IMAGE_ASSETS.FLOPPY} alt="floppy" width="15px"></img>
            </span>
          </div>
        </div>
      )}

      {isAllowance ? (
        <ChangeBioPanel
          contractToken={contractToken}
          open={open}
          setIsOpen={setIsOpen}
          id={id}
        />
      ) : (
        <SetAllowance
          open={open}
          setIsOpen={setIsOpen}
          setIsAllowance={setIsAllowance}
          isAllowance={isAllowance}
          contractToken={contractToken}
        />
      )}
    </div>
  );
};

type CHANGE_PANEL = {
  open: boolean;
  setIsOpen: any;
  id?: string;
  isCalling?: number;
  floppyNeed?: string | undefined;
  errMessage?: string;
  breedHumach?: any;
  floppy?: string;
  contractToken: string;
};

export const CallingHumachPanel = ({
  open,
  setIsOpen,
  id,
  isCalling,
  floppyNeed,
  errMessage,
  breedHumach,
  floppy,
}: CHANGE_PANEL) => {
  const [tokenName, setTokenName] = useState("");

  useEffect(() => {
    getTokenName(id);
  }, []);
  const getTokenName = async (tokenId: string | undefined) => {
    try {
      const res = await humach.methods.getTokenIdName(tokenId).call();

      setTokenName(
        res[0] !== "" ? res[0] + " #" + tokenId : "Humach #" + tokenId
      );
    } catch (err) {}
  };
  const renderCalling = (isCalling: number | undefined) => {
    switch (isCalling) {
      case 0:
        return <div></div>;
      case 1: //caliing
        return (
          <div className="bold-button text-center text-color-gray-100 mt-24">
            Spending{" "}
            <span className="text-color-secondary">{floppyNeed} $floppy</span>{" "}
            to call a humach
            <LoadingDots />
          </div>
        );
      case 2: //success
        return (
          <div className="bold-button text-center text-color-gray-100 mt-24">
            <div className="text-color-secondary">Success!</div>
            {floppyNeed !== undefined && floppy !== undefined ? (
              Number(floppy) > Number(floppyNeed) ? (
                <div
                  className="button-machinie mt-6 bold-button"
                  onClick={() => breedHumach(id)}
                >
                  Call Another
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        );
      case 3: //failed
        return (
          <div className=" text-center text-color-gray-100 mt-24">
            <div className="bold-button text-color-secondary">
              Calling Failed
            </div>
            <div className="reg-caption mt-6">{errMessage}</div>
            <div
              className="button-machinie mt-6 bold-button"
              onClick={() => breedHumach(id)}
            >
              try again
            </div>
          </div>
        );
    }
  };
  return (
    <Modal open={open} onClose={() => setIsOpen(false)}>
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">Humach Call</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>
      <div className="p-24 ">
        <div className="reg-subtitle-2 text-color-gray-100"></div>
        <div className="text-center">
          <img
            src={IMAGE_ASSETS.HUMACH_ACTION.CALLING}
            className="bg-color-gradient-radial more-calling-humach-image"
            width="200px"
          ></img>
        </div>

        <div className="bold-button text-center text-color-gray-100 mt-24">
          Calling by <span className="text-color-primary-200">{tokenName}</span>
        </div>

        {renderCalling(isCalling)}
      </div>
    </Modal>
  );
};

export const ChangeBioPanel = ({
  open,
  setIsOpen,
  id,
  contractToken,
}: CHANGE_PANEL) => {
  const [newBio, setNewBio] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [tokenBio, setTokenBio] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    getTokenBio(id);
  }, []);

  const updateTokenDescription = async (
    tokenId: string | undefined,
    newDes: string
  ) => {
    setErrMessage("");
    setIsUpdating(true);
    const accounts = await web3.eth.getAccounts();
    try {
      if (contractToken === CONTRACT_TOKEN.HUMACH) {
        await humach.methods.updateTokenDescription(tokenId, newDes).send({
          from: accounts[0],
        });
      } else if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        await machinieUpgrade.methods
          .updateTokenDescription(tokenId, newDes)
          .send({
            from: accounts[0],
          });
      }

      setIsUpdateSuccess(true);
    } catch (err: any) {
      setIsUpdateSuccess(false);
      setIsUpdating(false);
      setErrMessage(err.message);
    }
  };

  const getTokenBio = async (tokenId: string | undefined) => {
    try {
      if (contractToken === CONTRACT_TOKEN.HUMACH) {
        const res = await humach.methods.getTokenIdName(tokenId).call();
        setTokenBio(res[1] !== "" ? res[1] : "None");
        setTokenName(
          res[0] !== "" ? res[0] + " #" + tokenId : "Humach #" + tokenId
        );
      } else if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        const res = await machinieUpgrade.methods
          .getTokenIdName(tokenId)
          .call();
        setTokenBio(res[1] !== "" ? res[1] : "None");
        setTokenName(
          res[0] !== "" ? res[0] + " #" + tokenId : "Machinie #" + tokenId
        );
      }
    } catch (err) {}
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setIsOpen(false);
        setNewBio("");
        setErrMessage("");
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">
          Change <span className="first-upper">{contractToken}</span> Bio
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setNewBio("");
            setErrMessage("");
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>

      <div className="p-24">
        <div className="text-color-line-tint-04 reg-caption">
          <span className="">{tokenName}</span>'s Bio
        </div>
        <div className="text-color-primary-200 bold-subtitle-1 mb-12">
          {tokenBio}
        </div>
        <textarea
          className="textarea_bio input-change-name reg-subtitle-2 text-color-gray-100"
          value={newBio}
          maxLength={300}
          onChange={(e) => setNewBio(e.target.value)}
        ></textarea>
        <div className="count-text-length-container mt-6">
          <div className="reg-caption text-color-line-tint-04">
            {newBio.length}/300
          </div>{" "}
        </div>

        {errMessage !== "" ? (
          <div className="text-color-secondary mt-12 reg-caption">
            {errMessage}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
            setNewBio("");
            setErrMessage("");
          }}
        >
          CANCEL
        </div>
        {newBio.length > 0 ? (
          !isUpdating ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => updateTokenDescription(id, newBio)}
            >
              SAVE
            </div>
          ) : !isUpdateSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SAVING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setIsOpen(false)}
            >
              SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SAVE
          </div>
        )}
      </div>
    </Modal>
  );
};

export const ChangeNamePanel = ({
  open,
  setIsOpen,
  id,
  contractToken,
}: CHANGE_PANEL) => {
  const [newName, setNewName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [tokenName, setTokenName] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    getTokenName(id);
  }, []);

  const updateTokenName = async (
    tokenId: string | undefined,
    newName: string
  ) => {
    setErrMessage("");
    setIsUpdating(true);
    const accounts = await web3.eth.getAccounts();
    try {
      if (contractToken === CONTRACT_TOKEN.HUMACH) {
        await humach.methods.updateTokenName(tokenId, newName).send({
          from: accounts[0],
        });
      } else if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        await machinieUpgrade.methods.updateTokenName(tokenId, newName).send({
          from: accounts[0],
        });
      }
      getTokenName(id);
      setIsUpdateSuccess(true);
    } catch (err: any) {
      setIsUpdating(false);
      setIsUpdateSuccess(false);
      setErrMessage(err.message);
    }
  };

  const getTokenName = async (tokenId: string | undefined) => {
    try {
      if (contractToken === CONTRACT_TOKEN.HUMACH) {
        const res = await humach.methods.getTokenIdName(tokenId).call();

        setTokenName(
          res[0] !== "" ? res[0] + " #" + tokenId : "Humach #" + tokenId
        );
      } else if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        const res = await machinieUpgrade.methods
          .getTokenIdName(tokenId)
          .call();

        setTokenName(
          res[0] !== "" ? res[0] + " #" + tokenId : "Machinie #" + tokenId
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setIsOpen(false);
        setNewName("");
        setErrMessage("");
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">
          Change <span className="first-upper">{contractToken}</span> Name
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setNewName("");
            setErrMessage("");
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>
      <div className="p-24">
        <div className="bold-subtitle-1 text-color-primary-200 mb-12">
          {tokenName}
        </div>
        <input
          className="input-change-name reg-subtitle-2 text-color-gray-100"
          value={newName}
          type="text"
          maxLength={20}
          onChange={(e) => setNewName(e.target.value)}
        ></input>
        <div className="count-text-length-container mt-6">
          <div className="reg-caption text-color-line-tint-04">
            {newName.length}/20
          </div>{" "}
        </div>
        {errMessage !== "" ? (
          <div className="text-color-secondary text-center mt-12 reg-caption">
            {errMessage}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
            setNewName("");
            setErrMessage("");
          }}
        >
          CANCEL
        </div>

        {newName.length > 0 ? (
          !isUpdating ? (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => updateTokenName(id, newName)}
            >
              SAVE
            </div>
          ) : !isUpdateSuccess ? (
            <div className="modal-stake-button-stake-selected bold-button">
              SAVING
              <LoadingDots />
            </div>
          ) : (
            <div
              className="modal-stake-button-stake-selected cursor-pointer bold-button"
              onClick={() => setIsOpen(false)}
            >
              SUCCESS!
            </div>
          )
        ) : (
          <div className="modal-stake-button-stake-selected bold-button off">
            SAVE
          </div>
        )}
      </div>
    </Modal>
  );
};

type SET_ALLOW_NANCE = {
  open: boolean;
  setIsOpen: any;
  setIsAllowance: any;
  isAllowance: boolean | string;
  contractToken: string;
};

export const SetAllowance = ({
  open,
  setIsOpen,
  isAllowance,
  setIsAllowance,
  contractToken,
}: SET_ALLOW_NANCE) => {
  const [isSetAllowing, setIsSetAllowing] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const approveFloppy = async () => {
    setIsSetAllowing(true);
    try {
      setErrMessage("");
      const accounts = await web3.eth.getAccounts();
      if (contractToken === CONTRACT_TOKEN.HUMACH) {
        const res = await floppyContract.methods
          .approve(
            SMART_CONTRACT_ADDRESS.ETH_MAINNET.HUMACH_MAINNET,
            "1000000000000000000000000"
          )
          .send({
            from: accounts[0],
          });
      } else if (contractToken === CONTRACT_TOKEN.MACHINIE) {
        const res = await floppyContract.methods
          .approve(
            SMART_CONTRACT_ADDRESS.ETH_MAINNET.NEW_MACHINIE,
            "1000000000000000000000000"
          )
          .send({
            from: accounts[0],
          });
      }

      setIsAllowance(true);
    } catch (err: any) {
      setIsAllowance(false);
      setIsSetAllowing(false);
      setErrMessage(err.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setIsOpen(false);
        setIsSetAllowing(false);
        setErrMessage("");
      }}
    >
      <div className="close-modal-container-stake">
        <div className="bold-headline-6 text-color-gray-100">
          Smart contract allowing
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setIsSetAllowing(false);
            setErrMessage("");
          }}
        >
          <i className="fa fa-times close-modal"></i>
        </div>
      </div>
      <div className="p-24 ">
        <div className="reg-body-2 text-color-gray-100">
          To be able to spend $floppy in this collection, please allow $floppy
          to be spent on the smart contract. This action is needed to be done
          only once for each collection.
        </div>

        <div className="reg-caption text-color-secondary mt-6">
          {errMessage}
        </div>
      </div>

      <div className="modal-stake-bottom-container">
        <div
          className="modal-stake-button-cancel bold-button"
          onClick={() => {
            setIsOpen(false);
            setIsSetAllowing(false);
            setErrMessage("");
          }}
        >
          CANCEL
        </div>
        {isSetAllowing === true && isAllowance === false ? (
          <div className="modal-stake-button-stake-selected bold-button">
            ALLOWING
            <LoadingDots />
          </div>
        ) : isSetAllowing === true && isAllowance === true ? (
          <div
            className="modal-stake-button-stake-selected bold-button"
            onClick={() => setIsOpen(false)}
          >
            SUCCESS!
          </div>
        ) : (
          <div
            className="modal-stake-button-stake-selected cursor-pointer bold-button"
            onClick={() => approveFloppy()}
          >
            OK, I ALLOW
          </div>
        )}
      </div>
    </Modal>
  );
};
