import { url } from "inspector";

export const WelcomeSection = () => {
  const bg_image: string = `${process.env.PUBLIC_URL}/assets/navAssets/background/bg.gif`;
  // /Users/hathumkittitus/machiniverse-project/public/assets/background/bg.gif
  return (
    <section
      //   style={{
      //     backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/bg.gif)`,
      //   }}
      className="section text-center"
      id="overview"
    >
      <div className="container">
        <div className="main-text-welcome">Welcome to Machiniverse</div>
        <div className="text-white sub-text-welcome container">
          The beginning of <span className="text-main">Machinie</span> and the
          home of <span className="text-gold">Humach</span>. Get both and unlock
          the full potential of Machiniverse. .
        </div>
      </div>
    </section>
  );
};
