import { useState, useEffect } from "react";
import { getSubmissionBoard } from "../../apiCallReturn";
import { api } from "../../axiosURL";
import { API } from "../../valueApi";
import { Modal } from "../modal/Modal";
import { LOTTO_VALUE } from "./lottoConfigValue";

export const LottoRewardLast = () => {
  const hell_mach: string = `${process.env.PUBLIC_URL}/assets/humach/red.gif`;
  return (
    <div className="lotto-reward-container text-center">
      <div className="p-13 bold-subtitle-2 text-color-gray-100">Reward</div>
      <div className="p-24 lotto-reward-img-container">
        <img
          src={LOTTO_VALUE.REWARD.LAST_REWARD.IMAGE}
          className="lotto-reward-img col-12"
        ></img>
      </div>
      <div className="p-13 bold-subtitle-1 text-color-gray-100">
        {LOTTO_VALUE.REWARD.LAST_REWARD.NAME}
      </div>
    </div>
  );
};


export const LottoReward = () => {
  // const hell_mach: string = `${process.env.PUBLIC_URL}/assets/humach/red.gif`;
  return (
    <div className="lotto-reward-container text-center">
      <div className="p-13 bold-subtitle-2 text-color-gray-100">Reward</div>
      <div className="p-24 lotto-reward-img-container">
        <img
          src={LOTTO_VALUE.REWARD.CURRENT_REWARD.IMAGE}
          className="lotto-reward-img col-12"
        ></img>
      </div>
      <div className="p-13 bold-subtitle-1 text-color-gray-100">
        {LOTTO_VALUE.REWARD.CURRENT_REWARD.NAME}
      </div>
    </div>
  );
};

type WINNER_LOTTO_1 = {
  configId: string;
  walletList?: any;
};

export const WinnerLotto = ({ configId, walletList }: WINNER_LOTTO_1) => {
  const [winner, setWinner] = useState("");
  const [isWinner, setIsWinner] = useState(false);
  useEffect(() => {
    getWinnerCheck(LOTTO_VALUE.LAST_CONFIG_ID);
    getWinner(configId);
  }, []);

  const getWinner = async (configId: string) => {
    try {
      const res = await api.get(API.LOTTO_STATUS.CHECK_STATUS_PUBLIC, {
        params: {
          lottoConfigId: configId,
        },
      });
      setWinner(res.data.data[0]["winner"]["displayName"]);
    } catch (err: any) {
      setWinner(err.meesage);
    }
  };

  const getWinnerCheck = async (configId: string) => {
    try {
      const res = await api.get(API.LOTTO_STATUS.CHECK_STATUS, {
        params: {
          lottoConfigId: configId,
        },
      });
      console.log("get winner check", res);
    } catch (err: any) {
      console.log("get winner check err", err);
    }
  };

  return (
    <div className="winner-container mt-24 text-center">
      <div className="p-24 bold-subtitle-2 text-color-gray-100 winner-text">
        Winner
      </div>
      <div className="p-24 bold-subtitle-1 text-color-primary-200">
        {winner=="" ? "-"  : winner}
      </div>
    </div>
  );
};

type WINNER_LOTTO = {
  configId: string;
};

export const SubmissionBoard = ({ configId }: WINNER_LOTTO) => {
  const [loading, setIsLoading] = useState(false);
  const [totalEntry, setTotalEntry] = useState(0);
  const [submissionArray, setSubmissionArray] = useState<any>([]);

  useEffect(() => {
    const getStart = async () => {
      var now = new Date().getTime();

      setIsLoading(true);
      const res = await getSubmissionBoard(configId);
      // console.log("submissionArray", res);

      const lottoPlayer = [];

      const lottoPlayer2 = [
        // { displayName: "PU", entry: 15, in: 1712606944 },
        { displayName: "PU", entry: 15, in: 2712606944 },
        // { displayName: "notthing", entry: 10, in: 1686163200 },
        // { displayName: "Debbi_Jocasta Nu", entry: 10, in: 1676254815 },
        // { displayName: "gimmepls", entry: 3, in: 1696710659 },
        // { displayName: "Roda_Dooku Rizz", entry: 11, in: 1676254815 },
        // { displayName: "Eakkamaiz", entry: 6, in: 1699404574 },
        // { displayName: "Jaokatuk", entry: 10, in: 1696717019 },
      ];
      
      for (let i = 0; i < res.length; i++) {
        lottoPlayer.push(res[i]);
      }

      for (let i = 0; i < lottoPlayer2.length; i++) {
        let nextRound: any = new Date(lottoPlayer2[i].in * 1000);
        var distanceRN = nextRound - now;
        var daysNR = Math.floor(distanceRN / (1000 * 60 * 60 * 24));
        var hoursNR = Math.floor(
          (distanceRN % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutesNR = Math.floor(
          (distanceRN % (1000 * 60 * 60)) / (1000 * 60)
        );
        var secondsNR = Math.floor((distanceRN % (1000 * 60)) / 1000);

        if (daysNR <= 0 && hoursNR <= 0 && minutesNR <= 0 && secondsNR <= 0) {
          lottoPlayer.push(lottoPlayer2[i]);
        }
      }

      lottoPlayer.sort((a: any, b: any) => b.entry - a.entry);

      setSubmissionArray(lottoPlayer);

      let count = 0;

      for (let i = 0; i < lottoPlayer.length; i++) {
        count += lottoPlayer[i]["entry"];
      }

      setTotalEntry(count);
      setIsLoading(false);
    };

    getStart();
  }, []);

  return (
    <div className="submission-board-container mt-24">
      <div className="m-auto">
        <div className="bold-subtitle-1 text-center text-color-primary-200">
          Submission Board
        </div>
        <div className="bold-subtitle-2 text-center mt-14 text-color-secondary">
          {!loading ? "Total entry : " + totalEntry : "loading entry"}
        </div>

        <div className="submission-each-container mt-14">
          {submissionArray.length > 0
            ? submissionArray.map((x: any, index: any) => (
                <SubmissionEach
                  x={x["displayName"]}
                  o={x["entry"]}
                  i={index + 1}
                />
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

type SUBMISSION_EACH = {
  x: string;
  o: number;
  i: number;
};

export const SubmissionEach = ({ x, o, i }: SUBMISSION_EACH) => {
  return (
    <div className="submission-each">
      <div className="reg-caption text-color-gray-100">
        {i}. {x.length > 12 ? x.slice(0, 12) + "..." : x}
      </div>
      <div className="reg-caption text-color-tertiary">
        {o} {o > 1 ? "Entries" : "Entry"}
      </div>
    </div>
  );
};

export const CurrentDropNextDropLast = () => {
  return (
    <div className="cn-drop-container text-center ">
      <div className="p-28 current-drop">
        <div className="reg-caption text-color-gray-100">Current Drop</div>
        <div className="bold-subtitle-2 text-color-primary-200 mt-6">
          {LOTTO_VALUE.LAST_DROP}
        </div>
      </div>
      <div className="p-28 m-auto">
        <div className="reg-caption text-color-gray-100">Next Submission</div>
        <div className="bold-subtitle-2 text-color-primary-200 mt-6">
          <CountDownPanel timestamp={LOTTO_VALUE.SUBMISSION_START} />
        </div>
        <div className="reg-caption text-color-tertiary mt-6">
          {LOTTO_VALUE.SUBMISSION_DATE}
        </div>
      </div>
    </div>
  );
};

export const CurrentDropNextDrop = () => {
  return (
    <div className="cn-drop-container text-center ">
      <div className="p-28 current-drop">
        <div className="reg-caption text-color-gray-100">Current Drop</div>
        <div className="bold-subtitle-2 text-color-primary-200 mt-6">
          {LOTTO_VALUE.CURRENT_DROP}
        </div>
      </div>
      <div className="p-28 m-auto">
        <div className="reg-caption text-color-gray-100">Next Submission</div>
        <div className="bold-subtitle-2 text-color-primary-200 mt-6">
          <CountDownPanel timestamp={LOTTO_VALUE.SUBMISSION_START_NEXT} />
        </div>
        <div className="reg-caption text-color-tertiary mt-6">
          {LOTTO_VALUE.NEXT_SUBMISSION}
        </div>
      </div>
    </div>
  );
};

type COUNT_DOWN_PANLE = {
  timestamp: number;
};

export const CountDownPanel = ({ timestamp }: COUNT_DOWN_PANLE) => {
  const [d, setD] = useState(0);
  const [h, setH] = useState(0);
  const [m, setM] = useState(0);
  const [s, setS] = useState(0);
  useEffect(() => {
    const interval = setInterval(async () => {
      var now = new Date().getTime();
      var dateTimestamp = new Date(timestamp * 1000).getTime(); //start
      var distance = dateTimestamp - now;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setD(days);
      setH(hours);
      setM(minutes);
      setS(seconds);

      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        setD(0);
        setH(0);
        setM(0);
        setS(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);
  return <span>{d + " : " + h + " : " + m + " : " + s}</span>;
};

type LOTTO_TEXT = {
  setIsOpen: any;
};

export const LottoText = ({ setIsOpen }: LOTTO_TEXT) => {
  return (
    <div>
      <div className="bold-headline-4 text-color-gray-100 mb-14">
        Machi Lotto
      </div>
      <div className="reg-caption text-color-tertiary mb-14">
        Drop on every 8th of each month
      </div>

      <div
        className="bold-button text-color-primary-200 mb-14 cursor-pointer"
        onClick={() => {
          document.body.style.overflow = "hidden";
          document.body.style.paddingRight = "15px";
          setIsOpen(true);
        }}
      >
        SEE how it work{" "}
        <span className="text-color-tertiary">
          <i className="fa fa-plus"></i>
        </span>{" "}
      </div>
    </div>
  );
};

type MODAL_LOTTO = {
  isOpen: boolean;
  setIsOpen: any;
};

export const ModalLotto = ({ isOpen, setIsOpen }: MODAL_LOTTO) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="p-24">
        <div className="close-modal-container">
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              document.body.style.overflow = "unset";
              document.body.style.paddingRight = "0px";
            }}
          >
            <i className="fa fa-times close-modal"></i>
          </div>
        </div>
        <div className="text-color-primary-200 bold-headline-6 mt-24">
          How lotto works
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-12">
          (0) Tickets are generated from Machipon.
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (1) Ticket submission opens 1 day b4 the raffle.
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (2) 1 ticket = 1 entry
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (3) Max entry per wallet : {LOTTO_VALUE.MAX_ENTRY}
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (4) The submitted ticket cannot be returned.
        </div>
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (5) Submissions close 10 min before the raffle.
        </div>
        {/* <div className="text-color-gray-100 reg-body-2 mt-6">
          (6) Raffle runs at 12:00 PM EST (Lunch)
        </div> */}
        <div className="text-color-gray-100 reg-body-2 mt-6">
          (6) Winner announced and wait for airdrop!
        </div>
      </div>
    </Modal>
  );
};
