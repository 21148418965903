import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import { PORTFOLIO_VALUE } from "./portfolioConfig";

type PORT_FOLIO = {
  selectPage: string;
  selectProfile: any;
};

export const Portfolio = ({ selectPage, selectProfile }: PORT_FOLIO) => {
  useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);
  return (
    <div className="bg-1">
      <div className="container">
        <div className="portfolio-each">
          <PortfolioEachTop
            selectPage={selectPage}
            selectProfile={selectProfile}
          />
          <PortfolioEachBottom
            selectPage={selectPage}
            selectProfile={selectProfile}
          />
        </div>
      </div>
    </div>
  );
};

type PORT_EACH_TOP = {
  selectPage: string;
  selectProfile: any;
};

export const PortfolioEachTop = ({
  selectPage,
  selectProfile,
}: PORT_EACH_TOP) => {
  return (
    <div className="portfolio-each-top-container">
      <div className="portfolio-each-top-upper">
        <div>
          <img src={selectProfile.IMAGE} width="170px"></img>
        </div>
        <div className="portfolio-each-top-upper-name">
          <div className="bold-headline-3 text-color-primary-200">
            {selectProfile.NAME}
          </div>
          <div className="reg-subtitle-1 text-color-tertiary">
            {selectProfile.DATE}
          </div>
        </div>
      </div>
      <div className="portfolio-each-top-lower pt-50">
        <PortfolioSelectButton
          text="PROFILE"
          selectProfile={selectProfile}
          selectPage={PORTFOLIO_VALUE.SELECT_PAGE.PROFILE}
        />
        <PortfolioSelectButton
          text="EDUCATION & SKILL"
          selectProfile={selectProfile}
          selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION}
        />
        <PortfolioSelectButton
          text="EXPERIENCED"
          selectProfile={selectProfile}
          selectPage={PORTFOLIO_VALUE.SELECT_PAGE.EXP}
        />
      </div>
    </div>
  );
};

type PORTFOLIO_SELECT_BUTTON = {
  text: string;
  selectPage: string;
  selectProfile: any;
};

export const PortfolioSelectButton = ({
  text,
  selectPage,
  selectProfile,
}: PORTFOLIO_SELECT_BUTTON) => {
  return (
    <Link to={"/portfolio/" + selectProfile.PATH + "/" + selectPage}>
      <div className="my-nft-selected-button">
        <div className="text-color-tertiary bold-button m-auto-tb">{text}</div>
      </div>
    </Link>
  );
};

type PORT_EACH_BOTTOM = {
  selectPage: string;
  selectProfile: any;
};

export const PortfolioEachBottom = ({
  selectPage,
  selectProfile,
}: PORT_EACH_BOTTOM) => {
  return (
    <div className="pt-50">
      {selectPage === PORTFOLIO_VALUE.SELECT_PAGE.PROFILE ? (
        <PortfolioEachProfile object={selectProfile} />
      ) : (
        ""
      )}

      {selectPage === PORTFOLIO_VALUE.SELECT_PAGE.EDUCATION ? (
        <PortfolioEachEducationAndSkill selectProfile={selectProfile} />
      ) : (
        ""
      )}

      {selectPage === PORTFOLIO_VALUE.SELECT_PAGE.EXP ? (
        <PortfolioEachExperienced object={selectProfile.EXP} />
      ) : (
        ""
      )}

      {/* <PortfolioEachEducationAndSkill /> */}
      {/* <PortfolioEachExperienced object={PORTFOLIO_VALUE.KING.EXP} /> */}
    </div>
  );
};

type PORT_EACH_PROFILE = {
  object: any;
};

export const PortfolioEachProfile = ({ object }: PORT_EACH_PROFILE) => {
  return (
    <div className="text-color-gray-100 portfolio-each-profile">
      <div>
        <div className="reg-subtitle-1">{object.DESCRIBE}</div>
        <div className="portfolio-each-profile-bottom">
          <div className="bold-headline-4">
            <div>{object.TRAIT.FIRST}</div>
            <div>{object.TRAIT.SECOND}</div>
          </div>
          <div className="reg-subtitle-2">
            {object.TRAIT.TRAIT_EACH.map((x: any) => (
              <div>
                {x.name} - {x.percent}%
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="port-contract-container reg-subtitle-2">
        <div>{object.CONTRACT.HOME}</div>
        <div>{object.CONTRACT.PERSONAL_EMAIL}</div>
        <div>{object.CONTRACT.TWITTER}</div>
        <div>{object.CONTRACT.DISCORD}</div>
        <div>{object.CONTRACT.PHONE}</div>
      </div>
    </div>
  );
};

type PORT_EDUCATION_SKILL = {
  selectProfile: any;
};

export const PortfolioEachEducationAndSkill = ({
  selectProfile,
}: PORT_EDUCATION_SKILL) => {
  return (
    <div className="portfolio-each-education-and-skill-container">
      <PortfolioEachEducation object={selectProfile.EDUCATION} />
      <PortfolioEachSkill object={selectProfile.SKILL} />
    </div>
  );
};

type PORT_EDUCATION = {
  object: any;
};

export const PortfolioEachEducation = ({ object }: PORT_EDUCATION) => {
  return (
    <div className="portfolio-each-education-container">
      {object.map((x: any) => (
        <PortfolioEachEducationEach object={x} />
      ))}
    </div>
  );
};

export const PortfolioEachEducationEach = ({ object }: PORT_EDUCATION) => {
  return (
    <div className="text-color-gray-100">
      <div className="bold-headline-6">{object.TOPIC}</div>
      <div className="mt-6">
        {object.DETAIL.map((x: any) => (
          <div className="reg-subtitle-2">{x}</div>
        ))}
      </div>
    </div>
  );
};

type PORT_SKILL = {
  object: any;
};

export const PortfolioEachSkill = ({ object }: PORT_SKILL) => {
  return (
    <div className="text-color-gray-100">
      <div className="bold-headline-6 text-center">Skills</div>
      <div className="port-each-skill-container">
        {object.map((x: any) => (
          <div className="each-skill reg-subtitle-2">{x}</div>
        ))}
      </div>
    </div>
  );
};

type PORTFOLIO_EACH_EXPERIENCED = {
  object: any;
};

export const PortfolioEachExperienced = ({
  object,
}: PORTFOLIO_EACH_EXPERIENCED) => {
  return (
    <div className="portfolio-each-exp">
      {object.map((x: any) => (
        <PortfolioEachExperiencedEach object={x} />
      ))}
    </div>
  );
};

type PORTFOLIO_EACH_EXPERIENCED_EACH = {
  object: any;
};

export const PortfolioEachExperiencedEach = (
  object: PORTFOLIO_EACH_EXPERIENCED_EACH
) => {
  return (
    <div className="text-color-gray-100 portfolio-each-experienced-each">
      <div className="d-flex justify-content-between">
        <div className="bold-headline-6">{object.object.TOPIC}</div>
        <div className="bold-headline-6">
          {object.object.TIME_START} - {object.object.TIME_END}
        </div>
      </div>

      <div className="reg-subtitle-1">Status : {object.object.STATUS}</div>
      <div className="reg-subtitle-2 col-5">{object.object.DESCRIBE}</div>
    </div>
  );
};
