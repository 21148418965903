import axios from "axios";
import { useEffect, useState } from "react";
import { humachLogo } from "../../../imageForMachiniverse";

export const HumachForStakeEach = ({
  humachId,
  setIsOpen,
  index,
  objectPair,
  setObjectPair,
}: MODAL_HUMACH_FOR_STAKE_EACH) => {
  const [image, setImage] = useState(humachLogo);

  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    const res = await axios.get(
      "https://api.machinienft.com/api/humach/metadata/" + humachId
    );
    const getImageArray = res.data.image_list;

    const getImage = getImageArray[getImageArray.length - 1]["image"];

    const getIpfsImage = getImage.replace(
      "https://gateway.pinata.cloud/ipfs/",
      "https://ipfs.io/ipfs/"
    );

    setImage(getImage);
  };

  const selectThisHumach = async () => {
    const getObject = await [...objectPair];

    getObject[index]["humachId"] = humachId.toString();
    await setObjectPair(getObject);

    //console.log("selectHumach", getObject);

    setIsOpen(false);
  };
  return (
    <div className="humach-for-stake-each" onClick={() => selectThisHumach()}>
      <div>
        <img
          src={image}
          className="humach-for-stake-each-image"
          width={"80"}
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="bold-subtitle-2 text-color-tertiary">
          Humach #{humachId}
        </div>
        <div className="text-color-line-tint-04 reg-caption">Chilling</div>
      </div>
    </div>
  );
};

type MODAL_HUMACH_FOR_STAKE_EACH = {
  humachId: string;
  index: number;
  setIsOpen: any;
  objectPair: { machinieId?: string; humachId?: string }[];
  setObjectPair: any;
};
