import { IMAGE_ASSETS } from "../../const/imageAssetsLink";

export const PORTFOLIO_VALUE = {
  SELECT_PAGE: {
    PROFILE: "profile",
    EDUCATION: "education&skill",
    EXP: "experienced",
  },
  KING: {
    PATH: "KiNG",
    NAME: "King Photmaetha Jamfah",
    DATE: "12 DEC 1997",
    IMAGE: IMAGE_ASSETS.SANTA_MACH,
    CONTRACT: {
      HOME: "Bangkok Thailand",
      PERSONAL_EMAIL: "photmaetha.j@gmail.com",
      DISCORD: "KiNG#8888",
      TWITTER: "0xKiNG_machinie",
      PHONE: "(+66) 825529919",
    },
    DESCRIBE:
      "An explorer of spaces who believes in knowledge and challenge that makes us ride along our life. Since I was young, I have always loved learning and digging into new stuff. This was what introduced me to blockchain technology and excited about to come in the future.",
    TRAIT: {
      FIRST: "INTP-T (Logician)",
      SECOND: "Role - Analyst",
      TRAIT_EACH: [
        { name: "Introverted", percent: "54" },
        { name: "Intuitive", percent: "66" },
        { name: "Thinking", percent: "54" },
        { name: "Prospecting", percent: "57" },
        { name: "Turbulent", percent: "57" },
      ],
    },
    EDUCATION: [
      {
        TOPIC: "Bachelor of Economics",
        DETAIL: [
          "Thammasat University, Phra Chan Alley, Phra Nakhon, Bangkok 10200",
          "Major in Economics, Minor in Marketing",
          "Member of SCOBE ( Student Committee )",
          "Head and member of BECamp core team",
          "2016 - 2020",
        ],
      },
      {
        TOPIC: "GCSE",
        DETAIL: [
          "King’s Ely School, Ely, Cambridgeshire CB7 4EW",
          "Economics, Business Studies, Physic, Math, English",
          "Head boy for international section",
          "2013 - 2015",
        ],
      },
      {
        TOPIC: "Family Business Management",
        DETAIL: ["imNEG, Nikko Bangkok.", "2022"],
      },
    ],
    SKILL: [
      "Business Development",
      "Market Research and Analyst",
      "Community Management",
      "Public speaking",
      "Team coordinate",
      "English Fluent",
      "Photography",
      "Photoshop and illustrator",
    ],
    EXP: [
      {
        TOPIC: "Founder and CEO of TeamMach",
        STATUS: "Operating",
        DESCRIBE:
          "a web3 enabler and consultant team who bring your imagination to life thru blockchain technology.",
        TIME_START: "2021",
        TIME_END: "Present",
      },
      {
        TOPIC: "Founder of Machinie",
        STATUS: "Surfing the space",
        DESCRIBE:
          "The community based and the first NFT project  integrating a leveling mechanism. Apart from  staking and breeding system we built, the art  is splendid on its own. May Machinie be with you.",
        TIME_START: "2021",
        TIME_END: "Present",
      },
      {
        TOPIC: "Founder of glugfilm",
        STATUS: "Chilling",
        DESCRIBE:
          "a social club with a passionate team helping those who share the same passion with love. The club provide various kinds of service in the film industry. Develop,  Sell and Fix film cameras. The develop part is under construction while the others are still in the service.",
        TIME_START: "2019",
        TIME_END: "Present",
      },
    ],
  },
  HATHUM: {
    PATH: "kittitus",
    NAME: "Hathum Kittitus Kongprakan",
    DATE: "12 JUNE 1998",
    IMAGE:
      "https://media-exp1.licdn.com/dms/image/D5603AQFL3PxgyXHZzg/profile-displayphoto-shrink_200_200/0/1668580088344?e=1674086400&v=beta&t=EVV0M3FrJ0tinhNxfINXJpCcZtBbTiWsNtGAkxefUM8",
    CONTRACT: {
      HOME: "Bangkok Thailand",
      PERSONAL_EMAIL: "kittitus.kongprakan@outlook.com",
      // DISCORD: "(+66) 866002009",
      TWITTER: "(+66) 866002009",
      PHONE: "",
      // PHONE: "(+66) 866002009",
    },
    DESCRIBE:
      "An explorer of spaces who believes in knowledge and challenge that makes us ride along our life. Since I was young, I have always loved learning and digging into new stuff. This was what introduced me to blockchain technology and excited about to come in the future.",
    TRAIT: {
      FIRST: "INTP-T (Logician)",
      SECOND: "Role - Analyst",
      TRAIT_EACH: [
        { name: "Introverted", percent: "54" },
        { name: "Intuitive", percent: "66" },
        { name: "Thinking", percent: "54" },
        { name: "Prospecting", percent: "57" },
        { name: "Turbulent", percent: "57" },
      ],
    },
    EDUCATION: [
      {
        TOPIC: "Bachelor of Science ",
        DETAIL: [
          "King Mongkut’s University of Technology Thonburi (KMUTT)",
          "Media technology, Game Development",
          "2016 - 2020",
        ],
      },
      {
        TOPIC: "Science & Math",
        DETAIL: ["Nawamintrachinuthit Benjamarachalai School", "2013 - 2015"],
      },
    ],
    SKILL: [
      "React.js",
      "Solidity",
      "Hardhat",
      "HTML,CSS & SCSS",
      "JavaScript",
      "TypeScript",
      "Firebase",
      "PHP",
      "Node.js",
      "Average English",
    ],
    EXP: [
      {
        TOPIC: "Developer of Machinie",
        STATUS: "Developing",
        DESCRIBE:
          "Being a head developer of Machinie. Take care of everything about tech in machiniverse like website, smart contract, api, deploying, hosting, metadata and many things",
        TIME_START: "2021",
        TIME_END: "Present",
      },
    ],
  },
};
