import { useEffect, useState } from "react";
import { MachinieStakingEach } from "./MyMachinieStakingEach";
import { NoMachinie } from "../MyMachinie";
import web3 from "../../../web3";
import { api } from "../../../axiosURL";
import machinieUpgrade from "../../../machinieUpgrade";
import { LoadingDots } from "../../component/LoadingDots";

type MACHINIE_STAKING_PANEL_PROPS_TYPES = {
  machinieStaking: any[];
  setMachinieList: any;
  setHumachList: any;
  setWalletList: any;
  floppy: any;
  setFloppy: any;
};

export const MachinieStakingPanel = ({
  machinieStaking,
  setMachinieList,
  setHumachList,
  setWalletList,
  floppy,
  setFloppy,
}: MACHINIE_STAKING_PANEL_PROPS_TYPES) => {
  const [selectForUnstake, setSelectForUnstake] = useState([]);
  const [selectIdMoreToggle, setSelectIdMoreToggle] = useState<string>("");
  return (
    <div>
      {machinieStaking.length > 0 ? (
        <div>
          <MachinieEachTopSelectAllStaking
            selectForUnstake={selectForUnstake}
            setSelectForUnstake={setSelectForUnstake}
            machinieStaking={machinieStaking}
            setMachinieList={setMachinieList}
            setHumachList={setHumachList}
            setFloppy={setFloppy}
          />
          <div className="machinie-each-panel">
            {machinieStaking.map((x) => (
              <MachinieStakingEach
                machinieId={x.machinieNumber.toString()}
                selectForUnstake={selectForUnstake}
                setSelectForUnstake={setSelectForUnstake}
                setWalletList={setWalletList}
                setMachinieList={setMachinieList}
                machinieListEach={x}
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoMachinie
          topic="No staking machinie"
          text="Your Staking machinie will show up here."
          isStaking={true}
        />
      )}
    </div>
  );
};

type MACHINIE_EACH_TOP_SELECT_ALL_STAKING_PROPS_TYPES = {
  setSelectForUnstake: any;
  selectForUnstake: string[];
  machinieStaking: any[];
  setMachinieList: any;
  setHumachList: any;
  setFloppy: any;
};

export const MachinieEachTopSelectAllStaking = ({
  machinieStaking,
  setSelectForUnstake,
  selectForUnstake,
  setMachinieList,
  setHumachList,
  setFloppy,
}: MACHINIE_EACH_TOP_SELECT_ALL_STAKING_PROPS_TYPES) => {
  const [unStaking, setUnStaking] = useState(false);
  const selectAll = () => {
    setSelectForUnstake(
      machinieStaking.map((x) => x.machinieNumber.toString())
    );
  };

  const deselectAll = () => {
    setSelectForUnstake([]);
  };

  const unStakeMach = async () => {
    setUnStaking(true);
    try {
      const accounts = await web3.eth.getAccounts();
      await machinieUpgrade.methods.unStakeMachinie(selectForUnstake).send({
        from: accounts[0],
      });

      const thisMachinieList = await getMachinieList();
      const thisHumachList = await getHumachList();
      setMachinieList(thisMachinieList.data);
      setHumachList(thisHumachList.data);
      setUnStaking(false);
    } catch (err: any) {
      //console.log(err.message);
      setUnStaking(false);
    }
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return (
    <div className="machinie-chilling-top">
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => selectAll()}
      >
        SELECT ALL
      </div>
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => deselectAll()}
      >
        DESELECT ALL
      </div>
      {selectForUnstake.length > 0 ? (
        !unStaking ? (
          <div
            className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer"
            onClick={() => unStakeMach()}
          >
            UNSTAKE SELECTED
          </div>
        ) : (
          <div className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer">
            UNSTAKING
            <LoadingDots />
          </div>
        )
      ) : (
        <div className="stake-select-all-button bold-button text-color-gray-100 cursor-pointer">
          UNSTAKE SELECTED
        </div>
      )}
    </div>
  );
};
