import { Nav } from "../navbar/Nav";
import { SubNav } from "../navbar/SubNav";
import { AnnounceStake } from "./AnnounceStake";
import { BeforeFooterSection } from "./beforefoot/BeforeFooterSection";
import { FaqSection } from "./faq/FaqSection";
import { FooterSection } from "./footer/FooterSection";
import { GameSection } from "./GameSection";
import { HistorySection } from "./HistorySection";
import { HomeHumachSection } from "./HomeHumachSection";
import { HomeMachinieSection } from "./HomeMachinieSection";
import { RoadmapSection } from "./roadmap/RoadmapSection";
import { TeamSection } from "./team/TeamSection";
import { WelcomeSection } from "./WelcomeSection";
import { useEffect } from "react";
import { MetamaskPanel } from "../wallet_connect/ConnectWallet";
import { SELECTING } from "../../value";

import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { TreasureHuntTeaser } from "./treasure_hunt/TreasureHuntTeaser";
import { LottoPromote } from "./lotto_promote/LottoPromote";

export const HomePage = ({ setSelecting }: HOME_PAGE) => {
  useEffect(() => {
    setSelecting(SELECTING.HOME_PAGE);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Element name="overview-section">
        {/* <LottoPromote /> */}
        <WelcomeSection />
      </Element>
      {/* <TreasureHuntTeaser /> */}
      <HistorySection />
      <HomeMachinieSection />
      <HomeHumachSection />
      <GameSection />
      <Element name="roadmap-section">
        <RoadmapSection />
      </Element>
      <Element name="team-section">
        <TeamSection />
      </Element>
      <Element name="faq-section">
        <FaqSection />
      </Element>
      <BeforeFooterSection />
    </>
  );
};

type HOME_PAGE = {
  setSelecting: any;
};
