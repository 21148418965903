import { useEffect, useState } from "react";

export const LoadingDots = () => {
  const [dotsLoading, setDotsLoading] = useState(".");
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (count % 4 === 0) {
        setDotsLoading(".");
      } else {
        setDotsLoading(dotsLoading + ".");
      }
      setCount(count + 1);
    }, 700);

    return () => {
      clearInterval(interval);
    };
  }, [count]);
  return <span>{dotsLoading}</span>;
};
