import { useEffect, useState } from "react";
import { api } from "../../../axiosURL";

type MACHINIE_SLIDE_TYPES = {
  position: boolean;
};

export const HumachSlide = ({ position }: MACHINIE_SLIDE_TYPES) => {
  const [arrayPicId, setArrayPicId] = useState<any>([]);

  useEffect(() => {
    randomHumach();
    getListHumach();
  }, []);

  const getListHumach = async () => {
    try {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (err) {}
  };

  const randomHumach = async () => {
    const res = await api.get("/api/humach/random");
    let array = [
      `${process.env.PUBLIC_URL}/assets/humach/green.gif`,
      `${process.env.PUBLIC_URL}/assets/humach/red.gif`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/1.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/2.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/3.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/4.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/5.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/6.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/7.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/8.png`,
      `${process.env.PUBLIC_URL}/assets/humach/forshow/9.png`,
    ];
    const sum = res.data.data;
    for (let i = 0; i < sum.length - 9; i++) {
      array.push("https://api.machinienft.com/api/humach/random_pic/" + sum[i]);
    }
    const shuffled = await getShuffledArr(array);
    setArrayPicId(shuffled);
  };

  const getShuffledArr = (arr: any[]) => {
    return [...arr].map((_, i, arrCopy) => {
      var rand = i + Math.floor(Math.random() * (arrCopy.length - i));
      [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
      return arrCopy[i];
    });
  };

  //   const logo_humach = `${process.env.PUBLIC_URL}/assets/images/humach/Humach_logo.png`;

  return (
    <div className="slider">
      <div className={position ? "slide-track" : "slide-track-2"}>
        {arrayPicId !== ""
          ? arrayPicId.map((x: any) => (
              <div className="slide" key={"humach_" + x}>
                <img src={x} className="col-12 image_hover" alt="humach"></img>
              </div>
            ))
          : ""}
        {/* 10slide */}
        {arrayPicId !== ""
          ? arrayPicId.map((x: any) => (
              <div className="slide" key={"humach_" + x}>
                <img src={x} className="col-12 image_hover" alt="humach"></img>
              </div>
            ))
          : ""}
        {/* 10slide */}
      </div>
    </div>
  );
};
