import { useEffect, useState } from "react";
import { api } from "../../../axiosURL";
import { machinie_url } from "../../../MachinieURL";

type MACHINIE_SLIDE_TYPES = {
  position: boolean;
};

export const MachinieSlide = ({ position }: MACHINIE_SLIDE_TYPES) => {
  const [arrayPicId, setArrayPicId] = useState<any>([]);

  useEffect(() => {
    randomHumach();
    // getListHumach();
  }, []);

  const getListHumach = async () => {
    try {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
    } catch (err) {}
  };

  const randomHumach = async () => {
    let array = [...machinie_url];
    const shuffled = await getShuffledArr(array);
    setArrayPicId(shuffled);
  };

  const randomHumach2 = async () => {
    let array = [...machinie_url];
    const shuffled = await getShuffledArr(array);
    setArrayPicId(shuffled);
  };

  const getShuffledArr = (arr: any[]) => {
    return [...arr].map((_, i, arrCopy) => {
      var rand = i + Math.floor(Math.random() * (arrCopy.length - i));
      [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
      return arrCopy[i];
    });
  };

  //   const logo_humach = `${process.env.PUBLIC_URL}/assets/images/humach/Humach_logo.png`;

  return (
    <div className="slider">
      <div className={position ? "slide-track" : "slide-track-2"}>
        {arrayPicId !== ""
          ? arrayPicId.map((x: any) => (
              <div className="slide" key={"machinie_" + x}>
                <img
                  src={x}
                  className="col-12 image_hover"
                  alt="machinie"
                ></img>
              </div>
            ))
          : ""}
        {/* 10slide */}

        {arrayPicId !== ""
          ? arrayPicId.map((x: any) => (
              <div className="slide" key={"machinie_" + x}>
                <img
                  src={x}
                  className="col-12 image_hover"
                  alt="machinie"
                ></img>
              </div>
            ))
          : ""}
        {/* 10slide */}
      </div>
    </div>
  );
};
