import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../axiosURL";
import { IMAGE_ASSETS } from "../../const/imageAssetsLink";
import { MACHIPON, SELECTING } from "../../value";
import { API } from "../../valueApi";

import { ButtonSelect, ButtonSelected } from "../learn_humach/PowerOfHumach";

import { Modal } from "../modal/Modal";
import {
  MouseEnterPanel,
  MouseEnterPanelTop,
} from "../wallet_connect/MouseEnterPanel";
import { BeforeSubmission } from "./lotto/BeforeSubmission";
import { LottoWinner, LottoWinnerLast } from "./lotto/LottoWinner";
import { MachinieLottoLearn } from "./lotto/MachiLottoLearn";
import { Submission } from "./lotto/Submission";
import { SubmissionClose } from "./lotto/SubmissionClose";
import { PlayGameMachipon } from "./PlayGameMachipon";
import { PlayGameSlot } from "./PlayGameSlot";
import {
  CurrentDropNextDrop,
  LottoReward,
  SubmissionBoard,
  WinnerLotto,
} from "./PlayLotto";
import { LOTTO_ACTION_IMAGE } from "./lottoConfigValue";

type LEARN_GAME_TOP_PROPS_TYPES = {
  walletList: any;
  setWalletList: any;
  gameSelectPage: string;
  setSelecting: any;
};

export const LearnGamePage = ({
  walletList,
  setWalletList,
  setSelecting,
  gameSelectPage,
}: LEARN_GAME_TOP_PROPS_TYPES) => {
  useEffect(() => {
    setSelecting(SELECTING.GAME_PAGE);
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section-games bg-1">
      {/* <GamePage
        gameSelectPage={gameSelectPage}
        setWalletList={setWalletList}
        walletList={walletList}
      /> */}
      <GamePageOffline />
      {/* <--To open game comment this */}
    </section>
  );
};

type GAME_PAGE_ONLINE = {
  gameSelectPage: any;
  setWalletList: any;
  walletList: any;
};

export const GamePage = ({
  gameSelectPage,
  setWalletList,
  walletList,
}: GAME_PAGE_ONLINE) => {
  return (
    <div className="container">
      <div className="bold-headline-3 text-color-primary-200">Games</div>
      <div className="learn-games-select-container">
        <ButtonSelectGame
          text="MACHI LOTTO"
          path="/game/lotto"
          gameSelectPage={gameSelectPage}
          checkSelect="gameLotto"
        />
        <ButtonSelectGame
          text="MACHIPON"
          path="/game/machipon"
          gameSelectPage={gameSelectPage}
          checkSelect="gameMachipon"
        />
        <ButtonSelectGame
          text="SLOT MACHI"
          path="/game/slotmachi"
          gameSelectPage={gameSelectPage}
          checkSelect="gameSlot"
        />
      </div>

      <div>
        {gameSelectPage === "gameLotto" ? (
          <MachinieLottoLearn
            setWalletList={setWalletList}
            walletList={walletList}
          />
        ) : gameSelectPage === "gameSlot" ? (
          <SlotMachiLearn
            setWalletList={setWalletList}
            walletList={walletList}
          />
        ) : (
          <MachiponLearn
            walletList={walletList}
            setWalletList={setWalletList}
          />
        )}
      </div>
    </div>
  );
};

export const GamePageOffline = () => {
  const slot_idle: string = `${process.env.PUBLIC_URL}/assets/gameAssets/slot_idle_final.gif`;
  const machipon_idle: string = `${process.env.PUBLIC_URL}/assets/gameAssets/gasha_idle_final.gif`;
  return (
    <div className="container pb-5">
      <div className="games-fixing-container pb-5 text-center">
        <div className="games-fixing-image-container">
          <div className="games-image-fixing">
            <img
              src={LOTTO_ACTION_IMAGE.ACTION_1}
              alt="lotto"
              className="col-12"
            ></img>
          </div>
          <div className="games-image-fixing">
            <img src={slot_idle} alt="slot" className="col-12"></img>
          </div>
          <div className="games-image-fixing">
            <img src={machipon_idle} alt="machipon" className="col-12"></img>
          </div>
        </div>
        <div className="m-auto-tb">
          <div className="text-color-line-tint-04 bold-headline-3 text-center">
            Game machines are under construction
          </div>
        </div>
      </div>
    </div>
  );
};

type BUTTON_SELECT_GAME = {
  text: string;
  path: string;
  gameSelectPage: string;
  checkSelect: string;
};

export const ButtonSelectGame = ({
  text,
  path,
  gameSelectPage,
  checkSelect,
}: BUTTON_SELECT_GAME) => {
  return (
    <Link to={path}>
      {gameSelectPage === checkSelect ? (
        <div className="selected-four-things">
          <div className="selet-text-four-things bold-button">{text}</div>
        </div>
      ) : (
        <div className="select-four-things">
          <div className="selet-text-four-things bold-button">{text}</div>
        </div>
      )}
    </Link>
  );
};

type MACHIPON_LEARN_PROPS_TYPES = {
  walletList: any;
  setWalletList: any;
};
export const MachiponLearn = ({
  setWalletList,
  walletList,
}: MACHIPON_LEARN_PROPS_TYPES) => {
  const machipon_idle: string = `${process.env.PUBLIC_URL}/assets/gameAssets/gasha_idle_final.gif`;
  const machipon_pop: string = `${process.env.PUBLIC_URL}/assets/gameAssets/gasha_pop_final.gif`;
  const [isOpen, setIsOpen] = useState(false);

  const [machiponAction, setMachiponAction] = useState(machipon_idle);

  return (
    <div className="machipon-container section-games">
      <div className="game-display-mobile">
        <div className="bold-headline-4 text-color-gray-100 mb-14">
          Machipon
        </div>
        <div
          className="bold-button text-color-primary-200 mb-14 cursor-pointer"
          onClick={() => {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            setIsOpen(true);
          }}
        >
          SEE how it work{" "}
          <span className="text-color-tertiary">
            <i className="fa fa-plus"></i>
          </span>{" "}
        </div>
      </div>
      <div className="col-12">
        <img
          src={machiponAction}
          className="image-game-machine"
          alt="machi lotto"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="game-display-desktop">
          <div className="bold-headline-4 text-color-gray-100 mb-14">
            Machipon
          </div>
          <div
            className="bold-button text-color-primary-200 mb-14 cursor-pointer"
            onClick={() => {
              document.body.style.overflow = "hidden";
              document.body.style.paddingRight = "15px";
              setIsOpen(true);
            }}
          >
            SEE how it work{" "}
            <span className="text-color-tertiary">
              <i className="fa fa-plus"></i>
            </span>{" "}
          </div>
        </div>

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <div className="p-24">
            <div className="close-modal-container">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  document.body.style.overflow = "unset";
                  document.body.style.paddingRight = "0px";
                }}
              >
                <i className="fa fa-times close-modal"></i>
              </div>
            </div>
            <div className="text-color-primary-200 bold-headline-6 mt-24">
              How Machipon works
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-24">
              Like gashapon, insert $current to win some rewards.
            </div>
            {/* <div className="text-color-gray-100 reg-body-2 mt-6">
              1) Select how many plays you want.
            </div> */}
            <div className="text-color-gray-100 reg-body-2 mt-6">
              1) Press play.
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-6">
              2) Good luck.
            </div>
          </div>
        </Modal>
        <div className="learn-game-display-container">
          <div className="learn-game-rewards-container mb-14">
            <div className="text-center text-rewards-container">
              <div className="mt-14 mb-14 bold-subtitle-2 text-color-gray-100">
                Rewards
              </div>
            </div>
            <div className="m-14">
              <div className="reward-each-container">
                <RewardEachGm />
                <RewardEachDiscord />
                <RewardEachTicket />
                {/* <RewardEachRPS type={MACHIPON.REWARDS_GET.ROCK} />{" "}
                <RewardEachRPS type={MACHIPON.REWARDS_GET.PAPER} />
                <RewardEachRPS type={MACHIPON.REWARDS_GET.SCISSOR} /> */}
              </div>
            </div>
          </div>

          {walletList.floppy !== undefined ? (
            <PlayGameMachipon
              setWalletList={setWalletList}
              walletList={walletList}
              setMachiponAction={setMachiponAction}
            />
          ) : (
            <ConnectWalletPanelBefore />
          )}
        </div>
      </div>
    </div>
  );
};

export const SlotMachiLearn = ({
  setWalletList,
  walletList,
}: MACHIPON_LEARN_PROPS_TYPES) => {
  const slot_idle: string = `${process.env.PUBLIC_URL}/assets/gameAssets/slot_idle_final.gif`;

  const [isOpen, setIsOpen] = useState(false);
  const [slotAction, setSlotAction] = useState(slot_idle);

  return (
    <div className="machipon-container section-games">
      <div className="game-display-mobile">
        <div className="bold-headline-4 text-color-gray-100 mb-14">
          Slot Machi
        </div>
        <div
          className="bold-button text-color-primary-200 mb-14 cursor-pointer"
          onClick={() => {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            setIsOpen(true);
          }}
        >
          SEE how it work{" "}
          <span className="text-color-tertiary">
            <i className="fa fa-plus"></i>
          </span>{" "}
        </div>{" "}
      </div>
      <div className="col-12">
        <img
          src={slotAction}
          className="image-game-machine"
          alt="machi lotto"
        ></img>
      </div>
      <div className="m-auto-tb">
        <div className="game-display-desktop">
          <div className="bold-headline-4 text-color-gray-100 mb-14">
            Slot Machi
          </div>
          <div
            className="bold-button text-color-primary-200 mb-14 cursor-pointer"
            onClick={() => {
              document.body.style.overflow = "hidden";
              document.body.style.paddingRight = "15px";
              setIsOpen(true);
            }}
          >
            SEE how it work{" "}
            <span className="text-color-tertiary">
              <i className="fa fa-plus"></i>
            </span>{" "}
          </div>{" "}
        </div>

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <div className="p-24">
            <div className="close-modal-container">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  document.body.style.overflow = "unset";
                  document.body.style.paddingRight = "0px";
                }}
              >
                <i className="fa fa-times close-modal"></i>
              </div>
            </div>
            <div className="text-color-primary-200 bold-headline-6 mt-24">
              How Slot Machi works
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-24">
              Slot Machi is a betting machine just like a slot machine.
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-6">
              1) Select how many plays you want
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-6">
              2) Press play.
            </div>
            <div className="text-color-gray-100 reg-body-2 mt-6">
              3) Good luck.
            </div>
          </div>
        </Modal>

        <div className="learn-game-display-container">
          <div className="learn-game-rewards-container ">
            <div className="text-center text-rewards-container">
              <div className="mt-14 mb-14 bold-subtitle-2 text-color-gray-100">
                Rewards
              </div>
            </div>
            <div className="m-14">
              <div className="reward-each-container">
                <RewardEachGm />
                <RewardEachCurrent multiply="0.5" />
                <RewardEachCurrent multiply="2" />
                <RewardEachCurrent multiply="3" />
                <RewardEachCurrent multiply="5" />
                <RewardEachCurrent multiply="10" />
                <RewardEachCurrent multiply="20" />
              </div>
            </div>
          </div>

          {walletList.floppy !== undefined ? (
            <PlayGameSlot
              setWalletList={setWalletList}
              walletList={walletList}
              setSlotAction={setSlotAction}
            />
          ) : (
            <ConnectWalletPanelBefore />
          )}
        </div>
      </div>
    </div>
  );
};

export const RewardEachGm = () => {
  const gm: string = `${process.env.PUBLIC_URL}/assets/gameAssets/good_morning.gif`;
  const [isEnter, setIsEnter] = useState(false);
  return (
    <div
      className="reward-each-gm-container bg-color-gradient-radial position-relative cursor-pointer"
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => setIsEnter(false)}
    >
      <div className="reward-each-gm-img-container m-auto">
        <img src={gm} width="100%" className="m-auto" alt="img gm reward"></img>
      </div>

      <MouseEnterPanelTop
        isEnter={isEnter}
        text="Another good day to try again"
      />
    </div>
  );
};

export const RewardEachDiscord = () => {
  const discord: string = `${process.env.PUBLIC_URL}/assets/gameAssets/discord.png`;
  const [isEnter, setIsEnter] = useState(false);
  return (
    <div
      className="reward-each-gm-container bg-color-gradient-radial position-relative cursor-pointer"
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => setIsEnter(false)}
    >
      <div className="reward-each-discord-img-container">
        <img
          src={discord}
          alt="img discord reward"
          className="m-auto"
          width="100%"
        ></img>
      </div>

      <MouseEnterPanelTop
        isEnter={isEnter}
        text="For topping up your discord level and unlocking some perks! "
      />
    </div>
  );
};

export const RewardEachTicket = () => {
  const ticket: string = `${process.env.PUBLIC_URL}/assets/gameAssets/learn_game_ticket.png`;
  const [isEnter, setIsEnter] = useState(false);
  return (
    <div
      className="reward-each-gm-container bg-color-gradient-radial cursor-pointer"
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => setIsEnter(false)}
    >
      <div className="reward-each-discord-img-container">
        <img
          src={ticket}
          alt="img ticket reward"
          width="100%"
          className="m-auto"
        ></img>
      </div>

      <MouseEnterPanelTop
        isEnter={isEnter}
        text="Tickets are used for some plays or prizes in Machiniverse!"
      />
    </div>
  );
};

type REWARD_EACH_RPS = {
  type: string;
};

export const RewardEachRPS = ({ type }: REWARD_EACH_RPS) => {
  const [isEnter, setIsEnter] = useState(false);

  const typeRenderRPS = (type: string) => {
    switch (type) {
      case MACHIPON.REWARDS_GET.ROCK:
        return IMAGE_ASSETS.GAME.RPS.ROCK;
      case MACHIPON.REWARDS_GET.PAPER:
        return IMAGE_ASSETS.GAME.RPS.PAPER;
      case MACHIPON.REWARDS_GET.SCISSOR:
        return IMAGE_ASSETS.GAME.RPS.SCISSOR;
    }
  };
  return (
    <div
      className="reward-each-gm-container bg-color-gradient-radial cursor-pointer"
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => setIsEnter(false)}
    >
      <div className="reward-each-ticket-img-container">
        <img
          src={typeRenderRPS(type)}
          className="width-100"
          alt="img ticket reward"
        ></img>
      </div>

      <MouseEnterPanelTop
        isEnter={isEnter}
        text={type + " is use for play Machi RPS"}
      />
    </div>
  );
};

type REWARD_EACH_CURRENT_TYPE = {
  multiply: string;
};

export const RewardEachCurrent = ({ multiply }: REWARD_EACH_CURRENT_TYPE) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/learn_game_current.png`;
  const [isEnter, setIsEnter] = useState(false);

  const textEachCurrent = () => {
    return "x" + multiply + " of $current you inserted";
  };

  return (
    <div
      className="reward-each-current-container bg-color-gradient-radial position-relative cursor-pointer"
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => setIsEnter(false)}
    >
      <div className="reward-each-current-img-container">
        <img
          src={current}
          alt="img reward"
          className="image-reward-each-each-each"
        ></img>
        <div className="text-multiply-container">
          <div className="text-multiply bold-subtitle-2 text-color-primary-1100">
            x{multiply}
          </div>
        </div>
      </div>

      <MouseEnterPanelTop isEnter={isEnter} text={textEachCurrent()} />
    </div>
  );
};

export const ConnectWalletPanelBefore = () => {
  return (
    <div className="connect-wallet-panel-before-container">
      <div className="text-center m-24">
        <div className="bold-subtitle-1 text-color-gray-100 mb-16">
          Need to connect the wallet to play.
        </div>
      </div>
    </div>
  );
};
