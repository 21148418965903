import { IconInfo } from "../component/IconSD";
import { useState, useEffect } from "react";
import { NETWORKS, VALUE_STATIC } from "../../value";
import web3 from "../../web3";
import humach from "../../humach";
import machinieUpgrade from "../../machinieUpgrade";
import { LoadingDots } from "../component/LoadingDots";
import { Modal } from "../modal/Modal";

type PUBLIC_MINT_PANEL = {
  walletList: any;
  isMainnet: string;
};

declare var window: any;

export const PublicMintPanel = ({
  walletList,
  isMainnet,
}: PUBLIC_MINT_PANEL) => {
  const [isOpen, setIsOpen] = useState(false);
  const switchConnect = (connect: any) => {
    switch (connect) {
      case NETWORKS.ETH_MAINNET:
        return <PublicMint />;
      case NETWORKS.NOT_MAINNET:
        return <PleaseChangeNetwork />;
      case NETWORKS.NOT_CONNECT:
        return <PleaseConnectWallet />;
      default:
        return <PleaseConnectWallet />;
    }
  };

  return (
    <div className="left-humach-learn public-mint-panel">
      <div className="public-mint-text-top-container">
        <div className="public-mint-text-top">
          <span className="bold-headline-6 text-color-primary-200">
            {" "}
            Public Mint{" "}
          </span>
          <span
            className="text-color-tertiary cursor-pointer"
            style={{ fontSize: "25px" }}
            onClick={() => setIsOpen(true)}
          >
            {" "}
            <IconInfo />
          </span>

          <ModalMintNumber open={isOpen} setIsOpen={setIsOpen} />
        </div>{" "}
      </div>
      {VALUE_STATIC.MINT_PANEL.IS_ANNOUNCED_CHNAGE_SUPPLY
        ? switchConnect(isMainnet)
        : WaitForNewAnnouncement()}
    </div>
  );
};

type BUTTON_SELECT_MINT_TYPES = {
  number: string;
};

export const ButtonSelectMint = ({ number }: BUTTON_SELECT_MINT_TYPES) => {
  return (
    <div className="select-mint-button">
      <div className="bold-button text-color-tertiary select-mint-text">
        {number}
      </div>
    </div>
  );
};

export const ButtonSelectedMint = ({ number }: BUTTON_SELECT_MINT_TYPES) => {
  return (
    <div className="selected-mint-button">
      <div className="bold-button text-color-tertiary select-mint-text">
        {number}
      </div>
    </div>
  );
};

export const PleaseChangeNetwork = () => {
  return (
    <div className="public-mint-bottom">
      <div className="reg-caption text-color-secondary">0.068 ETH / Humach</div>
      <div className="text-color-gray-100 bold-button mt-14 mb-14">
        PLEASE CHANGE NETWORK TO ETHEREUM MAINNET
      </div>
    </div>
  );
};

export const WaitForNewAnnouncement = () => {
  return (
    <div className="public-mint-bottom">
      <div className="reg-caption text-color-secondary">0.068 ETH / Humach</div>
      <div className="text-color-gray-100 bold-button mt-14 mb-14">
        The mint is now closed
      </div>
    </div>
  );
};

export const PleaseConnectWallet = () => {
  return (
    <div className="public-mint-bottom">
      <div className="reg-caption text-color-secondary">0.068 ETH / Humach</div>
      <div className="text-color-gray-100 bold-button mt-14 mb-14">
        PLEASE CONNECT YOUR WALLET
      </div>
    </div>
  );
};

export const PublicMint = () => {
  const [value, setValue] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage("");
  }, [value]);

  const publicMintHumach = async (amout: number) => {
    setErrorMessage("");
    setIsMinting(true);
    try {
      const accounts = await web3.eth.getAccounts();
      const valueToString = (0.068 * amout).toFixed(3).toString();
      await humach.methods.publicMintHumach(amout).send({
        from: accounts[0],
        value: web3.utils.toWei(valueToString, "ether"),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      setIsMinting(false);
    } catch (err: any) {
      setIsMinting(false);
      setErrorMessage(err.message);
    }
    setIsMinting(false);
  };

  return (
    <div className="public-mint-bottom">
      <div>
        <div className="reg-caption text-color-secondary">
          0.068 ETH / Humach
        </div>
        {value === 1 ? (
          <div className="select-mint">
            <div onClick={() => setValue(1)}>
              <ButtonSelectedMint number="1" />
            </div>
            <div onClick={() => setValue(2)}>
              <ButtonSelectMint number="2" />
            </div>

            <div onClick={() => setValue(3)}>
              <ButtonSelectMint number="3" />
            </div>
          </div>
        ) : value === 2 ? (
          <div className="select-mint">
            <div onClick={() => setValue(1)}>
              <ButtonSelectMint number="1" />
            </div>
            <div onClick={() => setValue(2)}>
              <ButtonSelectedMint number="2" />
            </div>

            <div onClick={() => setValue(3)}>
              <ButtonSelectMint number="3" />
            </div>
          </div>
        ) : (
          <div className="select-mint">
            <div onClick={() => setValue(1)}>
              <ButtonSelectMint number="1" />
            </div>
            <div onClick={() => setValue(2)}>
              <ButtonSelectMint number="2" />
            </div>

            <div onClick={() => setValue(3)}>
              <ButtonSelectedMint number="3" />
            </div>
          </div>
        )}

        {errMessage !== "" ? (
          <div className="text-center mb-12 reg-caption text-color-secondary">
            {errMessage}
          </div>
        ) : (
          ""
        )}

        {isMinting ? (
          <div className="button-get-machinie cursor-pointer">
            <div className="m-auto-tb text-machinie text-white">
              MINT
              <LoadingDots /> {value} HUMACH
            </div>
          </div>
        ) : (
          <div
            className="button-get-machinie cursor-pointer"
            onClick={() => {
              publicMintHumach(value);
            }}
          >
            <div className="m-auto-tb text-machinie text-white">
              MINT {value} HUMACH
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type MODAL_MINT_NUMBER = {
  open: boolean;
  setIsOpen: any;
};

export const ModalMintNumber = ({ open, setIsOpen }: MODAL_MINT_NUMBER) => {
  const [isEnter, setIsEnter] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [callTotal, setCallTotal] = useState(0);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    theTotalSupply();
    listenMMAccount();
  }, []);

  const theTotalSupply = async () => {
    try {
      setErrMessage("");
      const getBreedAmount = await humach.methods.getBreedAmount().call();
      const mintAmount = await humach.methods.getMintAmount().call();
      const migrateAmount = await humach.methods.getUpgradeAmount().call();
      setTotalSupply(parseInt(mintAmount) + parseInt(migrateAmount));
      setCallTotal(getBreedAmount);
    } catch (err: any) {
      setTotalSupply(0);
      setCallTotal(0);
      setErrMessage("Please switch to eth mainnet");
    }
  };

  const listenMMAccount = async () => {
    if (window.ethereum) {
      window.ethereum.on("networkChanged", async function () {
        theTotalSupply();
      });
    }
  };

  return (
    <Modal open={open} onClose={() => setIsOpen(false)}>
      <div className="p-24">
        <div className="close-modal-container">
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="fa fa-times close-modal"></i>
          </div>
        </div>

        <div className="text-color-gray-100">
          <div className="mt-24 bold-headline-5 text-center">Public Mint</div>

          {errMessage === "" ? (
            <div>
              <div className="mt-24 d-flex justify-content-between">
                <div className="bold-subtitle-2">
                  Initial mint{" "}
                  <span
                    className="text-color-tertiary position-relative cursor-pointer"
                    style={{ fontSize: "25px" }}
                    onMouseEnter={() => setIsEnter(true)}
                    onMouseLeave={() => setIsEnter(false)}
                  >
                    {" "}
                    <MouseEnterPanelTop isEnter={isEnter} />
                    <IconInfo></IconInfo>
                  </span>
                </div>
                <div className="reg-subtitle-2">
                  {totalSupply} / {VALUE_STATIC.MINT_PANEL.TOTAL_SUPPLY}
                </div>
              </div>

              <div className="mt-14 d-flex justify-content-between">
                <div className="bold-subtitle-2">Call mint</div>
                <div className="reg-subtitle-2">
                  {callTotal} / {VALUE_STATIC.MINT_PANEL.TOTLA_CALL_SUPPLY}
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-24">
              <div className="text-center text-color-secondary bold-subtitle-2">
                {errMessage}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

type MOUSE_ENTER_PANEL_TOP = {
  isEnter: boolean;
};

export const MouseEnterPanelTop = ({ isEnter }: MOUSE_ENTER_PANEL_TOP) => {
  return (
    <div
      className={
        isEnter ? "mouse-enter-panel-top-mint" : "mouse-enter-panel-hide"
      }
    >
      <div className="reg-subtitle-2 text-color-gray-100">
        <div className="">888 for genesis</div>
        <div className="">2562 for mint</div>
        <div className="">50 for reserved</div>
      </div>
      <div className="triangle-container">
        <div className="triangle"></div>
      </div>
    </div>
  );
};
