import { api } from "../../../axiosURL";
import { useState, useEffect } from "react";
import { humachLogo } from "../../../imageForMachiniverse";
import { HumachLevelContainer } from "../MyHumachChilling/MyHumachChillingEach";
import humach from "../../../humach";
import { MyNftMore } from "../MyNftMore/MyNftMore";
import { CONTRACT_TOKEN } from "../../../value";
import { LoadingDots } from "../../component/LoadingDots";

type MY_HUMACH_TRAINING_EACH = {
  humachId: string;
  setHumachArrayForStopTrain: any;
  humachArrayForStopTrain: string[];
  setWalletList: any;
  humachListEach: any;
  setHumachList: any;
  selectIdMoreToggle: string;
  setSelectIdMoreToggle: any;
  floppy: any;
  setFloppy: any;
};

export const MyHumachTrainingEach = ({
  humachId,
  setHumachArrayForStopTrain,
  humachArrayForStopTrain,
  setWalletList,
  humachListEach,
  setHumachList,
  selectIdMoreToggle,
  setSelectIdMoreToggle,
  floppy,
  setFloppy,
}: MY_HUMACH_TRAINING_EACH) => {
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;

  const [isSelect, setIsSelect] = useState(false);
  const [humachImage, setHumachImage] = useState<any>(humachLogo);
  const [IsClaiming, setClaiming] = useState(false);
  const [humachLevel, setHumachLevel] = useState<any>(["0", "0"]);
  const [tokenName, setTokenName] = useState("");

  useEffect(() => {
    getStart();
  }, []);

  useEffect(() => {
    if (humachArrayForStopTrain.includes(humachId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [humachArrayForStopTrain]);

  const getStart = async () => {
    setClaiming(false);
    const thisImage = await getImage(humachId);
    setHumachImage(thisImage);
    await getTokenNameHumach(humachId);
  };

  const getImage = async (humachId: string) => {
    const res = await api.get(
      "https://api.machinienft.com/api/humach/metadata/" + humachId
    );
    const getImageArray = res.data.image_list;

    const getImage = getImageArray[getImageArray.length - 1]["image"];

    const getIpfsImage = getImage.replace(
      "https://gateway.pinata.cloud/ipfs/",
      "https://ipfs.io/ipfs/"
    );
    // setHumachWith(getIpfsImage);
    // return getIpfsImage;
    return getImage;
  };

  const selectToStopTrain = () => {
    const getSelectArray = [...humachArrayForStopTrain];
    getSelectArray.push(humachId);
    setHumachArrayForStopTrain(getSelectArray);
  };

  const removeSelect = () => {
    const getSelectArray = [...humachArrayForStopTrain];
    var filteredArray = getSelectArray.filter((e) => e !== humachId);
    setHumachArrayForStopTrain(filteredArray);
  };

  const onClickClaim = async () => {
    setClaiming(true);
    const url = `/api/humach/claim/${humachListEach["id"]}`;
    const res = await api.post(
      url,
      {},
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );

    const thisHumach = await getHumachList();
    const thisWalletList = await getWalletList();
    setHumachList(thisHumach.data);
    setWalletList(thisWalletList.data);
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // //console.log(res.data);
      return res.data;
    }
  };

  const getTokenNameHumach = async (tokenId: string | undefined) => {
    try {
      const res = await humach.methods.getTokenIdName(tokenId).call();
      setTokenName(
        res[0] !== "" ? res[0] + " #" + tokenId : "Humach #" + tokenId
      );
      return;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={
        !isSelect
          ? "machinie-each-container"
          : "machinie-each-container-selected"
      }
    >
      {isSelect ? (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(false);
            removeSelect();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-check-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      ) : (
        <div
          className="machinie-each-top cursor-pointer"
          onClick={() => {
            setIsSelect(true);
            selectToStopTrain();
          }}
        >
          <div className="bold-subtitle-2 machinie-each-id text-color-gray-100">
            {tokenName}
          </div>
          <div className="check-box-container">
            <i className="fa fa-square fa-lg text-color-gray-100"></i>
          </div>
        </div>
      )}
      <div className="machinie-each-detail">
        <div className="display-mobile">
          <div className="img-machinie-each-container">
            <img
              src={humachImage}
              alt="humach"
              className="img-machinie-each"
              width="100%"
              height="100%"
            ></img>
          </div>
        </div>

        <div className="border-left-mobile">
          <div className="text-color-gray-100 reg-caption machinie-each-text-second-container">
            <div className="machinie-each-text-second border-text">
              Training
            </div>
          </div>

          <div className="display-mobile">
            <div className="claim-current-contianer">
              {!humachListEach["isClaim"] ? (
                IsClaiming ? (
                  <div className="claim-current-padding">
                    <div className="claimed-current">
                      <div className="bold-button text-color-gray-100">
                        CLAIMING
                        <LoadingDots />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="claim-current-padding">
                    <div
                      className="claim-current cursor-pointer"
                      onClick={() => onClickClaim()}
                    >
                      <div className="bold-button text-color-gray-100">
                        CLAIM 5{" "}
                        <span>
                          <img src={current} alt="current" width="13.5px"></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIM 5{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <MyNftMore
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                id={humachId}
                contractToken={CONTRACT_TOKEN.HUMACH}
                floppy={floppy}
                setFloppy={setFloppy}
                humachLevel={humachLevel}
                isTraining={true}
              />
            </div>
          </div>

          <div className="display-mobile">
            <HumachLevelContainer
              humachId={humachId}
              humachLevel={humachLevel}
              setHumachLevel={setHumachLevel}
            />
          </div>
        </div>
        <div className="display-desktop">
          <div className="img-machinie-each-container">
            <img
              src={humachImage}
              alt="humach"
              className="img-machinie-each"
              width="100%"
            ></img>
          </div>
        </div>

        <div className="display-desktop">
          <HumachLevelContainer
            humachId={humachId}
            humachLevel={humachLevel}
            setHumachLevel={setHumachLevel}
          />
        </div>

        <div className="display-desktop">
          <div className="claim-current-contianer">
            {!humachListEach["isClaim"] ? (
              IsClaiming ? (
                <div className="claim-current-padding">
                  <div className="claimed-current">
                    <div className="bold-button text-color-gray-100">
                      CLAIMING
                      <LoadingDots />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="claim-current-padding">
                  <div
                    className="claim-current cursor-pointer"
                    onClick={() => onClickClaim()}
                  >
                    <div className="bold-button text-color-gray-100">
                      CLAIM 5{" "}
                      <span>
                        <img src={current} alt="current" width="13.5px"></img>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="claim-current-padding">
                <div className="claimed-current">
                  <div className="bold-button text-color-gray-100">
                    CLAIM 5{" "}
                    <span>
                      <img src={current} alt="current" width="13.5px"></img>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <MyNftMore
              selectIdMoreToggle={selectIdMoreToggle}
              setSelectIdMoreToggle={setSelectIdMoreToggle}
              id={humachId}
              contractToken={CONTRACT_TOKEN.HUMACH}
              floppy={floppy}
              setFloppy={setFloppy}
              humachLevel={humachLevel}
              isTraining={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
