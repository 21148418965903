export const API_REDEEM_MERCH = [
  {
    name: "TeamMach hoodie",
    description:
      "Crypto winter is coming and our Machinie beep us messages warning it will last longer than ever. This TeamMach hoodie is a 100% cotton tailored with a magical design to keep our Humach warm during the journey. Now suit up as we've got a long hunt ahead of us.",
    type: "Prize",
    closeIn: 1658941200,
    max: "TBA",
    maxPerUser: "3",
    need: "60",
    configId: "62c862bd1c669a2a74b58609",
    image: "https://machiniverse.xyz/assets/merch/TeamMach-hoodies.jpg",
  },
];

export const API_REDEEM_UP_COMING = [
  {
    name: "TeamMach hoodie",
    description:
      "Crypto winter is coming and our Machinie beep us messages warning it will last longer than ever. This TeamMach hoodie is a 100% cotton tailored with a magical design to keep our Humach warm during the journey. Now suit up as we've got a long hunt ahead of us.",
    type: "Prize",
    openIn: 1659906000, //1659894960 //1659906000
    closeIn: 1658941200,
    max: "3 per user",
    maxPerUser: "3",
    need: "60",
    configId: "62c862bd1c669a2a74b58609",
    image: "https://machiniverse.xyz/assets/merch/TeamMach-hoodies.jpg",
  },
];

export const API_REDEEM = [
  {
    name: "Team Merch Hoodie",
    description: "Machinie Merch Store",
    type: "SHOP",
    closeIn: 1658941200,
    max: 30,
    need: 100,
    image:
      "https://cdn.shopify.com/s/files/1/0279/1152/0390/products/E0C6AAB4-0764-4CCA-BC2D-59E6AB285E0F_1024x1024@2x.png?v=1643235906",
  },
];

export const API_REDEEM_OUTDATE = [
  {
    name: "TeamMach hoodie",
    description:
      "Crypto winter is coming and our Machinie beep us messages warning it will last longer than ever. This TeamMach hoodie is a 100% cotton tailored with a magical design to keep our Humach warm during the journey. Now suit up as we've got a long hunt ahead of us.",
    type: "Prize",
    closeIn: 1658941200,
    max: "3 per user",
    maxPerUser: "3",
    need: "60",
    configId: "62c862bd1c669a2a74b58609",
    image: "https://machiniverse.xyz/assets/merch/TeamMach-hoodies.jpg",
  },
  {
    name: "Plant Frens Allowlist",
    description:
      "Built for the metaverse and online games, Plant Frens are access keys for gated activities, giveaways and metaverse spaces.",
    type: "FCFS",
    closeIn: 1654827864,
    max: 4,
    need: 17,
    image:
      "https://cdn.discordapp.com/attachments/869437082639155280/955821928730492928/IMG_1650.png",
  },
  {
    name: "Santa Mach",
    description: "Happy giving season! This SantaMach has come for a give.",
    type: "Prize",
    max: "no limit",
    need: 20,
    image:
      "https://lh3.googleusercontent.com/JMkKCmoofBJL4uB-Rdy52Yy8NP-_svv_nLmyj0Db2beoVSjTLj4AMJoDAVdPBeuBlKMKdWpk4j2v70I3Pun8aBGJiDXvlre50SmR=w720",
  },
];
