import { useEffect, useState, useRef } from "react";
import { api } from "../../axiosURL";
import jwt from "jsonwebtoken";
import web3 from "../../web3";
import { guestWalletList, guestUser } from "../../guest_wallet";
import { NETWORKS } from "../../value";
import floppyContract from "../../floppyContract";
import {
  IconCenterDot,
  IconClose,
  IconMetamask,
  IconWallet,
} from "../component/Icon";
import { checkRandomName } from "../../apiCallReturn";
import { machinie_image_url } from "../../machinieImageUrl2";
import { LoadingDots } from "../component/LoadingDots";
import { ProfilePanel } from "./ProfilePanel";
import { Link } from "react-router-dom";
import { AnimateNumber } from "../animate_numbers/AnimateNumber";

declare var window: any;

type CONNECT_WALLET_PROPS_TYPES = {
  toggleProfile: boolean;
  setToggleProfile: any;
  setWalletList: any;
  walletList: any;
  setFloppy: any;
  floppyBalance: string;
  setWalletAddress: any;
  setMemberInfo: any;
  setMachinieList: any;
  setHumachList: any;
  isMainnet: string;
  setIsMainnet: any;
  walletAddress: string;
  memberInfo: any;
};

export const ConnectWallet = ({
  toggleProfile,
  setToggleProfile,
  walletList,
  setWalletList,
  setFloppy,
  floppyBalance,
  setWalletAddress,
  setMemberInfo,
  setMachinieList,
  setHumachList,
  isMainnet,
  setIsMainnet,
  walletAddress,
  memberInfo,
}: CONNECT_WALLET_PROPS_TYPES) => {
  const [checkLogin, setCheckLogin] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const [isConnected, setIsConnected] = useState(false);
  const [isChangingWallet, setIsChangingWallet] = useState(false);

  const [isReOpened, setIsReOpened] = useState<undefined | boolean>(undefined);

  let timestampReopen = 1675969200; //10 feb 2022 02:00 indo time
  // let timestampReopen = 1675958220;

  var nextRound: any = new Date(timestampReopen * 1000);

  useEffect(() => {
    const interval = setInterval(async () => {
      var now = new Date().getTime();

      var distanceRN = nextRound - now;

      var daysNR = Math.floor(distanceRN / (1000 * 60 * 60 * 24));
      var hoursNR = Math.floor(
        (distanceRN % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutesNR = Math.floor((distanceRN % (1000 * 60 * 60)) / (1000 * 60));
      var secondsNR = Math.floor((distanceRN % (1000 * 60)) / 1000);

      if (daysNR <= 0 && hoursNR <= 0 && minutesNR <= 0 && secondsNR <= 0) {
        setIsReOpened(true);
      } else {
        setIsReOpened(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isReOpened) {
      getStart();
      listenMMAccount();
    }
  }, [isReOpened]);

  const getStart = async () => {
    // setIsConnected(false);
    const res = await web3.eth.getAccounts();
    const network = await web3.eth.net.getNetworkType();
    // const network = "main";
    if (res[0] === undefined) {
      setIsConnecting(false);
      if (localStorage.getItem("accessToken")) {
        await logout();
      }
    } else {
      if (!localStorage.getItem("accessToken")) {
        setIsConnected(false);
        setIsConnecting(false);
      } else {
        setIsConnecting(true);

        const thisUser = await getUser();

        if (thisUser.data.walletAddress === res[0]) {
          const thisWalletList = await getWalletList();
          const thisMachinieList = await getMachinieList();
          const thisHumachList = await getHumachList();
          await checkRandomName(thisUser.data);

          if (network === "main") {
            const getFloppy = await floppyContract.methods
              .balanceOf(res[0])
              .call();
            const toWei = await web3.utils.fromWei(getFloppy, "ether");
            const floppyToInt = parseInt(toWei).toFixed(0);
            setFloppy(floppyToInt);
            setIsMainnet(NETWORKS.ETH_MAINNET);
          } else {
            setIsMainnet(NETWORKS.NOT_MAINNET);
          }

          setWalletList(thisWalletList.data);
          setWalletAddress(res[0]);
          setMemberInfo(thisUser.data);
          setMachinieList(thisMachinieList.data);
          setHumachList(thisHumachList.data);
          setIsConnecting(false);
          setIsConnected(true);
        } else {
          await connectMetamask();
        }
      }
    }
  };

  const connectMetamask = async () => {
    try {
      setIsConnecting(true);
      if (localStorage.getItem("accessToken")) {
        await logout();
      }
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const res = await web3.eth.getAccounts();
      const network = await web3.eth.net.getNetworkType();
      // console.log("network : ", network);
      if (res[0] !== undefined) {
        if (network === "main") {
          const getFloppy = await floppyContract.methods
            .balanceOf(res[0])
            .call();
          const toWei = await web3.utils.fromWei(getFloppy, "ether");
          const floppyToInt = parseInt(toWei).toFixed(0);
          setFloppy(floppyToInt);
          setIsMainnet(NETWORKS.ETH_MAINNET);
        } else {
          setFloppy(0);
          setIsMainnet(NETWORKS.NOT_MAINNET);
        }
        const configValue: string | undefined =
          process.env.REACT_APP_SECRET_KEY;
        const configValue2: string | undefined =
          process.env.REACT_APP_SECOND_SECRET_KEY;
        const token = jwt.sign(res[0], configValue as string);
        const secondToken = jwt.sign(token, configValue2 as string);
        await checkUser(secondToken);
        setWalletAddress(res[0]);
        setIsConnecting(false);
        setIsConnected(true);
      }
    } catch (err) {
      setIsConnecting(false);
    }
  };

  const listenMMAccount = async () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function () {
        setIsChangingWallet(true);
        const accounts = await web3.eth.getAccounts();
        if (accounts[0] === undefined) {
          // //console.log("logout");
          logout();
        } else {
          //console.log(accounts[0]);
          connectMetamask();
        }
        setIsChangingWallet(false);
      });

      window.ethereum.on("networkChanged", async function () {
        const network = await web3.eth.net.getNetworkType();
        if (network === "main") {
          setIsMainnet(NETWORKS.ETH_MAINNET);
          connectMetamask();
        } else {
          setIsMainnet(NETWORKS.NOT_MAINNET);
          connectMetamask();
        }
      });
    }
  };

  const checkUser = async (key: string) => {
    if (!checkLogin) {
      setCheckLogin(true);
      try {
        const res = await api.post("/api/user/check", {
          secretKey: key,
        });

        if (
          res["data"]["data"]["isCreated"] === false &&
          res["data"]["data"]["isHave"] === true
        ) {
          await createUser(key);
        } else if (
          res["data"]["data"]["isCreated"] === true &&
          res["data"]["data"]["isHave"] === true
        ) {
          await login(key);
        } else if (
          res["data"]["data"]["isCreated"] === false &&
          res["data"]["data"]["isHave"] === false
        ) {
          setMachinieList([]);
          setWalletList(guestWalletList);
          setHumachList([]);
          setMemberInfo(guestUser);
        } else if (
          res["data"]["data"]["isCreated"] === true &&
          res["data"]["data"]["isHave"] === false
        ) {
          await login(key);
        }
      } catch (err) {
        //console.log(err);
      }
    } else {
      return;
    }
    setCheckLogin(false);
  };

  const createUser = async (key: string) => {
    if (!isCreatingUser) {
      setIsCreatingUser(true);
      try {
        const res = await api.post("/api/user/", {
          secretKey: key,
        });

        await login(key);
      } catch (err) {
        //console.log(err);
      }
    } else {
      return;
    }

    setIsCreatingUser(false);
  };

  const login = async (key: string) => {
    console.log("logining...");
    if (localStorage.getItem("accessToken")) {
      await logout();
    }
    try {
      const res = await api.post("/api/user/login", {
        username: key,
        password: key,
      });

      localStorage.setItem("accessToken", res.data.data.access_token);

      const thisUser = await getUser();
      const thisWalletList = await getWalletList();
      const thisMachinieList = await getMachinieList();
      const thisHumachList = await getHumachList();
      checkRandomName(thisUser.data);

      //console.log("thisWalletList login", thisWalletList);
      //console.log("thisMachinieList login", thisMachinieList);
      //console.log("thisHumachList login", thisHumachList);

      setMachinieList(thisMachinieList.data);
      setHumachList(thisHumachList.data);
      setMemberInfo(thisUser.data);
      setWalletList(thisWalletList.data);
    } catch (err) {
      //console.log(err);
    }
  };

  const logout = async () => {
    try {
      const res = await api.get("/api/user/logout", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      await localStorage.removeItem("accessToken");
      setWalletList([]);
      setMemberInfo([]);
      setIsConnected(false);
    } catch (err) {
      //console.log(err);
    }
  };

  const getWalletList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/wallet/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      // //console.log(res.data);
      return res.data;
    }
  };

  const getMachinieList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/machinie/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  const getUser = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/user/", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return !isConnected ? (
    isConnecting ? (
      <div className="wallet-connect-container">
        <div className="display-wallet-connect">
          <div className="m-auto wallet-logo">
            <IconWallet />
          </div>
          <div className="connect-wallet-button m-auto text-machinie font-bold-mini text-white">
            CONNECTING
            <LoadingDots />
          </div>
        </div>
      </div>
    ) : (
      <div
        className={
          isReOpened
            ? "wallet-connect-container cursor-pointer"
            : "wallet-connect-container cursor-pointer off"
        } //need to remove off
        onClick={() => (isReOpened ? connectMetamask() : "")}
      >
        <div className="display-wallet-connect">
          <div className="m-auto wallet-logo">
            <IconWallet />
          </div>
          <div className="connect-wallet-button m-auto text-machinie font-bold-mini text-white">
            CONNECT WALLET
          </div>
        </div>
      </div>
    )
  ) : (
    <ConnectedWallet
      toggleProfile={toggleProfile}
      setToggleProfile={setToggleProfile}
      walletList={walletList}
      floppyBalance={floppyBalance}
      walletAddress={walletAddress}
      memberInfo={memberInfo}
      isMainnet={isMainnet}
      setMemberInfo={setMemberInfo}
      setWalletList={setWalletList}
    />
  );
};

type CONNECTED_WALLET_PROPS = {
  setToggleProfile: any;
  toggleProfile: boolean;
  walletList: any;
  floppyBalance: string;
  walletAddress: string;
  memberInfo: any;
  isMainnet: any;
  setMemberInfo: any;
  setWalletList: any;
};

export const ConnectedWallet = ({
  setToggleProfile,
  toggleProfile,
  walletList,
  floppyBalance,
  walletAddress,
  memberInfo,
  isMainnet,
  setMemberInfo,
  setWalletList,
}: CONNECTED_WALLET_PROPS) => {
  const floppyImag: string = `${process.env.PUBLIC_URL}/assets/gameAssets/Floppy.png`;
  const current: string = `${process.env.PUBLIC_URL}/assets/gameAssets/current.png`;
  const machinie =
    "https://lh3.googleusercontent.com/DRYsG5KGYyH0tedfXxUOcErm3cHVYzqFYmz0QzD1qtg1uCaz_tkzTOjzJi7fju95brh4WCy6hdb8vvzJ7SzjImZyxaQPL6j9vP5f";

  const toToggleProfile = () => {
    if (!toggleProfile) {
      setToggleProfile(true);
    } else {
      setToggleProfile(false);
    }
  };

  const [windowSize, setWindowSize] = useState<number>(0);

  const updateDimensions = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    setWindowSize(window.innerWidth);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div
      className="wrapper connect"
      onMouseEnter={() => {
        if (windowSize > 1000) {
          setToggleProfile(true);
        }
      }}
      onMouseLeave={() => {
        if (windowSize > 1000) {
          setToggleProfile(false);
        }
      }}
    >
      {windowSize > 1000 ? (
        <Link
          to="/my-machinie/chilling"
          className="connected-wallet-container cursor-pointer"
          // onClick={() => toToggleProfile()}
        >
          <div className="connected-wallet">
            <div className="d-flex">
              <div>
                <img src={floppyImag} alt="floppy" width={"18px"}></img>
              </div>
              <div className="reg-subtitle-2 text-color-gray-100 m-auto pl-6">
                {floppyBalance}
              </div>
            </div>
            <IconCenterDot colorClass="text-color-tertiary" />
            <div className="d-flex">
              <div>
                <img src={current} alt="current" width={"18px"}></img>
              </div>
              <div className="reg-subtitle-2 text-color-gray-100 m-auto pl-6">
                {/* {walletList.floppy} */}{" "}
                <AnimateNumber num={walletList.floppy} />
              </div>
            </div>
          </div>
          <div className="pfp-container">
            <img
              src={machinie_image_url[594]}
              className="pfp-image-connected"
              alt="pfp"
              width="46px"
            ></img>
          </div>
        </Link>
      ) : (
        <div
          className="connected-wallet-container cursor-pointer"
          onClick={() => {
            if (windowSize <= 1000) {
              toToggleProfile();
            }
          }}
        >
          <div className="connected-wallet">
            <div className="d-flex">
              <div>
                <img src={floppyImag} alt="floppy" width={"18px"}></img>
              </div>
              <div className="reg-subtitle-2 text-color-gray-100 m-auto pl-6">
                {floppyBalance}
              </div>
            </div>
            <IconCenterDot colorClass="text-color-tertiary" />
            <div className="d-flex">
              <div>
                <img src={current} alt="current" width={"18px"}></img>
              </div>
              <div className="reg-subtitle-2 text-color-gray-100 m-auto pl-6">
                <AnimateNumber num={walletList.floppy} />
              </div>
            </div>
          </div>
          <div className="pfp-container">
            <img
              src={machinie_image_url[594]}
              className="pfp-image-connected"
              alt="pfp"
              width="46px"
            ></img>
          </div>
        </div>
      )}

      <div
        className={
          toggleProfile ? "test-profile-panel show" : "test-profile-panel"
        }
      >
        <ProfilePanel
          setToggleProfile={setToggleProfile}
          walletList={walletList}
          floppy={floppyBalance}
          walletAddress={walletAddress}
          memberInfo={memberInfo}
          isMainnet={isMainnet}
          setMemberInfo={setMemberInfo}
          setWalletList={setWalletList}
        />
      </div>
    </div>
  );
};

export const MetamaskPanel = () => {
  return (
    <div className="border vh-100 col-12 metamask-panel">
      <div className="border">
        <div className="d-flex p-5">
          <div>Connect wallet</div>
          <div>
            <IconClose />
          </div>
        </div>
      </div>
      <div>
        <div className="p-5 border">
          <div className="p-5 d-flex">
            <IconMetamask size="46" />
            <div>
              <div className="bold-subtitle-1 text-color-gray-100">
                Metamask
              </div>
              <div className="reg-caption text-color-line-tint-04">
                Using a browser extension.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
