import { useEffect, useState } from "react";
import { getLottoAdminBoard, getLottoStatus } from "../../../apiCallReturn";
import { api } from "../../../axiosURL";
import { API } from "../../../valueApi";
import { LoadingDots } from "../../component/LoadingDots";
import { CountDownPanel } from "../../learn_game/PlayLotto";
import web3 from "../../../web3";
import humach from "../../../humach";

export const CreateLottoConfig = () => {
  const [wantToCreate, setWantToCreate] = useState(false);
  const [array, setArray] = useState<any>([]);
  const [createSuccess, setCreateSuccess] = useState(false);
  return (
    <div className="p-24 bg-1">
      <div className="text-color-gray-100 bold-headline-4">Banana Lotto</div>

      {/* <div className="p-3">
        <WithdrawEth></WithdrawEth>
      </div> */}


    <CalculateLottoTime ></CalculateLottoTime>

    <div  className="pt-4 pb-4" >
      {wantToCreate ? (
        !createSuccess ? (
          <OnCreateLottoConfig
            setWantToCreate={setWantToCreate}
            setArray={setArray}
          />
        ) : (
          <CraeteSuccess
            setWantToCreate={setWantToCreate}
            setCreateSuccess={setCreateSuccess}
          />
        )
      ) : (
        <ClickToCrate setWantToCreate={setWantToCreate} />
      )}  
      </div>

      <div className="pt-4 pb-4">
        <LottoBoard setArray={setArray} array={array} />
      </div>


    </div>
  );
};

type ON_CREATE_LOTTO_CONFIG = {
  setWantToCreate: any;
  setArray: any;
};

export const OnCreateLottoConfig = ({
  setWantToCreate,
  setArray,
}: ON_CREATE_LOTTO_CONFIG) => {
  const [name, setName] = useState("");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [raffleDate, setRaffleDate] = useState("");
  const [minEntry, setMinEntry] = useState(1);
  const [maxEntry, setMaxEntry] = useState(30);
  const [type, setType] = useState("61c00c4752e1603b9cb95b4f");
  const [isSaving, setIsSaving] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const createConfigLotto = async () => {
    setIsSaving(true);
    console.log("save!");
    try {
      if (localStorage.getItem("adminToken")) {
        const res = await api.post(
          API.LOTTO_CREATE.CREATE,
          {
            name: name,
            openDate: Number(openDate),
            closeDate: Number(closeDate),
            raffleDate: Number(raffleDate),
            minEntry: minEntry,
            maxEntry: maxEntry,
            walletConfigId: type,
            isActive: true,
          },
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("adminToken"),
            },
          }
        );
      }
      const thisAdminBoard: any = await getLottoAdminBoard();
      setArray(thisAdminBoard.data.data);
      setIsSaving(false);
      setWantToCreate(false);
    } catch (err: any) {
      setIsSaving(false);
      setErrMessage(err.message);
      console.log("err", err.message);
    }
  };

  // headers: {
  //   authorization: "Bearer " + localStorage.getItem("adminToken"),
  // },

  return (
    <div className="text-color-gray-100">
      <div>
        <input
          placeholder="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
      </div>
      <div>
        <input
          placeholder="openDate (timestamp)"
          value={openDate}
          onChange={(e) => setOpenDate(e.target.value)}
        ></input>
      </div>
      <div>
        <input
          placeholder="closeDate (timestamp)"
          value={closeDate}
          onChange={(e) => setCloseDate(e.target.value)}
        ></input>
      </div>
      <div>
        <input
          placeholder="raffleDate (timestamp)"
          value={raffleDate}
          onChange={(e) => setRaffleDate(e.target.value)}
        ></input>
      </div>
      <div>
        <input
          placeholder="min entry"
          value={minEntry}
          onChange={(e) => setMinEntry(Number(e.target.value))}
        ></input>
      </div>
      <div>
        <input
          placeholder="max entry"
          value={maxEntry}
          onChange={(e) => setMaxEntry(Number(e.target.value))}
        ></input>
      </div>
      <div>
        <input
          placeholder="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        ></input>
      </div>
      <div>61c00c4752e1603b9cb95b4f : copy this for ticket type</div>

      {isSaving ? (
        <div className="btn btn-primary">
          SAVE
          <LoadingDots />
        </div>
      ) : (
        <div className="btn btn-primary" onClick={() => createConfigLotto()}>
          SAVE
        </div>
      )}

      <div className="btn btn-primary" onClick={() => setWantToCreate(false)}>
        Close
      </div>

      {errMessage.length > 0 ? (
        <div className="text-color-secondary reg-subtitle-2">{errMessage}</div>
      ) : (
        ""
      )}
    </div>
  );
};

type CLICK_TO_CREATE = {
  setWantToCreate: any;
};

export const ClickToCrate = ({ setWantToCreate }: CLICK_TO_CREATE) => {
  return (
    <div className="btn btn-primary" onClick={() => setWantToCreate(true)}>
      Create
    </div>
  );
};

type LOTTO_BOARD = {
  array: any;
  setArray: any;
};

export const LottoBoard = ({ array, setArray }: LOTTO_BOARD) => {
  useEffect(() => {
    getStart();
  }, []);

  const getStart = async () => {
    const thisAdminBoard: any = await getLottoAdminBoard();
    setArray(thisAdminBoard.data.data);
  };
  return (
    <div className="lotto-board-admin-container">
      {array.length > 0 ? array.map((x: any) => <LottoBoardEach x={x} />) : ""}
    </div>
  );
};

type LOTTO_BOARD_EACH = {
  x: any;
};

export const LottoBoardEach = ({ x }: LOTTO_BOARD_EACH) => {
  return (
    <div className="pt-1 pb-1">
      <div className="text-color-gray-100 reg-subtitle-2">
        <span>{x.lottoConfigId}</span> <span>{x.name}</span>{" "}
      </div>
      <div className="text-color-gray-100 reg-subtitle-2">
        OD <CountDownPanel timestamp={x.openDate / 1000} />
      </div>

      <div className="text-color-gray-100 reg-subtitle-2">
        CD <CountDownPanel timestamp={x.closeDate / 1000} />
      </div>

      <div className="text-color-gray-100 reg-subtitle-2">
        RD <CountDownPanel timestamp={x.raffleDate / 1000} />
      </div>
    </div>
  );
};

type CREATE_SUCCESS = {
  setWantToCreate: any;
  setCreateSuccess: any;
};

export const CraeteSuccess = ({
  setWantToCreate,
  setCreateSuccess,
}: CREATE_SUCCESS) => {
  return (
    <div>
      <div className="text-color-primary-200">CREATE SUCCESS!</div>
      <div
        className="btn btn-primary"
        onClick={() => {
          setWantToCreate(false);
          setCreateSuccess(false);
        }}
      >
        CLOSE
      </div>
    </div>
  );
};


const CalculateLottoTime = () => {
  const [openTime, setOpenTime] = useState(0);
  const [closeTime, setCloseTime] = useState(0);
  const [raffleTime, setRaffleTime] = useState(0);

  const handleChange =  (e: React.ChangeEvent<HTMLInputElement>) => {

    const newValue = parseFloat(e.target.value);
    if(!isNaN(newValue)){
      setOpenTime(newValue);
      setCloseTime(newValue + 85800);
      setRaffleTime(newValue + 86400);
    }

  }
  
  return <div className="text-white">
    <input value={openTime} onChange={(e) => handleChange(e)}></input>
      <div>
        <div>timeStamp : {openTime}</div>
        <div>OD <CountDownPanel timestamp={openTime} /></div>
      </div>
      <div>
        <div>timeStamp : {closeTime}</div>
        <div>OD <CountDownPanel timestamp={closeTime} /></div>
      </div>
      <div>
        <div>timeStamp : {raffleTime}</div>
        <div>OD <CountDownPanel timestamp={raffleTime} /></div>
      </div>
  </div>
}



const WithdrawEth = () => {
  const withdrawEth = async () => {
    try{
      const accounts = await web3.eth.getAccounts();
      await humach.methods.withdraw().send({
        from: accounts[0],
      });
    }catch(err){
      console.log('err');
    }
  }

  return <button className="btn btn-primary" onClick={() => withdrawEth()}>withdraw eth</button>
}