import { NavRight } from "./NavRight";
import { NavLeft } from "./NavLeft";
import { AnnounceStake } from "../homepage/AnnounceStake";
import { SubNav } from "./SubNav";
import { SideNav } from "./SideNav";
import { useState, useEffect, useCallback } from "react";
import { SELECT_PAGE } from "../../value";
import { LoadingScreen } from "../loading_screen/LoadingScreen";

type NAV_PROPS_TYPES = {
  selectPage: string | number;
  walletList: any;
  setWalletList: any;
  floppy: string;
  setFloppy: any;
  walletAddress: string;
  setWalletAddress: any;
  memberInfo: string[];
  setMemberInfo: any;
  setMachinieList: any;
  setHumachList: any;
  isMainnet: string;
  setIsMainnet: any;
  toggleProfile: boolean;
  setToggleProfile: any;
  selecting: string;
  toggleSideNav: boolean;
  setToggleSideNav: any;
};

export const Nav = ({
  selectPage,
  walletList,
  setWalletList,
  floppy,
  setFloppy,
  walletAddress,
  setWalletAddress,
  memberInfo,
  setMemberInfo,
  setMachinieList,
  setHumachList,
  isMainnet,
  setIsMainnet,
  toggleProfile,
  setToggleProfile,
  selecting,
  toggleSideNav,
  setToggleSideNav,
}: NAV_PROPS_TYPES) => {
  useEffect(() => {
    if (!toggleSideNav) {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    }
    console.log("here hello");
  }, [toggleSideNav]);

  const [y, setY] = useState(window.scrollY);

  const [show, setShow] = useState(true);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setShow(true);
      } else if (y < window.scrollY) {
        setShow(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <nav className={!toggleSideNav ? "nav-container" : ""}>
      {/* {selectPage === SELECT_PAGE.HOMEPAGE ? (
        <AnnounceStake
          toggleProfile={toggleProfile}
          toggleSideNav={toggleSideNav}
        />
      ) : (
        ""
      )} */}

      <div className={!toggleSideNav ? "" : "nav-container-side-bar"}>
        <div className={"nav-display"}>
          <div className="container d-flex justify-content-between">
            <NavLeft
              setToggleSideNav={setToggleSideNav}
              toggleSideNav={toggleSideNav}
              toggleProfile={toggleProfile}
              setToggleProfile={setToggleProfile}
              selecting={selecting}
            />
            <NavRight
              walletList={walletList}
              setWalletList={setWalletList}
              floppy={floppy}
              setFloppy={setFloppy}
              walletAddress={walletAddress}
              setWalletAddress={setWalletAddress}
              memberInfo={memberInfo}
              setMemberInfo={setMemberInfo}
              setMachinieList={setMachinieList}
              setHumachList={setHumachList}
              isMainnet={isMainnet}
              setIsMainnet={setIsMainnet}
              toggleProfile={toggleProfile}
              setToggleProfile={setToggleProfile}
              setToggleSideNav={setToggleSideNav}
              toggleSideNav={toggleSideNav}
            />
          </div>{" "}
        </div>
      </div>

      {selectPage === SELECT_PAGE.HOMEPAGE ? (
        !toggleSideNav ? (
          <div className="sub-nav-up-container">
            <div className={show ? "sub-nav-up" : "sub-nav-up hide-sub-nav"}>
              <SubNav show={show} />
            </div>
          </div>
        ) : (
          <SideNav selecting={selecting} />
        )
      ) : !toggleSideNav ? (
        ""
      ) : (
        <SideNav selecting={selecting} />
      )}

      {/* <div className={toggleSideNav ? "test-sidenav show" : "test-sidenav"}>
        <SideNav selecting={selecting} />
      </div> */}
    </nav>
  );
};
