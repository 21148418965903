export const MERCH_TEXT = {
  COLOR: {
    BLACK: "Black",
    WHITE: "White",
  },
  SIZE: {
    SIZE_50: 'The 50"',
    SIZE_54: 'The 54"',
  },
  IMAGE: {
    HOODIE_BLACK_BACK: `${process.env.PUBLIC_URL}/assets/redeemMerch/black-back-2325.png`,
    HOODIE_BLACK_FRONT: `${process.env.PUBLIC_URL}/assets/redeemMerch/black-front-2325.png`,
    HOODIE_WHITE_BACK: `${process.env.PUBLIC_URL}/assets/redeemMerch/white-back-2325.png`,
    HOODIE_WHITE_FRONT: `${process.env.PUBLIC_URL}/assets/redeemMerch/white-front-2325.png`,
    MERCH_INFO: `${process.env.PUBLIC_URL}/assets/redeemMerch/merch-info.png`,
    THE50: `${process.env.PUBLIC_URL}/assets/redeemMerch/the-50.png`,
    THE54: `${process.env.PUBLIC_URL}/assets/redeemMerch/the-54.png`,
    LOGO_MACH: `${process.env.PUBLIC_URL}/assets/logo/machinie_l.png`,
  },
};

const object = {
  id: "62c4d80aceb08b24c867eb21",
  userId: "6178e12e78cb3f1148685c51",
  toUserId: "",
  type: "hoodie_reward_test",
  addressId: "62c43a265d688726284325b6",
  address: {
    id: "62c43a265d688726284325b6",
    createdDate: "2022-07-05T13:18:30.157Z",
    updateDate: "2022-07-05T13:18:30.157Z",
    userId: "6178e12e78cb3f1148685c51",
    fullName: "Test2 Test2",
    phoneNumber: "Test2",
    subAdress: "Test2",
    address: "Test2",
    city: "Test2",
    state: "Test2",
    zip: "Test2",
    country: "Test2",
  },
  status: 0,
  user: {
    id: "6178e12e78cb3f1148685c51",
    createdDate: "2021-10-27T05:18:38.639Z",
    updateDate: "2022-05-30T12:03:29.504Z",
    walletAddress: "0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c",
    discordId: "Machiniverse",
    displayName: "Ma",
  },
  walletAddress: "0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c",
  details:
    'Black The 54" Machinie 0 0x714FdF665698837f2F31c57A3dB2Dd23a4Efe84c ',
};
