import humach from "../../../humach";
import web3 from "../../../web3";
import { api } from "../../../axiosURL";
import { useState, useEffect } from "react";
import { NoHumach } from "../MyHumach";
import { MyHumachChillingEach } from "./MyHumachChillingEach";

type MY_HUMACH_CHILLING_PROPS_TYPE = {
  humachChilling: any;
  setHumachList: any;
  setMachinieList: any;
  setWalletList: any;
  floppy: any;
  setFloppy: any;
};

export const MyHumachChillingPanel = ({
  humachChilling,
  setHumachList,
  setMachinieList,
  setWalletList,
  floppy,
  setFloppy,
}: MY_HUMACH_CHILLING_PROPS_TYPE) => {
  const [humachArrayForTrain, setHumachArrayForTrain] = useState([]);
  const [selectIdMoreToggle, setSelectIdMoreToggle] = useState<string>("");

  return (
    <div>
      {humachChilling.length > 0 ? (
        <div>
          <HumachEachTopSelectAllChilling
            humachArrayForTrain={humachArrayForTrain}
            setHumachArrayForTrain={setHumachArrayForTrain}
            humachChilling={humachChilling}
            setHumachList={setHumachList}
          />
          <div className="machinie-each-panel">
            {humachChilling.map((x: any) => (
              <MyHumachChillingEach
                humachId={x.humachNumber.toString()}
                humachArrayForTrain={humachArrayForTrain}
                setHumachArrayForTrain={setHumachArrayForTrain}
                setWalletList={setWalletList}
                humachListEach={x}
                setHumachList={setHumachList}
                selectIdMoreToggle={selectIdMoreToggle}
                setSelectIdMoreToggle={setSelectIdMoreToggle}
                floppy={floppy}
                setFloppy={setFloppy}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoHumach
          topic="No chilling Humach"
          text="Your chilling Humach will show up here."
          selectPage="HUMACH_CHILLING"
        />
      )}
    </div>
  );
};

type HUMACH_EACH_TOP_SELECT_ALL_CHILLING = {
  humachArrayForTrain: string[];
  setHumachArrayForTrain: any;
  humachChilling: any;
  setHumachList: any;
};

export const HumachEachTopSelectAllChilling = ({
  humachArrayForTrain,
  setHumachArrayForTrain,
  humachChilling,
  setHumachList,
}: HUMACH_EACH_TOP_SELECT_ALL_CHILLING) => {
  const selectAll = () => {
    setHumachArrayForTrain(
      humachChilling.map((x: any) => x.humachNumber.toString())
    );
  };

  const deselectAll = () => {
    setHumachArrayForTrain([]);
  };

  const trainSelectHumach = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      await humach.methods.stakeHumach(humachArrayForTrain).send({
        from: accounts[0],
      });

      const thisHumachList = await getHumachList();
      setHumachList(thisHumachList.data);
    } catch (err: any) {
      //console.log(err.message);
    }
  };

  const getHumachList = async () => {
    if (localStorage.getItem("accessToken")) {
      const res = await api.get("/api/humach/list", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      return res.data;
    }
  };

  return (
    <div className="machinie-chilling-top">
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => selectAll()}
      >
        SELECT ALL
      </div>
      <div
        className="select-all-button bold-button text-color-gray-100 cursor-pointer"
        onClick={() => deselectAll()}
      >
        DESELECT ALL
      </div>
      {humachArrayForTrain.length > 0 ? (
        <div
          className="stake-selected-all-button bold-button text-color-gray-100 cursor-pointer"
          onClick={() => trainSelectHumach()}
        >
          TRAIN SELECTED
        </div>
      ) : (
        <div className="stake-select-all-button bold-button text-color-gray-100 cursor-pointer">
          TRAIN SELECTED
        </div>
      )}
    </div>
  );
};
